define('msgme/views/channel',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/util/format',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/channel',
    'msgme/viewmodel/profile',
    'msgme/views/View',
    'json!widgets/shared-strings.json'
], function (
    _,
    splash,
    path,
    ko,
    format,
    viewmodel,
    mapping,
    channelModel,
    profileModel,
    View,
    sharedStrings
) {
    var view = new View('#channel-view');
    var url = sharedStrings.sitemap.channels.url;
    viewmodel.channels = ko.observableArray();

    function showSplashScreen() {
        view.root.find('.splash-screen').show();
    }

    function populate(channelProfiles, channelName) {
        var channelRecordModel;

        if (!channelProfiles) {
            return showSplashScreen();
        }

        viewmodel.channels(_.map(viewmodel.globals.channels(),
            function (channel) {
                if (channel.name === channelName) {
                    channelRecordModel =
                        new mapping.RecordModel(channel, channelModel);

                    return channelRecordModel;
                } else {
                    return new mapping.RecordModel(channel, channelModel);
                }
            }));

        channelRecordModel.profiles(_.map(channelProfiles, function (profile) {
            return new mapping.RecordModel(profile, profileModel);
        }));

        viewmodel.channel(channelRecordModel);

        splash.hide();
        $('#channel-view-accounts').removeClass('hidden');
    }

    view.root.on('click', '.add-profile-link', function () {
        var CURRENT_URL = encodeURIComponent(location.href);

        window.location = window.location.origin + '/api/v2/channel/' +
            viewmodel.channel().name() + '/authorize?successURL=' +
            CURRENT_URL + '&failURL=' + CURRENT_URL;
    });

    view.root.on('click', '.profile-delete', function () {
        var $this = $(this);
        var entryEl = $this.closest('.profile');
        var index = entryEl.index();
        var record = viewmodel.channel().profiles()[index];

        record.del().
            done(function () {
                viewmodel.channel().profiles.splice(index, 1);
            }).fail(view.getRequestFailureFn(null, 'channel.profile.delete'));
    });

    path.map(url + '(/:channel)').to(function () {
        var channelName = this.params.channel;

        if (!channelName) {
            path.history.replaceState(null, null, url + '/facebook');
            channelName = 'facebook';
        }

        $('#channel-view-accounts').addClass('hidden');
        view.show();

        viewmodel.globals.channels.refresh().
            then(function () {
                var channel = viewmodel.globals.channels.oneByName(channelName);
                return channel ? channel.profiles.fetch() : null;
            }).
            done(function (profiles) {
                populate(profiles, channelName);
            }).
            fail(view.getRequestFailureFn(null, 'channels.refresh'));
    });

    return view;
});


