define('widgets/ads-sponsorships/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    './../three-widget',
    'json!./strings.json',
    'text!./template.html',
    'json!widgets/shared-strings.json'
], function (_, viewmodel, ThreeWidget, strings, template, sharedStrings) {

    var passedObservables = {
        ad: null,
        sponsorship: null,
        isDisabled: false
    };

    var internalObservables = {
        select2DropdownCssClass: 'dynamic-insertion-dropdown',
        type: '',
        setAll: false
    };

    var allObservables = _.extend({}, passedObservables, internalObservables);

    var mapping = {
        defaults: _.clone(allObservables),
        computed: {
            isComponent: function () {
                return window.location.pathname.
                    indexOf(sharedStrings.sitemap.flows.url) !== -1;
            },

            hasAdOrSponsor: function () {
                return this.ad() || this.sponsorship();
            }
        }
    };

    $.widget('msgme.msgme_ads_sponsorships', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            this._initLocals(this.options);
            this._prepareModal();
            this.on('click', '.open-modal', '_openModal');
            this.on('click', '.set-type', '_onSetType');
            this.on('click', 'button.ok', '_onAdd');
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        },

        _initLocals: function () {
            this._$modal = this.element.find('.msgme-modal');
            this._$dropdown = this._$modal.find('.modal-body select');
        },

        _prepareModal: function () {
            this._$modal.msgme_modal({});
        },

        _openModal: function () {
            var vm = this.option('viewmodel');

            this._$modal.msgme_modal('open');
            vm.setAll(null);

            if (vm.sponsorship()) {
                vm.type('Sponsorship');
            } else {
                vm.type('Ad');
            }
        },

        _onSetType: function (e) {
            var vm = this.option('viewmodel');

            vm.ad(null);
            vm.sponsorship(null);
            vm.type(e.target.value);
        },

        _onAdd: function (e) {
            var vm = this.option('viewmodel');
            var ad = vm.ad();
            var sponsorship = vm.sponsorship();
            var index;
            var module;

            if (vm.setAll()) {
                index = $(e.target.closest('.msgme-flowmodule')).
                    attr('data-index');
                module = viewmodel.flow().modules()[index]();

                if (ad) {
                    if (module.type() === 'BASICTEXT') {
                        module.params.ad(ad);
                        module.params.sponsorship(null);
                    }
                    if (module.type() === 'DATEGATE') {
                        module.params.preDateAd(ad);
                        module.params.postDateAd(ad);
                        module.params.preDateSponsorship(null);
                        module.params.postDateSponsorship(null);
                    }
                    if (module.type() === 'AGEGATE') {
                        module.params.ageRequestAd(ad);
                        module.params.invalidFormatAd(ad);
                        module.params.notOfAgeAd(ad);
                        module.params.ageRequestSponsorship(null);
                        module.params.invalidFormatSponsorship(null);
                        module.params.notOfAgeSponsorship(null);
                    }
                    if (module.type() === 'COLLECTMETADATA') {
                        module.params.requestAd(ad);
                        module.params.responseAd(ad);
                        module.params.invalidFormatAd(ad);
                        module.params.requestSponsorship(null);
                        module.params.responseSponsorship(null);
                        module.params.invalidFormatSponsorship(null);
                    }
                    if (module.type() === 'POLL') {
                        _.each(module.params.choices(), function (choice) {
                            choice.ad(ad);
                            choice.sponsorship(null);
                        });
                        module.params.pollQuestionAd(ad);
                        module.params.invalidAnswerAd(ad);
                        module.params.pollQuestionSponsorship(null);
                        module.params.invalidAnswerSponsorship(null);
                    }
                    if (module.type() === 'SUBSCRIPTION') {
                        module.params.confirmAd(ad);
                        module.params.subscribedAd(ad);
                        module.params.confirmSponsorship(null);
                        module.params.subscribedSponsorship(null);
                    }
                    if (module.type() === 'SWEEPS') {
                        module.params.preSweepsAd(ad);
                        module.params.postSweepsAd(ad);
                        module.params.alreadyEnteredAd(ad);
                        module.params.ad(ad);
                        module.params.preSweepsSponsorship(null);
                        module.params.postSweepsSponsorship(null);
                        module.params.alreadyEnteredSponsorship(null);
                        module.params.sponsorship(null);
                    }
                    if (module.type() === 'TEXTTOWIN') {
                        _.each(module.params.winnerGroups(),
                            function (group) {
                            group.ad(ad);
                            group.sponsorship(null);
                        });
                        module.params.duplicateEntryAd(ad);
                        module.params.preFlightAd(ad);
                        module.params.defaultAd(ad);
                        module.params.postFlightAd(ad);
                        module.params.duplicateEntrySponsorship(null);
                        module.params.preFlightSponsorship(null);
                        module.params.defaultSponsorship(null);
                        module.params.postFlightSponsorship(null);
                    }
                    if (module.type() === 'VALIDATION') {
                        module.params.consequentAd(ad);
                        module.params.alternativeAd(ad);
                        module.params.consequentSponsorship(null);
                        module.params.alternativeSponsorship(null);
                    }
                } else if (sponsorship) {
                    if (module.type() === 'BASICTEXT') {
                        module.params.ad(null);
                        module.params.sponsorship(sponsorship);
                    }
                    if (module.type() === 'DATEGATE') {
                        module.params.preDateAd(null);
                        module.params.postDateAd(null);
                        module.params.preDateSponsorship(sponsorship);
                        module.params.postDateSponsorship(sponsorship);
                    }
                    if (module.type() === 'AGEGATE') {
                        module.params.ageRequestAd(null);
                        module.params.invalidFormatAd(null);
                        module.params.notOfAgeAd(null);
                        module.params.ageRequestSponsorship(sponsorship);
                        module.params.invalidFormatSponsorship(sponsorship);
                        module.params.notOfAgeSponsorship(sponsorship);
                    }
                    if (module.type() === 'COLLECTMETADATA') {
                        module.params.requestAd(null);
                        module.params.responseAd(null);
                        module.params.invalidFormatAd(null);
                        module.params.requestSponsorship(sponsorship);
                        module.params.responseSponsorship(sponsorship);
                        module.params.invalidFormatSponsorship(sponsorship);
                    }
                    if (module.type() === 'POLL') {
                        _.each(module.params.choices(), function (choice) {
                            choice.ad(null);
                            choice.sponsorship(sponsorship);
                        });
                        module.params.pollQuestionAd(null);
                        module.params.invalidAnswerAd(null);
                        module.params.pollQuestionSponsorship(sponsorship);
                        module.params.invalidAnswerSponsorship(sponsorship);
                    }
                    if (module.type() === 'SUBSCRIPTION') {
                        module.params.confirmAd(null);
                        module.params.subscribedAd(null);
                        module.params.confirmSponsorship(sponsorship);
                        module.params.subscribedSponsorship(sponsorship);
                    }
                    if (module.type() === 'SWEEPS') {
                        module.params.preSweepsAd(null);
                        module.params.postSweepsAd(null);
                        module.params.alreadyEnteredAd(null);
                        module.params.ad(null);
                        module.params.preSweepsSponsorship(sponsorship);
                        module.params.postSweepsSponsorship(sponsorship);
                        module.params.alreadyEnteredSponsorship(sponsorship);
                        module.params.sponsorship(sponsorship);
                    }
                    if (module.type() === 'TEXTTOWIN') {
                        _.each(module.params.winnerGroups(),
                            function (group) {
                            group.ad(null);
                            group.sponsorship(sponsorship);
                        });
                        module.params.duplicateEntryAd(null);
                        module.params.preFlightAd(null);
                        module.params.defaultAd(null);
                        module.params.postFlightAd(null);
                        module.params.duplicateEntrySponsorship(sponsorship);
                        module.params.preFlightSponsorship(sponsorship);
                        module.params.defaultSponsorship(sponsorship);
                        module.params.postFlightSponsorship(sponsorship);
                    }
                    if (module.type() === 'VALIDATION') {
                        module.params.consequentAd(null);
                        module.params.alternativeAd(null);
                        module.params.consequentSponsorship(sponsorship);
                        module.params.alternativeSponsorship(sponsorship);
                    }
                }
            }
        }
    });

    return {
        widget: $.msgme.msgme_ads_sponsorships,
        mapping: mapping
    };
});

