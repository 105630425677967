define('msgme/util/promises',[
    'jquery',
    'msgme/underscore'
], function ($, _) {
    function sequence() {
        var d = new $.Deferred();
        var tasks = _.toArray(arguments).reverse();

        function onFailure() {
            d.reject.apply(d, arguments);
        }

        function nextTask() {
            if (tasks.length > 0) {
                // TODO: allow tasks to be either fns or
                // { task: fn, context: obj } objects
                tasks.pop().apply(null, arguments).
                    done(nextTask).
                    fail(onFailure);
            } else {
                d.resolve.apply(d, arguments);
            }
        }

        nextTask();

        return d.promise();
    }

    return {
        sequence: sequence
    };
});

