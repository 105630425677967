define('msgme/views/flows',[
    'msgme/underscore',
    'msgme/path',
    'msgme/splash',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/flow',
    'msgme/views/View',
    'msgme/widgets/pager',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
    'msgme/plugins'
],
function (
    _,
    path,
    splash,
    ko,
    viewmodel,
    flow,
    View,
    Pager,
    featureFlags,
    sharedStrings
) {
    var view = new View('#flows-view');
    var pager = new Pager(flow.collection);
    var url = sharedStrings.sitemap.flows.url;
    var cachedCampaigns;
    var currentPage;
    flow.collection.noResults = ko.observable(false);
    flow.collection.hasCampaignActivity = ko.observable(false);

    function onFlowsSuccess(result) {
        flow.collection.rows(result.slice());
        pager.update(result);
        $('#flows-view-main .table').msgme('busy', false);
        $('#flows-view-empty').removeClass('hidden');
        splash.hide();
    }

    function fetchFlows(page) {
        page = page && _.isNumber(parseInt(page, 10)) ? page : currentPage;
        $('#flows-view-main .table').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !flow.collection.rows().length) {
            if (!flow.collection.pageSize() || !flow.collection.pageIndex() ||
                !flow.collection.pageCount()) {
                resetViewmodelCollection();
            }
        
            view.callAPI('mobileflows.fetch', {
                    page: page,
                    size: flow.collection.pageSize
                }).
                done(function (result) {
                    cachedCampaigns = result;
                    onFlowsSuccess(result);
                });
        } else {
            // fetch current page for cache
            view.callAPI('mobileflows.fetch', {
                    page: page,
                    size: 15
                }).
                done(function (result) {
                    cachedCampaigns = result;
                    $('#flows-view-main .table').msgme('busy', false);
                });
        }

        viewmodel.globals.flows.refresh(true).done(function () {
            instantiateSearchBox();
        });
    }

    function resetViewmodelCollection() {
        flow.collection.pageSize(15);
        flow.collection.pageCount(-1);
        flow.collection.pageIndex(-1);
        flow.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var flowNames = _.map(viewmodel.globals.flows(), function (flow) {
            return flow.name;
        });
        var $searchBoxEl = view.root.find('.search-box');

        if (!!$searchBoxEl.hasOwnProperty('autocomplete')) {
            $searchBoxEl.autocomplete('destroy');
        }

        $searchBoxEl.autocomplete({
            source: flowNames,
            delay: 100
        });
    }

    // TODO: refactor this as functionality on the datatable
    $('#flows-view').on('click', 'td i.fa.fa-remove', function () {
        var rowEl = $(this).closest('tr');
        var record = flow.collection.rows()[rowEl.index()];
        var campaignName = record.name;

        // super hacky way to insert html into the modal body because the
        // msgme.modal.confirm only takes in a string
        _.defer(function () {
            $('#msgme-modal .modal-body').html('<div>' +
                'Are you sure you want to delete campaign ' +
                campaignName + '?' + '</div><div>' +
                sharedStrings.flow.delete + '</div><div>' +
                sharedStrings.flow.deleteConfirm + '</div><br>');
        });
        msgme.modal.confirm(sharedStrings.flow.delete, {
            title: 'Delete Campaign'
        }).done(_.bind(function (value) {
            if (value) {
                rowEl.addClass('deleting');
                view.callAPI('keywords.fetch', { mobileFlow: record.id }).
                    done(function (keywords) {
                        var promises;

                        if (keywords.length) {
                            promises = _.map(keywords, function (keyword) {
                                return view.callAPI(
                                    'keywords.delete', keyword.id);
                            });

                            promises.push(record.del());
                        } else {
                            promises = [record.del()];

                        }
                        
                        $.when.apply($, promises).
                            done(function () {
                                flow.collection.rows.splice(rowEl.index(), 1);
                                msgme.alert.success(
                                    'The campaign has been deleted');
                                fetchFlows();
                            }).
                            fail(view.getRequestFailureFn(null,
                                'keywords.delete',
                                keywords));
                    });
            }
        }, this));
    });

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        flow.collection.noResults(false);
        if (!query) {
            onFlowsSuccess(cachedCampaigns);
        } else {
            results = _.map(viewmodel.globals.flows(), function (flow) {
                if(flow.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return flow;
                }
            });

            onFlowsSuccess(_.compact(results));

            if (!_.compact(results).length) {
                flow.collection.noResults(true);
            }
        }

        view.root.find('.search-box').autocomplete('close');
    });

    view.root.on('click', '.search-flow', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        onFlowsSuccess(cachedCampaigns);
        view.root.find('.search-box').val('');
        flow.collection.noResults(false);
    });

    path.map('/flows(/page/:page)').to(function () {
        var page = this.params.page ? '/page/' + this.params.page : '';
        path.history.replaceState(null, null, url + page);
    });

    path.map(url + '(/page/:page)').to(function () {
        featureFlags('marketronFeatures').then(function () {
            flow.collection.hasCampaignActivity(true);
        });

        currentPage = this.params.page;

        if (!flow.collection.pageSize() || !flow.collection.pageIndex() ||
            !flow.collection.pageCount() || !this.params.page) {
            resetViewmodelCollection();
        }
        
        view.show();
        viewmodel.globals.flows.refresh(true);
        fetchFlows(this.params.page);
    });
    return view;
});


