define('widgets/likes-stat-card/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    './../stat-card/index',
    'text!./template.html'
], function (_, ko, viewmodel, statCard, template) {
    $.widget('msgme.msgme_likes_stat_card', statCard.widget, {
        _mapping: {
            defaults: {
                fetchPromise: null,
                profilesCount: null,
                audienceCount: null,
            },

            computed: {
                hasFB: function () {
                    return !!viewmodel.globals.channels.oneByName('facebook');
                },

                pending: function () {
                    var fetchPromise = this.fetchPromise();

                    return this.hasFB() &&
                        fetchPromise && fetchPromise.state() === 'pending';
                },

                status: function () {
                    var pending = this.pending();
                    var profiles = this.profilesCount();
                    var audiences = this.audienceCount();

                    if (!this.hasFB()) {
                        return 'NO_FB';
                    } else if (pending) {
                        return 'PENDING';
                    } else if (!profiles) {
                        return 'NO_PROFILES';
                    } else if (!audiences) {
                        return 'NO_AUDIENCES';
                    } else {
                        return 'READY';
                    }
                }
            }
        },

        _template: template,

        _createViewModel: function () {
            var vm = this._super.apply(this, arguments);

            vm.data(ko.mapping.fromJS({
                type: 'likes',
                count: null
            }));

            return vm;
        },

        _loadData: function () {
            var vm = this.option('viewmodel');
            var channel = viewmodel.globals.channels.oneByName('facebook');
            var fetchPromise;

            if (!vm.hasFB()) {
                return $.Deferred().resolve().promise();
            }

            fetchPromise = $.when(
                    channel.profiles.fetch(),
                    channel.audiences.fetch()
                ).then(function (profiles, audiences) {
                    var sizes = _.pluck(audiences, 'size');
                    var count = _.reduce(sizes, function (count, size) {
                        return count + size;
                    }, 0);

                    vm.data().count(count);
                    vm.profilesCount(profiles.length);
                    vm.audienceCount(audiences.length);
                }).always(function () {
                    // because the fetchPromise observable doesn't update
                    // when the state changes, you need to manually update
                    // the observable with a resolved state or else the
                    // pending computed doesn't update
                    vm.fetchPromise($.Deferred().resolve().promise());
                });

            vm.fetchPromise(fetchPromise);
            return fetchPromise;
        }
    });

    return {
        widget: $.msgme.msgme_likes_stat_card,
        mapping: statCard.mapping
    };
});

