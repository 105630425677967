define('msgme/views/uploads',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/uploads',
    'msgme/views/View',
    'msgme/util/api',
    'msgme/widgets/pager',
    'msgme/util/format',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
    'msgme/plugins'
],
function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    uploads,
    View,
    api,
    Pager,
    format,
    featureFlags,
    sharedStrings
) {
    var view = new View('#uploads-view');
    var page, pager;

    pager = new Pager(uploads.collection);
    uploads.collection.listId = ko.observable();

    function onFetchSuccess(uploadInstances) {
        var groups = [];

        _.each(uploadInstances, function (upload) {
            groups = [];
            _.each(upload.groups, function (group) {
                var hasGroup = viewmodel.globals.groups.oneById(group);
                
                if (hasGroup) {
                    groups.push(hasGroup.name);
                }
            });
            upload.group = groups.join(', ');
        });

        uploads.collection.rows(uploadInstances.slice());
        pager.update(uploadInstances);

        splash.hide();
        $('#uploads-view-main').msgme('busy', false);
        $('#uploads-view-empty').removeClass('hidden');
    }

    function onFailure(what, e) {
        console.error('Failed to fetch', what + ': ', e);
    }

    function fetch() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#uploads-view-main').msgme('busy', true);

        // fetch the lists
        view.callAPI('uploads.fetch', {
                page: page,
                size: uploads.collection.pageSize
            }).
            done(onFetchSuccess).
            fail(_.bind(onFailure, this, 'uploads'));
    }

    /**
     * Initialize the upload list modal
     */
    view.root.find('.msgme-modal.upload-list-modal').msgme_modal({
        okEnabled: false,
        submit: function () {
            var input = $('.upload-list-modal input[type="file"]');
            var filepath = input.val();
            var fileName = filepath.slice(filepath.lastIndexOf('\\') + 1);
            view.root.find('.upload-list').ajaxSubmit({
                data: {
                    fileName: fileName
                },
                success: function () {
                    fetch();
                },
                error: (function () {
                    var failureFn = api.getRequestFailureFn(null, 'file');
                    return function () {
                        failureFn.call(this, arguments);
                        path.history.pushState(null, null,
                            sharedStrings.sitemap.uploads.url);
                    };
                }())
            });
        }
    });

    view.root.on('click', '.btn.upload', function () {
        view.root.find('.msgme-modal.upload-list-modal').msgme_modal('open');
        view.root.find('.upload-input').val('');
        view.root.find('.filename').val('');
    });

    view.root.on('click', '.btn.browse', function () {
        view.root.find('.upload-input').trigger('click');
    });

    view.root.on('change', '.upload-input', function () {
        var filepath = view.root.find('.upload-input').val();
        view.root.find('.filename').val(
            filepath.slice(filepath.lastIndexOf('\\') + 1));
        view.root.find('.msgme-modal.upload-list-modal').
          msgme_modal('option', 'okEnabled', true);
    });

    path.map(sharedStrings.sitemap.uploads.url + '(/page/:page)').
        to(function () {
            view.show();
            page = this.params.page;
            fetch();
        });

    return view;
});

