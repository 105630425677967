define('msgme/views/messages',[
    'msgme/underscore',
    'msgme/alert',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'lib/moment-timezone',
    'msgme/util/format',
    'msgme/util/api',
    'msgme/util/feature-flags',
    'msgme/viewmodel',
    'msgme/viewmodel/message',
    'msgme/viewmodel/mapping',
    'msgme/views/View',
    'msgme/widgets/pager',
    'widgets/calendar/index',
    'json!widgets/shared-strings.json',
    'msgme/plugins'
],
function (
    _,
    alert,
    splash,
    path,
    ko,
    moment,
    format,
    api,
    featureFlags,
    viewmodel,
    message,
    mapping,
    View,
    Pager,
    calendar,
    sharedStrings
) {
    var view = new View('#messages-view');
    var pager;
    var url = sharedStrings.sitemap.scheduled_messages.url;
    var cm = new mapping.CursorModel(waterfall.broadcasts, {
        record : {mapping: message.mapping}
    });
    var $modal = $('#messages-view .preview-modal').
        modal({show: false}).
        on('click', '.done', function () {
            $modal.modal('hide');
        });
    var $calendar;
    var loadingInterval;
    var messages = viewmodel.messages;
    var $testMessageModal =
        $('#messages-view .msgme-modal.test-message').msgme_modal();
    var timezones = ['Pacific', 'Mountain', 'Central', 'Eastern', 'Atlantic'];

    pager = new Pager(message.collection);

    messages.unmodifiedRows = ko.observable();
    messages.currentMessage = ko.observable();
    messages.numbers = ko.observable('');
    messages.selectedSlice = ko.observable();
    messages.slices = ko.observable();

    messages.numbers.extend({
        validation: {
            validator: function () {
                var numbers = messages.numbers().split(',');
                var isValid = true;

                _.each(numbers, function (number) {
                    if (!format.findPhoneNo(number)) {
                        isValid = false;
                        return;
                    }
                });

                return isValid;
            },

            message: 'One or more numbers are invalid'
        }
    });

    function onMessagesSuccess(result, options) {
        messages.unmodifiedRows(ko.mapping.toJS(result.rows));
        // here we're translating the from the viewmodel/message.js mapping to
        // something the table template can use. there are 2 approaches (that i
        // can think of) to laying out this table:
        //
        //  - for each broadcast, make a sub-table, which would consist of the
        //  'destination', 'segment', 'content', and 'recipients' columns from
        //  the actual table
        //
        //  - translate the broadcasts into a flat list of rows, where certain
        //  cells correspond to multiple rows (i.e. each 'schedule' cell
        //  corresponds to every row in the broadcast). use 'rowspan' to get
        //  the correct appearance for these rows.
        //
        // the sub-table approach is better because the html more closely
        // matches the viewmodel/message.js mapping, so less data munging is
        // required. things like zebra-striping the table (based on broadcast)
        // work out of the box.
        //
        // the thing is there are only two columns of the table that we want
        // the browser to dynamically size for us: the audiences, and the
        // content. since both of these are buried in the broadcast, these
        // columns' widths would be specific to the sub-table. this is a
        // problem because we want those columns to be both (a) dynamically
        // sized based on the content and (b) uniform across broadcasts (not to
        // mention the outer table's headers need to match the width).
        //
        // the process of translating the nested viewmodel/message.js data to a
        // flat list of rows is error prone and ugly, but in my experience
        // there's nothing worse than trying to dynamically set width based on
        // content, and we should leave that up to the browser whenever
        // possible. so we're going with the flat-list-of-rows approach.
        //
        var rows = [];
        _.each(result.rows(), function (broadcast, broadcastIndex) {
            _.each(broadcast.content(), function (content, contentIndex) {
                var slices = content.slices();

                if (slices.length === 0) {
                    slices = [content];
                }

                _.each(slices, function (slice, sliceIndex) {
                    var channel = content.channel();
                    var o = {
                        cssClass: broadcastIndex % 2 === 0 ? 'zebra' : '',
                        message: slice.message(),
                        subject: slice.subject(),
                        files: slice.files(),
                        mobileFlow: slice.mobileFlowName ?
                            slice.mobileFlowName() : ''
                    };

                    // some cells, like the date the broadcast was scheduled,
                    // span all the rows of the broadcast, so they only need to
                    // be added if we're dealing with the first row of the
                    // broadcast. we know this is the case if the contentIndex
                    // and sliceIndex are both 0.
                    if (contentIndex === 0 && sliceIndex === 0) {
                        if (broadcast.timezoneStatus() !== 'OFF') {
                            o.schedule = moment(broadcast.sendAt()).
                                tz(moment.tz.guess()).format('L LT') + ' LOCAL';
                        } else {
                            o.schedule = moment(broadcast.sendAt()).
                                tz(moment.tz.guess()).format('L LT z');
                        }
                        o.scheduleRowSpan = _.reduce(broadcast.content(),
                            function (rowSpan, content) {
                                return rowSpan +
                                    (content.slices().length || 1);
                            }, 0);
                        o.id = broadcast.id();
                    }

                    // similarly, cells like the audience span every slice, so
                    // they only need to be added if we're on the first row of
                    // the given content item. we know this is the case if
                    // sliceIndex is 0.
                    if (sliceIndex === 0) {
                        if (_.isEmpty(content.audience())) {
                            o.audience = content.embeddedAudience();
                        } else {
                            o.audience = content.audience();
                        }
                        o.segment = channel !== 'sms' ?
                            '--' :
                            (content.slices().length > 1 ?
                                sharedStrings.yes : sharedStrings.no);
                        o.audienceRowSpan = slices.length;
                        o.iconClass = {
                                sms: 'fa fa-list-ul',
                                twitter: 'fa fa-twitter',
                                facebook: 'fa fa-facebook'
                            }[channel];
                        o.broadcastStatus = broadcast.broadcastStatus &&
                            broadcast.broadcastStatus();
                        if (o.broadcastStatus) {
                            o.broadcastStatus =
                                o.broadcastStatus.charAt(0).toUpperCase() +
                                o.broadcastStatus.substr(1).toLowerCase();
                        }

                        if (o.broadcastStatus === 'Started') {
                            o.broadcastStatus = 'In Progress';
                        }
                    }
                    o.percentage = slice.percentage && slice.percentage();

                    o.value = slice.value && slice.value();

                    o.recipients = slice.recipients ?
                        slice.recipients() : content.recipients();

                    if (!options.enableCount && o.recipients === 0) {
                        o.recipients = '...';
                    }

                    o.image = content.image() ?
                        waterfall.file.url + '/' + content.image() : null;

                    o.files = _.each(o.files, function (file, i) {
                        o.files[i] = waterfall.file.url + '/' + file;
                    });

                    if (slice.ad() && viewmodel.globals.ads()) {
                        if (viewmodel.globals.ads.oneById(slice.ad())) {
                            o.ad = 'Ad: ' + viewmodel.globals.ads.
                                oneById(slice.ad()).adCopy;
                        } else {
                            o.ad = 'Expired ad: ' + viewmodel.globals.
                                expiredAds.oneById(slice.ad()).adCopy;
                        }
                    } else {
                        o.ad = '';
                    }

                    if (slice.sponsorship() &&
                        viewmodel.globals.sponsorships()) {
                        if (viewmodel.globals.sponsorships.
                            oneById(slice.sponsorship())) {
                            o.sponsorship = 'Sponsored by: ' +
                                viewmodel.globals.sponsorships.oneById(
                                    slice.sponsorship()).name;
                        } else {
                            o.sponsorship = 'Expired sponsored by: ' +
                                viewmodel.globals.
                                expiredSponsorships.oneById(
                                    slice.sponsorship()).name;
                        }
                    } else {
                        o.sponsorship = '';
                    }

                    o.recipients = format.integer(o.recipients);

                    rows.push(o);
                });
            });
        });

        var recipients = _.filter(rows, function (row) {
            return isNaN(row.recipients.replace(/,/g, ''));
        });

        if (!_.isEmpty(recipients) && !options.enableCount) {
            fetchMessages(options.page, options.disableBusy, true);
        }

        message.collection.rows(rows);
        pager.update(ko.mapping.toJS(result));

        splash.hide();

        if (!options.enableCount) {
            setListViewVisible(true);
            view.root.find('.view-toggle').msgme('busy', false);
            $('#messages-view-main').msgme('busy', false);
            $('#messages-view-empty').removeClass('hidden');
        }
    }

    function fetchMessages(page, disableBusy, enableCount) {
        var count = enableCount ? true : false;
        var options = {
            page: page,
            disableBusy: disableBusy,
            enableCount: count
        };

        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        if (!disableBusy) {
            $('#messages-view-main').msgme('busy', true);
        }

        cm.fetch({
                page: page,
                size: message.collection.pageSize(),
                count: count
            }).
            done(function (result) {
                onMessagesSuccess(result, options);
            });
    }

    // TODO: refactor this as functionality on the datatable
    $('#messages-view').on('click', 'td .remove', function () {
        var id = $(this).parent().attr('data-broadcast');
        var rowEl = $(this).closest('tr');
        var recordModel = _.find(cm.rows(), function (recordModel) {
            return recordModel.id() === id;
        });
        var confirmText;
        var timezoneSlices;
        var remainingTimezones;

        if (recordModel.broadcastStatus() !== 'STARTED') {
            confirmText = 'Are you sure you want to cancel this broadcast?';
        } else {
            timezoneSlices = _.map(recordModel.timzoneSlices(),
                function (slice) {
                return slice.timezone();
            });

            remainingTimezones = _.difference(timezones, timezoneSlices);

            if (remainingTimezones.length) {
                remainingTimezones = remainingTimezones.join(', ');
            }
            confirmText = 'Are you sure you want to cancel the remainder' +
                'of this broadcast? Broadcast for subscribers in ' +
                remainingTimezones + ' timezones will be cancelled';
        }

        msgme.modal.confirm(confirmText, {
            title: 'Cancel Broadcast'
        }).done(_.bind(function (confirmed) {
            if (confirmed) {
                rowEl.addClass('deleting');
                if (recordModel.timezoneStatus() === 'ON_OLDERS' ||
                   recordModel.timezoneStatus() === 'ON_IGNORE') {
                    api.call('broadcasts.state', {
                        id: recordModel.id(),
                        state: 'CANCEL'
                    }).
                    done(function () {
                        fetchMessages();
                        msgme.alert.success('The message has been deleted');
                    }).
                    fail(function () { rowEl.removeClass('deleting'); }).
                    fail(view.getRequestFailureFn(null,
                        'message.delete',
                        recordModel.id()));
                } else {
                    recordModel.del().
                        done(function () {
                            fetchMessages();
                            msgme.alert.success('The message has been deleted');
                        }).
                        fail(function () { rowEl.removeClass('deleting'); }).
                        fail(view.getRequestFailureFn(null,
                            'message.delete',
                            recordModel.id()));
                }
            }
        }, this));
    });

    $('#messages-view-main').on('click', 'a.preview', function (evt) {
        var src = $(this).attr('href');
        var fileId = _.last(src.split('/'));

        evt.preventDefault();
        api.call('fileV2.fetch', fileId + '/details').
            done(function (file) {
                if (_.first(file.contentType.split('/')) === 'image') {
                    $modal.find('img').attr('src', src);
                } else if (_.first(file.contentType.split('/')) === 'video') {
                    $modal.find('video').attr({
                        src: src,
                        controls: 'controls'
                    });
                } else if (_.first(file.contentType.split('/')) === 'audio') {
                    $modal.find('audio').attr({
                        src: src,
                        controls: 'controls'
                    });
                }

                $modal.modal('show');
            }).
            fail(api.getRequestFailureFn(null, 'fileV2.fetch', fileId));
    });

    $('#messages-view').on('hide', '.modal', function (){
        $modal.find('img').removeAttr('src');
        $modal.find('video').removeAttr('src controls');
        $modal.find('audio').removeAttr('src controls');
    });

    $('#messages-view').on('click', '.btn.send', function () {
        var slice = messages.currentMessage().slices[messages.selectedSlice()];
        var msisdnifyNumbers = _.map(messages.numbers().split(','),
            function (num) {
            return format.msisdn(num);
        });
        var payload = {
            msisdns: msisdnifyNumbers
        };

        if (!messages.numbers.isValid()) {
            messages.numbers.isModified(true);
            return;
        }
        
        var message = slice.message;
        var mobileFlow = slice.mobileFlow;
        var subject = slice.subject;
        var files = slice.files;

        var isNoMessage = !message && !subject && !files && !mobileFlow;
        var isMobileFlow = !isNoMessage && mobileFlow;
        var isMMS = !isNoMessage && !isMobileFlow && (subject || files);
        var isSMS = !isNoMessage && !isMobileFlow && !isMMS && message;

        if (isNoMessage) {
            payload.message = '';
        } else if (isMobileFlow) {
            payload.mobileFlow = mobileFlow;
        } else if (isSMS || isMMS) {
            payload.message = message;
            payload.subject = subject;
            payload.files = files;
        } else {
            console.warn(sharedStrings.message.unknownMessageType);
        }

        api.call('messaging.send', payload).
            done(function () {
                msgme.alert.success('Test message has been sent');
            }).
            fail(api.getRequestFailureFn(null, 'messaging.send', payload));
    });

    $('#messages-view-main').on('click', 'i.test-message', function () {
        var id = $(this).parent().attr('data-broadcast');
        var content;
        var slices;
        var row  = _.find(messages.unmodifiedRows(), function (row) {
            return row.id === id;
        });

        content = _.find(row.content, function (content) {
            return content.channel === 'sms';
        });

        messages.numbers('');
        messages.numbers.isModified(false);
        messages.currentMessage(content);
        if (messages.currentMessage().slices.length === 1) {
            messages.selectedSlice(0);
        }

        slices = _.map(messages.currentMessage().slices,
            function (slice, index) {
                return {
                    text: _.sprintf('Segment %s - %s%%',
                        index + 1, slice.percentage),
                    value: index
                };
            });
        messages.slices(slices);

        $testMessageModal.msgme_modal('open');
    });

    function setViewVisible($view, $toggle, isVisible) {
        if (isVisible) {
            $view.removeClass('hidden');
            $toggle.addClass('active');
        } else {
            $view.addClass('hidden');
            $toggle.removeClass('active');
        }
    }

    var setCalendarViewVisible =
        _.partial(setViewVisible, view.root.find('.calendar-view'),
                  view.root.find('.show-calendar-view'));

    var setListViewVisible =
        _.partial(setViewVisible, view.root.find('.list-view'),
                  view.root.find('.show-list-view'));

    var i = 0;
    loadingInterval = setInterval(function() {
        i = ++i % 4;
        _.each(message.collection.rows(), function (row) {
            if (isNaN(row.recipients.replace(/,/g, ''))) {
                row.recipients = '' + new Array(i+2).join('.');
                message.collection.rows.valueHasMutated();
            }
        });
    }, 500);

    path.map(url + '/calendar').to(function () {
        view.show();
        splash.hide();
        setListViewVisible(false);
        setCalendarViewVisible(true);

        if (!$calendar) {
            _.defer(function() {
                $calendar = view.root.find('.calendar-view').msgme_calendar();
            });
        } else {
            $calendar.msgme_calendar('refresh');
        }
    });

    path.map(url + '(/page/:page)').to(function () {
        var page = this.params.page;
        view.show();
        view.root.find('.view-toggle').msgme('busy', true);
        setCalendarViewVisible(false);
        if (view.root.find('.list-view').hasClass('hidden')) {
            splash.show();
        }
        fetchMessages(page);
    });

    return view;
});

