define('msgme/viewmodel/list',[
    'msgme/ko',
    'msgme/viewmodel'
], function(ko, viewmodel) {
    var model = {};
    model.list = ko.observable(null);
    model.duplicateName = ko.observable(false);

    // Don't bother using mapping for subscribers since it's one-way (not saved
    // back)
    model.subscribers = {
        rows: ko.observableArray(),
        pageIndex: ko.observable(-1),
        pageCount: ko.observable(-1),
        pageSize: ko.observable(15),
        links: ko.observableArray(),
        url: ko.observable(null)
    };

    return {
        model: viewmodel.listDetails = model,
        collection: viewmodel.lists = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('lists/page')
        },
        mapping: {
            defaults: {
                name: ''
            },
            local: {
                exportListDetailsPromise: null
            },
            computed: {
                urlName: function () {
                    var name = this.name() || '';

                    return name.toLowerCase();
                },
                url: function () {
                    var name = this.urlName();

                    return '/lists' + (name === '' ?  '' :
                        '/' + encodeURIComponent(name).toLowerCase());
                }
            },
            validation: {
                name: {
                    required: true,
                    minLength: 1,
                    maxLength: 150
                    // FIXME: check that name is not duplicated
                    // model.duplicateName() === false;
                }
            }
        }
    };
});

