define('msgme/ko/extenders/api-date',[
    'msgme/underscore',
    'lib/knockout',
    'msgme/util/format'
],
function (_, ko, format) {
    /**
     * Extend an observable to store a date with timezone offset or null,
     * returning either null or a properly formatted API date string.
     *
     * It offers getters and setters for the date parts and the offset.
     */
    var apiDate = {
        read: function () {
            var value = this();

            if (value === null) {
                return null;
            }

            // Qt webkit doesn't like the colon
            var offset = this.offset().replace(':', '');

            return format.apiDate(
                value.format('{MM}/{dd}/{yyyy} {HH}:{mm}:{ss}') + ' ' + offset);
        },

        write: function (value) {
            var asDate = new Date(value);

            if (value === null) {
                this(value);
            } else if (!isNaN(asDate)) {
                this(asDate);
            }
        }
    };

    function assertNotNull(value, datePartName) {
        if (value === null) {
            throw 'Cannot set ' + datePartName + ' of null API date';
        }
    }

    var asDate = {
        read: function readAsDate() {
            return this();
        },

        write: apiDate.write
    };

    var seconds = {
        read: function readSeconds() {
            var value = this();

            return value === null ? null : value.getSeconds();
        },

        write: function writeSeconds(seconds) {
            var asInt = parseInt(seconds, 10);
            assertNotNull(this(), 'seconds');

            if (!isNaN(asInt) && 0 <= asInt && asInt < 60) {
                this.valueWillMutate();
                this().setSeconds(asInt);
                this.valueHasMutated();
            } else {
                throw new Error(seconds + ' is not a valid seconds value');
            }
        }
    };

    var minutes = {
        read: function readMinutes() {
            var value = this();

            return value === null ? null : value.getMinutes();
        },

        write: function writeMinutes(minutes) {
            var asInt = parseInt(minutes, 10);
            assertNotNull(this(), 'minutes');

            if (!isNaN(asInt) && 0 <= asInt && asInt < 60) {
                this.valueWillMutate();
                this().setMinutes(asInt);
                this.valueHasMutated();
            } else {
                throw minutes + ' is not a valid minutes value';
            }
        }
    };

    var hours = {
        read: function readHours() {
            var value = this();

            return value === null ? null : value.getHours();
        },

        write: function writeHours(hours) {
            var asInt = parseInt(hours, 10);
            assertNotNull(this(), 'hours');

            if (!isNaN(asInt) && 0 <= asInt && asInt < 24) {
                this.valueWillMutate();
                this().setHours(asInt);
                this.valueHasMutated();
            } else {
                throw hours + ' is not a valid hours value';
            }
        }
    };

    var period = {
        read: function readPeriod() {
            var value = this();

            if (value === null) {
                return null;
            } else {
                return value.getHours() < 12 ? 'AM' : 'PM';
            }
        },

        write: function writePeriod(period) {
            var upperCaseValue = period.toUpperCase();
            var value = this();

            assertNotNull(value, 'period');

            var hours = value.getHours();
            if (upperCaseValue === 'AM') {
                if (hours > 11) {
                    this.valueWillMutate();
                    this().setHours(hours - 12);
                    this.valueHasMutated();
                }
            } else if (upperCaseValue === 'PM') {
                if (hours < 12) {
                    this.valueWillMutate();
                    this().setHours(hours + 12);
                    this.valueHasMutated();
                }
            } else {
                throw period + ' is not a valid period value';
            }
        }
    };

    var date = {
        read: function readDate() {
            var value = this();

            return value === null ? null : value.getDate();
        },

        write: function writeDate(date) {
            var asInt = parseInt(date, 10);
            assertNotNull(this(), 'date');

            if (!isNaN(asInt) && 0 < asInt && asInt <= 31) {
                this.valueWillMutate();
                this().setDate(asInt);
                this.valueHasMutated();
            } else {
                throw date + ' is not a valid date value';
            }
        }
    };

    var month = {
        read: function readMonth() {
            var value = this();

            // if a date is set, return its month, but in the expected range
            return value === null ? null : value.getMonth() + 1;
        },

        write: function writeMonth(month) {
            var asInt = parseInt(month, 10);
            assertNotNull(this(), 'month');

            if (!isNaN(asInt) && 0 < asInt && asInt <= 12) {
                this.valueWillMutate();
                // JS wants a zero-indexed month for some reason
                this().setMonth(asInt - 1);
                this.valueHasMutated();
            } else {
                throw month + ' is not a valid month value';
            }
        }
    };

    var year = {
        read: function readDate() {
            var value = this();

            return value === null ? null : value.getFullYear();
        },

        write: function writeDate(year) {
            var asInt = parseInt(year, 10);
            assertNotNull(this(), 'year');

            if (!isNaN(asInt) && 0 < asInt) {
                this.valueWillMutate();
                this().setFullYear(asInt);
                this.valueHasMutated();
            } else {
                throw year + ' is not a valid year value';
            }
        }
    };

    function offsetMinutesToString(minutes) {
        // convert an offset in minutes to '+/-HH:MM' format
        // get the hours
        var offset = minutes < 0 ? Math.ceil(minutes / 60) :
            Math.floor(minutes / 60);
        offset = (minutes < 0 ? '-' : '+') + _.pad(Math.abs(offset), 2, '0');

        // add the minutes
        offset += ':' + _.pad(minutes % 60, 2, '0');

        return offset;
    }

    function offsetStringToMinutes(offset) {
        // convert an offset in '+/-HH:MM' format to minutes
        offset = offset.split(':');
        return parseInt(offset[0], 10) * 60 + parseInt(offset[1], 10);
    }

    var offsetMinutes = {
        read: function readOffsetMinutes() {
            var value = this();

            return value === null ?
                null : offsetStringToMinutes(this.offset());
        },

        write: function writeOffsetMinutes(offsetMinutes) {
            var asInt = parseInt(offsetMinutes, 10);

            if (!isNaN(asInt)) {
                this.valueWillMutate();
                this.offset(offsetMinutesToString(offsetMinutes));
                this.valueHasMutated();
            } else {
                throw offsetMinutes + ' is not a valid offset minutes value';
            }
        }
    };

    var offsetStringRegEx = /[\-+]\d\d:?\d\d/;

    function coerceOffsetToString(offset) {
        if (_.isNumber(offset)) {
            offset = offsetMinutesToString(offset);
        } else if (!_.isString(offset) || !offsetStringRegEx.test(offset)) {
            throw offset + ' is not a valid offset value';
        }

        return offset;
    }

    var offset = {
        read: function readOffset() {
            return this.offset();
        },

        write: function writeOffset(offset) {
            offset = coerceOffsetToString(offset);

            this.valueWillMutate();
            this.offset(offset);
            this.valueHasMutated();
        }
    };

    var formatted = {
        read: function readFormatted() {
            var value = this();

            return value !== null ? value.format(this.format()) : null;
        }
    };

    var defaultOffset = -(new Date()).getTimezoneOffset();
    return function apiDateExtender(target, config) {
        config = config || {};

        var value = target();

        config.offset = config.offset !== void undefined ?
            config.offset : null;

        target(null);
        var result = ko.computed(apiDate, target);

        var dateInstance = new Date(value);
        if (dateInstance instanceof Date && !isNaN(dateInstance.getTime())) {
            // dateInstance is a valid Date, so use its offset
            config.offset = config.offset ||
                offsetMinutesToString(-dateInstance.getTimezoneOffset());
        }

        target.offset = ko.observable(config.offset !== null ?
            coerceOffsetToString(config.offset) :
            offsetMinutesToString(defaultOffset));

        result(value);
        target._apiDateComputed = result;

        result.asDate = ko.computed(asDate, target);
        result.seconds = ko.computed(seconds, target);
        result.minutes = ko.computed(minutes, target);
        result.hours = ko.computed(hours, target);
        result.period = ko.computed(period, target);
        result.date = ko.computed(date, target);
        result.month = ko.computed(month, target);
        result.year = ko.computed(year, target);
        result.offset = ko.computed(offset, target);
        result.offsetMinutes = ko.computed(offsetMinutes, target);
        target.format = result.format =
            ko.observable(config.format || '{M}/{d}/{yyyy} {h}:{mm} {Tt}');
        result.formatted = ko.computed(formatted, target);

        return result;
    };
});


