define('msgme/util/deep',[
    'msgme/underscore',
    'jquery'
], function (_, $) {

    /**
     * Recursively clone a value
     *
     * Arrays and objects are deep-cloned with $.extend. Dates are constructed
     * anew. Other values are returned unchanged.
     */
    function deepClone(source) {
        if (_.isArray(source)) {
            return $.extend(true, [], source);
        } else if (_.isObject(source)) {
            return $.extend(true, {}, source);
        } else if (_.isDate(source)) {
            return new Date(source);
        } else {
            return source;
        }
    }

    /**
     * Recursively apply default values to an object
     *
     * If more than one source is supplied, they are applied from left to
     * right, meaning that `deepDefaults(t, s1, s2)` is equivalent to
     * `deepDefaults(t, s1); deepDefaults(t, s2)`.
     *
     * If the property in question is an array, it will be populated with the
     * elements of a default array iff the target is missing that property or
     * the target's property is the empty array.
     */
    function deepDefaults(target) {
        var sources = _.toArray(arguments).slice(1);
        var source, t, s;

        // apply sources from left to right
        for (var i = 0; i < sources.length; i++) {
            source = sources[i];

            for (var k in source) {
                if (source.hasOwnProperty(k)) {

                    t = target[k];
                    s = source[k];

                    if (_.isArray(s)) {
                        // this nested conditional is necessary because
                        // _.isArray([]) and _.isObject([]) are both true
                        if (!target.hasOwnProperty(k) ||
                            (_.isArray(t) && t.length < 1)
                        ) {
                            // if the source property is an array and the
                            // target property is absent or the empty array,
                            // insert clones of the elements in the source into
                            // the target
                            target[k] = t = t || [];
                            t.splice.apply(t, [0, 0].concat(deepClone(s)));
                        }
                    } else if (_.isObject(s) && !_.isFunction(s) &&
                            (!target.hasOwnProperty(k) || _.isObject(t))) {
                        target[k] = t = t || {};
                        deepDefaults(t, s);
                    } else if (!target.hasOwnProperty(k)) {
                        target[k] = s;
                    }
                }
            }
        }

        return target;
    }

    return {
        defaults: deepDefaults,
        clone: deepClone
    };
});

