define('widgets/select/index',[
    'msgme/underscore',
    'msgme/ko',
    './../three-widget',
    'lib/select2'
], function (_, ko) {
    ko.bindingHandlers.select2 = {
        init: function (el, valueAccessor, allBindings) {
            $(el).select2(_.extend({
                selectOnBlur: true
            }, valueAccessor())).addClass('three-widget');

            ko.utils.domNodeDisposal.addDisposeCallback(el, function () {
                $(el).select2('destroy');
            });

            var observable = allBindings().value;
            var val = ko.utils.unwrapObservable(observable);

            if (val && ko.isObservable(observable)) {
                _.defer(function() {
                    observable(val);
                });
            }
        },

        update: function (el) {
            $(el).trigger('change');
        }
    };
});

