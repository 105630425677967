define('widgets/toolbar/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    './../three-widget',
    'json!./../shared-strings.json',
    'json!./strings.json',
    'text!./template.html',
    'widgets/dynamic-insertion/index',
    'widgets/ads-sponsorships/index',
    'widgets/tag-metadata/index',
    'widgets/character-counter/index',
    'widgets/url-shortener/index',
    'widgets/coupon-insertion/index',
    'widgets/pass-insertion/index',
    'widgets/emoji-insertion/index'
], function (_, viewmodel, ThreeWidget, sharedStrings, strings, template) {

    var requiredOptions = {};
    var optionalOptions = {dynamicInsertion: false, characterCounter: false,
        imageFileUploader: false, videoFileUploader: false,
        audioFileUploader: false, vcfFileUploader: false,
        adsSponsorships: false, tagMetadata: false};
    var acceptedOptions = _.extend({}, requiredOptions, optionalOptions);

    var mapping = {
        local: _.clone(acceptedOptions),

        computed: {
            hasShortUrlPermissions: function () {
                return msgme.auth.has('shorturl:create:*') &&
                    msgme.auth.has('shorturl:update:*') &&
                    msgme.auth.has('shorturl:read:*');
            },

            limitPass: function () {
                return waterfall.authenticate.username === 'wmadmin' ||
                    waterfall.authenticate.username === 'twang' ||
                    waterfall.authenticate.username === 'superwallet';
            },

            hasPasses: function () {
                if (viewmodel.globals.wallets() &&
                    viewmodel.globals.wallets().length) {
                    return _.filter(viewmodel.globals.wallets(),
                        function (wallet) {
                        return wallet.status === 'PUBLISHED' ||
                            wallet.status === 'COMPLETED';
                    }).length;
                }
            },

            isNotWhitelabel: function () {
                return sharedStrings.deployTarget === 'msgme3.0';
            }
        }
    };

    $.widget('msgme.msgme_toolbar', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);
            this._extractOptions();
            this._initSubWidgets();
        },

        _extractOptions: function () {
            this._checkRequiredOptions();
            this._applyAcceptedOptionsToViewmodel();
        },

        _checkRequiredOptions: function () {
            var passedOptionNames = _.keys(this.options);
            var requiredUnpassedOptions =
                _.omit(requiredOptions, passedOptionNames);

            function warnRequiredOptionNotProvided(optionVal, optionName) {
                var warningString = _.sprintf(
                    strings.warning.requiredOptionNotProvided,
                    {option: optionName});

                console.warn(warningString);
            }

            _.each(requiredUnpassedOptions, warnRequiredOptionNotProvided);
        },

        _applyAcceptedOptionsToViewmodel: function () {
            var passedOptions = this.options;
            var acceptedOptionNames = _.keys(acceptedOptions);
            var acceptedPassedOptions =
                _.pick(passedOptions, acceptedOptionNames);

            function applyOptionToViewmodel(optionVal, optionName) {
                passedOptions.viewmodel[optionName](optionVal);
            }

            _.each(acceptedPassedOptions, applyOptionToViewmodel);
        },

        _initSubWidgets: function () {
            var vm = this.options.viewmodel;
            if (vm.imageFileUploader()) { this._initImageFileUploader(); }
            if (vm.videoFileUploader()) { this._initVideoFileUploader(); }
            if (vm.audioFileUploader()) { this._initAudioFileUploader(); }
            if (vm.vcfFileUploader()) { this._initVcfFileUploader(); }
            if (vm.dynamicInsertion()) { this._initDynamicInsertion(); }
            if (vm.adsSponsorships()) { this._initAdsSponsorships(); }
            if (vm.tagMetadata()) { this._initTagMetadata(); }
            if (vm.characterCounter()) { this._initCharacterCounter(); }
            if (msgme.auth.has('coupon:read:*')) {
                this._initCouponInsertion();
            }
            if (vm.imageFileUploader()) { this._initEmojiInsertion(); }
            this._initPassInsertion();
            this._initUrlShortener();
        },

        _initImageFileUploader: function () {
            var vm = this.options.viewmodel;
            var $container =
                this.element.find('.image-file-uploader-container');
            var fileUploaderOptions = vm.imageFileUploader();

            $container.msgme_file_uploader(fileUploaderOptions);
        },

        _initVideoFileUploader: function () {
            var vm = this.options.viewmodel;
            var $container =
                this.element.find('.video-file-uploader-container');
            var fileUploaderOptions = vm.videoFileUploader();

            $container.msgme_file_uploader(fileUploaderOptions);
        },

        _initAudioFileUploader: function () {
            var vm = this.options.viewmodel;
            var $container =
                this.element.find('.audio-file-uploader-container');
            var fileUploaderOptions = vm.audioFileUploader();

            $container.msgme_file_uploader(fileUploaderOptions);
        },

        _initVcfFileUploader: function () {
            var vm = this.options.viewmodel;
            var $container =
                this.element.find('.vcf-file-uploader-container');
            var fileUploaderOptions = vm.vcfFileUploader();

            $container.msgme_file_uploader(fileUploaderOptions);
        },

        _initDynamicInsertion: function () {
            var vm = this.options.viewmodel;
            var $container = this.element.find('.dynamic-insertion-container');
            var dynamicInsertionOptions = vm.dynamicInsertion();

            $container.msgme_dynamic_insertion(dynamicInsertionOptions);
        },

        _initAdsSponsorships: function () {
            var vm = this.options.viewmodel;
            var $container = this.element.find('.ads-sponsorships-container');
            var adsSponsorshipsOptions = vm.adsSponsorships();

            $container.msgme_ads_sponsorships(adsSponsorshipsOptions);
        },

        _initTagMetadata: function () {
            var vm = this.options.viewmodel;
            var $container = this.element.find('.tag-metadata-container');
            var tagMetadataOptions = vm.tagMetadata();

            $container.msgme_tag_metadata(tagMetadataOptions);
        },

        _initCharacterCounter: function () {
            var vm = this.options.viewmodel;
            var $container = this.element.find('.character-counter-container');
            var characterCounterOptions = vm.characterCounter();

            $container.msgme_character_counter(characterCounterOptions);
        },

        _initUrlShortener: function () {
            var vm = this.options.viewmodel;
            var $container = this.element.find('.url-shortener-container');
            var tagMetadataOptions = vm.tagMetadata();

            // we use the tag metadata options for the isDisabled param
            $container.msgme_url_shortener({
                model: {
                    isDisabled: tagMetadataOptions ?
                        tagMetadataOptions.model.isDisabled : null
                }
            });
        },

        _initCouponInsertion: function () {
            var vm = this.options.viewmodel;
            var $container = this.element.find('.coupon-insertion-container');
            var tagMetadataOptions = vm.tagMetadata();

            // we use the tag metadata options for the isDisabled param
            $container.msgme_coupon_insertion({
                model: {
                    isDisabled: tagMetadataOptions ?
                        tagMetadataOptions.model.isDisabled : null
                }
            });
        },

        _initPassInsertion: function () {
            var vm = this.options.viewmodel;
            var $container = this.element.find('.pass-insertion-container');
            var tagMetadataOptions = vm.tagMetadata();

            // we use the tag metadata options for the isDisabled param
            $container.msgme_pass_insertion({
                model: {
                    isDisabled: tagMetadataOptions ?
                        tagMetadataOptions.model.isDisabled : null
                }
            });
        },

        _initEmojiInsertion: function () {
            var vm = this.options.viewmodel;
            var $container = this.element.find('.emoji-insertion-container');
            var tagMetadataOptions = vm.tagMetadata();

            // we use the tag metadata options for the isDisabled param
            $container.msgme_emoji_insertion({
                model: {
                    isDisabled: tagMetadataOptions ?
                        tagMetadataOptions.model.isDisabled : null
                }
            });
        }
    });

    return {
        widget: $.msgme.msgme_toolbar,
        mapping: mapping
    };
});

