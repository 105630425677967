define('msgme/views/sweep',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/sweep',
    'msgme/views/View',
    'json!widgets/shared-strings.json'
], function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    mapping,
    sweep,
    View,
    sharedStrings
) {
    var view = new View('#sweep-view');

    var cm = new mapping.CursorModel(waterfall.sweeps, {
        record : {
            mapping: sweep.mapping
        }
    });

    function populate(sweepInstance) {
        sweep.model(sweepInstance);
        sweep.model.error(false);
        splash.hide();
        view.root.find('#sweep-view-body').msgme('busy', false);
    }

    view.root.on('click', '.select-winners', function () {
        if (sweep.model().isValid()) {
            view.root.find('#sweep-view-body').msgme('busy', true);
            var query = {
                id: sweep.model().id(),
                name: sweep.model().name(),
                noOfWinners: sweep.model().noOfWinners()
            };

            sweep.model().winnerGroupsPromise(
                waterfall.sweeps.winnerGroups(sweep.model().id(), query).
                    done(function (result) {
                        populate(ko.mapping.fromJS(result, sweep.mapping));
                    }).
                    always(function () {
                        view.root.find('#sweep-view-body').msgme('busy', false);
                    }).
                    fail(function (result) {
                        if (result.responseText.
                            match('Not enough unique winners')) {
                            msgme.alert.info('All current entrants ' +
                                'have been selected as winners');
                        } else {
                            view.getRequestFailureFn(null,
                                'sweeps.winnerGroups',
                                sweep.model().id());
                        }
                    }));
        } else {
            sweep.model().isModified(true);
        }
    });

    view.root.on('click', 'i.delete-winner', function (e) {
        var evt = e;

        msgme.modal.confirm('Are you sure you want to remove this winner?', {
            title: 'Remove Winner'
        }).done(function (value) {
            if (value) {
                var winnerGroupIndex =
                    $(evt.target.closest('.each-winner-group')).index();
                var winnerIndex = $(evt.target.closest('.winner')).index();
                var currentWinnerGroup =
                    sweep.model().winnerGroups()[winnerGroupIndex];

                view.callAPI('sweeps.removeWinner', {
                    sweepId: sweep.model().id(),
                    winnerGroupId: currentWinnerGroup.id(),
                    winnerId: currentWinnerGroup.winners()[winnerIndex].id()
                }).
                done(function () {
                    currentWinnerGroup.winners.splice(winnerIndex, 1);
                    msgme.alert.success('The winner has been removed');
                });
            }
        });
    });

    view.root.on('click', 'i.delete-winner-group', function (e) {
        var evt = e;

        // prevent accordion from opening on delete click
        evt.stopImmediatePropagation();
        msgme.modal.confirm('Are you sure you want to remove this group?', {
            title: 'Remove Winner Group'
        }).done(function (value) {
            if (value) {
                var winnerGroupIndex =
                    $(evt.target.closest('.each-winner-group')).index();
                var currentWinnerGroup =
                    sweep.model().winnerGroups()[winnerGroupIndex];

                view.callAPI('sweeps.removeWinnerGroup', {
                    sweepId: sweep.model().id(),
                    winnerGroupId: currentWinnerGroup.id()
                }).
                done(function () {
                    sweep.model().winnerGroups.splice(winnerGroupIndex, 1);
                    msgme.alert.success('The winner has been removed');
                });
            }
        });
    });

    path.map(sharedStrings.sitemap.sweeps.url + '/:id').to(function () {
        view.show();

        if (this.params.id.toLowerCase() === 'new') {
            populate(cm.create());
        } else {
            cm.fetch(this.params.id).
                done(populate).
                fail(view.getRequestFailureFn(null,
                    'sweeps.fetch',
                    this.params.id));
        }
    });

    return view;
});

