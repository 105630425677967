define('msgme/viewmodel/smartlists',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel'
], function(_, ko, viewmodel) {
    var model = ko.observable(null);
    return {
        model: viewmodel.smartlist = model,
        collection: viewmodel.smartlists = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('smartlists/page')
        },

        mapping: {
            local: {
                list: null,
                count: null,
                collection: {
                    rows: [],
                    pageIndex: -1,
                    pageCount: -1,
                    pageSize: 15,
                    links: [],
                    url: null
                },
                isNewSmartlist: null,
                isStale: null,
                pending: null,
                savePending: null
            },
            defaults: {
                id: null,
                name: null,
                lists: [],
                queryFilterDetails: {
                    operator: 'and',
                    details: [{
                        operator: 'or',
                        details: [{
                            metadata: null,
                            operator: null,
                            value: null,
                            radius: null
                        }]
                    }]
                }
            },
            computed: {
                listNames: function () {
                    return _.map(this.lists(), function (list) {
                        if (viewmodel.globals.lists.oneById(list)) {
                            return viewmodel.globals.lists.oneById(list);
                        } else {
                            return '(list no longer exists)';
                        }
                    });
                },
                showCount: function () {
                    return this.count() !== null;
                }
            },
            details: {
                metadata: null,
                operator: null,
                value: null,
                radius: null
            },
            validation: {
                name: {
                    required: true,
                    minLength: 5
                }
            }
        }
    };
});

