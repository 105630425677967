define('widgets/audience-filter/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/ko/option',
    'msgme/util/api',
    'msgme/util/format',
    'msgme/viewmodel',
    'msgme/viewmodel/query-filter',
    'msgme/viewmodel/mapping',
    'msgme/modelcollection/filter',
    './../three-widget',
    './../metadata-value/index',
    'text!./template.html',
    'json!./strings.json',
    'json!widgets/shared-strings.json',
    'lib/bootstrap',
    './../selectize/index'
], function (
    _,
    ko,
    option,
    api,
    format,
    viewmodel,
    queryfilter,
    viewmodelMapping,
    filter,
    ThreeWidget,
    metadataValue,
    template,
    strings,
    shared
) {
    function updateGeoValue(root, parent) {
        var params = {
            radius: parent.geoRadius(),
            zipcode: parent.geoZipcode()
        };
        var isGeo = parent.data().__operatorIsGeo__;

        // this is evaluated before the 'after' plugins, which include the
        // validation rules, so the `isValid` computed's may not be present
        var isValid = ko.utils.unwrapObservable(parent.geoRadius.isValid) &&
            ko.utils.unwrapObservable(parent.geoZipcode.isValid);

        if (isValid && params.radius && params.zipcode && isGeo) {
            parent.geoValueRequest(api.call('filters.zipcode', params).
                then(function (result) {
                    parent.data().value(result.zipcodes.join(', '));
                    parent.geoValueRequest(null);
                }));
        } else {
            parent.geoValueRequest(null);

            if (isGeo) {
                parent.data().value('');
            }
        }
    }

    function isGeo() {
        return this.root.type() === 'geo';
    }

    var detailsMapping = {
        defaults: {
            data: null,
            geoRadius: null,
            geoZipcode: null,
            geoValueRequest: null,
            metadataYear: null,
            metadataDate: null
        },
        computed: {
            isDateMetadata: function () {
                var metadata = this.data().metadata();

                if (metadata && viewmodel.globals.metadata.oneById(metadata)) {
                    return viewmodel.globals.metadata.oneById(
                        this.data().metadata()).type === 'DATE';
                } else {
                    return false;
                }
            },

            operatorOptions: function () {
                if (this.isDateMetadata()) {
                    return shared.dateOperators;
                } else {
                    return shared.operators;
                }
            },

            // when the operator is '[not]exists', the filter value is true or
            // false, and it's independent of the msgme_metadata_value widget's
            // value. we need to make sure that the msgme_metadata_value widget
            // can only set the filter value when the operator is *not*
            // [not]exists
            metadataValue: {
                read: function (root) {
                    return root.data().value();
                },

                write: function (root, value) {
                    if (root.data().operator() !== 'exists') {
                        root.data().value(value);
                    }
                }
            },

            metadataDate: function (root) {
                if (this.isDateMetadata()) {
                    var prefix;
                    var date = root.data().value() ? root.data().value().
                        replace(/(\d{2})(\d{2})(\d{4})/, '$1-$2-$3') : null;

                    if (root.data().operator() === '>') {
                        prefix = 'Born after: ';
                    } else {
                        prefix = 'Born on or before: ';
                    }

                    return prefix + date;
                }
            },

            // since the operator select has two possibilities that aren't
            // actually submitted to the api ('geo', 'notgeo'), we make the
            // 'operator' computed an extra layer of indirection to the
            // 'data().operator' value that gets persisted to the API. the
            // local operatorIsGeo value is used to store whether or not the
            // computed should return the geo/notgeo value instead of the API's
            // in/nin.
            //
            // this is dum though. 'this' and the 'root' parameter values are
            // different in the read fn vs. the write, and  we need the
            // operatorIsGeo value to *not* be reactive, so the only thing i
            // could come up w/ is storing the __operatorIsGeo__ property on
            // the data attr (this doesn't get persisted to the API).
            //
            // the other tricky thing for which the operator computed is
            // responsible is munging 'exists' and 'notexists'. the UI just has
            // those two values as options in the select, but the api accepts
            // an 'exists' operator whose value is true or false. the
            // read/write functions handle translating exists/notexists to just
            // exists and a value of either true or false
            operator: {
                read: function () {
                    var op = this.data().operator();

                    if (this.data().__operatorIsGeo__ && /(n)?in/.test(op)) {
                        return op === 'in' ? 'geo' : 'notgeo';
                    } else if (op === 'exists') {
                        return this.data().value() ? op : 'not' + op;
                    } else {
                        return op;
                    }
                },

                write: function (root, op) {
                    var current = this.operator();

                    if (/(not)?geo/.test(op)) {
                        this.data().__operatorIsGeo__ = true;
                        this.data().operator(op === 'geo' ? 'in' : 'nin');
                        return;
                    }

                    this.data().__operatorIsGeo__ = false;

                    if (/exists/.test(op)) {
                        this.data().operator('exists');
                        this.data().value(/not/.test(op) ? false : true);
                        return;
                    } else if (/exists/.test(current)) {
                        this.data().value(null);
                    }

                    this.data().operator(op);
                }
            },

            type: function () {
                var data = this.data();
                var op = data.operator();

                if (data.__operatorIsGeo__) {
                    return 'geo';
                }

                if (/exists/.test(op)) {
                    return 'novalue';
                }

                return 'standard';
            }
        },

        subscribe: {
            geoRadius: updateGeoValue,
            geoZipcode: updateGeoValue,
            metadataYear: function (value, parent) {
                if (parent.isDateMetadata()) {
                    if (value && !isNaN(value)) {
                        var date = Date.create().rewind({ years: value }).
                            format('{MM}{dd}{yyyy}');

                        parent.data().value(date);
                    }
                }
            },
            operator: function (value, parent) {
                parent.geoRadius(null);
                parent.geoZipcode(null);
            }
        },

        validation: {
            data: {
                validator: function () {
                    return this.observable().isValid();
                },

                message: function () {
                    return this.observable().errors();
                }
            },

            geoZipcode: {
                validator: function () {
                    // the geo value could be either a zip or area code
                    return !isGeo.call(this) ||
                        (/^\d{1,5}$/).test(this.observable());
                },

                message: function () {
                    return [strings.geo.error.value];
                }
            },

            geoRadius: {
                number: {onlyIf: isGeo},
                required: {onlyIf: isGeo}
            },

            metadataValue: {
                validator: function () {
                    return this.root.data().value.isValid();
                },

                message: function () {
                    return this.root.data().value.error();
                }
            },

            metadataYear: {
                required: {
                    onlyIf: function () {
                        return this.parent.isDateMetadata();
                    }
                },
                minLength: 1,
                maxLength: 2,
                number: true,
                message: function () {
                    return 'Please enter an age value';
                }
            }
        }
    };

    var mapping = {
        defaults: {
            data: null,
            queryFilters: [],
            queryFilterDetails: null,
            recount: null,
            pending: null,
            metadataOptions: null,
            metadataOptgroups: null,

            // this is for reverting to the a previous state
            snapshot: null
        },

        computed: {
            // the 'filters' computed is basically an array of the
            // 'detailsMapping' objects whose 'data' is the corresponding item
            // in the queryFilterDetails array.
            //
            // the 'filters' computed somehow gets re-calculated when the value
            // of a queryFilterDetails item changes. if we do the simple thing
            // and just re-generate 'detailsMapping' instances from each item
            // in the queryFilterDetails array, the corresponding DOM rows from
            // the binding get swapped out and the metadata-value widgets are
            // re-instantiated on each row. this triggers a 'change' on the
            // metadata-value's 'field' property when it goes from its initial
            // value of null to the original row's metadata id, which clears
            // the metadata value.
            //
            // in short the above process loses the metadata value, along with
            // the focus and is a jarring user experience, so what we need is
            // an array that intelligently updates itself with only the stuff
            // that has changed, kind of like a computedObservableArray. that's
            // what all of the mess is below. it would be relatively easy to
            // break out, and we may be able to make a nice open source lib out
            // of it.
            filters: function () {
                var previous = this.__previousFilters__ =
                    this.__previousFilters__ || [];
                var queryFilterDetails = this.queryFilters();

                // for each item in queryFilterDetails, instantiate an object
                // from the detailsMapping unless we've already done so, in
                // which case just use the previous instance.
                var filters = _.map(queryFilterDetails, function (detail) {
                    var existing = _.find(previous, function (prev) {
                        return prev.data() === detail;
                    });

                    if (detail.operator() === 'exists') {
                        if (detail.value() === 'false') {
                            detail.value(false);
                        } else if (detail.value() === 'true'){
                            detail.value(true);
                        }
                    }

                    if (!detail.isValid) {
                        detail = ko.mapping.fromJS({},
                            queryfilter.mapping.queryFilterDetails.mapping);
                    }

                    return existing || ko.mapping.fromJS({
                        data: ko.observable(detail)
                    }, detailsMapping, {});
                });
                var length = previous.length;

                previous.splice.apply(previous, [0, length].concat(filters));

                return previous;
            },
            sortedMetadata: function () {
                return _.sortBy(viewmodel.globals.metadata(), function (meta) {
                    return meta.name.toLowerCase();
                });
            },
            metadataProfile: function () {
                return viewmodel.globals.metadata.grouped().profile;
            },

            metadataSystem: function () {
                return viewmodel.globals.metadata.groupedAndSorted().system;
            },

            metadataCustom: function () {
                return viewmodel.globals.metadata.grouped().custom;
            },
            allMetadata: function () {
                return viewmodel.globals.metadata();
            },
            showCount: function () {
                return true;
            },
            operatorOptions: function () {
                return shared.operators;
            },
            sortedGroupedMetadata: function () {
                var ret = [];

                if (this.metadataProfile()) {
                    ret.push({label: 'Profile',
                        options: this.metadataProfile()});
                }
                if (this.metadataSystem()) {
                    ret.push({label: 'System',
                        options: this.metadataSystem()});
                }
                if (this.metadataCustom()) {
                    ret.push({label: 'Custom',
                        options: this.metadataCustom()});
                }

                return ret;
            },

            createMetadataDropdown: function () {
                var metadata = viewmodel.globals.metadata();

                this.metadataOptions(
                    _.compact(
                        _.map(metadata, function (meta) {
                            if (meta.scope === 'PROFILE') {
                                return {
                                    class: 'profile',
                                    value: meta.id,
                                    name: meta.name
                                };
                            } else if (meta.scope === 'GROUP' ||
                                meta.scope === 'ACCOUNT') {
                                return {
                                    class: 'custom',
                                    value: meta.id,
                                    name: meta.name
                                };
                            } else if (meta.scope === 'SYSTEM') {
                                return {
                                    class: 'system',
                                    value: meta.id,
                                    name: meta.name
                                };
                            }
                        })));
                this.metadataOptgroups([]);
                if (_.find(metadata, function (m) {
                    return m.scope === 'ACCOUNT' || m.scope === 'GROUP';
                })) {
                    this.metadataOptgroups().push({
                        value: 'custom',
                        label: 'Custom'
                    });
                }
                if (_.find(metadata, function (m) {
                    return m.scope === 'PROFILE';
                })) {
                    this.metadataOptgroups().push({
                        value: 'profile',
                        label: 'Profile'
                    });
                }
                if (_.find(metadata, function (m) {
                    return m.scope === 'SYSTEM';
                })) {
                    this.metadataOptgroups().push({
                        value: 'system',
                        label: 'System'
                    });
                }
            }
        },

        validation: {
            filters: function (val) {
                return _.all(val, function (filter) {
                    return filter.isValid();
                });
            }
        }
    };

    $.widget('msgme.msgme_audience_filter', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            var viewmodel;

            ThreeWidget.prototype._create.apply(this, arguments);

            viewmodel = this.option('viewmodel');

            this.on('click', '.add', 'onClickAdd');
            this.on('click', '.fa.fa-times-circle-o', 'onClickTrash');
            //this.on('change', '.metadata-operator', 'onOperatorChange');
            this.on('click', '.or-button', '_addFilter');
            this.on('change', 'select', 'onInputChange');
            this.on('change', 'input', 'onInputChange');
            _.each(this.element.find('.query-row'), function (row, i) {
                if ($(row).find('.metadata-select').length) {
                    $(row).find('.metadata-select').selectize({
                        maxOptions: 1000,
                        options: viewmodel.metadataOptions(),
                        optgroups: viewmodel.metadataOptgroups(),
                        optgroupField: 'class',
                        labelField: 'name',
                        searchField: ['name']
                    })[0].selectize.setValue(viewmodel.filters()[i].
                        data().metadata());
                }
            });
        },

        _addFilter: function () {
            var viewmodel = this.option('viewmodel');

            viewmodel.queryFilters.push(ko.mapping.fromJS({},
                queryfilter.mapping.queryFilterDetails.mapping));
            
            _.last(viewmodel.queryFilters()).isModified(false);
            viewmodel.recount(true);
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        },

        onClickAdd: function () {
            this._addFilter();
        },

        onClickTrash: function (evt) {
            var idx = $(evt.target).closest('.query-filter-details-row').
                index();
            this.option('viewmodel').queryFilters.splice(idx, 1);
            this.onInputChange();
        },

        onInputChange: function () {
            this.option('viewmodel').recount(true);
        },

        // this is necessary because some operators use the same values, thus
        // not triggering an event
        /*
        onOperatorChange: function (event) {
            var index = $(event.target).
                parents('.query-filter-details-row').index();
            var metadata = this.option('viewmodel').queryFilters()[index];
            
            if (metadata.value() === true || metadata.value() === false) {
                metadata.value.valueHasMutated(false);
                metadata.operator.valueHasMutated();
            } else {
                metadata.value(null);
                metadata.value.valueHasMutated(false);
                metadata.operator.valueHasMutated();
            }
        },
        */
    });

    return {
        widget: $.msgme.msgme_audience_filter,
        mapping: mapping
    };
});

