define('msgme/views/sponsorship',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/sponsorships',
    'msgme/views/View',
    'json!widgets/shared-strings.json'
], function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    mapping,
    sponsorship,
    View,
    sharedStrings
) {
    var view = new View('#sponsorship-view');

    var cm = new mapping.CursorModel(waterfall.sponsorships, {
        record : {
            mapping: sponsorship.mapping
        }
    });

    function populate(sponsorshipInstance) {
        sponsorship.model(sponsorshipInstance);
        splash.hide();
        sponsorship.model().startDate.valueHasMutated();
        sponsorship.model().endDate.valueHasMutated();
        view.root.find('#sponsorship-view-body').msgme('busy', false);
    }

    view.root.on('click', 'button.save-sponsorship', function () {
        var sponsorshipModel = sponsorship.model();

        sponsorshipModel.save().
            done(function() {
                path.history.pushState(
                    null, null, sharedStrings.sitemap.sponsorships.url);
                msgme.alert.success('The sponsorship has been saved');
                viewmodel.globals.sponsorships.refresh(true);
            }).
            fail(view.getRequestFailureFn(null,
                'sponsorship.save',
                ko.mapping.toJS(sponsorshipModel)));
    });

    path.map(sharedStrings.sitemap.sponsorships.url + '/:id').to(function () {
        view.show();

        if (this.params.id.toLowerCase() === 'new') {
            populate(cm.create());
            view.root.find('input.editable').focus();
        } else {
            cm.fetch(this.params.id).
                done(populate).
                fail(view.getRequestFailureFn(null,
                    'sponsorships.fetch',
                    this.params.id));
        }
    });

    return view;
});

