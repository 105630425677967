define('msgme/viewmodel/remove-from-list',[], function () {
    var nextId = 0;
    var mapping = {
        local: {
            lists: [],
            listId: null,
            widgetIdValue: null
        },

        computed: {
            widgetId: function () {
                var id = this.widgetIdValue();
                if (id === null) {
                    id = 'remove-from-list-' + nextId++;
                    this.widgetIdValue(id);
                }
                return id;
            }
        }
    };

    return {
        mapping: mapping
    };
});

