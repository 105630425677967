define('msgme/widgets/flowmodule-subscription',[
    'msgme/underscore',
    'jquery',
    'msgme/ko',
    'msgme/util/feature-flags',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-subscription',
    'widgets/new-list-modal/index'
],
function (
    _,
    $,
    ko,
    featureFlags,
    flowmodule,
    subscription
) {
    $.widget('msgme.msgme_flowmodule_subscription', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-subscription-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
            
            var data = this.options.data();
            
            featureFlags('disableListManagement').then(_.bind(function () {
                data.oldOptInStyle(true);
            }, this));

            if (data.params.optInType() === 'doubleOptIn' &&
               !data.oldOptInStyle()) {
                this.element.find('.opt-in-group').hide();
            }
            
            this.on('click', '.new-list', '_openModal');
            this.on('change', '.list-selection', '_onListChange');

            data.params.listId.subscribe(function(oldValue) {
                data.previousList(oldValue);
            }, this, 'beforeChange');
        },

        _onListChange: function (e) {
            var data = this.options.data();

            if (e.target.value === 'new') {
                data.params.listId(data.previousList());
                this._openModal(e);
            } else if (e.target.value === 'placeholder') {
                data.params.listId(data.previousList());
            }
        },

        _openModal: function () {
            var $newListEl = this.element.find('.new-list-container');

            $newListEl.msgme_new_list_modal('show');
        }
    });

    $.msgme.msgme_flowmodule_subscription.Model = flowmodule.modelFactory({}, {
        mapping: subscription.mapping
    });

    return $.msgme.msgme_flowmodule_subscription;
});


