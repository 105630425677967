define('msgme/widgets/flowmodule-agegate',[
    'jquery',
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-agegate'
], function (
    $,
    _,
    ko,
    flowmodule,
    ageGate
) {
    $.widget('msgme.msgme_flowmodule_agegate', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-agegate-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
            var data = this.options.data();
            var params = data.params;
            var delay;

            if (data.metadataOptions()) {
                delay = 0;
            } else {
                delay = 300;
            }
            _.delay(_.bind(function () {
                this.element.find('.store-age').
                    selectize({
                    maxOptions: 1000,
                    options: data.metadataOptions(),
                    optgroups: data.metadataOptgroups(),
                    optgroupField: 'class',
                    labelField: 'name',
                    searchField: 'name',
                    onInitialize: function () {
                        this.setValue(params.metadataId());
                    }
                });
            }, this), delay);
        }
    });

    $.msgme.msgme_flowmodule_agegate.Model =
        flowmodule.modelFactory({}, {mapping: ageGate.mapping});

    return $.msgme.msgme_flowmodule_agegate;
});


