define('widgets/new-list-modal/index',[
    'msgme/underscore',
    './../three-widget',
    'msgme/viewmodel',
    'msgme/alert',
    'msgme/util/api',
    'text!./template.html'
], function (_, ThreeWidget, viewmodel, alert, api, template) {
    var mapping = {
        defaults: {
            name: '',
            list: null,
            inFlightRequest: null
        },
        computed: {
            isAddDisabled: function () {
                return !this.name();
            }
        }
    };

    $.widget('msgme.msgme_new_list_modal', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            this._super.apply(this, arguments);
            this.on('click', 'button.close-modal', 'hide');
            this.on('click', 'button.save-list', '_onSave');
            this.$modal = this.element.find('.modal');
        },

        _onSave: function () {
            var vm = this.option('viewmodel');
            var hide = this.hide;
            var promise;

            promise = api.call('lists.fetch',
                {name: vm.name()}).done(function (result) {
                if (result.length === 0) {
                    vm.list(null);
                    api.call('lists.newList', vm.name()).done(function (list) {
                        msgme.alert.success('The list has been saved');
                        viewmodel.globals.lists.refresh(true).done(function () {
                            _.defer(function () {
                                vm.list(list.id);
                            });
                        });
                        hide();
                    });
                } else {
                    msgme.alert.info('This list name is already in use');
                }
            });
            vm.inFlightRequest(promise);

            return promise;
        },

        hide: function () {
            this.$modal.modal('hide');
        },

        show: function () {
            var vm = this.option('viewmodel');

            vm.name('');
            this.$modal.modal('show');
            this.$modal.find('.list-name').focus();
        }
    });

    return {
        widget: $.msgme.msgme_new_list_modal,
        mapping: mapping
    };
});

