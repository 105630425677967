define('widgets/subscriber-growth-chart/index',[
    'lib/d3',
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/format',
    './../line-chart/index',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    'text!./popover.tmpl',
    'lib/bootstrap'
], function (d3, _, ko, viewmodel, format, lineChart, ThreeWidget, template,
    strings, popover
) {
    var mapping = {
        defaults: {
            data: null,
            lists: [],
            timeframe: 'LAST30',
            hierarchy: null
        }
    };
    var popoverText = _.template(popover);
    var popoverContext = {
        subscriber: strings.popover.subscribers,
        strings: strings,
        format: format
    };

    function SubscriberChartPopover(el, opts) {
        this.init('popover', el, _.extend({
            placement: 'in right'
        }, opts));
    }

    function createDataSet(lists, subGrowth, broadcastsByLists) {
        return _.map(subGrowth, function (dataSet, i) {
            return {
                id: lists[i],
                data: _.map(dataSet, function (record) {
                    var data = [Date.create(record.date), record.listSize];

                    if (broadcastsByLists[lists[i]]) {
                        var blasts = _.chain(broadcastsByLists[lists[i]]).
                            map(function (d) {
                                if (d[0].short() ===
                                    Date.create(record.date).short()) {
                                    return d[1];
                                }
                            }).
                            compact().
                            value();

                        if (!_.isEmpty(blasts)) {
                            data.push(blasts);
                        }
                    }

                    return data;
                })
            };
        });
    }

    SubscriberChartPopover.prototype =
        _.extend({}, $.fn.popover.Constructor.prototype, {
            constructor: SubscriberChartPopover,
            getPosition: function () {
                return _.extend({
                    width: 10,
                    height: 11
                }, $(this.options.point.el).offset());
            }
        });

    $.widget('msgme.msgme_subscriber_growth_chart', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            var vm;

            _.bindAll(this, '_onInputChange', '_onChartMouseleave',
                '_onChartPointMouseenter');

            ThreeWidget.prototype._create.apply(this, arguments);

            this.$chart = this.element.find('.line-chart').msgme_line_chart({
                    data: ko.observable({dataSets: ko.observableArray()}),
                    model: {
                        height: 200,
                        margin: {top: 30, right: 0, bottom: 20, left: 70}
                    }
                }).
                on('mouseleave', this._onChartMouseleave).
                on('point-mouseenter', this._onChartPointMouseenter);

            vm = this.option('viewmodel');

            vm.lists.subscribe(this._onInputChange);
            vm.timeframe.subscribe(this._onInputChange);
            vm.hierarchy.subscribe(_.bind(this._onHierarchyChange, this));

            this._onInputChange();
        },

        _createViewModel: function () {
            var vm =
                ThreeWidget.prototype._createViewModel.apply(this, arguments);

            vm.availableLists = viewmodel.globals.lists;

            return vm;
        },

        _onChartMouseleave: function () {
            if (this._popover) {
                this._popover.hide();
            }

            this._removeHoverClasses();
        },

        _onChartPointMouseenter: function (evt, obj) {
            var list = viewmodel.globals.lists.byId(obj.id);
            var text = popoverText(_.extend(popoverContext, {
                date: format.uiDateOnly(obj.data[0]),
                subCount: format.integer(obj.data[1]),
                blastData: obj.data[2]
            }));
            var blastData = obj.data[2];

            this._blastData = obj.data[2];

            if (blastData && blastData.length === 1) {
                this._getBitlyData(obj.data);
            }

            if (this._popover) {
                this._popover.hide();
            }

            this._popover = new SubscriberChartPopover(this.$chart, {
                title: list ? list.name : '',
                content: text,
                animation: false,
                point: obj
            });

            this._removeHoverClasses();
            this._popover.show();
            obj.el.setAttribute('class', 'hover');
        },

        _getBitlyData: _.debounce(function (data) {
            var blastData = data[2];
            var widgetEl = this.element;
            var bitlyUrl = 'https://api-ssl.bitly.com/v3/link/clicks?';
            var re = /bit\.ly\S*|bitly.com\S*\b/;
            var bitlyDisallowedPunctuation = '.,!?';
            var trailingPunctuationRegex =
                new RegExp('[' + bitlyDisallowedPunctuation + ']*$');

            _.each(blastData[0], _.bind(function (segment) {
                if (segment.message && !_.isNumber(segment.clicks) &&
                    segment.message.match(re)) {
                    var bitlink = 'https://' + segment.message.match(re)[0];
                    var bitlinkNoTrailingPunctuation =
                        bitlink.replace(trailingPunctuationRegex, '');
                    var params = $.param({
                        access_token:
                            '0f361a1266db582d97312bc0a8e433f6168c6330',
                        link: bitlinkNoTrailingPunctuation
                    });
                    var offset = params + '&' + $.param({
                        units: 1000,
                        unit_reference_ts: data[0].getTime()/1000
                    });
                    $.when($.getJSON(bitlyUrl + params), $.getJSON(bitlyUrl +
                        offset)).then(
                            _.bind(function (all, offset) {
                                if (all[0].status_code === 200) {
                                    var clicks = all[0].data.link_clicks -
                                        offset[0].data.link_clicks;
                                    var context;

                                    if (this._blastData === blastData) {
                                        segment.clicks = clicks;
                                        context = _.extend(popoverContext, {
                                            date: format.uiDateOnly(data[0]),
                                            subCount: format.integer(data[1]),
                                            blastData: blastData
                                        });
                                        widgetEl.find('.subgrowth-popover').
                                            html(popoverText(context));
                                    }
                                }
                            }, this));
                }
            }, this));
        }, 300),

        _onHierarchyChange: function () {
            var vm = this.option('viewmodel');
            var hierarchy = vm.hierarchy();
            var indexable = viewmodel.globals.lists._indexable;
            var config = indexable.config;

            viewmodel.globals.lists._indexable.source.fetch({group: hierarchy}).
                done(_.bind(function (result) {
                    var keys = config.keys;
                    var ignoreCase =
                        _.reduce(config.ignoreCase, function (result, item) {
                            result[item] = true;

                            return result;
                        }, {});

                    // index the result
                    // TODO: break this out into an index function
                    result._indices = {};
                    _.each(result, function (record, i) {
                        var key, value, bucket, index;

                        for (var j = 0; j < keys.length; j++) {
                            key = keys[j];

                            value = result[i][key];
                            if (ignoreCase[key]) {
                                value = value.toLowerCase();
                            }

                            index = result._indices[key] =
                                result._indices[key] || {};
                            bucket = index[value];

                            if (_.isUndefined(bucket)) {
                                index[value] = [i];
                            } else {
                                bucket.push(i);
                            }
                        }
                    });

                    vm.availableLists(result);
                    this._onInputChange();
                }, this));
        },

        _onInputChange: function () {
            var promises;
            var broadcastsByListsPromise;
            var vm = this.option('viewmodel');
            var timeframe = vm.timeframe();
            var hierarchy = vm.hierarchy();
            var lists = vm.lists();
            var chart = this.$chart;
            var subGrowthPromise;

            if (lists.length && timeframe) {
                chart.msgme('busy', true);
                promises = _.map(lists, function (list) {
                    return waterfall.reporting.recordFactory({
                        query: {
                            type: 'subscriberGrowth',
                            lists: [list],
                            timeframe: timeframe,
                            bucketSize: 'DAY',
                            group: hierarchy
                        }
                    }).
                    view({
                        // convert timeframe string (like 'LAST30') to number
                        size: +timeframe.replace(/^\D+/, '')
                    });
                });

                broadcastsByListsPromise = waterfall.reporting.recordFactory({
                    query: {
                        type: 'broadcastSummary',
                        timeframe: timeframe,
                        group: hierarchy
                    }
                }).
                view({
                    size: +(timeframe.replace(/^\D+/, '') * 10)
                }).then(function (broadcasts) {
                    return _.reduce(lists, function(result, list) {
                        var data = _.chain(broadcasts).
                            map(function (broadcast) {
                                if (_.contains(broadcast.lists, list)) {
                                    broadcast.segments.list = list;
                                    return [
                                        Date.create(broadcast.date),
                                        broadcast.segments
                                    ];
                                }
                            }).
                            compact().
                            value();

                        if (data.length) {
                            result[list] = data;
                        }
                        return result;
                    }, {});
                });

                // it's expecting a list of lists, that's why this is here
                // there's probably a better way to do this though
                subGrowthPromise = $.when.apply($, promises).then(function () {
                    return arguments;
                });

                $.when(subGrowthPromise, broadcastsByListsPromise).then(
                    _.bind(function (subGrowth, broadcastsByLists) {
                        this.$chart.msgme_line_chart('option', 'viewmodel').
                            data().dataSets(
                                createDataSet(lists, subGrowth,
                                    broadcastsByLists));

                        chart.msgme('busy', false);

                        setTimeout(_.bind(this._addBlastIcon, this),
                            this.$chart.msgme_line_chart(
                                'option', 'viewmodel').transitionDuration());
                    }, this));
            } else {
                this.$chart.msgme_line_chart('option', 'viewmodel').
                    data().dataSets([]);
                chart.msgme('busy', false);
            }
        },

        _addBlastIcon: function () {
            var vm = this.$chart.msgme_line_chart('option', 'viewmodel');
            var lineChartWidget =
                this.$chart.msgme_line_chart().data().msgme_line_chart;
            var lineChart = lineChartWidget.chart;
            var colorMap = lineChartWidget._colorMap;
            var dataSets = vm.data().dataSets();
            var data = _.chain(dataSets).
                map(function (dataSet) {
                    return _.filter(dataSet.data, function (data) {
                        return !!data[2];
                    });
                }).
                flatten(true).
                uniq(false, function (data) {
                    return data[0].toString() + data[1].toString();
                }).
                value();

            lineChart.select('g.overlay').
                selectAll('text.blast').data(data).
                enter().
                    append('text').
                    attr('class', 'blast').
                    attr('x', function (d) {
                        return lineChartWidget._chartScales.x(d[0]) - 5;
                    }).
                    attr('y', function (d) {
                        return lineChartWidget._chartScales.y(d[1]) + 7;
                    }).
                    attr('data-fill-color', function (d) {
                        return colorMap[_.first(d[2]).list];
                    }).
                    html(function() { return '&#xf02e'; });

        },

        _removeHoverClasses: function () {
            // $.fn.removeClass doesn't work on svg <circle>'s
            this.$chart.find('svg .hover').each(function (i, el) {
                el.setAttribute('class', '');
            });
        }
    });

    return {
        widget: $.msgme.msgme_subscriber_growth_chart,
        mapping: mapping
    };
});


