define('msgme/util/format',[
    'msgme/underscore',
    'json!widgets/shared-strings.json',
    'lib/sugar'
], function (_, strings) {
    // Add locale for relative stream time
    Date.addLocale('short', {
        'units':      'ms,s,m,h,d,w,mo,y',
        'past':       '{num}{unit}'
    });

    var format = {
        /**
         * Return a date formatted in the manner expected by Waterfall's API
         *
         * Ex: "Sun Aug 17 17:01:02 CDT 2011"
         */
        apiDate: function (date) {
            return Date.create(date).format(
                '{Dow} {Mon} {date} {H}:{mm}:{ss} {tz} {yyyy}');
        },

        /**
         * Return an ISODateString
         */
        ISODate: function (date) {
            return Date.create(date).toISOString();
        },

        /**
         * Return a date formatted in the manner expected by Waterfall's
         * reporting APIs (which are date only)
         *
         * Ex: "2012-08-12"
         */
        apiReportingDate: function (date) {
            return Date.create(date).format('{yyyy}-{MM}-{dd}');
        },

        /**
         * Return a date formatted in the manner expected by Waterfall's API
         *
         * Ex: "Sun Aug 17 17:01:02 CDT 2011"
         */
        apiLocalDate: function (date) {
            return Date.create(date).format(
                '{yyyy}-{MM}-{dd}T{HH}:{mm}:{ss}.{ms}{zzzz}');
        },

        /**
         * Return a date formatted in the manner expected by the UI
         *
         * Ex: "HH:MM:SS PM PDT MM-DD-YY"
         */
        uiDate: function (date) {
            return Date.create(date).format('{M}/{d}/{yy} {h}:{mm}:{ss} {TT}');
        },

        /**
         * Return the date only formatted in the manner expected by the UI
         *
         * Ex: "MM-DD-YYYY"
         */
        uiDateOnly: function (date) {
            return Date.create(date).format('{MM}-{dd}-{yyyy}');
        },

        /**
         * Return the time only formatted in the manner expected by the UI
         *
         * Ex: "HH:MM:SS PM PDT"
         */
        uiTimeOnly: function (date) {
            return Date.create(date).format('{h}:{mm}:{ss} {TT}');
        },

        /**
         * Return the day only
         *
         * Ex: "Saturday"
         */
        uiDayOnly: function (date) {
            return Date.create(date).format('{Weekday}');
        },

        /**
         * Return the time only without the seconds
         *
         * Ex: "H:MM PM PDT"
         */
        uiTimeOnlyNoSeconds: function (date) {
            return Date.create(date).format('{h}:{mm} {TT}');
        },

        /**
         * Return the time only in 24 hour format
         *
         * Ex: "HH:MM"
         */

        ui24HourTime: function (date) {
            return Date.create(date).format('{HH}:{mm}');
        },

        /**
         * Return a short representation of the time elapsed since a date
         *
         * If the date is in the future, return the absolute date instead.
         *
         * Ex: "2d"
         */
        uiShortRelativeDate: function (date) {
            var now = Date.now();
            var then = Date.create(date);

            if (then.getTime() < now) {
                return then.relative('short');
            } else {
                return format.uiDate(date);
            }
        },

        /**
         * Return an integer as a string with commas inserted as expected. If
         * the result is in scientific notation, return that unaltered.
         */
        integer: function (n) {
            n = n + '';
            var len = n.length;
            var result = [];

            if (n.match(/[^\d]/)) {
                // the string representation of n is in scientific notation, so
                // return that
                return n;
            }

            for (var i = len; i > 0; i -= 3) {
                result.push(n.slice(i - 3 >= 0 ? i - 3 : 0, i));
            }

            result = result.reverse();
            return result.join(',');
        },

        /**
         * Attempt to format a number or string as a US telephone number.
         *
         * If unable to do so, return the string unmodified.
         *
         * Ex: phoneNo(1234567890) -> '(123) 456-7890'
         *     phoneNo('001 123-456-7890') -> '(123) 456-7890'
         */
        phoneNo: function (value) {
            // check if there is anything in value before continuing
            if (value === void undefined || value === null) {
                return value;
            } else {
                // get the last 10 numerals in the string
                var num = ('' + value).match(/\d+/g).join('').slice(-10);

                if (num.length === 10) {
                    // if we extracted a 10 digit number, format it
                    return _.sprintf('(%s) %s-%s',
                        num.slice(0, 3), num.slice(3, 6), num.slice(6));
                } else {
                    // otherwise, return it unchanged
                    return value;
                }
            }
        },

        /**
         * Returns a ten digit unformatted telephone number
         * 
         * Ex: 0014087261028 -> 4087261028
         *     (408) 726-1028 -> 4087261028
         */
        unformattedPhoneNo: function (value) {
            if (value === void undefined || value === null) {
                return value;
            } else {
                var stripped = ('' + value).replace(/[^\d]/g, '');

                return stripped.slice(-10);
            }
        },

        /**
         * Returns a thirteen digit msisdn
         * 
         * Ex: 4087261028 -> 0014087261028
         *     (408) 726-1028 -> 0014087261028
         */

        msisdn: function (value) {
            if (this.findPhoneNo(value)) {
                return '001' + this.findPhoneNo(value);
            }
        },

        /**
         * Returns a ten digit telephone number if string is a number
         *
         * Ex: (408) 726-1028 -> 4087261028
         *     abcd -> null
         */

        findPhoneNo: function (value) {
            if (value === void undefined || value === null ||
                /[a-z]/i.test(value)) {
                return null;
            } else {
                var stripped = ('' + value).replace(/[^\d]/g, '');

                if (stripped.length === 10 || stripped.length === 13) {
                    return stripped.slice(-10);
                } else if (stripped.length === 11 && stripped[0] === '1') {
                    return stripped.slice(-10);
                } else {
                    return null;
                }
            }
        },

        /**
         * Return a value url-encoded and lowercase
         *
         * Spaces are encoded as pluses for legibility. If the input is null
         * or undefined, instead return ''.
         *
         * Ex: urlString('HEY BRO') -> 'hey+bro'
         *     urlString(123) -> '123'
         *     urlString(null) -> ''
         */
        urlString: function (value) {
            if (value == null) {
                return '';
            }

            return encodeURIComponent(value).
                replace(/\%20/g, '+').toLowerCase();
        },

        /**
         * Return a value url-decoded after replacing pluses with encoded ' '
         *
         * Ex: parseUrlString('hey+bro') -> 'hey bro'
         *     parseUrlString('hey%2c+%2bbro') -> 'hey, +bro'
         */
        parseUrlString: function (value) {
            return decodeURIComponent(value.replace(/\+/g, '%20'));
        },

        groupNonSystemMetadata: function (metadata) {
            var ret = {};
            var profile;
            var hasProfile = !!_.find(metadata, function(d) {
                return d.scope === 'PROFILE';
            });
            var custom = _.filter(metadata, function (meta) {
                return meta.scope === 'GROUP' || meta.scope === 'ACCOUNT';
            });

            if (hasProfile) {
                profile = _.filter(metadata, function (meta) {
                    return meta.scope === 'PROFILE';
                });
            }

            ret.custom = custom;
            ret.profile = profile;
            return ret;
        },

        /**
         * Return a list of metadata groups, ordered for the user
         *
         * Takes either a list of metadata POJO's or Record instances
         */
        groupMetadata: function (metadata) {
            var hasProfile = !!_.find(metadata, function(d) {
                return d.scope === 'PROFILE';
            });
            var profileMetadataOrdering = [
                'mobileNumber',
                'areaCode',
                'state',
                'timeZone',
                'zipCode',
                'addedAt',
                'firstName',
                'lastName',
                'bornOn',
                'email',
                'facebook',
                'twitter'
            ];
            var systemMetadataOrdering = [
                'msisdn',
                'areaCode',
                'state',
                'timeZone',
                'carrier'
            ];
            var split;
            var ret = {};

            function order(ordering, metadata) {
                var ordered = [];

                if (!metadata.length) {
                    return [];
                }

                _.each(ordering, function(name) {
                    var idx;
                    var meta = _.find(metadata, function (meta) {
                        return meta.name === name && meta.scope === 'PROFILE';
                    });
                    var id = meta ? meta.id : null;

                    if (hasProfile) {
                        idx = _.indexOf(_.pluck(metadata, 'id'), id);
                    } else {
                        idx = _.indexOf(_.pluck(metadata, 'name'), name);
                    }

                    if (idx >= 0) {
                        ordered.push(metadata.splice(idx, 1)[0]);
                    }
                });

                metadata.sort(function(a, b) {
                    if (a.name && b.name) {
                        return a.name.toLowerCase() >
                            b.name.toLowerCase() ? 1 : -1;
                    }
                });

                return [ordered, metadata];
            }

            split = hasProfile ?
                order(profileMetadataOrdering, metadata) :
                order(systemMetadataOrdering, metadata);

            ret[hasProfile ? 'profile' : 'system'] = split[0];
            ret.custom = split[1];

            return ret;
        },

        groupAndSortMetadata: function (metadata) {
            var hasProfile = !!_.find(metadata, function(d) {
                return d.scope === 'PROFILE';
            });

            var profile;
            var system;
            var custom;

            if (!metadata.length) {
                return [];
            }

            if (hasProfile) {
                profile = _.filter(metadata, function (meta) {
                    return meta.scope === 'PROFILE';
                });

                profile.sort(function(a, b) {
                    return a.name.toLowerCase() >
                        b.name.toLowerCase() ? 1 : -1;
                });
            }

            system = _.filter(metadata, function (meta) {
                return meta.scope === 'SYSTEM';
            });

            system.sort(function(a, b) {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            });

            custom = _.filter(metadata, function (meta) {
                return meta.scope !== 'SYSTEM' && meta.scope !== 'PROFILE';
            });
             
            custom.sort(function(a, b) {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            });

            return {
                profile: profile,
                system: system,
                custom: custom
            };
        },

        gsmRegex: function () {
            return new RegExp('[^A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå' +
                '\u0394_\u03A6\u0393\u0027\u0022\u039B\u03A9\u03A0\u03A8' +
                '\u03A3\u0398\u039EÆæßÉ!#$%&amp;()*+<>`,.:;\\-\\/&lt;=&gt;?' +
                '¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*', 'g');
        },

        knownCharCodeAt: function(str, idx) {
            var code;
            var surrogatePairs = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
            var hi, low;

            str += '';
            var end = str.length;

            while ((surrogatePairs.exec(str)) != null) {
                var li = surrogatePairs.lastIndex;

                if (li - 2 < idx) {
                    idx++;
                } else {
                    break;
                }
            }

            if (idx >= end || idx < 0) {
                return NaN;
            }

            code = str.charCodeAt(idx);

            if (0xD800 <= code && code <= 0xDBFF) {
                hi = code;
                low = str.charCodeAt(idx + 1);
                // Go one further, since one of the "characters"
                // is part of a surrogate pair
                return ((hi - 0xD800) * 0x400) +
                (low - 0xDC00) + 0x10000;
            }

            return code;
        },

        charCountStatus: function(message, maxChar, adCopyLength) {
            var dynamicInsertionRe = /{[^}]+}/;
            var doubleLengthCharRe = /([\^_{}[\]|€~\\])/g;
            var spanishRegex = /[èéùìòÇöñüà]/g;
            var gsmRegex = new RegExp('^[!\"#$%&\'()*+,-.\/0123456789:;<=>' +
                '?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_abcdefghijklmnopqrstuvwx' +
                'yz{|}~\u00A3\u00A5\u00D8\u00F8\u00C5\u00E5\u0394\u03A6' +
                '\u0393\u039B\u03A9\u03A0\u000D\u03A8\u03A3\u0398\u039E' +
                '\u00C6\u00E6\u00DF\u00C9\u00A4\u0020\\\\\u00A1\u00C4' +
                '\u00D6\u00D1\u00DC\u00A7\u00BF\u00E4\u20AC\u000A' +
                '\u2018\u2019\u201C\u201D]*$');
            var hasDynamicInsertion = message &&
                dynamicInsertionRe.test(message);
            var doubleLengthChars = 0;
            var length = message ? message.length : 0;
            var urlRegex = new RegExp('<URL:(.*?)>', 'gi');
            var isMms = maxChar > 160;
            var diff = 0;

            if (message && doubleLengthCharRe.test(message)) {
                doubleLengthChars = message.match(doubleLengthCharRe).length;
            }
                    
            if (message && message.match(urlRegex)) {
                _.each(message.match(urlRegex), function (match) {
                    diff = diff - 7 + match.length;
                });
            }

            if (adCopyLength) {
                length = length + adCopyLength.length;
            }

            if (isMms) {
                return {
                    max: strings.message.mmsMaxSize,
                    isOverChar: maxChar - length + diff < 0
                };
            } else if (hasDynamicInsertion) {
                return {
                    max: 0,
                    isOverChar: true
                };
            } else if (message && message.match(gsmRegex)) {
                if (message.match(spanishRegex)) {
                    return {
                        max: 70,
                        isOverChar: 70 - length + diff < 0
                    };
                } else {
                    return {
                        max: maxChar,
                        isOverChar: maxChar - length + diff -
                            doubleLengthChars < 0
                    };
                }
            } else if (!message) {
                return {
                    max: maxChar,
                    isOverChar: false
                };
            } else {
                return {
                    max: 70,
                    isOverChar: 70 - length + diff < 0
                };
            }
        }
    };

    return format;
});

