
define("json!widgets/stream/strings.json", function(){ return {
  "title": "Conversation Stream",
  "search": "Search conversations...",
  "newMessage": "new message",
  "newMessages": "new messages",
  "more": "Load more...",
  "sendMessage": {
    "title": {
      "sms": "Reply via",
      "other": "Reply"
    },
    "messagePlaceholder": "Message goes here",
    "email": {
      "addEmailHelp": {
        "text": {
          "paragraph1": "There is currently no email address on record for this person.\n",
          "paragraph2": {
            "beforeInterpolation": "If you know the email address for this person, click\n",
            "afterInterpolation": "below. If not, consider running a campaign that includes a Collect Metadata component that asks for the email addresses of your subscribers.\n"
          }
        },
        "button": "Add Email"
      },
      "placeholder": {
        "to": "To: Email address",
        "from": {
          "name": "From: First and last name",
          "address": "From: Email address"
        },
        "subject": "Subject:"
      },
      "toPrefix": "To: ",
      "updateTo": " (update)",
      "repliedIndicator": "Replied by email",
      "validation": "Please enter a valid email address"
    },
    "channel": {
      "sms": "Mobile",
      "email": "Email"
    }
  },
  "reply": {
    "sms": "Send SMS Response",
    "mms": "Send MMS Response",
    "facebook": "Send Facebook Response",
    "email": "Send Email Response"
  }
};});

