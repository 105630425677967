define('msgme/views/lists',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/list',
    'msgme/views/View',
    'msgme/widgets/pager',
    'msgme/util/format',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
    'msgme/plugins'
],
function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    list,
    View,
    Pager,
    format,
    featureFlags,
    sharedStrings
) {
    var view = new View('#lists-view');
    var page, pager;
    var cachedLists;

    list.collection.noResults = ko.observable(false);
    pager = new Pager(list.collection);

    function onFetchSuccess(listInstances) {
        _.each(listInstances, function (list) {
            list.noOfSubscribers = format.integer(list.noOfSubscribers);

            list.url = format.urlString(list.name);
        });
        list.collection.rows(listInstances.slice());
        pager.update(listInstances);
        splash.hide();
        $('#lists-view-main').msgme('busy', false);
        $('#lists-view-empty').removeClass('hidden');
    }

    function onFailure(what, e) {
        console.error('Failed to fetch', what + ': ', e);
    }

    function fetch() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#lists-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !list.collection.rows().length) {
            if (!list.collection.pageSize() || !list.collection.pageIndex() ||
                !list.collection.pageCount()) {
                resetViewmodelCollection();
            }

            // fetch the lists and populate page if not in a search
            view.callAPI('lists.fetch', {
                    page: page,
                    size: list.collection.pageSize
                }).
                done(function (result) {
                    cachedLists = result;
                    onFetchSuccess(result);
                }).
                fail(_.bind(onFailure, this, 'lists'));
        } else {
            //fetch for cache
            view.callAPI('lists.fetch', {
                    page: page,
                    size: 15
                }).
                done(function (result) {
                    cachedLists = result;
                    $('#lists-view-main').msgme('busy', false);
                }).
                fail(_.bind(onFailure, this, 'lists'));
        }
        
        viewmodel.globals.lists.refresh(true).done(function () {
            instantiateSearchBox();
        });
    }

    function resetViewmodelCollection() {
        list.collection.pageSize(15);
        list.collection.pageCount(-1);
        list.collection.pageIndex(-1);
        list.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var listNames = _.map(viewmodel.globals.lists(), function (list) {
            return list.name;
        });
        var $searchBoxEl = view.root.find('.search-box');

        if (!!$searchBoxEl.hasOwnProperty('autocomplete')) {
            $searchBoxEl.autocomplete('destroy');
        }

        $searchBoxEl.autocomplete({
            source: listNames,
            delay: 100
        });
    }

    // TODO: refactor this as functionality on the datatable
    $('#lists-view').on('click', 'td i.fa.fa-remove', function () {
        var rowEl = $(this).closest('tr');
        var record = list.collection.rows()[rowEl.index()];
        var listName = record.name;

        msgme.modal.confirm('Are you sure you want to delete list ' +
            listName + '?', {
            title: 'Delete List'
        }).done(_.bind(function (value) {
            if (value) {
                rowEl.addClass('deleting');
                record.del().
                    done(function () {
                        list.collection.rows.splice(rowEl.index(), 1);
                        msgme.alert.success('The list has been deleted');
                        fetch();
                    }).
                    fail(function () { rowEl.removeClass('deleting'); }).
                    fail(view.getRequestFailureFn(null,
                        'list.delete',
                        record.data().id));
            }
        }, this));
    });

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        list.collection.noResults(false);
        if (!query) {
            onFetchSuccess(cachedLists);
        } else {
            results = _.map(viewmodel.globals.lists(), function (list) {
                if(list.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return list;
                }
            });

            onFetchSuccess(_.compact(results));

            if (!_.compact(results).length) {
                list.collection.noResults(true);
            }
        }

        view.root.find('.search-box').autocomplete('close');
    });

    view.root.on('click', '.search-flow', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        onFetchSuccess(cachedLists);
        view.root.find('.search-box').val('');
        list.collection.noResults(false);
    });

    featureFlags('restrictPapaMurphysUI').then(function () {
        view.root.find('#lists-view-header a.btn').hide();
    });

    path.map(sharedStrings.sitemap.lists.url + '(/page/:page)').to(function () {
        if (!list.collection.pageSize() || !list.collection.pageIndex() ||
            !list.collection.pageCount() || !this.params.page) {
            resetViewmodelCollection();
        }
        view.show();
        instantiateSearchBox();
        page = this.params.page;
        fetch();
    });

    return view;
});

