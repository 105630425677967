define('msgme/viewmodel/flow',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/ko/mapping',
    'msgme/viewmodel',
    'lib/xregexp',
    'json!widgets/shared-strings.json'
], function(_, ko, komapping, viewmodel, xregexp, strings) {
    var model = ko.observable(null);

    function createModule(data) {
        var widgetName = 'msgme_flowmodule_' + data.type.toLowerCase();

        return ko.observable(new $.msgme[widgetName].Model(data));
    }

    // WARNING: i think this is just completely not-used. but i'm afraid to
    // delete it.
    model.isValid = function (valid) {
        var flow = model();

        if (!flow) {
            return true;
        }

        var keys = _.keys(flow);
        if (valid === void undefined) {
            return _.all(keys, function (k) {
                if (flow[k].isValid) {
                    return flow[k].isValid();
                }

                return true;
            });
        }
    };

    return {
        mapping: function(view) {
            return {
                defaults: {
                    campaign: null,
                    keywords: [],
                    mobileflow: {
                        account: null,
                        campaign: null,
                        programName: null,
                        frequency: null,
                        group: null,
                        id: null,
                        name: null,
                        shortCode: null,
                        status: null
                    },
                    modules: []
                },

                campaign: {
                    create: function (options) {
                        return ko.mapping.fromJS(options.data || {
                            id: null
                        });
                    }
                },
                modules: {
                    create: function (options) {
                        return createModule(options.data);
                    }
                },

                local: {
                    newKeyword: '',
                    pending: null
                },

                extend: {
                    newKeyword: {
                        validation: {
                            async: true,
                            validator: function (val, params, callback) {
                                var newKeywordEl =
                                    view.root.find('.new-keyword');
                                var addKeywordBtn =
                                    view.root.find('.add-keyword.btn');
                                var keywordInputEl =
                                    view.root.find(
                                        '.emojionearea.keyword-emoji-input');
                                var flow = viewmodel.flow();
                                val = _.trim(val);

                                if (!flow) {
                                    callback(true);
                                    return;
                                }

                                var keywords = flow.keywords().
                                    map(function (keyword) {
                                        return keyword.name();
                                    });

                                newKeywordEl.
                                    removeClass('validating valid invalid').
                                    removeClass('error');
                                keywordInputEl.
                                    removeClass('validating valid invalid').
                                    removeClass('error');

                                if (val === '' || (flow.deletedKeywords &&
                                    _.has(flow.deletedKeywords, val))) {
                                    // set valid on the next tick
                                    newKeywordEl.addClass('valid');
                                    keywordInputEl.addClass('valid');
                                    addKeywordBtn.addClass('btn-success');
                                    setTimeout(function () {
                                        callback(true);
                                    }, 0);
                                    return;
                                }

                                // invalid if empty, contains non-alphanum
                                // chars, or already in use for flow
                                // TODO: condense these to one
                                var hasSpaces = val.match(/\s/);

                                if (hasSpaces || val.length > 39 ||
                                    keywords.indexOf(val) >= 0 ||
                                    viewmodel.globals.keywords.oneByName(
                                        val.toLowerCase())
                                ) {
                                    newKeywordEl.addClass('error invalid');
                                    keywordInputEl.addClass('error invalid');
                                    addKeywordBtn.removeClass('btn-success');

                                    // set invalid on the next tick
                                    setTimeout(function () {
                                        callback(false);
                                    }, 0);
                                    return;
                                }

                                newKeywordEl.addClass('validating');
                                this._inflightValidations =
                                    this._inflightValidations || 0;
                                this._inflightValidations++;

                                waterfall.keywords.checkAvailability(val).
                                    done(function (result) {
                                        this._inflightValidations--;
                                        newKeywordEl.removeClass('validating').
                                            addClass(result.available ?
                                                'valid' :
                                                'error invalid');
                                        keywordInputEl.
                                            removeClass('validating').
                                            addClass(result.available ?
                                                'valid' :
                                                'error invalid');
                                        if (result.available) {
                                            addKeywordBtn.
                                                addClass('btn-success');
                                        } else {
                                            addKeywordBtn.
                                                removeClass('btn-success');
                                        }
                                        callback(result.available);
                                    }).
                                    fail(view.getRequestFailureFn(
                                        null,
                                        'keywords.checkAvailability',
                                        val));
                            }
                        }
                    }
                },

                keywords: {
                    mapping: {
                        local: {
                            pending: false
                        }
                    }
                },

                mobileflow: {
                    mapping: {
                        local: {
                            error: null,
                            duplicateName: false
                        },
                        computed: {
                            isNameLengthValid: function () {
                                if (this.name() === null) {
                                    return false;
                                } else {
                                    return this.name().length > 4 &&
                                        this.name().length < 151;
                                }
                            },
                            isNameValid: function () {
                                return this.isNameLengthValid() &&
                                    this.duplicateName() === false;
                            }
                        },
                        subcomputed: {
                            name: {
                                asString: {
                                    read: function () {
                                        if (this.name() &&
                                            this.name().length > 0
                                        ) {
                                            return this.name();
                                        } else {
                                            return void undefined;
                                        }
                                    },

                                    write: function (root, parent, value) {
                                        if (value) {
                                            this.name(value);
                                        } else {
                                            this.name(null);
                                        }
                                    }
                                }
                            },
                            programName: {
                                asString: {
                                    read: function () {
                                        if (this.programName() &&
                                            this.programName().length > 0
                                        ) {
                                            return this.programName();
                                        } else {
                                            return void undefined;
                                        }
                                    },

                                    write: function (root, parent, value) {
                                        if (value) {
                                            this.programName(value);
                                        } else {
                                            this.programName(null);
                                        }
                                    }
                                }
                            }
                        }
                    }
                },

                subcomputed: {
                    keywords: {
                        asString: {
                            read: function () {
                                if (this.keywords().length < 1) {
                                    return void undefined;
                                }

                                return _.map(this.keywords(),
                                    function (keyword) {
                                        return keyword.name();
                                    }).join(' ');
                            },

                            write: function (root, parent, value) {
                                var keywords = {};

                                // index the keywords
                                _.each(this.keywords(),
                                    function (keyword) {
                                        keywords[keyword.name()] =
                                            keyword.id();
                                    });

                                // save keywords, including ids if already
                                // present
                                this.keywords(_.map(value.split(' '),
                                    function (keyword) {
                                        return ko.mapping.fromJS({
                                            name: keyword,
                                            id: keywords[keyword]
                                        });
                                    }));
                            }
                        }
                    }
                },

                validation: {
                    modules: {
                        validator: function (val) {
                            return _.chain(val).
                                map(function (module) {
                                    return module().isValid();
                                }).
                                all().
                                value();
                        },

                        message: strings.validation.allMustBeValid
                    }
                }
            };
        },
        model: viewmodel.flow = model,
        collection: viewmodel.flows = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('campaigns/page')
        },
        createModule: createModule
    };
});


