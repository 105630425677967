
define("json!widgets/file-uploader/strings.json", function(){ return {
  "remove": "Remove",
  "browse": "Browse",
  "errors": {
    "0": "There was an error uploading the file",
    "413": "File is too large",
    "maxFileSize": "This file is greater than the %(maxSizeMBs)s MB limit"
  },
  "filePreview": {
    "header": "Preview",
    "dismiss": "Ok"
  }
};});

