
define("json!widgets/subscriber-search/strings.json", function(){ return {
  "mainHeading": "Subscriber search results",
  "noResultsMessage": "No results were found for that search query.",
  "columnHeadings": {
    "firstName": "First name",
    "lastName": "Last name",
    "email": "Email",
    "mobileNumber": "Mobile number"
  }
};});

