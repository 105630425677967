
define("json!widgets/segment-metadata-value/strings.json", function(){ return {
  "add": "Add Segment",
  "selectOperator": "Select Operator",
  "geo": {
    "error": {
      "value": "Code must only be digits",
      "radius": "Radius must be a number"
    }
  }
}
;});

