define('msgme/views/sweeps',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/sweep',
    'msgme/views/View',
    'msgme/widgets/pager',
    'msgme/util/format',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
    'msgme/plugins'
],
function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    sweep,
    View,
    Pager,
    format,
    featureFlags,
    sharedStrings
) {
    var view = new View('#sweeps-view');
    var page, pager;
    var cachedSweeps;

    sweep.collection.noResults = ko.observable(false);
    pager = new Pager(sweep.collection);

    function onFetchSuccess(sweepInstances) {
        _.each(sweepInstances, function (sweep) {
            var list = '';
            sweep.status = new Date().isBetween(
                sweep.headNode.startDate, sweep.headNode.endDate) ?
                'ACTIVE' : 'INACTIVE';
            sweep.start = format.uiDate(sweep.headNode.startDate);
            sweep.end = format.uiDate(sweep.headNode.endDate);
            _.each(sweep.keywords, function (keyword, index) {
                list = index !== sweep.keywords.length - 1 ?
                    list + keyword.name + ', ' :
                    list + keyword.name;
            });
            sweep.keywordList = list;
        });

        sweep.collection.rows(sweepInstances.slice());
        pager.update(sweepInstances);
        splash.hide();
        $('#sweeps-view-main').msgme('busy', false);
        $('#sweeps-view-empty').removeClass('hidden');
    }

    function onFailure(what, e) {
        console.error('Failed to fetch', what + ': ', e);
    }

    function fetch() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#sweeps-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !sweep.collection.rows().length) {
            if (!sweep.collection.pageSize() || !sweep.collection.pageIndex() ||
                !sweep.collection.pageCount()) {
                resetViewmodelCollection();
            }

            // fetch the sweeps and populate page if not in a search
            view.callAPI('sweeps.fetch', {
                    page: page,
                    size: sweep.collection.pageSize
                }).
                done(function (result) {
                    cachedSweeps = result;
                    onFetchSuccess(result);
                }).
                fail(_.bind(onFailure, this, 'sweeps'));
        } else {
            //fetch for cache
            view.callAPI('sweeps.fetch', {
                    page: page,
                    size: 15
                }).
                done(function (result) {
                    cachedSweeps = result;
                    $('#sweeps-view-main').msgme('busy', false);
                }).
                fail(_.bind(onFailure, this, 'sweeps'));
        }
        
        viewmodel.globals.sweeps.refresh(true).done(function () {
            instantiateSearchBox();
        });
    }

    function resetViewmodelCollection() {
        sweep.collection.pageSize(15);
        sweep.collection.pageCount(-1);
        sweep.collection.pageIndex(-1);
        sweep.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var sweepNames = _.map(viewmodel.globals.sweeps(), function (sweep) {
            return sweep.mobileFlow.name;
        });
        var $searchBoxEl = view.root.find('.search-box');

        if (!!$searchBoxEl.hasOwnProperty('autocomplete')) {
            $searchBoxEl.autocomplete('destroy');
        }

        $searchBoxEl.autocomplete({
            source: sweepNames,
            delay: 100
        });
    }

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        sweep.collection.noResults(false);
        if (!query) {
            onFetchSuccess(cachedSweeps);
        } else {
            results = _.map(viewmodel.globals.sweeps(), function (sweep) {
                if(sweep.mobileFlow.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return sweep;
                }
            });

            onFetchSuccess(_.compact(results));

            if (!_.compact(results).length) {
                sweep.collection.noResults(true);
            }
        }

        view.root.find('.search-box').autocomplete('close');
    });

    view.root.on('click', '.search-flow', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        onFetchSuccess(cachedSweeps);
        view.root.find('.search-box').val('');
        sweep.collection.noResults(false);
    });

    featureFlags('restrictPapaMurphysUI').then(function () {
        view.root.find('#sweeps-view-header a.btn').hide();
    });

    path.map(sharedStrings.sitemap.sweeps.url + '(/page/:page)').to(
        function () {
            if (!sweep.collection.pageSize() || !sweep.collection.pageIndex() ||
                !sweep.collection.pageCount() || !this.params.page) {
                resetViewmodelCollection();
            }
            view.show();
            instantiateSearchBox();
            page = this.params.page;
            fetch();
        });

    return view;
});

