define('msgme/widgets/flowmodule-sportsfeed',[
    'msgme/underscore',
    'jquery',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-sportsfeed',
    'widgets/new-list-modal/index'
],
function (_, $, ko, flowmodule, sportsfeed) {
    $.widget('msgme.msgme_flowmodule_sportsfeed', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-sportsfeed-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);

            this.on('click', '.create-list', '_openModal');
        },

        _openModal: function () {
            var $newListEl = this.element.find('.new-list-container');

            $newListEl.msgme_new_list_modal('show');
        }
    });

    $.msgme.msgme_flowmodule_sportsfeed.Model =
        flowmodule.modelFactory({}, {
        mapping: sportsfeed.mapping
    });

    return $.msgme.msgme_flowmodule_sportsfeed;
});


