define('widgets/spinner/index',[
    'lib/spin',
    'msgme/ko'
], function (Spinner, ko) {
    ko.bindingHandlers.spinner = {
        init: function (el, valueAccessor) {
            new Spinner(valueAccessor()).spin(el);
        }
    };

    return Spinner;
});

