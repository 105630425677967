define('msgme/views/subscriber-search',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/path',
    'msgme/splash',
    'msgme/views/View',
    'msgme/viewmodel',
    'json!widgets/shared-strings.json',
    'widgets/subscriber-search/index'
], function (
    _,
    ko,
    path,
    splash,
    View,
    viewmodel,
    sharedStrings
) {
    var view = new View('#subscriber-search-view');
    var baseUrl = sharedStrings.sitemap.subscriber.searchUrl;
    var urlRe = new RegExp('^' + baseUrl + '/([^/]+$)');

    path.map(urlRe).to(function (fullPath, searchQuery) {
        _.defer(function performSearch() {
            var $subscriberSearchContainer =
                view.root.find('.subscriber-search-container');

            $subscriberSearchContainer.
                msgme_subscriber_search('searchOn', searchQuery);
        });

        view.show();
        splash.hide();
    });

    return view;
});

