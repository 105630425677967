define('msgme/user-preferences',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel/mapping'
], function (_, ko, mapping) {
    var recordModel;
    var KEY;

    /**
     * accessor
     *
     * this is the return value of this module, and it returns a promise of a
     * RecordModel-ish instance that contains the user preferences.
     *
     * it should be opaque to the application whether the preferences are
     * coming from localStorage or an api.
     */
    function accessor() {
        // assume that by now the user has authenticated
        KEY = 'waterfall-user-prefs-' + waterfall.authenticate.username;

        if (!recordModel) {
            recordModel = window.prefs = UserPreferencesModel.create();
        }

        return $.Deferred().resolve(recordModel).promise();
    }

    /**
     * clear
     *
     * clear the user preferences, both in localStorage and the cached
     * reference to the instance.
     */
    accessor.clear = function () {
        var promise = recordModel ?
            recordModel.del() : $.Deferred().resolve().promise();

        recordModel = null;

        return promise;
    };

    accessor.key = function() {
        return KEY;
    };

    /**
     * UserPreferencesModel
     *
     * this is intended to mimic the behavior of a RecordModel, except it is
     * saved to window.localStorage instead of persisting it to an API.
     *
     * key differences with RecordModel are that it has no pre-defined mapping,
     * and it is a singleton in the sense that if two different instances are
     * saved, the second will overwrite the first (because they're using the
     * same KEY).
     */
    function UserPreferencesModel() {
        mapping.RecordModel.apply(this, arguments);
    }

    UserPreferencesModel.prototype.save = function () {
        localStorage.setItem(KEY, JSON.stringify(ko.mapping.toJS(this)));

        return $.Deferred().resolve(this).promise();
    };

    UserPreferencesModel.prototype.del = function () {
        localStorage.removeItem(KEY);

        return $.Deferred().resolve().promise();
    };

    /**
     * UserPreferencesModel#initPref
     *
     * views and widgets define their own preference keys, so the mapping is
     * essentially determined at run time. knockout mappings aren't designed to
     * be changed, so this is a convenience method that adds a property to the
     * mapping if it's not already there.
     *
     * note this has no effect for existing properties.
     *
     * name: name of the property to initialize
     * value: value to set *if* the property did not already exist
     * returns: UserPreferencesModel instance
     */
    UserPreferencesModel.prototype.initPref = function (name, value) {
        var obj = {};

        if (!this[name]) {
            obj[name] = value;
            ko.mapping.fromJS(obj, this._mapping, this);
        }

        return this;
    };

    /**
     * UserPreferencesModel#init
     *
     * initialize preference settings. runs UserPreferencesModel#initPref for
     * each property/value pair in the object it receives.
     *
     * prefs: object with property/value pairs to be passed to initPref
     * returns: UserPreferencesModel instance
     */
    UserPreferencesModel.prototype.init = function (prefs) {
        _.each(prefs, _.bind(function (value, name) {
            this.initPref(name, value);
        }, this));
    };

    UserPreferencesModel.create = function () {
        var stored = localStorage.getItem(KEY);
        var raw = stored ? JSON.parse(stored) : {};
        var record = new waterfall.record.Record(raw, {url: 'local'});

        return new UserPreferencesModel(record);
    };

    return accessor;
});

