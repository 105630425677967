
define("json!widgets/mobile-message-body/strings.json", function(){ return {
  "sms": "SMS",
  "mms": "MMS",
  "subject": "Subject",
  "mmsUpsell": {
    "heading": "MMS is here!",
    "info": "Wow your audience with engaging images, audio and video.  Share enticing coupons and deals with your subscribers.  Send texts that stand out with subject lines and up to %(mmsMaxNumChars)s characters.\n",
    "callToAction": "For more information please contact %(mailToLink)s.\n",
    "contactEmail": "sales@jatxt.com"
  },
  "fixedAppendedText": "This text will be appended to the message.",
  "fixedPrependedText": "This text will be prepended to the message.",
  "smsFallbackDefault": "We're sorry, your phone cannot receive MMS messages. But you can still receive our great offer.",
  "smsFallbackInfo": "MMS subject field is optional when fallback message is enabled. For devices that do not support MMS, a SMS fallback message is sent. The default message is the subject line plus a web link to content. Here you have the ability to customize the text preceding the link.",
  "smsFallbackNote": "Note: the above message will be followed by a URL link to the content (",
  "smsExampleUrl": "Example: http://content.waterfall.com/XXXXXXX",
  "smsExampleEnd": ")",
  "smsFallbackLabel": "Add custom SMS fallback message"
};});

