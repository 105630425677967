define('widgets/url-shortener/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    './../three-widget',
    'json!./strings.json',
    'text!./template.html'
], function (_, viewmodel, ThreeWidget, strings, template) {
    var passedObservables = {
        isDisabled: false
    };

    var internalObservables = {
        select2DropdownCssClass: 'dynamic-insertion-dropdown',
        link: 'http://'
    };

    var allObservables = _.extend({}, passedObservables, internalObservables);

    var mapping = {
        defaults: _.clone(allObservables),
        computed: {
            url: function () {
                if (this.link()) {
                    return '<URL:' + this.link() + '>';
                }
            }
        },
        validation: {
            link: {
                validator: function (value) {
                    if (value) {
                        return value.match('^https?://');
                    }
                },

                message: 'Please enter a valid url'
            }
        }
    };

    $.widget('msgme.msgme_url_shortener', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            this.on('click', '.open-popover', '_openPopover');
            this.on('click', '.insert', '_insert');
            this.on('mouseenter', '.popover', '_onEnter');
            this.on('mouseleave', '.popover', '_onLeave');
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        },

        _onEnter: function () {
            this.element.find('.url-shortener').addClass('remove-hover');
        },

        _onLeave: function () {
            this.element.find('.url-shortener').removeClass('remove-hover');
        },

        _insert: function (e) {
            var vm = this.option('viewmodel');
            var $message = $(e.target.closest('.tab-pane')).find('.message')[0];
            var currentPosition = $message.selectionStart;

            if (!vm.link()) {
                this._closePopover();
                return;
            }

            if (!vm.link.isValid()) {
                vm.link.isModified(true);
                return;
            }

            if (currentPosition || currentPosition === 0) {
                var startPos = currentPosition;
                var endPos = $message.selectionEnd;

                $message.value = $message.value.substring(0, startPos) +
                    vm.url() +
                    $message.value.substring(endPos, $message.value.length);
                $($message).change();
            }

            this._closePopover();
        },

        _openPopover: function () {
            // hide any popover that may already be open 
            $('.toolbar-popover').hide();

            this.option('viewmodel').link('http://');
            this.element.find('.popover').show();
        },

        _closePopover: function () {
            this.element.find('.popover').hide();
        }
    });

    return {
        widget: $.msgme.msgme_url_shortener,
        mapping: mapping
    };
});

