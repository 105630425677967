define('msgme/ko/ignore',[
    'lib/knockout'
], function (ko) {

    // just block knockout from trying to bind to anything under this element
    ko.bindingHandlers.ignore = {
        init: function () {
            return {controlsDescendantBindings: true};
        }
    };

    return ko.bindingHandlers.ignore;
});


