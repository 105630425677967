define('widgets/recent-broadcast-stat-card/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/format',
    './../stat-card/index',
    'text!./template.html'
], function (_, ko, viewmodel, format, statCard, template) {
    $.widget('msgme.msgme_recent_broadcast_stat_card', statCard.widget, {
        _mapping: {
            defaults: {
                clickthrough: 0,
                reach: 0,
                unsubRate: 0,
                hierarchy: null
            },
        },

        _template: template,

        _createViewModel: function () {
            var vm = this._super.apply(this, arguments);

            vm.data(ko.mapping.fromJS({
                type: 'recentBroadcast',
            }));
            return vm;
        },

        _create: function () {
            statCard.widget.prototype._create.apply(this, arguments);

            this.option('viewmodel').hierarchy.subscribe(
                _.bind(this._loadData, this));
        },

        _loadData: function () {
            var vm = this.option('viewmodel');
            var me = this;
            var hierarchy = vm.hierarchy();
            var recentEl = this.element;

            recentEl.msgme('busy', true);
            return waterfall.reporting.recordFactory({
                query: {
                    type: 'broadcastSummary',
                    timeframe: 'LAST30',
                    group: hierarchy
                }
            }).
            view({ size: 1 }).
            then(function (result) {
                recentEl.msgme('busy', false);
                if (result.length) {
                    var reach =
                        _.reduce(result[0].segments, function (num, segment) {
                            return segment.count + num;
                        }, 0);
                    vm.reach(format.integer(reach));
                    me._getBitlyData(result[0]);
                    me._getUnsubRate(result[0]);
                } else {
                    vm.reach(0);
                }
            });
        },

        _getUnsubRate: function (record) {
            var vm = this.option('viewmodel');
            var hierarchy = vm.hierarchy();

            waterfall.reporting.subscriberGrowth.fetch({
                beginOn: Date.create(record.date).format('{yyyy}-{MM}-{dd}'),
                size: 30,
                endOn: Date.create().format('{yyyy}-{MM}-{dd}'),
                bucketSize: 'DAY',
                group: hierarchy
            }).
            then(function (results) {
                if (results.length) {
                    var rate = results[0].noUnsubs / results[0].listSize;
                    vm.unsubRate((rate*100).toFixed(2));
                }
            });
        },

        _getBitlyData: function (record) {
            var vm = this.option('viewmodel');
            var bitlyUrl = 'https://api-ssl.bitly.com/v3/link/clicks?';
            var re = /bit\.ly\S*|bitly.com\S*\b/;
            var bitlyDisallowedPunctuation = '.,!?';
            var trailingPunctuationRegex =
                new RegExp('[' + bitlyDisallowedPunctuation + ']*$');

            _.each(record.segments, _.bind(function (segment) {
                if (segment.message && segment.message.match(re)) {
                    var bitlink = 'https://' + segment.message.match(re)[0];
                    var bitlinkNoTrailingPunctuation =
                        bitlink.replace(trailingPunctuationRegex, '');
                    var params = $.param({
                        access_token:
                            '0f361a1266db582d97312bc0a8e433f6168c6330',
                        link: bitlinkNoTrailingPunctuation
                    });
                    var offset = params + '&' + $.param({
                        units: 1000,
                        unit_reference_ts: new Date(record.date).getTime()/1000
                    });
                    $.when($.getJSON(bitlyUrl + params), $.getJSON(bitlyUrl +
                        offset)).then(
                            _.bind(function (all, offset) {
                                if (all[0].status_code === 200) {
                                    var clicks = all[0].data.link_clicks -
                                        offset[0].data.link_clicks;
                                    vm.clickthrough(
                                        (clicks/segment.count*100).toFixed(2));
                                }
                            }, this));
                }
            }), this);

        }
    });

    return {
        widget: $.msgme.msgme_recent_broadcast_stat_card,
        mapping: statCard.mapping
    };
});

