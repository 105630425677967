define('widgets/file-preview/index',[
    'msgme/underscore',
    './../three-widget',
    'text!./template.html',
], function (_, ThreeWidget, template) {

    var mapping = {
        defaults: {
            id: null,
            fileData: null,
            loadEvent: null
        },

        computed: {
            isLoading: function () {
                return this.id() && !this.loadEvent();
            },

            src: function () {
                var id = this.id();

                return id && (waterfall.file.url + '/' + id);
            },

            type: function () {
                var fileData = this.fileData();

                return fileData && fileData.contentType.split('/')[0];
            }
        }
    };

    $.widget('msgme.msgme_file_preview', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            this._super();
            this.option('viewmodel').id.subscribe(this._onIdChange.bind(this));
        },

        _onIdChange: function () {
            var vm = this.option('viewmodel');

            vm.fileData(null);
            vm.loadEvent(null);

            if (vm.id()) {
                waterfall.fileV2.fetch(vm.id() + '/details').then(vm.fileData);
            }
        }
    });

    return {
        widget: $.msgme.msgme_file_preview,
        mapping: mapping
    };
});

