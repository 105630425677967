define('msgme/views/dashboard',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/path',
    'msgme/splash',
    'msgme/views/View',
    'msgme/viewmodel',
    'msgme/user-preferences',
    'widgets/dashboard/index',
    'widgets/dashboard-tour/index',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
], function (
    _,
    ko,
    path,
    splash,
    View,
    viewmodel,
    userPreferences,
    dashboard,
    dashboardTour,
    featureFlags,
    sharedStrings
) {
    var view = new View('#dashboard-view');
    function initialize() {
        view.root.find('.dashboard').
            msgme_dashboard();
            // temp remove dashboard tour
            //msgme_dashboard_tour();
    }
    var initialized = false;

    featureFlags('dashboard').then(function () {
        path.map(sharedStrings.sitemap.dashboard.url).to(function () {
            viewmodel.globals.lists.refresh().then(function () {
                view.show();
                splash.hide();

                // on startup there is a case where we get here before the
                // initial ko.applyBindings call. this causes an exception
                // when the outer-most applyBindings tries to process
                // bindings of the subscriber growth three-widget, so defer
                // this call
                if (!initialized) {
                    _.defer(initialize);
                    initialized = true;
                }
            });
        });
    });

    return view;
});

