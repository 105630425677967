define('msgme/widgets/message-textbox',[
    'msgme/underscore',
    'msgme/widgets/widget',
    'msgme/viewmodel',
    'widgets/dynamic-insertion/index'
], function (_, Widget, viewmodel) {
    $.widget('msgme.msgme_message_textbox', Widget, {
        options: {
            widgetEventPrefix: 'message-textbox-',
            overMaxClass: 'warning',
            updateDelay: 100
        },

        _create: function () {
            var defaultMaxLength =
                viewmodel.globals.shortcodes.session().maxSmsLength;

            Widget.prototype._create.apply(this);

            // always call _updateValue in the context of the widget
            this._updateValue = _.bind(this._updateValue, this);
            this.on('keydown', 'textarea', '_onKeyDown');

            this.on(this.options.widgetEventPrefix + 'data-change',
                '_onDataChange');
            this.element.on('message-textbox-data-change',
                this._onDataChange.bind(this));

            this.options.maxLength =
                this.options.maxLength ?
                this.options.maxLength : defaultMaxLength;

            this._subscribe();

            // Commented out to disable dynamic insertion for PT #66234136.
            // Uncomment to re-enable.
            //this._initDynamicInsertion();
        },

        _onDataChange: function () {
            this._dataSubscription.dispose();
            this._dataSubscription = null;
            this._subscribe();
        },

        _subscribe: function () {
            // update the character count when our data changes
            if (!this._dataSubscription) {
                this._dataSubscription = this.options.data.
                subscribe(_.bind(this._onDataValueChange, this));
            }
            this._onDataValueChange();
        },

        _onRevive: function () {
            this._subscribe();
            this.on('keydown', 'textarea', '_onKeyDown');
            this.element.on('message-textbox-data-change',
                this._onDataChange.bind(this));
        },

        _onKeyDown: function () {
            /**
             * When the user hits a key, we will update the value of the
             * TEXTAREA at least updateDelay milliseconds later. Note that this
             * is not the same as either _.debounce, which will not update until
             * the user stops typing, or _.throttle, which does not update at
             * the end of its wait period.
             */

            if (!this._updateTimeoutHandle) {
                this._updateTimeoutHandle =
                    setTimeout(this._updateValue, this.options.updateDelay);
            }
        },

        _updateValue: function () {
            this.options.data(this.element.find('textarea').val());
            this._updateTimeoutHandle = null;
        },

        _onDataValueChange: function () {
            var data = this.options.data() || '';
            var len = data.length;
            var max = this.options.maxLength;
            this.element.find('.count').text(max - len);
            this.element.toggleClass(
                this.options.overMaxClass, len > this.options.maxLength);
        },

        _initDynamicInsertion: function () {
            var $textarea = this.element.find('textarea');
            var $container = this.element.find('.dynamic-insertion-container');
            $container.msgme_dynamic_insertion({
                $insertionTarget: $textarea
            });
        },

        destroy: function () {
            this._dataSubscription.dispose();
            Widget.prototype.destroy.apply(this);
        }
    });

    return $.msgme.msgme_message_textbox;
});

