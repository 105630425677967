define('msgme/views/campaign-activity',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/path',
    'msgme/splash',
    'msgme/views/View',
    'msgme/viewmodel',
    'msgme/viewmodel/campaign-activity',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
    'widgets/campaign-activity-entry/index'
], function (
    _,
    ko,
    path,
    splash,
    View,
    viewmodel,
    campaignActivityView,
    featureFlags,
    sharedStrings
) {
    var view = new View('#campaign-activity-view');
    var lastMessageId = null;
    var latestMessageId = null;
    var url = sharedStrings.sitemap.campaignActivity.url;
    var id;
    var campaignActivity;
    var size = 25;
    var pollInterval = 20 * 1000;
    var polling;
    var timeout = 0;
    var idleModalShown = true;

    viewmodel.campaignActivity(
        ko.mapping.fromJS({}, campaignActivityView.mapping));
    campaignActivity = viewmodel.campaignActivity();

    view.root.on('click', '.new-messages', function () {
        _.each(campaignActivity.newMessageCache().reverse(),
            function (message) {
            campaignActivity.data().unshift(message);
        });

        campaignActivity.newMessageCache(null);
        campaignActivity.data.valueHasMutated();
        campaignActivity.newMessage(null);
        latestMessageId = _.first(campaignActivity.data()).id;
    });

    $('body').on('click mousemove keyup', function () {
        campaignActivity.idle(0);
    });

    view.root.on('click', '.more-messages', function () {
        var query = {
            id: id,
            orderBy: '-_id',
            size: size,
            pivotId: lastMessageId,
        };

        campaignActivity.moreMessagesPromise(
            view.callAPI('mobileflows.activity', query).
                done(function (result) {
                    if (!result.length) {
                        campaignActivity.hasMoreMessages(false);
                    } else {
                        _.each(result, function (message) {
                            campaignActivity.data().push(message);
                        });

                        if (result.length < size) {
                            campaignActivity.hasMoreMessages(false);
                        }

                        campaignActivity.data.valueHasMutated();
                        lastMessageId = _.last(result) ?
                            _.last(result).id : null;
                    }
                }));
    });

    function poll() {
        var idle = campaignActivity.idle();
        var query = {
            id: id,
            size: -1,
            orderBy: '_id'
        };

        // stop polling once the view is not active
        if (view.root.hasClass('hidden')) {
            clearInterval(polling);
        }

        if (latestMessageId) {
            query.pivotId = latestMessageId;
        }

        if (idleModalShown &&
            $('#login-view').is(':hidden')) {
            if (timeout < 90) {
                timeout = timeout + 1;
            } else {
                $('#msgme-modal').modal('hide');
                return view.callAPI('mobileflows.activity', query).
                    done(function () {
                        timeout = 0;
                    });
            }
        }

        if (idle < 60 && $('#msgme-modal').is(':hidden') &&
            $('#login-view').is(':hidden')) {
            return view.callAPI('mobileflows.activity', query).
                done(function (result) {
                    campaignActivity.newMessageCache(result);
                    campaignActivity.newMessage(result.length);
                    campaignActivity.idle(idle + 1);
                    timeout = 0;
                    idleModalShown = false;
                });
        } else {
            if (idle === 60 && !idleModalShown) {
                idleModalShown = true;
				msgme.modal.notice('You have been idle for 20 minutes.'+
					' Continue viewing the Campaign Activity?', {
					title: 'Idle Campaign Activity',
					button: 'Continue'
				});
            }
        }
    }

    path.map(url + '/:id').to(function () {
        id = this.params.id;
        var query = {
            id: id,
            size: size
        };

        // reset state on page load
        campaignActivity.data(null);
        campaignActivity.hasMoreMessages(true);
        latestMessageId = null;
        lastMessageId = null;
        view.show();
        splash.hide();

        view.callAPI('mobileflows.activity', query).
            done(function (result) {
                campaignActivity.data(result);
                lastMessageId = _.last(result) ? _.last(result).id : null;
                latestMessageId = _.first(result) ? _.first(result).id : null;

                if (result.length < size) {
                    campaignActivity.hasMoreMessages(false);
                }
            });

        polling = setInterval(poll, pollInterval);
    });

    return view;
});

