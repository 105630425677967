define('msgme/widgets/add-metadata',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/widget',
    'msgme/util/api'
], function (_, ko, Widget, api) {
    $.widget('msgme.msgme_add_metadata', Widget, {

        _create: function () {
            Widget.prototype._create.apply(this);
            this.on('change', 'select.metadata', '_onMetadataFieldChange');
        },

        _onMetadataFieldChange: function (e) {
            var metadataId = $(e.currentTarget).val();
            var valueEls = this.element.find('.value');

            valueEls.msgme('busy', true);
            if (metadataId) {
                api.call('metadata.fetch', metadataId).
                    done(_.bind(function (metadata) {
                        this.options.data.addMetadata.validValues(
                            metadata.validValues);
                        this.options.data.addMetadata.format(
                            metadata.format);
                        valueEls.msgme('busy', false);
                    }, this)).
                    fail(function () {
                        valueEls.msgme('busy', false);
                    });
            }
        }
    });

    return $.widget.msgme_add_metadata;
});

