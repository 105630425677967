define('widgets/audience-row/index',[
    'jquery',
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/api',
    'msgme/util/format',
    'msgme/alert',
    'msgme/modelcollection/filter',
    'msgme/viewmodel/query-filter',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    './../audience-filters/index'
], function (
    $,
    _,
    ko,
    viewmodel,
    api,
    format,
    alert,
    filter,
    queryfilter,
    ThreeWidget,
    template,
    strings
) {
    function updateCount(root) {
        var data = root.data();
        var qfd = data;
        var countQuery = JSON.stringify([ko.mapping.toJS(qfd)]);

        if (!root.filterable() || !root.recount() &&
            _.isEmpty(data.queryFilterDetails.details())) {
            return;
        }
        root.countRequest(api.call('subscribers.count', countQuery).
            then(function (count) {
                root.countRequest(null);
                root.recount(false);
                root.count(count);
                root.data().count(count);
            }));
    }

    var mapping = {
        defaults: {
            data: null,
            selected: true,
            count: null,
            countRequest: true,
            filterDialogVisible: false,
            viewWidth: 12,
            recount: false,
            isFiltered: false,
            queryFilters: null,
            currentChannel: 'sms',
            name: null,
            widgetId: null
        },

        computed: {
            filtered: {
                read: function () {
                    if (this.data()) {
                        this.isFiltered(!_.isEmpty(
                            this.data().queryFilterDetails.details()));
                        return !_.isEmpty(
                            this.data().queryFilterDetails.details());
                    } else {
                        return false;
                    }
                },
                write: function (root, value) {
                    this.filterDialogVisible(value);
                    if (!value) {
                        this.data().queryFilterDetails.details([]);
                    }
                    return value;
                }
            },

            filterable: function () {
                return this.currentChannel() === 'sms' ||
                    this.currentChannel() === 'smartlists';
            },

            disableSubmit: function () {
            //    var f = filter().oneById()[this.data().filterId()];
            //    return !f || !f.queryFilterDetails().length;
            },

            name: function () {
                if (this.data().name) {
                    return this.data().name();
                } else if (this.data().listName) {
                    return this.data().listName();
                } else {
                    if (this.data().lists() &&
                        this.data().lists().length > 1) {
                        return 'All Lists';
                    } else {
                        return viewmodel.globals.lists.oneById(
                            this.data().lists()[0]).name;
                    }
                }
            },

            isSmartlist: function () {
                return this.currentChannel() === 'smartlists';
            }
        },

        subcomputed: {
            count: {
                formatted: function (root, parent) {
                    var count = parent();
                    return _.isNumber(count) ? format.integer(count) : 'N/A';
                },
                listFormatted: function (root, parent) {
                    var count = parent();

                    if (root.data().lists().length > 1) {
                        return _.isNumber(count) ? format.integer(count) :
                            'Calculating...';
                    }

                    if (!root.isSmartlist()) {
                        if (root.data().lists() &&
                            root.data().lists().length > 0 &&
                            viewmodel.globals.lists.
                                oneById(root.data().lists()[0])) {
                            root.data().count(viewmodel.globals.lists.oneById(
                                root.data().lists()[0]).noOfSubscribers);
                            return format.integer(
                                viewmodel.globals.lists.oneById(
                                root.data().lists()[0]).noOfSubscribers);
                        }
                    }
                }
            }
        }
    };

    $.widget('msgme.msgme_audience_row', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            var viewmodel;

            ThreeWidget.prototype._create.apply(this, arguments);

            this.on('click', '.audience-row-bar', '_onClick');
            this.on('click', '.fa-remove', '_onTrashClick');
            this.on('click', '.cancel', '_onClickCancel');
            this.on('click', '.edit', '_onClickEdit');
            this.on('click', '.submit', '_onClickSubmit');
            this.on('click', '.onoffswitch-label', '_onToggle');

            viewmodel = this.option('viewmodel');
            viewmodel.filterDialogVisible.subscribe(
                _.bind(this._onFilterDialogVisibleChange, this));

            var qfd = viewmodel.data().queryFilterDetails;

            // if using old query filter model, conver to new
            if (qfd.details().length) {
                if (!qfd.details()[0].details) {
                    var details = ko.mapping.toJS(qfd.details());

                    qfd.details = ko.observableArray([]);

                    _.each(details, function (detail) {
                        qfd.details.push({
                            operator: ko.observable('or'),
                            details: ko.observableArray(
                                [ko.mapping.fromJS(detail,
                                queryfilter.mapping.
                                queryFilterDetails.mapping)])
                        });
                    });
                }
            }

            // instantiate filter if editing broadcast
            if (!this._audienceFilterInstance) {
                this._audienceFilterInstance = this.element.
                    find('.audience-filter-container').
                    msgme_audience_filters({
                        model: {
                            queryFilters: viewmodel.data().
                                queryFilterDetails.details,
                            queryFilterDetails: ko.observable(viewmodel.data()),
                            isSmartlist: viewmodel.isSmartlist,
                            showSegmentation: viewmodel.filtered,
                            listCount: viewmodel.count.listFormatted
                        }
                    });
                this._modal = this.element.find('.audience-filter-container');
                this._modal.on('hidden', _.bind(this._onModalHidden, this));
            }

            viewmodel.widgetId(this.element.attr('data-msgme-widget-id'));

            if (viewmodel.isSmartlist() ||
                viewmodel.data().lists().length > 1) {
                viewmodel.recount(true);
                updateCount(viewmodel);
            }
        },

        _onToggle: function (e) {
            var viewmodel = this.option('viewmodel');

            if (viewmodel.isSmartlist()) {
                e.preventDefault();
            }
        },

        _filterModal: function () {
            this._modal = this.element.find('.audience-filter-container');

            return this._modal;
        },

        _onClick: function (evt) {
            var $target = $(evt.target);
            if ($target.is('.fa-remove, .edit') ||
                $target.closest('.segmentation').length ||
                $target.closest('.modal').length
            ) {
                return;
            }

            var selected = this.option('viewmodel').selected;

            if (selected()) {
                return;
            } else {
                selected(!selected());
                this.element.trigger('selected');
            }
        },

        _onClickCancel: function () {
            this._audienceFilterInstance.
                msgme_audience_filter('rollback').
                then(_.bind(function() {
                    this.option('viewmodel').filtered(
                        !_.isEmpty(this.option('viewmodel').
                                   data().queryFilterDetails.details()));
                    this.option('viewmodel').filterDialogVisible(false);
                    updateCount(this.option('viewmodel'));
                }, this));
        },

        _onClickEdit: function () {
            this._audienceFilterInstance.msgme_audience_filter('snapshot');
            this.option('viewmodel').filterDialogVisible(true);
        },

        _onClickSubmit: function() {
            // set a timeout so the contained widgets have a chance to blur,
            // fire change events, and send out any potential xhr requests
            this._audienceFilterInstance.msgme_audience_filter('save').
                done(_.bind(function () {
                    this.option('viewmodel').filtered(
                        !_.isEmpty(this.option('viewmodel').
                            data().queryFilterDetails.details()));
                    this.option('viewmodel').filterDialogVisible(false);
                    this._modal.modal('hide');
                }, this)).
                fail(function () {
                    alert.error(strings.invalid);
                });
        },

        _onFilterDialogVisibleChange: function (value) {
            if (value) {
                var viewmodel = this.option('viewmodel');

                viewmodel.data().queryFilterDetails.details.push({
                    operator: ko.observable('or'),
                    details: ko.observableArray([ko.mapping.fromJS({},
                        queryfilter.mapping.queryFilterDetails.mapping)])
                });

                viewmodel.data().queryFilterDetails.details()[0].details()[0].
                    isModified(false);

                this._filterModal().show();
            } else if (this._modal) {
                this._modal.hide();
            }
        },

        _onModalHidden: function () {
            this.option('viewmodel').filterDialogVisible(false);
        },

        _onTrashClick: function () {
            this.element.trigger('deleted');
        }
    });

    return {
        widget: $.msgme.msgme_audience_row,
        mapping: mapping
    };
});

