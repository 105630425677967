define('widgets/subscriber-contact-stat-card/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/util/format',
    './../stat-card/index',
    'text!./template.html'
], function (_, ko, format, statCard, template) {
    $.widget('msgme.msgme_subscriber_contact_stat_card', statCard.widget, {
        _mapping: {
            defaults: {
                subscriberCount: null,
                hierarchy: null
            },
            computed: {
                hasSubscribers: function (root) {
                    return ko.utils.unwrapObservable(root.data().count) > 0;
                },
                hasContacts: function (root) {
                    return !!root.subscriberCount() ?
                        parseInt(root.subscriberCount().
                                 replace(',',''), 10) > 0 : 0;
                }
            }
        },

        _template: template,

        _createViewModel: function () {
            var vm = this._super.apply(this, arguments);

            vm.data(ko.mapping.fromJS({
                type: 'subscribersContacts',
                count: null
            }));

            return vm;
        },

        _loadData: function () {
            var data = this.option('viewmodel').data;
            var element = this.element;
            var hierarchy = this.option('viewmodel').hierarchy();

            element.msgme('busy', true);
            return waterfall.reporting.subscriberGrowth.fetch({
                    timeframe: 'LAST30',
                    size: 30,
                    group: hierarchy
                }).
                then(function (results) {
                    var last = _.last(results);

                    data().count(last.listSize);
                }).
                always(function () {
                    element.msgme('busy', false);
                });
        },

        _fetchContacts: function () {
            var vm = this.option('viewmodel');

            waterfall.contacts.count().then(function (result) {
                vm.subscriberCount(format.integer(result));
            });
        },

        _create: function () {
            statCard.widget.prototype._create.apply(this, arguments);
            this._fetchContacts();

            this.option('viewmodel').hierarchy.subscribe(
                _.bind(this._loadData, this));
        }
    });

    return {
        widget: $.msgme.msgme_subscriber_contact_stat_card,
        mapping: statCard.mapping
    };
});

