define('widgets/stream/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    'msgme/util/api',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    './../stream-entry/index',
    './../async-button/index'
], function (_, viewmodel, api, ThreeWidget, template, strings) {
    var mapping = {
        defaults: {
            data: null,
            search: '',
            pageSize: 20,
            size: 20,
            pollInterval: 30 * 1000,
            newMessageCount: 0,
            moreMessages: false,
            inFlightMessagesRequest: null,
            cachedNewMessages: null,
            idle: 0,
            timeout: 0,
            idleModalShown: null,
            allChecked: false,
            showRemoveFromList: false,
            showMessageSubscriber: false,
            replyChannel: null,
            editEmailTo: null,
            emailTo: null,
            emailAddress: null,
            emailSubject: null,
            emailFrom: null,
            storedEmailTo: null,
            sendMessage: null,
            replyPromise: null,
            addEmailButtonClicked: false,
            removeList: null,
            bulkMessage: null,
            bulkSubject: null,
            bulkFiles: [],
            currentTab: 'sms',
            bulkAd: null,
            bulkSponsorship: null
        },

        computed: {
            newMessagesText: function (root) {
                var count = root.newMessageCount();
                var string = strings[count > 1 ? 'newMessages' : 'newMessage'];

                return count ? count + ' ' + string : '';
            },

            moreMessagesText: function (root) {
                return root.moreMessages() ? strings.more : '';
            },

            hasEmailProfileMetadata: function () {
                return !!emailMetadata();
            },

            emailFormVisible: {
                read: function (root) {
                    return root.isChannelEmail() && !root.addEmailHelpVisible();
                },

                deferEvaluation: true
            },

            isChannelEmail: function (root) {
                return root.replyChannel() === 'email';
            },

            addEmailHelpVisible: {
                read: function (root) {
                    return !root.storedEmailTo() &&
                        !root.addEmailButtonClicked();
                },

                deferEvaluation: true
            },

            mobileReplyButtonText: function (root) {
                var mobileReplyType = root.currentTab();

                if (mobileReplyType === 'mms') {
                    return strings.reply.mms;
                } else {
                    return strings.reply.sms;
                }
            },

            allLists: function () {
                return viewmodel.globals.lists();
            },

            hasEntries: function () {
                return this.data && this.data() && this.data().length;
            }
        }
    };

    function emailMetadata() {
        var profileMetadata = viewmodel.globals.metadata.allByScope('PROFILE');

        return  _.find(profileMetadata, function(metadatum) {
            return metadatum.name === 'email';
        });
    }

    $.widget('msgme.msgme_stream', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            var vm;

            _.bindAll(this, '_setPollTimeout', 'refresh');

            this._super.apply(this, arguments);

            this._polling = true;

            this.on('cleared', '_onClearedStreamEntry');
            this.on('click', '.new-messages', '_onClickNewMessages');
            this.on('click', '.more-messages', '_onClickMoreMessages');
            this.on('click', '.bulk-remove', '_onRemoveClick');
            this.on('click', '.bulk-message', '_onMessageClick');
            this.on('click', '.add-email-help button',
                '_addEmailButtonClicked');
            this.on('click', '.edit-email-to', '_makeEditableAndFocusEmailTo');
            this.on('click', '.cancel', '_resetBulkActions');
            this.on('click', '.remove-list-action', '_onRemoveBulkClick');
            this.on('click', '.bulk-send', '_onBulkSend');
            this.on('click', '.bulk-clear', '_onBulkClear');

            vm = this.option('viewmodel');
            vm.search.subscribe(_.debounce(this.refresh, 300));

            this._setPollTimeout();

            $('body').on('click mousemove keyup', function () {
                vm.idle(0);
            });
        },

        _abortInFlightRequest: function () {
            var inFlight = this.option('viewmodel').inFlightMessagesRequest();

            if (inFlight) {
                inFlight.abort();
            }
        },

        _defaultParams: function () {
            return {size: this.option('viewmodel').size()};
        },

        _destroy: function () {
            this._super.apply(this, arguments);
            this._polling = false;
        },

        _onClearedStreamEntry: function (evt) {
            var index = $(evt.target).index();
            var vm = this.option('viewmodel');

            vm.data().splice(index, 1);
            vm.data.valueHasMutated();
        },

        _onClickMoreMessages: function () {
            var vm = this.option('viewmodel');
            var params = this._defaultParams();
            var lastMessage = _.last(vm.data());
            var promise;

            params.pivotId = lastMessage.id;
            params.orderBy = '-_id';
            promise = api.call('stream.fetch', params);
            vm.inFlightMessagesRequest(promise);

            return promise.
                then(_.bind(function (result) {
                    var vm = this.option('viewmodel');
                    vm.moreMessages(result.pageIndex < result.pageCount);

                    _.each(result, function (message) {
                        vm.data().push(message);
                    });

                    vm.data.valueHasMutated();

                    return result;
                }, this)).
                fail(api.getRequestFailureFn(null, 'stream.fetch')).
                always(function () {
                    vm.inFlightMessagesRequest(null);
                });
        },

        _onClickNewMessages: function () {
            var vm = this.option('viewmodel');
            var newSize = vm.size() + vm.newMessageCount();
            var newMessages = vm.cachedNewMessages().reverse();

            vm.search('');
            vm.size(newSize);
            vm.newMessageCount(0);
            _.each(newMessages, function (message) {
                _.each(vm.data(), function (currentMessage, i) {
                    if (currentMessage.subscriber === message.subscriber) {
                        vm.data().splice(i, 1);
                    }
                });
                vm.data().unshift(message);
            });
            vm.data.valueHasMutated();
            this._latestMessage = vm.data()[0];
        },

        _onRemoveClick: function () {
            var vm = this.option('viewmodel');

            vm.showMessageSubscriber(false);
            vm.showRemoveFromList(!vm.showRemoveFromList());
        },

        _onMessageClick: function () {
            var vm = this.option('viewmodel');

            vm.showRemoveFromList(false);
            vm.showMessageSubscriber(!vm.showMessageSubscriber());
        },

        _resetBulkActions: function () {
            var vm = this.option('viewmodel');

            vm.showMessageSubscriber(false);
            vm.showRemoveFromList(false);
        },

        _addEmailButtonClicked: function () {
            var vm = this.option('viewmodel');
            vm.addEmailButtonClicked(true);
            this._makeEditableAndFocusEmailTo();
        },

        _makeEditableAndFocusEmailTo: function () {
            var vm = this.option('viewmodel');
            var $emailToInput = this.element.find('.email-to input');

            vm.editEmailTo(true);
            $emailToInput.focus().select();
        },

        _setPollTimeout: function () {
            var vm = this.option('viewmodel');
            var inFlight = vm.inFlightMessagesRequest() ||
                $.Deferred().resolve();

            inFlight.then(_.bind(function () {
                setTimeout(_.bind(function () {
                    if (this._polling) {
                        this.poll();
                    }
                }, this), vm.pollInterval());
            }, this));
        },

        _onRemoveBulkClick: function () {
            var vm = this.option('viewmodel');
            var checked = this.element.find('.bulk-actions input:checked');
            var subscribers = _.map(checked, function (subscriber) {
                return $(subscriber).attr('subscriberid');
            });
            var listId = this.option('viewmodel').removeList();

            if (!_.isEmpty(subscribers)) {
                api.call('subscribers.unsubscribe', {
                    list: listId,
                    subscribers: subscribers
                }).
                fail(api.getRequestFailureFn(null, 'unsubscribe')).
                done(function () {
                    msgme.alert.success('Subscribers have been removed from ' +
                        'list ' + viewmodel.globals.lists.oneById(listId).name);
                    vm.showRemoveFromList(null);
                });
            }
        },

        _onBulkSend: function () {
            var checked = this.element.find('.bulk-actions input:checked');
            var subscribers = _.map(checked, function (subscriber) {
                return $(subscriber).attr('subscriberid');
            });
            var vm = this.option('viewmodel');
            var data = {
                message: vm.bulkMessage(),
                subject: vm.bulkSubject(),
                files: vm.bulkFiles(),
                subscribers: subscribers
            };

            if (!_.isEmpty(subscribers)) {
                api.call('messaging.send', data).
                    fail(api.getRequestFailureFn(null, 'sendContent')).
                    done(function () {
                        msgme.alert.success('Messages have been sent');
                        vm.bulkMessage(null);
                        vm.bulkSubject(null);
                        vm.bulkFiles([]);
                        vm.currentTab('sms');
                        vm.showMessageSubscriber(null);
                    });
            }
        },

        _onBulkClear: function () {
            var checked = this.element.find('.bulk-actions input:checked');
            var subscribers = _.map(checked, function (subscriber) {
                return $(subscriber).attr('message');
            });
            var vm = this.option('viewmodel');
            var me = this;

            if (!_.isEmpty(subscribers)) {
                msgme.modal.confirm('Are you sure you want to clear ' +
                    'these messages?', {
                    title: 'Clear Messages'
                }).done(_.bind(function (value) {
                    if (value) {
                        api.call('stream.clear', subscribers).
                        fail(api.getRequestFailureFn(null, 'stream.clear')).
                        done(function () {
                            msgme.alert.success('Messages have been cleared');
                            _.each(checked, function (message) {
                                $(message.closest('.stream-entry-container')).
                                    remove();
                            });
                            vm.allChecked(false);
                            if (!me.element.find(
                                '.stream-entry-container').length) {
                                me.refresh();
                            }
                        });
                    }
                }));
            }
        },

        poll: function () {
            var params = this._defaultParams();
            var vm = this.option('viewmodel');

            if (window.location.pathname === '/conversations') {
                if (this._latestMessage) {
                    params.pivotId = this._latestMessage.id;
                    params.orderBy = '_id';
                }

                params.size = 0;

                if (!!vm.idleModalShown() &&
                    $('#login-view').is(':hidden')) {
                    if (vm.timeout() < 59) {
                        vm.timeout(vm.timeout() + 1);
                    } else {
                        $('#msgme-modal').modal('hide');
                        return api.call('stream.fetch', params).
                            done(function () {
                                vm.timeout(0);
                            });
                    }
                }

                if (vm.idle() < 40 && $('#msgme-modal').is(':hidden') &&
                    $('#login-view').is(':hidden')) {
                    return api.call('stream.fetch', params).
                        then(_.bind(function (result) {
                            vm.cachedNewMessages(result);
                            vm.newMessageCount(result.total);
                            vm.idle(vm.idle() + 1);
                            vm.timeout(0);
                            vm.idleModalShown(false);
                        }, this)).
                        always(this._setPollTimeout);
                } else {
                    if (vm.idle() === 40 && !vm.idleModalShown()) {
                        vm.idleModalShown(true);
                        msgme.modal.notice('You have been idle for 20 minutes.'+
                            ' Continue viewing the Conversation Stream?', {
                            title: 'Idle Conversation Stream',
                            button: 'Continue'
                        });
                    }
                    this._setPollTimeout();
                }
            } else {
                this._polling = false;
            }
        },

        refresh: function () {
            var params = this._defaultParams();
            var vm = this.option('viewmodel');
            var promise;

            this._abortInFlightRequest();

            if (vm.search()) {
                params.search = vm.search();
            }

            promise = api.call('stream.fetch', params);
            vm.inFlightMessagesRequest(promise);

            return promise.
                then(_.bind(function (result) {
                    var vm = this.option('viewmodel');
                    var streamEntries;

                    _.each(result, _.bind(function (entry) {
                        if (!this._latestMessage ||
                                entry.createdAt >
                                this._latestMessage.createdAt) {
                            this._latestMessage = entry;
                        }
                    }, this));

                    vm.data(result);
                    vm.moreMessages(result.pageIndex < result.pageCount);

                    streamEntries =
                        this.element.find('.stream-entry-container');

                    _.each(streamEntries, function (entry) {
                        $(entry).
                            msgme_stream_entry('instantiateMessageTextbox');
                    });

                    return result;
                }, this)).
                fail(api.getRequestFailureFn(null, 'stream.fetch')).
                always(_.bind(function () {
                    vm.inFlightMessagesRequest(null);
                    if (!this._polling) {
                        this._polling = true;
                        this._setPollTimeout();
                    }
                }, this));
        }
    });

    return {
        widget: $.msgme.msgme_stream,
        mapping: mapping,
    };
});

