define('msgme/views/stream',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/path',
    'msgme/views/View',
    'msgme/splash',
    'msgme/util/feature-flags',
    'widgets/stream/index',
    '!json!widgets/shared-strings.json'
], function (
    _,
    ko,
    path,
    View,
    splash,
    feature,
    streamWidget,
    sharedStrings
) {
    var view = new View('#stream-view');
    var $stream;

    path.map(sharedStrings.sitemap.stream.url).to(function () {
        view.show();
        view.root.msgme('busy', true);

        if (!$stream) {
            splash.show();
            $stream = $('<div>').
                appendTo(view.root).
                msgme_stream({data: ko.observable()});
        }

        $stream.msgme_stream('refresh').
            then(function () {
                view.root.msgme('busy', false);
                splash.hide();
            });
    });

    return view;
});

