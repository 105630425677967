define('msgme/viewmodel/report',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/format',
    'json!widgets/shared-strings.json'
], function(_, ko, viewmodel, format, sharedStrings) {
    var strings = sharedStrings.reports;
    var util = waterfall.util;

    /**
     * memo
     *
     * basically just _.reduce except it initializes the memo to an empty
     * object and always returns it
     */
    function memo(iterable, fn, seed) {
        return _.reduce(iterable, function (memo) {
            fn.apply(this, arguments);
            return memo;
        }, seed === void undefined ? {} : seed);
    }

    /**
     * partial
     *
     * partially apply arguments. similar to _.bind, but it doesn't fix the
     * 'this' binding
     */
    function partial(fn) {
        var args = _.toArray(arguments).slice(1);
        return function() {
            return fn.apply(this, args.concat(_.toArray(arguments)));
        };
    }

    /**
     * omit
     *
     * return an new object w/ all of the props of obj except 'key'
     */
    function omit(obj, key) {
        return memo(obj, function (newObj, v, k) {
            if (k !== key) {
                newObj[k] = v;
            }
        });
    }

    /**
     * optionize
     *
     * converts an options object to something that's easily used by ko's
     * <select> bindings
     */
    function optionize(opts) {
        return memo(opts, function (memo, string, token) {
            memo.push({value: token, text: string});
        }, []);
    }

    /**
     * optionizeResource
     *
     * given an OA (presumably from `viewmodel.globals`), produce an array of
     * options that ko's <select> bindings can use
     */
    function optionizeResource(observableArray) {
        return memo(observableArray(), function (memo, record) {
            memo.push({value: record.id, text: record.name});
        }, []);
    }

    /**
     * alphaOptionizeResource
     *
     * given an OA (presumably from `viewmodel.globals`), produce an array of
     * options that ko's <select> bindings can use in alphabetical order
     */
    function alphaOptionizeResource(observableArray) {
        var sorted = _.sortBy(observableArray(), function (record) {
            return record.name.toLowerCase();
        });

        return memo(sorted, function (memo, record) {
            memo.push({value: record.id, text: record.name});
        }, []);
    }

    /**
     * firstFromArray
     *
     * given the name of a query parameter which corresponds to an observable
     * array, provide a computed interface that gets/sets the first item of the
     * array.
     *
     * note that if the array has more than one item in the 'write' function,
     * all of them will be replaced by an array with just the single value.
     *
     * see the description above the 'coupon' computed for motivation.
     *
     * note that the api has a bad habit of setting these arrays to 'null', so
     * we try to guard against that case here.
     */
    function firstFromArray(queryParam) {
        return {
            read: function (root) {
                var array = root.query[queryParam]();
                return array && array[0];
            },

            write: function (root, value) {
                var array = root.query[queryParam];

                // ensure array is an empty array, not null or filled w/ values
                if (!array() || array().length) {
                    array([]);
                }

                if (value) {
                    array.push(value);
                }
            }
        };
    }

    var emailRegex =
        /^\S*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    function mutuallyExcludeScope() {
        var props = ['mobileFlow', 'campaign', 'keyword'];
        var parent = this.parent;

        return _.compact(_.map(props, function (p) {
            return parent[p]();
        })).length < 2;
    }

    function mutuallyExcludeDuration() {
        var root = this.root;

        if (root.type() === 'listDetails') {
            return true;
        }

        var isTimeframe = this.parent.timeframe() == null &&
            (root.startDate() != null && root.endDate() != null);
        var isStartEnd = this.parent.timeframe() != null &&
            (root.startDate() == null && root.endDate() == null);

        if (root.type() === 'listDetails' || root.type() === 'pollSummary') {
            return true;
        } else {
            return (isTimeframe && !isStartEnd) || (!isTimeframe && isStartEnd);
        }
    }

    function apiDateWrapper(which) {
        return {
            read: function (root) {
                var val = root.query[which + 'On']();

                // in the case where this viewmodel represents an existing
                // report (not a new one we created in the browser), the value
                // will already be a date object, so just return that.
                if (!_.isString(val)) {
                    return val;
                } else {
                    return util.dateStringToDate(val);
                }
            },

            write: function (root, val) {
                var type = 'On';

                root.query[which + type](val == null ? val :
                    util.dateToDateString(val));
            }
        };
    }

    function startEndValidation(which) {
        return {
            validator: function () {
                var value = this.observable();
                var startLimit = this.parent.startLimit();
                var endLimit = this.parent.endLimit();

                if (this.parent.timeframe() !== 'CUSTOM') {
                    return true;
                }

                return which === 'start' ?
                    value > startLimit : value < endLimit;
            },

            message: function () {
                var validation = strings.validation;
                var type = this.parent.type();
                var label = (strings.type.options[type] || '').
                    toLowerCase();
                var startLimitDays = this.parent.startLimitDays();
                var durationLimitDays = this.parent.durationLimitDays();

                return _.sprintf(validation.startEndLimits, {
                    type: _.capitalize(label),
                    limit: startLimitDays + ' ' + validation.days,
                    duration: durationLimitDays + ' ' + validation.days
                });
            }
        };
    }

    return {
        model: viewmodel.report = ko.observable(null),

        collection: viewmodel.reports = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(5),
            links: ko.observableArray(),
            url: ko.observable('reports/page'),
            noResults: ko.observable(false)
        },

        bucketSizes: ['DAY', 'WEEK', 'MONTH'],

        contentTypes: [
            'SUBSCRIPTION',
            'STOP',
            'BASICTEXT',
            'CATCHALL',
            'COLLECTMETADATA',
            'TAGMETADATA',
            'DYNAMICCONTENT'
        ],

        messageTypes: ['MO', 'MT'],

        queryTypes: [
            'broadcastSummary',
            'couponSummary',
            'messageDetails',
            'messageSummary',
            'subscriberGrowth',
            'listDetails',
            'optInOptOut'
        ],

        summaryTypes: ['MOBILEFLOW', 'KEYWORD', 'CAMPAIGN'],

        timeframes: [
            'TODAY',
            'YESTERDAY',
            'THISWEEK',
            'LAST7',
            'LASTWEEK',
            'LAST14',
            'THISMONTH',
            'LAST30',
            'LASTMONTH',
            'CUSTOM'
        ],

        mapping: {
            defaults: {
                id: null,

                // the following 3 are optional
                account: null,
                group: null,
                shortCode: null,

                name: null,
                runAt: null,
                repeatInterval: null,
                repeatEvery: 0,
                endAt: null,
                notificationEmailList: null,
                errors: [],
                query: {},
                options: {}
            },

            local: {
                timeframeState: null,
                startLimitDays: 365,
                durationLimitDays: 95,
                tempScope: null,
                tempSummaryType: null,
                withinModal: false,
                error: null,
                restrictUI: false,
                isMarketron: false,
                lists: null,
                list: null,
                emails: null,
                smartlist: null,
                subGrowthList: null
            },

            options: {
                mapping: {
                    computed: {
                        bucketSize:
                            partial(optionize, strings.bucketSize.options),

                        contentType: function (root) {
                            return root.isMarketron() ? partial(optionize,
                            _.omit(strings.contentType.options, 'SPORTSFEED')) :
                            partial(optionize, strings.contentType.options);
                        },

                        couponGroup: partial(optionizeResource,
                                viewmodel.globals.coupons),

                        flow: partial(alphaOptionizeResource,
                                viewmodel.globals.flows),

                        keyword: partial(alphaOptionizeResource,
                                viewmodel.globals.keywords),

                        list: partial(alphaOptionizeResource,
                                viewmodel.globals.lists),

                        messageType: partial(optionize,
                                strings.messageType.options),

                        queryType: function () {
                            var args = [omit(this.queryTypes())];
                            return optionize.apply(
                                this,
                                args.concat(_.toArray([optionize, args])));
                        },

                        scope: partial(optionize, strings.scope.options),

                        summaryType: partial(optionize,
                                strings.summaryType.options),

                        timeframe:
                            partial(optionize, strings.timeframe.options),

                        queryTypes: function (root) {
                            if (root.restrictUI()) {
                                return _.omit(strings.type.options,
                                    'messageDetails', 'couponSummary',
                                    'listDetails');
                            } else {
                                return strings.type.options;
                            }
                        },

                        repeatInterval:
                            partial(optionize, strings.repeatInterval.options),

                        repeatEvery:
                            partial(optionize, strings.repeatEvery.options),

                        smartlist: partial(optionizeResource,
                                viewmodel.globals.smartlists),
                    }
                }
            },

            computed: {
                startLimit: {
                    read: function () {
                        var startLimitDays = this.startLimitDays();

                        return this.type() === 'messageDetails' ?
                            Date.create().
                                rewind({days: startLimitDays}).reset() :
                            new Date(null);
                    },

                    deferEvaluation: true
                },

                endLimit: {
                    read: function () {
                        var startDate = this.startDate();
                        var durationLimitDays = this.durationLimitDays();
                        var hasLimit = this.type() === 'messageDetails' &&
                            startDate != null;

                        if (this.type() !== 'messageDetails') {
                            return Date.create().addYears(5);
                        }

                        return hasLimit ?
                            startDate.clone().addDays(durationLimitDays) :
                            Date.create().addDays(1);
                    },

                    deferEvaluation: true
                },

                // this is a proxy to the `query.type` value, but setting it
                // will un-set the values that don't apply to the given type
                type: {
                    read: function (root) {
                        return root.query.type();
                    },

                    write: function (root, value) {
                        root.query.summaryType(null);
                        root.query.contentTypes(null);
                        root.query.messageType(null);
                        root.query.bucketSize(null);
                        root.query.couponGroups([]);
                        root.timeframe(null);
                        root.query.queryFilters([]);
                        root.query.lists([]);
                        root.query.mobileFlow(null);
                        root.query.keyword(null);
                        root.query.type(value);
                    }
                },

                // this is also a proxy. it unsets the start/endDate when
                // appropriate.
                //
                // the api will also return an error if the value 'CUSTOM' is
                // submitted for the timeframe, but we use that client-side for
                // validation purposes, so we store the 'CUSTOM' value in the
                // `timeframeState` local and set the actual timeframe to null.
                timeframe: {
                    read: function (root) {
                        return root.timeframeState();
                    },

                    write: function (root, value) {
                        if (value !== 'CUSTOM') {
                            root.startDate(null);
                            root.endDate(null);
                        }

                        root.query.timeframe(value === 'CUSTOM' ? null : value);
                        root.timeframeState(value);
                    }
                },

                // these are just pass-through's to query.{start,end}Date
                //
                // for some reason we convert dates to
                // api-formatted-date-strings in the sdk's Record#save method,
                // but not the Collection#create method that initially saves
                // the records. so we have an api-date ko mapping extender.
                //
                // the extender is problematic because:
                // 1) it blows away the stuff set up by 'validation'
                // 2) it's buggy and very complicated to fix
                //
                // so we'll just store the dates as api-date-strings in the
                // query object, and then provide computeds that produce the
                // corresponding date objects
                startDate: apiDateWrapper('begin'),
                endDate: apiDateWrapper('end'),

                // the UI only allows one coupon or list, but the api allows
                // multiple, so map the computed to the first item of the array
                coupon: firstFromArray('couponGroups'),

                limitText: {
                    read: function (root) {
                        var type = strings.type.options[root.type()];
                        var startLimit =
                            _.sprintf('%s days', root.startLimitDays());
                        var durationLimit =
                            _.sprintf('%s days', root.durationLimitDays());
                        var support = _.sprintf('<a href="mailto:%s">%s</a>',
                                sharedStrings.supportEmail,
                                sharedStrings.supportEmail);

                        return _.sprintf(strings.largeReports, {
                            type: type,
                            limit: startLimit,
                            duration: durationLimit,
                            contact: support
                        });
                    },

                    deferEvaluation: true
                },

                scope: {
                    read: function (root) {
                        return root.tempScope();
                    },

                    write: function (root, value) {
                        root.tempScope(value);

                        if (value !== 'mobileFlow') {
                            root.query.mobileFlow(null);
                        }

                        if (value !== 'keyword') {
                            root.query.keyword(null);
                        }
                    }
                },

                // in the case of a `messageSummary` report, if the user sets
                // the "Summarize by" select box to "All", or they set it to
                // "Campaigns" and then leave the campaign select box set to
                // "All", then we want to set `data.query.summaryType` to
                // `MOBILEFLOW`. we accomplish this via the computed below,
                // which also has to set `summaryType` to null when it's
                // not a `messageSummary` report
                summaryType: {
                    read: function (root) {
                        return root.tempSummaryType();
                    },

                    write: function (root, value) {
                        var query = root.query;
                        var newValue = query.type() === 'messageSummary' ?
                            value : null;

                        root.tempSummaryType(newValue);

                        if (newValue === 'SINGLE_MOBILEFLOW') {
                            query.summaryType('MOBILEFLOW');
                        } else {
                            query.summaryType(newValue);
                        }

                        if (value !== 'SINGLE_MOBILEFLOW') {
                            query.mobileFlow(null);
                        }

                        if (value !== 'KEYWORD') {
                            query.keyword(null);
                        }
                    }
                },

                isNotWhitelabel: function () {
                    return sharedStrings.deployTarget === 'msgme3.0';
                },

                showList: function () {
                    return _.isEmpty(this.query.queryFilters());
                },

                showSmartlist: function () {
                    return _.isEmpty(this.query.lists()) &&
                        !_.isEmpty(viewmodel.globals.smartlists());
                }
            },

            subscribe: {
                emails: function (val, parent) {
                    if (val) {
                        parent.notificationEmailList(val.split(','));
                    }
                }
            },

            validation: {
                startDate: startEndValidation('start'),
                endDate: startEndValidation('end'),
                runAt: {
                    validation: {
                        validator: function (val) {
                            return val ? val > new Date() : true;
                        },
                        message: strings.validation.scheduleDate
                    }
                },
                emails: {
                    validator: function (value) {
                        if (value) {
                            var emails = value.split(',');
                            var validEmails = true;

                            _.each(emails, function (email) {
                                if (!emailRegex.test(email.trim())) {
                                    validEmails = false;
                                    return;
                                }
                            });

                            return validEmails;
                        } else {
                            return true;
                        }
                    },

                    message: 'Please enter vaild email addresses ' +
                        'separated by commas'
                }
            },

            query: {
                mapping: {
                    defaults: {
                        // must be one of the 'queryTypes'
                        type: null,

                        // **for messageSummary**
                        // must be one of summaryTypes
                        summaryType: null,
                        excludeBroadcasts: false,

                        // **for messageDetails or messageSummary**
                        mobileFlow: null,
                        campaign: null,
                        keyword: null,

                        // **for messageDetails**
                        // must be one of 'contentTypes'
                        contentTypes: null,
                        // must be one of 'messageTypes'
                        messageType: null,

                        // **for all types**
                        timeframe: null,
                        beginOn: null,
                        endOn: null,

                        // **for messageSummary, subscriberGrowth, and
                        // couponSummary**
                        // must be one of 'bucketSizes'
                        bucketSize: null,

                        // **for broadcastSummary, subscriberGrowth, and
                        // listDetails**
                        lists: null,

                        // **for couponSummary**
                        couponGroups: null,

                        queryFilters: null
                    },

                    validation: {
                        type: {
                            required: true
                        },

                        // required for messageSummary
                        summaryType: {
                            validation: {
                                validator: function (val) {
                                    var parent = this.parent;

                                    return parent.type() !== 'messageSummary' ||
                                        !!val;
                                }
                            }
                        },

                        // the following 3 are mutually exclusive and optional
                        mobileFlow: {
                            validation: {
                                validator: mutuallyExcludeScope
                            }
                        },
                        campaign: {
                            validation: {
                                validator: mutuallyExcludeScope
                            }
                        },
                        keyword: {
                            validation: {
                                validator: mutuallyExcludeScope
                            }
                        },

                        // timeframe and start/endDate are mutually exclusive
                        // but one is required
                        timeframe: mutuallyExcludeDuration
                    }
                }
            }
        }
    };
});




