define('msgme/viewmodel/query-filter',[
    'msgme/underscore',
    'msgme/viewmodel',
    'json!widgets/shared-strings.json'
],
function (
    _,
    viewmodel,
    strings
) {
    var validationMessages = strings.metadata.validation.fields;
    var multiValueOperators = _.reduce(['in', 'nin', 'all'], function (o, k) {
        o[k] = true;
        return o;
    }, {});

    return {
        mapping: {
            defaults: {
                account: null,
                group: null,
                id: null,
                name: null,
                lists: [],
                queryFilterDetails: [],
                shortCode: null
            },

            queryFilterDetails: {
                mapping: {
                    defaults: {
                        metadata: null,
                        operator: null,
                        value: null,
                        radius: null
                    },
                    validation: {
                        operator: {
                            required: true
                        },

                        value: {
                            validator: function (val) {
                                var field = this.parent.metadata();
                                var operator = this.parent.operator();
                                var record =
                                    viewmodel.globals.metadata.oneById(field);
                                var tenDigitPattern = /^[0-9]{10}$/;
                                var pattern;

                                if (!record) {
                                    return true;
                                }

                                if (operator === 'exists') {
                                    return _.isBoolean(val);
                                }

                                val = val == null ? '' : val;

                                if (operator in multiValueOperators) {
                                    val = _.isString(val) ? val.split(',') : '';
                                } else {
                                    val = [val];
                                }

                                if (record.format == null) {
                                    pattern = /^\d{2}\d{2}\d{4}$/;
                                } else {
                                    pattern =
                                        new RegExp('^' + record.format + '$');
                                }

                                if (record.validValues) {
                                    // fixed values are valid if they are in
                                    // the validValues array
                                    return _.all(val, function (value) {
                                        return _.contains(
                                            record.validValues, value);
                                    });
                                } else {
                                    // free values are valid if they match the
                                    // format regex
                                    return _.all(val, function (value) {
                                        value = _.trim(value);
                                        if (field ===
                                            '4ec0a3dc0364de64869d93c2') {
                                            return pattern.test(value) ||
                                                tenDigitPattern.test(value);
                                        } else {
                                            return pattern.test(value);
                                        }
                                    });
                                }
                            },
                            message: function () {
                                var field = this.parent.metadata();
                                var operator = this.parent.operator();
                                var record =
                                    viewmodel.globals.metadata.oneById(field);

                                var arity = operator in multiValueOperators ?
                                    'multiple' : 'single';

                                var messageName = !record ||
                                        !(record.name in validationMessages) ?
                                    '__default__' : record.name;
                                
                                if (field === '4ec0a3dc0364de64869d93c2') {
                                    return strings.metadata.validation.msisdn;
                                } else {
                                    return validationMessages
                                        [messageName][arity];
                                }
                            }
                        }
                    }
                }
            }
        }
    };
});

