define('msgme/views/group',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/group',
    'msgme/views/View',
    'json!widgets/shared-strings.json'
], function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    mapping,
    group,
    View,
    sharedStrings
) {
    var view = new View('#group-view');

    var cm = new mapping.CursorModel(waterfall.groups, {
        record : {
            defaultData: {
                id: null
            },
            mapping: group.mapping
        }
    });

    function populate(groupInstance) {
        group.model(groupInstance);
        group.model.error(false);
        splash.hide();
    }

    view.root.on('click', 'a.group-save', function () {
        var model = group.model();

        if (model.isValid()) {
            model.save().
                done(function() {
                    viewmodel.globals.groups.refresh(true);
                    path.history.pushState(null, null,
                        sharedStrings.sitemap.accounts.url);
                    msgme.alert.success('The sub-account has been saved');
                }).
                fail(view.getRequestFailureFn(null,
                    'group.save',
                    ko.toJS(model)));
        } else {
            model.isModified(true);
        }
    });

    path.map(sharedStrings.sitemap.accounts.url + '/:id').to(function () {
        view.show();

        if (this.params.id.toLowerCase() === 'new') {
            populate(cm.create());
        } else {
            cm.fetch(this.params.id).
                done(populate).
                fail(view.getRequestFailureFn(null,
                    'groups.fetch',
                    this.params.id));
        }
    });

    return view;
});

