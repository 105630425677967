define('msgme/viewmodel/role',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/auth',
    'json!widgets/shared-strings.json'
], function(_, ko, viewmodel, auth, sharedStrings) {
    var model = ko.observable(null);
    model.error = ko.observable(false);
    // TODO: Move these to strings file
    var ACCESS_NONE = 'none';
    var ACCESS_RO = 'ro';
    var ACCESS_RW = 'rw';

    var labelToPerm = {
        Accounts :          'account',
        Assets :            'asset',
        Blacklist:          'blacklist',
        Broadcasts :        'broadcast',
        Campaigns :         'mobileflow',
        Carriers :          'carrier',
        Coupon:             'coupon',
        Content :           'content',
        Facebook:           'facebook',
        File:               'file',
        Filters :           'queryfilter',
        Inbox :             'catchall',
        Keystore :          'keystore',
        Keywords :          'keyword',
        Lists :             'list',
        'List Export' :     'listexport',
        Location :          'location',
        Metadata :          'metadata',
        MMS :               'mms',
        'Number Masking' :  'masknumber',
        Passbook :          'passbook',
        'Pass Template' :   'passTemplate',
        Reporting :         'reporting',
        Resource :          'resource',
        Roles :             'role',
        'Simple Message' :  'message',
        'Short Codes' :     'shortcode',
        Stream :            'stream',
        'Sub-Accounts' :    'group',
        Subscriber :        'subscriber',
        Trigger :           'trigger',
        Twitter :           'twitter',
        Uploads :           'upload',
        Users :             'user',
        'URL Shortener' :   'shorturl',
        Widget :            'widget'
    };

    if (sharedStrings.deployTarget === 'marketron') {
        labelToPerm.Ads = 'ad';
        labelToPerm.Sponsorships = 'sponsorship';
    }

    function parsePermission(functionName, permstr) {
        // Expected permission element structure:
        //  {
        //      featureName: '',
        //      accessSelected: 'none'/'ro'/'rw',
        //      isReadWrite: true/false,
        //      perms: [ create: true, read: true, update: true, delete: true ]
        //  }

        var pcreate = false;
        var pread = false;
        var pupdate = false;
        var pdelete = false;
        var access = ACCESS_NONE;

        if (permstr) {
            var privs = permstr.split(',');
            _.each(privs, function(priv) {
                if (priv === 'create') {
                    pcreate = true;
                } else if (priv === 'read') {
                    pread = true;
                } else if (priv === 'update') {
                    pupdate = true;
                } else if (priv === 'delete') {
                    pdelete = true;
                }
            }, this);
            access = (pcreate || pupdate || pdelete) ? ACCESS_RW : ACCESS_RO;
        }

        return {
            featureName : functionName,
            hasPermission: auth.has(labelToPerm[functionName] + ':read:*'),
            accessSelected : access,
            privs : {
                create : pcreate,
                read : pread,
                update : pupdate,
                'delete': pdelete
            }
        };
    }

    // Convert a computed permission element into a permission string without
    // the account/group prefix i.e., ":campaign:read:*"
    function buildPrivString(perm) {
        var privString = [];
        if (perm.privs.create) {
            privString.push('create');
        }

        if (perm.privs.read) {
            privString.push('read');
        }

        if (perm.privs.update) {
            privString.push('update');
        }

        if (perm.privs['delete']) {
            privString.push('delete');
        }

        if (privString.length > 0) {
            return ':' + labelToPerm[perm.featureName] +
                ':' + privString + ':*';
        } else {
            return null;
        }
    }

    // Update the role's permission observable base on the perms computed
    // observable.  This is called by the write back of the perms computed
    // observable as well as when saving the role.  This takes care of the
    // scenario where a role is edited but the perms are not touched, only the
    // groupsVisible observable is changed and then the role is saved.  If we
    // don't explicitely call updatePermissions(), the group field in the
    // permissions observable would still reference the old group.
    function updatePermissions(thisModel, newPermissions) {
        // if the model doesn't have account and group, default to account and
        // group of logged in user
        var account =
            model().account() || waterfall.authenticate.account;
        var groups;
        if (!_.isEmpty(model().permissions()) &&
            model().permissions()[0].split(':')[1] === '*') {
            groups = '*';
        } else {
            groups = (model().groupsVisible().length > 0) ?
                model().groupsVisible().join(',') :
                waterfall.authenticate.group;
        }
        newPermissions = newPermissions || model().perms();

        var perms = [];
        _.each(newPermissions.rows, function(permrow) {
            // Since we don't have an explicit checkbox for read (it's inferred
            // from the Access drop down), set it here.
            permrow.privs.read = permrow.accessSelected === ACCESS_RO ||
                permrow.accessSelected === ACCESS_RW;
            var postfix = buildPrivString(permrow);
            if (postfix) {
                perms.push(account + ':' + groups + postfix);
            }
        });

        model().permissions(perms);
    }

    return {
        model: viewmodel.role = model,
        collection: viewmodel.roles = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('roles/page')
        },
        updatePermissions: updatePermissions,
        mapping: {
            defaults: {
                account: null,
                group: null,
                groupsVisible: [],
                name: null,
                permissions: []
            },
            validation: {
                name: {
                    required: true,
                    minLength: 1
                },
                group: {
                    required: true
                },
                groupsVisible: {
                    required: true,
                    minLength: 1,
                    allAreValid: true
                }
            },
            computed: {
                perms: {
                    read: function() {
                        // construct map of permissions for random access
                        // below
                        var permsMap = {};
                        var permArr = [];
                        var permFields = !_.isEmpty(this.permissions()) ?
                            this.permissions()[0].split(':') : [];
                        if (permFields[2] === '*') {
                            _.each(labelToPerm, function (permName) {
                                if (permName !== 'masknumber') {
                                    permArr.push(permFields[0] + ':*:' +
                                        permName +
                                        ':create,read,update,delete:*');
                                }
                            });
                        } else {
                            permArr = this.permissions();
                        }
                        _.each(permArr, function(perm) {
                            var fields = perm.split(':');
                            // This is so that we don't mistakenly interpret
                            // SYSTEM and ACCOUNT metadata as our own.
                            //
                            // HACK: I know the SYSTEM and ACCOUNT metadata
                            //       fields are added last by the platform
                            //       so for now only read the first one
                            //       through.  This only matters if someone
                            //       is creating a role in the Account Admin
                            //       group.
                            if (!permsMap[fields[2]]) {
                                permsMap[fields[2]] = fields[3];
                            }
                        }, this);

                        var perms = { rows : [] };
                        var helper = _.bind(function(functionName) {
                            perms.rows.push(parsePermission(
                                functionName,
                                permsMap[labelToPerm[functionName]]));
                        }, this);

                        _.each(_.keys(labelToPerm).sort(), helper);

                        return perms;
                    },

                    // The perms computed field breaks down the permission
                    // strings array from the real role object into an array
                    // of permission objects that are more easily usable by
                    // the UI. We need to make this computed field writeable
                    // so that we can do some helpful things like
                    // uncheck/check the function checkboxes when the user
                    // changes the access drop down (and viceversa).  We
                    // should update the permission strings as needed.
                    write: updatePermissions
                }
            }
        }
    };
});


