define('widgets/audience-filters/index',[
    'lib/jquery.animateNumber',
    'msgme/underscore',
    'msgme/ko',
    'msgme/util/api',
    'msgme/util/format',
    'msgme/viewmodel',
    'msgme/viewmodel/query-filter',
    'msgme/viewmodel/mapping',
    'msgme/modelcollection/filter',
    './../three-widget',
    './../metadata-value/index',
    './../audience-filter/index',
    'text!./template.html',
    'lib/bootstrap'
], function (
    animate,
    _,
    ko,
    api,
    format,
    viewmodel,
    queryfilter,
    viewmodelMapping,
    filter,
    ThreeWidget,
    metadataValue,
    audFilter,
    template
) {
    var commaSeparator = $.animateNumber.numberStepFactories.separator(',');

    // this functionality is duplicated in the audience row and audience filter
    // widgets. we should have a single 'count' widget whose input is a count
    // value and deferred container.
    function updateCount(root) {
        var countQuery;
        var queryFilters = root.queryFilterDetails();

        root.pending(true);

        countQuery = JSON.stringify([ko.mapping.toJS(queryFilters)]);
        api.call('subscribers.count', countQuery).
            then(_.bind(function (count) {
                root.count(count);
                root.recount(false);
                root.pending(false);
                this.element.find('.count').animateNumber({
                    number: count,
                    numberStep: commaSeparator
                });
            }, this));
    }

    var mapping = {
        defaults: {
            data: null,
            queryFilters: null,
            queryFilterDetails: null,
            count: null,
            pending: null,
            recount: null,
            showSegmentation: null,
            listCount: null,
            isSmartlist: null
        },

        computed: {
            showRefreshText: function () {
                return this.count() && (!isNaN(this.count()) ||
                    !isNaN(this.count().replace(/,/g, '')));
            }
        }
    };

    $.widget('msgme.msgme_audience_filters', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            var viewmodel;

            ThreeWidget.prototype._create.apply(this, arguments);

            viewmodel = this.option('viewmodel');

            this.on('click', '.add', 'onClickAdd');
            this.on('click', '.refresh-count', 'onClickRefresh');
            this.on('click', '.delete-filter', 'onClickDelete');

            viewmodel.count(viewmodel.listCount());
            this.element.find('.count').animateNumber({
                number: viewmodel.count() &&
                    viewmodel.count().replace(/,/g, ''),
                numberStep: commaSeparator
            });

            if (viewmodel.queryFilters().length && !viewmodel.isSmartlist() ||
               viewmodel.count() === 'Calculating...') {
                this._refreshCount();
            }
        },

        _addFilter: function () {
            var viewmodel = this.option('viewmodel');
            viewmodel.recount(true);

            viewmodel.queryFilters.push({
                operator: ko.observable('or'),
                details: ko.observableArray([ko.mapping.fromJS({},
                    queryfilter.mapping.queryFilterDetails.mapping)])
            });

            viewmodel.recount(true);
            _.last(viewmodel.queryFilters()).details()[0].isModified(false);
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        },

        _refreshCount: function () {
            var vm = this.option('viewmodel');
            var pattern = /^[0-9]{10}$/;
            var isValid = true;

            _.each(vm.queryFilters(), function (qf) {
                _.each(qf.details(), function (details) {
                    if (!details.isValid()) {
                        isValid = false;
                    }
                });
            });

            if (!isValid) {
                return;
            }

            _.each(vm.queryFilterDetails().queryFilterDetails.details(),
                function (details) {
                    _.each(details.details(), function (filter, index) {
                        if (filter.metadata() === '4ec0a3dc0364de64869d93c2') {
                            var op = filter.operator();
                            var values;

                            if (op === 'in' || op  === 'all' || op === 'nin') {
                                values = filter.value().split(',');
                                _.each(values, function (value, index) {
                                    if (pattern.test(value.trim())) {
                                        values[index] = '001' + value.trim();
                                    }
                                });
                                vm.filters()[index].value(values.join(','));
                            } else {
                                if (pattern.test(filter.value())) {
                                    vm.filters()[index].
                                        value('001' + filter.data().value());
                                }
                            }
                        }

                    });
                });
            updateCount.call(this, vm);
        },

        onClickAdd: function () {
            this._addFilter();
        },

        onClickDelete: function (e) {
            var index = $(e.target).attr('data');
            var viewmodel = this.option('viewmodel');

            viewmodel.recount(true);

            if (viewmodel.queryFilterDetails().queryFilterDetails.
                details().length === 1 && viewmodel.queryFilterDetails().
                queryFilterDetails.details()[0].details().length === 1) {
                viewmodel.showSegmentation(false);
            } else {
                viewmodel.queryFilters.splice(index, 1);
            }
        },

        onClickRefresh: function () {
            if (this.option('viewmodel').recount()) {
                this._refreshCount();
            }
        }
    });

    return {
        widget: $.msgme.msgme_audience_filter,
        mapping: mapping
    };
});

