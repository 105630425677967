
define("json!msgme/util/pmi-smut-list.json", function(){ return ["49ers",
"76ers",
"Aces",
"Aggies",
"America's Incredible Pizza Company",
"Angels",
"Anteaters",
"Anthony's Pizza",
"Arni's Restaurant",
"Astros",
"Athletics",
"Aurelio's Pizza",
"Avalanche",
"Aztecs",
"Badgers",
"Bearcats",
"Bearno's",
"Bears",
"Beavers",
"Bengals",
"Bertucci's",
"Big Green",
"Big Red",
"Billikens",
"Bills",
"Bison",
"Black Bears",
"Black Knights",
"Blackbirds",
"Blackhawks",
"Blackjack Pizza",
"Blazers",
"Blue Demons",
"Blue Devils",
"Blue Hose",
"Blue Jackets",
"Blue Jays",
"Blue Raiders",
"Bluejays",
"Blues",
"Bobcats",
"Boilermakers",
"Bonnies",
"Braves",
"Brewers",
"Broncos",
"Broncs",
"Browns",
"Bruins",
"Bucaneers",
"Buckaneers",
"Buckeyes",
"Bucks",
"Buddy's Pizza",
"Buffaloes",
"Bulldogs",
"Bulls",
"Bullwinkle's Restaurant",
"Caesars",
"California Pizza Kitchen",
"Canadiens",
"Canucks",
"Capitals",
"Cardinal",
"Cardinals",
"Cassano's Pizza King",
"Catamounts",
"Cavaliers",
"Celtics",
"Chanticleers",
"Chargers",
"Chiefs",
"Chippewas",
"Chivas USA",
"Chivas",
"Chuck E. Cheese's",
"CiCi's Pizza",
"Clippers",
"Coca Cola",
"Coca-Cola",
"Coke",
"Colonels",
"Colts",
"Commodores",
"Corn Huskers",
"Cottage Inn Pizza",
"Cougars",
"Cowboys",
"Coyotes",
"Crew",
"Crimson Tide",
"Crimson",
"Cristy's Pizza",
"Crusaders",
"Cubs",
"Cyclones",
"Delta Devils",
"Demon Deacons",
"Demons",
"Devils",
"Diamondbacks",
"Dion's",
"Dodgers",
"Dolphins",
"Domino's Pizza",
"Domino's",
"Dominos",
"Donatos Pizza",
"Dons",
"DoubleDave's Pizzaworks",
"Dragons",
"Ducks",
"Dukes",
"Dynamo",
"Eagles",
"Earthquakes",
"East of Chicago Pizza",
"Eatza Pizza",
"Explorers",
"Extreme Pizza",
"FBS",
"FC Dallas",
"FCS",
"Falcons",
"Fazoli's",
"Fellini's Pizza",
"Fightin Blue Hens",
"Fighting Camels",
"Fighting Illini",
"Fighting Irish",
"Fighting Sioux",
"Fire",
"Flames",
"Flyers",
"Fox's Pizza Den",
"Frank Pepe Pizzeria Napoletana",
"Friars",
"Gaels",
"Galaxy",
"Gamecocks",
"Gators",
"Gatti's Pizza",
"Gauchos",
"Gentlemen",
"Giants",
"Gino's East",
"Gino's Pizza and Spaghetti",
"Giordano's Pizzeria",
"Giovannis",
"Godfather's Pizza",
"Golden Bears",
"Golden Eagles",
"Golden Flashes",
"Golden Gophers",
"Golden Griffins",
"Golden Grizzlies",
"Golden Hurricane",
"Golden Lions",
"Golden Panthers",
"Govenors",
"Great Danes",
"Green Wave",
"Greyhounds",
"Grimaldi's Pizzeria",
"Grizzlies",
"Happy Joe's",
"Happy's Pizza",
"Hatters",
"Hawkeyes",
"Hawks",
"Heat",
"Hideaway Pizza",
"Highlanders",
"Hilltoppers",
"Home Run Inn",
"Hookies",
"Hoosiers",
"Horned Frogs",
"Hornets",
"Hoyas",
"Hungry Howie's Pizza",
"Hunt Brothers Pizza",
"Hurricanes",
"Huskies",
"Imo's Pizza",
"Indians",
"Islanders",
"Jackrabbits",
"Jaguars",
"Jaspers",
"Jayhawks",
"Jazz",
"Jerry's Subs & Pizza",
"Jet's Pizza",
"Jets",
"John's Incredible Pizza",
"Kangaroos",
"Keydets",
"Kings",
"Knicks",
"Knights",
"LaRosa's Pizzeria",
"Lakers",
"Lancers",
"Leathernecks",
"Ledo Pizza",
"Leopards",
"Lions",
"Little Caesars",
"Lobos",
"Longhorns",
"Lou Malnati's Pizzeria",
"Lumberjacks",
"MLB",
"MLS Cup",
"MLS",
"Magic",
"Maple Leafs",
"March Madness",
"Marco's Pizza",
"Mariners",
"Marion's Piazza",
"Mark's Pizzeria",
"Marlins",
"Mastodons",
"Matadors",
"Mavericks",
"Mazzio's",
"Mean Green",
"Mellow Mushroom",
"Mets",
"Midshipmen",
"Miners",
"Minnesota Wild",
"Minutemen",
"Mocs",
"Monarchs",
"Monical's Pizza",
"Mountain Hawks",
"Mountain Mike's Pizza",
"Mountaineers",
"Mr. Jim's Pizza",
"Musketeers",
"Mustangs",
"NBA Finals",
"NBA",
"NCAA",
"NFL",
"NHL",
"Nationals",
"Nets",
"Nittany Lions",
"Noble Roman's",
"Nuggets",
"OIlers",
"Old Chicago",
"Olympics",
"Orange",
"Orioles",
"Ospreys",
"Owls",
"Pacers",
"Packers",
"Pacpizza",
"Padres",
"Pagliacci Pizza",
"Paladins",
"Panthers",
"Papa Gino's",
"Papa John's Pizza",
"Papa John's",
"Papa Johns",
"Pat's Pizza",
"Patriots",
"Patxi's Chicago Pizza",
"Peacocks",
"Penguins",
"Peter Piper Pizza",
"Phillies",
"Phoenix",
"Pie Five",
"Pietro's Pizza",
"Pilots",
"Pioneers",
"Pirates",
"Pistons",
"Pizza Fusion",
"Pizza Hut",
"Pizza Inn",
"Pizza My Heart",
"Pizza Patrón",
"Pizza Pizzaz",
"Pizza Ranch",
"Pizza Schmizza",
"Pizzeria Venti",
"Predators",
"Pride",
"Privateers",
"Purple Eagles",
"Quakers",
"Racers",
"Ragin' Cajuns",
"Raiders",
"Ramblers",
"Rams",
"Rangers",
"Rapids",
"Raptors",
"Rattlers",
"Ravens",
"Ray's Pizza",
"Rays",
"Razorbacks",
"Real Salt Lake",
"Rebels",
"Red Bulls",
"Red Flash",
"Red Foxes",
"Red Raiders",
"Red Sox",
"Red Storm",
"Red Wings",
"Red Wolves",
"Redbirds",
"Redhawks",
"Reds",
"Redskins",
"Regina Pizzeria",
"Retrievers",
"Revolution",
"Roadrunners",
"Rockets",
"Rockies",
"Rocky Rococo",
"Rosati's",
"Round Table Pizza",
"Royals",
"Runnin' Bulldogs",
"Runnin' Rebels",
"Runnin' Utes",
"Russo's New York Pizzeria",
"Sabres",
"Saints",
"Sal's Pizza",
"Salukis",
"Sammy's Pizza",
"Sbarro",
"Scarlet Knights",
"Seahawks",
"Seawolves",
"Seminoles",
"Senators",
"Shakey's Pizza",
"Sharks",
"Shockers",
"Showbiz Pizza Place",
"Skyhawks",
"Snappy Tomato Pizza",
"Sooners",
"Sounders",
"Sox",
"Spartans",
"Spiders",
"Sporting KC",
"Sporting Kansas City",
"Spurs",
"Stags",
"Stanley Cup",
"Stars",
"Steelers",
"Straw Hat Pizza",
"Subway",
"Sun Devils",
"Suns",
"Super Bowl",
"Sycamores",
"Tampa Bay Lighting",
"Tar Heels",
"Terrapines",
"Terriers",
"Texans",
"The Pizza Studio",
"Thrashers",
"Thunder",
"Thunderbirds",
"Thunering Herd",
"Tigers",
"Timbers",
"Timberwolves",
"Titans",
"Toppers Pizza",
"Toreros",
"Toronto FC",
"Tribe",
"Trojans",
"Twins",
"Uncle Maddio's Pizza Joint",
"Union",
"United",
"Uno Chicago Grill",
"Upper Crust Pizzeria",
"Valentino's",
"Vandals",
"Veraci Pizza",
"Vikings",
"Vocelli Pizza",
"Volunteers",
"Warhawks",
"Warriors",
"Waves",
"White Sox",
"Whitecaps",
"Wildcats",
"Wizards",
"Wolf Pack",
"Wolfpack",
"Wolverines",
"World Cup",
"World Series",
"Yankees",
"Yellow Jackets",
"Your Pie",
"Zips",
"anus",
"arse",
"arsehole",
"ass",
"ass-hat",
"ass-jabber",
"ass-pirate",
"assbandit",
"assbanger",
"assbite",
"assboag",
"assclown",
"asscock",
"asscracker",
"asses",
"assface",
"assfuck",
"assfucker",
"assgoblin",
"asshat",
"asshead",
"asshole",
"asshopper",
"assjacker",
"asslick",
"asslicker",
"assmonkey",
"assmunch",
"assmuncher",
"assnigger",
"asspirate",
"assshit",
"assshole",
"asssucker",
"assswad",
"asswipe",
"bampot",
"bastard",
"beaner",
"bitch",
"bitchass",
"bitches",
"bitchtits",
"bitchy",
"blow job",
"blowjob",
"bollocks",
"bollox",
"boner",
"brotherfucker",
"bullshit",
"bumblefuck",
"butt plug",
"butt-pirate",
"buttfucka",
"buttfucker",
"camel toe",
"carpetmuncher",
"chinc",
"chink",
"choad",
"chode",
"clit",
"clitface",
"clitfuck",
"clusterfuck",
"cocaine",
"cock",
"cockass",
"cockbite",
"cockburger",
"cockface",
"cockfucker",
"cockhead",
"cockjockey",
"cockmaster",
"cockmongler",
"cockmongruel",
"cockmonkey",
"cockmuncher",
"cocknoker",
"cocknose",
"cocknugget",
"cockshit",
"cocksmith",
"cocksmoke",
"cocksmoker",
"cocksniffer",
"cocksucker",
"cockwaffle",
"condom",
"condoms",
"coochie",
"coochy",
"coon",
"cooter",
"crack",
"cracker",
"cum",
"cumbubble",
"cumdumpster",
"cumguzzler",
"cumjockey",
"cumslut",
"cumtart",
"cunnie",
"cunnilingus",
"cunt",
"cuntass",
"cuntface",
"cunthole",
"cuntlicker",
"cuntrag",
"cuntslut",
"dago",
"damn",
"deggo",
"dick",
"dickbag",
"dickbeaters",
"dickface",
"dickfuck",
"dickfucker",
"dickhead",
"dickhole",
"dickjuice",
"dickmilk",
"dickmonger",
"dicks",
"dickslap",
"dicksucker",
"dicksucking",
"dickwad",
"dickweasel",
"dickwod",
"dike",
"dildo",
"dipshit",
"doochbag",
"dookie",
"douche",
"douche-fag",
"douchebag",
"douchewaffle",
"duckweed",
"dumass",
"dumb ass",
"dumbass",
"dumbfuck",
"dumbshit",
"dumshit",
"dyke",
"fag",
"fagbag",
"fagfucker",
"faggit",
"faggot",
"faggotcock",
"fagtard",
"fatass",
"fck",
"fellatio",
"feltch",
"flamer",
"fuck",
"fuckass",
"fuckbag",
"fuckboy",
"fuckbrain",
"fuckbutt",
"fucked",
"fucker",
"fuckersucker",
"fuckface",
"fuckhead",
"fuckhole",
"fuckin",
"fucking",
"fucknut",
"fucknutt",
"fuckoff",
"fucks",
"fuckstick",
"fucktard",
"fucktart",
"fuckup",
"fuckwad",
"fuckwit",
"fuckwitt",
"fudgepacker",
"gay",
"gayass",
"gaybob",
"gaydo",
"gayfuck",
"gayfuckist",
"gaylord",
"gaytard",
"gaywad",
"goddamn",
"goddamnit",
"gooch",
"gook",
"goshdarn",
"gringo",
"guido",
"handjob",
"hard on",
"heeb",
"hell",
"ho",
"hoe",
"homo",
"homodumbshit",
"honkey",
"humping",
"jackass",
"jap",
"jerk off",
"jigaboo",
"jizz",
"jungle bunny",
"junglebunny",
"kike",
"kooch",
"kraut",
"kunt",
"kyke",
"lameass",
"lesbian",
"lesbo",
"lezzie",
"limbaugh",
"lube",
"m.i.l.f.",
"malt",
"marijuana",
"mcfagget",
"meth",
"mick",
"milf",
"minge",
"mothafucka",
"mothafucking",
"muff",
"muffdiver",
"munging",
"negro",
"nigaboo",
"nigga",
"nigger",
"niggers",
"niglet",
"nusack",
"nut sack",
"paki",
"panooch",
"pecker",
"peckerhead",
"penis",
"penisbanger",
"penisfucker",
"penispuffer",
"piss",
"pissed off",
"pissed",
"pissflaps",
"polesmoker",
"pollock",
"poon",
"poonani",
"poonany",
"poontang",
"porch monkey",
"porchmonkey",
"pot",
"prick",
"punanny",
"punta",
"pussies",
"pussy",
"pussylicking",
"puto",
"queef",
"queer",
"queerbait",
"queerhole",
"renob",
"rimjob",
"ruski",
"sandnigger",
"schlong",
"scrote",
"sex",
"shit",
"shitass",
"shitbag",
"shitbagger",
"shitbrains",
"shitbreath",
"shitcanned",
"shitcunt",
"shitdick",
"shitface",
"shitfaced",
"shithead",
"shithole",
"shithouse",
"shitspitter",
"shitstain",
"shitter",
"shittiest",
"shitting",
"shitty",
"shiz",
"shiznit",
"shorebag",
"skank",
"skeet",
"skullfuck",
"skullfucked",
"slut",
"slutbag",
"smeg",
"snad nigger",
"snatch",
"spic",
"spick",
"spook",
"suckass",
"tard",
"testicle",
"thundercunt",
"tit",
"titfuck",
"tits",
"twatlips",
"twats",
"twatwaffle",
"unclefucker",
"va-j-j",
"vag",
"vagina",
"vajayjay",
"vjayjay",
"wank",
"wankjob",
"weed",
"wetback",
"whore",
"whoreface",
"wop",
"zpizza",
"‘ho",
"‘hobag"]
;});

