define('widgets/dynamic-insertion/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    './../three-widget',
    'json!./strings.json',
    'text!./template.html',
    './../metadata-select/index',
    './../selectize/index'
], function (_, viewmodel, ThreeWidget, strings, template) {

    var passedObservables = {
        message: '',
        isDisabled: false
    };

    var internalObservables = {
        metadatumId: null,
        defaultText: '',
        dateOffset: null,
        msisdnFormat: 'phone:short'
    };

    var allObservables = _.extend({}, passedObservables, internalObservables);

    var mapping = {
        defaults: _.clone(allObservables),

        computed: {
            isDateOffset: function () {
                return this.metadatumId() === 'date';
            },

            hideDefaultText: function () {
                return this.metadatumId() === 'subscriber' ||
                    this.metadatumId() === 'date';
            },

            isMsisdn: function () {
                return this.metadatumId() === '4ec0a3dc0364de64869d93c2';
            },

            formatString: function () {
                if (this.isMsisdn() && this.msisdnFormat() &&
                    this.msisdnFormat() !== 'null') {
                    return this.msisdnFormat();
                } else {
                    return null;
                }
            },

            dateOffsetOptions: function () {
                var options = [];
                _(91).times(function (n) {
                    if (n !== 0) {
                        options.push({
                            value: n,
                            name: n
                        });
                    }
                });

                return options;
            },

            metadataOptions: function () {
                var options = [
                    { name: 'Date Offset', value: 'date' },
                    { name: 'Subscriber ID', value: 'subscriber' }
                ];
                var profile = viewmodel.globals.metadata.grouped().profile;
                var system = viewmodel.globals.metadata.grouped().system;
                var custom = viewmodel.globals.metadata.grouped().custom;

                if (profile) {
                    _.each(profile, function (metadata) {
                        options.push({
                            value: metadata.id,
                            name: metadata.name,
                            class: 'profile'
                        });
                    });
                }

                if (system) {
                    _.each(system, function (metadata) {
                        options.push({
                            value: metadata.id,
                            name: metadata.name,
                            class: 'system'
                        });
                    });
                }

                if (custom) {
                    _.each(custom, function (metadata) {
                        options.push({
                            value: metadata.id,
                            name: metadata.name,
                            class: 'custom'
                        });
                    });
                }

                return options;
            },

            metadataOptgroups: function () {
                var options = [];
                var profile = viewmodel.globals.metadata.grouped().profile;
                var system = viewmodel.globals.metadata.grouped().system;
                var custom = viewmodel.globals.metadata.grouped().custom;

                if (profile) {
                    options.push({
                        value: 'profile',
                        label: 'Profile'
                    });
                }

                if (system) {
                    options.push({
                        value: 'system',
                        label: 'System',
                    });
                }

                if (custom) {
                    options.push({
                        value: 'custom',
                        label: 'Custom',
                    });
                }

                return options;
            }
        },

        validation: {
            metadatumId: {
                required: true
            }
        }
    };

    $.widget('msgme.msgme_dynamic_insertion', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            this._initLocals(this.options);
            this._prepareModal();
            this.on('click', 'button.open-modal', '_openModal');
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        },

        _initLocals: function () {
            this._$modal = this.element.find('.msgme-modal');
            this._$dropdown = this._$modal.find('.modal-body select');
        },

        _prepareModal: function () {
            this._$modal.msgme_modal({
                submit: _.bind(this._onModalSubmit, this)
            });
        },

        _onModalSubmit: function (event) {
            var vm = this.option('viewmodel');

            if (vm.metadatumId.isValid()) {
                this._buildAndInsertCode();
            } else {
                event.preventDefault();
                vm.metadatumId.isModified(true);
            }
        },

        _buildAndInsertCode: function () {
            var code = this._buildCodeFromSelection();
            this._insertIntoMessage(code);
        },

        _buildCodeFromSelection: function () {
            var vm = this.option('viewmodel');
            var metadataToken =
                'subscriber.metadata.("' + vm.metadatumId() + '")';
            var defaultTextToken =
                vm.defaultText() ? '; default="' + vm.defaultText() + '"' : '';
            var formatTextToken =
                vm.formatString() ? '; format="' + vm.formatString() + '"' : '';
                    

            if (vm.metadatumId() === 'date') {
                return '{date+' + vm.dateOffset() + '}';
            }

            if (vm.metadatumId() === 'subscriber') {
                return '{subscriber.id}';
            }

            return '{' + metadataToken + defaultTextToken +
                formatTextToken +'}';
        },

        _insertIntoMessage: function (text) {
            var $message =
                this.element.closest('.tab-pane').find('.message')[0];
            var startPos = $message.selectionStart;

            if (startPos || startPos === 0) {
                var endPos = $message.selectionEnd;
                var completeMessage = $message.value.substring(0, startPos) +
                    text +
                    $message.value.substring(endPos, $message.value.length);
                
                $message.value = completeMessage;
                $($message).change();
            }
        },

        _openModal: function () {
            this._$modal.msgme_modal('open');
        }
    });

    return {
        widget: $.msgme.msgme_dynamic_insertion,
        mapping: mapping
    };
});

