define('widgets/dashboard/index',[
    'msgme/underscore',
    'msgme/user-preferences',
    'msgme/viewmodel',
    'msgme/util/feature-flags',
    './../subscriber-growth-chart/index',
    './../subscriber-contact-stat-card/index',
    './../subscriber-stat-card/index',
    './../contact-stat-card/index',
    './../likes-stat-card/index',
    './../follower-stat-card/index',
    './../recent-broadcast-stat-card/index',
    './../sms-engagement-chart/index',
    './../glance-stat-card/index',
    './../three-widget',
    'json!./strings.json',
    'text!./template.html'
], function (_,
    userPreferences,
    viewmodel,
    featureFlags,
    subscriberGrowthChart,
    subscriberContactStatCard,
    subscriberStatCard,
    contactStatCard,
    likesStatCard,
    followerStatCard,
    recentBroadcastStatCard,
    smsEngagementChart,
    glanceStatCard,
    ThreeWidget,
    strings,
    template
) {
    var mapping = {
        defaults: {
            prefs: null,
            restrictUI: false,
            timeframe: 'LAST30',
            rootHierarchy: null,
            secondHierarchy: null,
            thirdHierarchy: null,
            lastHierarchy: null,
            rootHierarchyList: null,
            secondHierarchyList: null,
            thirdHierarchyList: null,
            lastHierarchyList: null,
            hierarchy: null,
            isMarketron: false
        },

        computed: {
            subscriberGrowthDays: function (root) {
                var str = [];

                str = [
                    strings.last,
                    root.timeframe().replace(/\D+/, ''),
                    strings.days
                ];

                return str.join(' ');
            },

            currentGroup: function () {
                return this.lastHierarchy() || this.thirdHierarchy() ||
                    this.secondHierarchy() || this.rootHierarchy();
            }
        },

        subscribe: {
            rootHierarchy: function (value, parent) {
                var list = [];

                if (!value) {
                    parent.secondHierarchy(null);
                    parent.secondHierarchyList(null);
                } else {
                    _.each(viewmodel.globals.groups(), function (group) {
                        if (group.parent === value) {
                            list.push({
                                id: group.id,
                                text: group.name
                            });
                        }
                    });

                    list = list.length ? list : null;
                    parent.secondHierarchy(null);
                    parent.secondHierarchyList(list);
                }
            },

            secondHierarchy: function (value, parent) {
                var list = [];

                if (!value) {
                    parent.thirdHierarchy(null);
                    parent.thirdHierarchyList(null);
                } else {
                    _.each(viewmodel.globals.groups(), function (group) {
                        if (group.parent === value) {
                            list.push({
                                id: group.id,
                                text: group.name
                            });
                        }
                    });

                    list = list.length ? list : null;
                    parent.thirdHierarchy(null);
                    parent.thirdHierarchyList(list);
                }
            },

            thirdHierarchy: function (value, parent) {
                var list = [];

                if (!value) {
                    parent.lastHierarchy(null);
                    parent.lastHierarchyList(null);
                } else {
                    _.each(viewmodel.globals.groups(), function (group) {
                        if (group.parent === value) {
                            list.push({
                                id: group.id,
                                text: group.name
                            });
                        }
                    });

                    list = list.length ? list : null;
                    parent.lastHierarchy(null);
                    parent.lastHierarchyList(list);
                }
            }
        }
    };

    $.widget('msgme.msgme_dashboard', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            _.bindAll(this, '_instantiateSubscriberGrowthChart');
            ThreeWidget.prototype._create.apply(this, arguments);

            var list = [];
            var vm = this.options.viewmodel;

            userPreferences().then(this._instantiateSubscriberGrowthChart);
            featureFlags('restrictPapaMurphysUI').then(_.bind(function () {
                vm.restrictUI(true);
            }, this));
            featureFlags('marketronFeatures').then(function () {
                vm.isMarketron(true);
            });
            this.on('click', '.submit-hierarchy', '_submitHierarchy');

            _.each(viewmodel.globals.groups(), function (group) {
                if (group.parent === waterfall.authenticate.group) {
                    list.push({
                        id: group.id,
                        text: group.name
                    });
                }
            });

            list = list.length ? list : null;
            vm.rootHierarchyList(list);
        },

        _instantiateSubscriberGrowthChart: function (prefs) {
            var save = _.bind(prefs.save, prefs);
            var top5Lists = _.pluck(viewmodel.globals.lists().
                slice().
                sort(function (a, b) {
                    return b.noOfSubscribers - a.noOfSubscribers;
                }).
                slice(0, 5),
                'id');

            prefs.init({
                subscriberGrowthChartLists: top5Lists,
                subscriberGrowthChartTimeframe:
                    this.options.viewmodel.timeframe
            });

            this.option('viewmodel').prefs(prefs);

            this.element.find('.subscriber-growth-chart').
                msgme_subscriber_growth_chart({
                    model: {
                        lists: prefs.subscriberGrowthChartLists,
                        timeframe: this.options.viewmodel.timeframe,
                        hierarchy: this.options.viewmodel.hierarchy
                    }
                });

            prefs.subscriberGrowthChartLists.subscribe(save);
            prefs.subscriberGrowthChartTimeframe.subscribe(save);
        },

        _submitHierarchy: function () {
            var vm = this.options.viewmodel;

            vm.hierarchy(vm.currentGroup());
        }
    });

    return {
        widget: $.msgme.msgme_dashboard,
        mapping: mapping
    };
});

