define('msgme/widgets/widget',[
    'jquery',
    'msgme/ko',
    'msgme/underscore'
], function ($, ko, _) {
    var instances = {};

    /*
     * Knockout removes and replaces DOM nodes when updating its bindings. This
     * is a problem for jQueryUI widgets, as they expect to be invoked on their
     * original node.
     *
     * So, we keep track of widget instances and destroy and recreate them
     * whenever their binding is re-evaluated.
     *
     * The root node of each widget template must bind to a unique id with
     * the `widget' binding. It must also set the data-widget attr to the
     * widget name.
     *
     * Register a 'revive' listener on the widget element to be notified when
     * the widget is going to be reincarnated. The handler will be passed the
     * new widget element in addition to the event object.
     *
     * Example:
     *   .mywidget(data-bind='widget: "widget123", data-widget='msgme_mywidget')
     *     // widget markup here
     */

    function reviveWidget(id, element, instance) {
        delete instances[id];

        var el = instance.el;
        var options = instance.options;

        /**
         * jQueryUI expects the instance to exist at 'msgme-msgme_foo', but it
         * in fact exists at 'msgmeMsgme_foo'. We do not know if this is because
         * the name is canonicalized incorrectly elsewhere or if this is our
         * fault.
         *
         * For now, work around the bug by copying the instance.
         */
        el.data('msgme-' + instance.widget, $(el)[instance.widget]);
        try {
            $(el)[instance.widget]('destroy');
        } catch (e) {
            // ignore attempts to destroy uninitialized widgets
            if (e.message.toLowerCase().indexOf('no such method') < 0) {
                throw e;
            }
        }
        $(element)[instance.widget](options);
        $(el).trigger('revive', element);
    }

    function updateWidget(element, getValue) {
        var id = getValue();
        var instance = instances[id];

        if (instance && !$(element).is(instance.el)) {
            reviveWidget(id, element, instance);
        }
    }

    ko.bindingHandlers.widget = {
        init: function (element, getValue) {
            var koId = getValue();
            var curId = $(element).attr('data-msgme-widget-id');

            if (curId && instances[curId]) {
                // there's already an instance record for this widget,
                // so copy it over to the new id
                instances[koId] = instances[curId];
                delete instances[curId];

                if (curId.indexOf(TEMP_ID_PREFIX) < 0) {
                    console.warn('Unexpected data-msgme-widget-id',
                        curId, element);
                }
            }

            $(element).attr('data-msgme-widget-id', koId);
        },
        update: updateWidget
    };

    var nextTempId = 0;
    var TEMP_ID_PREFIX = 'msgme-widget-temp-id-';

    $.widget('msgme.Widget', {
        _create: function () {
            var widgetId = this.element.attr('data-msgme-widget-id');

            if (!widgetId) {
                widgetId = TEMP_ID_PREFIX + nextTempId;
                nextTempId++;
                this.element.attr('data-msgme-widget-id', widgetId);
            }


            if (instances[widgetId]) {
                console.warn('Instances collision', widgetId,
                    instances[widgetId], this);
                reviveWidget(widgetId, this.element, instances[widgetId]);
            }

            instances[widgetId] = {
                el: this.element,
                widget: this.widgetName,
                options: _.clone(this.options)
            };

            // force the correct event prefix
            this.widgetEventPrefix = this.options.widgetEventPrefix;
        },

        _setOption: function (key, value) {
            var triggerResult =
                this._trigger(key + '-change', null, { value: value });
            if (triggerResult !== false) {
                // XXX: this goes away when we move to jQueryUI 1.9
                $.Widget.prototype._setOption.apply( this, arguments );
            }
        },

        _bindHandler: function (name) {
            var handlers = this._handlers = this._handlers || {};

            if (_.isUndefined(handlers[name])) {
                this[name] = _.bind(this[name], this);
                handlers[name] = true;
            }

            return this[name];
        },

        _hookEvent: function (hookFn, events, selector, data, handlerName) {
            var handler;

            if (_.isUndefined(data)) {
                // widget.on('some events', '_myHandlerFn')
                handlerName = selector;
                handler = this._bindHandler(handlerName);
                return this.element[hookFn](events, handler);
            } else if (_.isUndefined(handlerName)) {
                // widget.on('some events', 'css selector', '_myHandlerFn')
                handlerName = data;
                handler = this._bindHandler(handlerName);
                return this.element[hookFn](events, selector, handler);
            } else {
                // widget.on('some events', 'css selector',
                //     dataObject, '_myHandlerFn')
                handler = this._bindHandler(handlerName);
                return this.element[hookFn](events, selector, data, handler);
            }
        },

        on: function () {
            var args = _.toArray(arguments);
            args.unshift('on');
            return this._hookEvent.apply(this, args);
        },

        one: function () {
            var args = _.toArray(arguments);
            args.unshift('one');
            return this._hookEvent.apply(this, args);
        },

        off: function (events, selector, handlerName) {
            if (_.isUndefined(handlerName)) {
                // widget.off('some events', '_myHandlerFn')
                return this.element.off(events, this[selector]);
            } else {
                // widget.off('some events', 'css selector', '_myHandlerFn')
                return this.element.off(events, selector, this[handlerName]);
            }
        },

        destroy: function () {
            // TODO: track handlers by event type and selector, and
            // automagically unbind on destroy.

            this._super.apply(this, arguments);

            this.element.
              attr('data-msgme-widget-id', TEMP_ID_PREFIX + nextTempId);
            nextTempId++;
            //instances[widgetId] = null;
        }
    });

    return $.msgme.Widget;
});

