define('widgets/contact-stat-card/index',[
    'msgme/underscore',
    'msgme/ko',
    './../stat-card/index',
    'text!./template.html'
], function (_, ko, statCard, template) {
    $.widget('msgme.msgme_contact_stat_card', statCard.widget, {
        _mapping: {
            computed: {
                hasContacts: function (root) {
                    return ko.utils.unwrapObservable(root.data().count) > 0;
                }
            }
        },

        _template: template,

        _createViewModel: function () {
            var vm = this._super.apply(this, arguments);

            vm.data(ko.mapping.fromJS({
                type: 'contacts',
                count: null
            }));

            return vm;
        },

        _loadData: function () {
            var count = this.option('viewmodel').data().count;

            return waterfall.contacts.count().then(count);
        }
    });

    return {
        widget: $.msgme.msgme_contact_stat_card,
        mapping: statCard.mapping
    };
});


