
define("json!widgets/coupon-insertion/strings.json", function(){ return {
  "openModalButton": {
    "text": "{ }",
    "title": "Dynamic Insertion"
  },
  "modal": {
    "info": "Use dynamic insertion to create custom messages for your subscribers. Insert a metadata field code into your message below and it will be replaced with the corresponding value for each of your subscribers when the message is sent.",
    "header": "Dynamic Insertion",
    "defaultTextInputPlaceholder": "Include default text (optional)",
    "okButton": "Insert",
    "cancelButton": "Cancel",
    "dateOffset": "Date Offset"
  }
};});

