define('msgme/views/ad',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/ads',
    'msgme/views/View',
    'json!widgets/shared-strings.json'
], function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    mapping,
    ad,
    View,
    sharedStrings
) {
    var view = new View('#ad-view');

    var cm = new mapping.CursorModel(waterfall.ads, {
        record : {
            mapping: ad.mapping
        }
    });

    function populate(adInstance) {
        ad.model(adInstance);
        splash.hide();
        if (ad.model().startDate() || ad.model().endDate()) {
            ad.model().startDate.valueHasMutated();
            ad.model().endDate.valueHasMutated();
        }
        ad.model().startDateTime.isModified(false);
        ad.model().endDateTime.isModified(false);
        view.root.find('#ad-view-body').msgme('busy', false);
    }

    view.root.on('click', 'button.save-ad', function () {
        var adModel = ad.model();

        if (adModel.isValid()) {
            if (adModel.impressionsAllowed() === '') {
                adModel.impressionsAllowed(null);
            }

            adModel.save().
                done(function() {
                    path.history.pushState(
                        null, null, sharedStrings.sitemap.ads.url);
                    msgme.alert.success('The ad has been saved');
                    viewmodel.globals.ads.refresh(true);
                }).
                fail(view.getRequestFailureFn(null,
                    'ad.save',
                    ko.mapping.toJS(adModel)));
        } else {
            adModel.isModified(true);
        }
    });

    path.map(sharedStrings.sitemap.ads.url + '/:id').to(function () {
        view.show();

        if (this.params.id.toLowerCase() === 'new') {
            populate(cm.create());
            view.root.find('input.editable').focus();
        } else {
            cm.fetch(this.params.id).
                done(populate).
                fail(view.getRequestFailureFn(null,
                    'ads.fetch',
                    this.params.id));
        }
    });

    return view;
});

