define('msgme/views/user',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'lib/md5',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/user',
    'msgme/views/View',
    'msgme/util/ko-wrap-observable',
    'json!widgets/shared-strings.json'
],
function (
    _,
    splash,
    path,
    md5,
    ko,
    viewmodel,
    mapping,
    user,
    View,
    koWrapObservable,
    sharedStrings
) {
    var view = new View('#user-view');

    var cm = new mapping.CursorModel(waterfall.users, {
        record : {
            defaultData: {
                id: null,
                name: null,
                password: '',
                group: null,
                role: null,
                details: {
                    company: '',
                    title: '',
                    email: '',
                    mobile: '',
                    phone: ''
                },
                defaultShortCode: null,
                shortCodes: []
            },
            mapping: user.mapping
        }
    });

    function populate(userInstance) {
        function koWrapShortcodes() {
            if (!_.has(userInstance, 'shortCodes')) { return; }

            var observableShortcodes =
                _.map(userInstance.shortCodes(), koWrapObservable);
            userInstance.shortCodes(observableShortcodes);
        }

        koWrapShortcodes();
        user.model(userInstance);
        splash.hide();
    }

    view.root.on('click', 'button.add-shortcode', function () {
        user.model().shortCodes.push(ko.observable(null));
        user.model().shortCodes.isModified(false);
    });

    view.root.on('click', 'i.fa.fa-remove', function () {
        var i = $(this).closest('.shortcode-row').index();
        user.model().shortCodes.splice(i, 1);     // remove element
    });

    view.root.on('change', 'select.shortcode', function () {
        var $this = $(this);
        var i = $this.closest('.shortcode-row').index();
        var shortcode = user.model().shortCodes()[i];
        shortcode($this.val());
    });

    view.root.on('click', 'a.user-save', function () {
        if (!user.model().isValid || user.model().isValid())
        {
            // before saving, convert the password to an md5 hashed version
            if (user.model().password()) {
                user.model().password(md5(user.model().password()));
            }

            user.model().save().
                done(function() {
                    path.history.pushState(null, null,
                        sharedStrings.sitemap.users.url);
                    msgme.alert.success('This user has been saved');
                }).
                fail(view.getRequestFailureFn(null,
                    'users.save',
                    ko.toJS(user.model)));
        } else {
            user.model().isModified(true);
        }
    });

    view.root.on('click', '.user-delete', function () {
        msgme.modal.confirm('Are you sure you want to delete this user?', {
            title: 'Delete User'
        }).done(_.bind(function (value) {
            if (value) {
                user.model().del().
                    done(function () {
                        msgme.alert.success('The user has been deleted');
                        path.history.pushState(
                            null, null, sharedStrings.sitemap.users.url);
                    }).
                    fail(view.getRequestFailureFn(null,
                        'user.delete',
                        user.model().id()));
            }
        }, this));
    });

    path.map(sharedStrings.sitemap.users.url + '/:id').to(function () {
        view.show();

        if (this.params.id.toLowerCase() === 'new') {
            populate(cm.create());
        } else {
            cm.fetch(this.params.id).
                done(populate).
                fail(view.getRequestFailureFn(null,
                    'users.fetch',
                    this.params.id));
        }
    });

    return view;
});

