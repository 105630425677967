define('msgme/viewmodel/profile',[
    'msgme/ko',
    'msgme/viewmodel'
], function (ko, viewmodel) {
    var model = ko.observable(null);
    return {
        mapping: {
            defaults: {
                name: '',
                id: '',
                channel: '',
                group: '',
                account: ''
            }
        },
        model: viewmodel.channel.profile = model,
        collection: viewmodel.channel.profile = {
            rows: ko.observableArray()
        }
    };
});

