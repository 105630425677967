define('msgme/viewmodel/metadata',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'json!widgets/shared-strings.json'
], function(_, ko, viewmodel, strings) {
    var LENGTH_REGEX = /\{1,(\d+)\}$/;
    var formats = {
        'Zipcode' : '[0-9]{5}',
        'Msisdn' : '[0-9]{13}',
        'Text' : '[a-zA-Z0-9,.:_!?@#$%^&*()/ -;"\'\\[\\]{}+=<>\\\\|]',
        'Number' : '[0-9]',
        'Boolean' : 'yes|no',
        'State' : '[a-zA-Z]{2}',
        'Email' : '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}',
        'Date' : undefined
    };
    var formatToType = {};
    _.each(formats, function (format, type) {
        formatToType[format] = type;
    });
    var minLength = 1;
    var maxLength = 500;

    function getLength(format, type) {
        if (format === null || format === undefined) {
            return '--';
        }
        var lengthMatch = format.match(LENGTH_REGEX);
        if (lengthMatch) {
            // second element is parenthesized subexpression value
            return lengthMatch[1];
        } else if (type === 'State') {
            return 2;
        } else if (type === 'Text') {
            return '160';
        } else {
            return '--';
        }
    }

    return {
        model: viewmodel.metadatum = ko.observable(null),
        collection: viewmodel.metadata = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            noResults: ko.observable(null),
            hasBulkUpload: ko.observable(null),
            url: ko.observable('metadata/page')
        },
        formats: formats,
        formatToType: formatToType,
        getLength: getLength,
        mapping: {
            defaults: {
                multiValue: false,
                format: formats.Text,
                validValues: null,
                eventUrl: null,
                error: false,
                name: '',
                type: '',
                isAdmin: false
            },
            computed: {
                overwrite: {
                    read: function () {
                        return !this.multiValue();
                    },
                    write: function (base, value) {
                        this.multiValue(!value);
                    }
                },
                selectType: {
                    read: function () {
                        if (this.format() == null) {
                            return 'Date';
                        }
                        var type =
                            _.find(formatToType, function (val, key) {
                                return (this.format().indexOf(key) === 0);
                            }, this);
                        return type || 'Text';
                    },
                    write: function (base, value) {
                        this.format(formats[value]);
                    }
                },
                length: {
                    read: function () {
                        return getLength(this.format(), this.selectType());
                    },
                    write: function (base, value) {
                        var length = parseInt(value, 10);

                        if (!isNaN(length)) {
                            this.format(
                                formats[this.selectType()] +
                                '{1,' + value + '}');
                        } else {
                            this.format(formats[this.selectType()] + '{1,}');
                        }
                    }
                },
                values: {
                    read: function () {
                        return this.validValues() !== null ?
                            'predefined' : 'open';
                    },
                    write: function (base, value) {
                        this.validValues(value === 'predefined' ?
                            '' : null);
                    }
                },
                event: {
                    read: function () {
                        return this.eventUrl() !== null;
                    },
                    write: function (base, value) {
                        this.eventUrl(value ? '' : null);
                        this.eventUrl.isModified(false);
                    }
                }
            },
            subcomputed: {
                validValues: {
                    asString: {
                        read: function() {
                            return this.validValues() ?
                                this.validValues().join(', ') :
                                null;
                        },
                        write: function(base, parent, value) {
                            this.validValues(value ?
                                value.split(/\s*,\s*/) :
                                null);
                        }
                    }
                }
            },
            validation: {
                name: {
                    required: true,
                    minLength: 5,
                    maxLength: 30
                },
                selectType: {
                    required: true
                },
                values: {
                    required: true
                },
                length: {
                    validator: function (val) {
                        var length = parseInt(val, 10);
                        if (this.parent.selectType() === 'Date') {
                            return true;
                        } else if (this.parent.values() === 'open') {
                            return !isNaN(length) && length >= minLength &&
                                length <= maxLength;
                        } else {
                            return true;
                        }
                    },
                    message: _.sprintf(strings.metadata.validation.length,
                        {minLength: minLength, maxLength: maxLength})
                },
                validValues: {
                    required: {
                        onlyIf: function () {
                            return this.parent.values() === 'predefined' ?
                                true : false;
                        }
                    }
                },
                eventUrl: {
                    required: {
                        onlyIf: function () {
                            return this.parent.event() === true ?
                                true : false;
                        }
                    }
                }
            }
        }
    };
});

