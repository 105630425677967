define('msgme/viewmodel/add-to-list',[
    'msgme/underscore',
    'json!widgets/shared-strings.json',
    'msgme/ko',
    'msgme/viewmodel'
], function (_, sharedStrings) {
    var nextId = 0;
    var mapping = {
        local: {
            listId: null,
            optInType: 'singleOptIn',
            confirmMessage: _.sprintf(
                sharedStrings.message.placeholder.confirmMessage,
                {legalUrl: sharedStrings.legalUrl.short}),
            optInMessage: _.sprintf(
                sharedStrings.message.placeholder.optInMessage,
                {legalUrl: sharedStrings.legalUrl.short}),
            subscribedMessage: '',
            widgetIdValue: null,
            optIn: {},
            confirm: {},
            subscribed: {}
        },

        computed: {
            widgetId: function () {
                var id = this.widgetIdValue();
                if (id === null) {
                    id = 'add-to-list-' + nextId++;
                    this.widgetIdValue(id);
                }
                return id;
            }
        },
        subcomputed: {
            optIn: {
                widgetId: function (root) {
                    return root.widgetId() + '-opt-in';
                }
            },
            confirm: {
                widgetId: function (root) {
                    return root.widgetId() + '-confirm';
                }
            },
            subscribed: {
                widgetId: function (root) {
                    return root.widgetId() + '-subscribed';
                }
            }
        }
    };

    return {
        mapping: mapping
    };
});

