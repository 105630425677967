define('msgme/path',[
    'lib/path',
    'msgme/underscore'
], function(path, _) {

    // use Modernizr as the pivot for whether to use pushState. path makes the
    // really poor design decision to set this property in
    // `Path.history.listen`. we want it to be externally configurable for
    // testing, so we'll use `Object.defineProperty` to make it *not* settable
    // and just return `Modernizr.history`. this is ok because if the browser
    // doesn't support `Object.defineProperty`, then `Path.history.supported`
    // will always be false.
    if (Object.defineProperty) {
        Object.defineProperty(path.history, 'supported', {
            get: function () {
                return Modernizr.history;
            },

            // noop
            set: function () {}
        });
    }

    if (!Modernizr.history && location.pathname !== '/') {
        location.replace(location.origin + '#' + location.pathname);
    }

    // intercept link clicks and do client-side routing
    $(document).on('click', 'a[href]', function (evt) {
        var href = $(this).attr('href');
        if (!evt.metaKey && !evt.modifierKey && path.match(href, true)) {
            path.history.pushState(null, null, href);
            return false;
        }
    });

    // add triggering/handling capabilities to the path object. using jquery's
    // event system
    path._events = $({});

    path.on = function () {
        this._events.on.apply(this._events, arguments);
    };

    path.trigger = function () {
        this._events.trigger.apply(this._events, arguments);
    };

    /**
     * RegExpRoute
     *
     * this class makes it possible to route based on regex's instead of the
     * weak syntax path.js provides. so for example if we have a resource "foo"
     * and we want to view a single instance at "/foo/<id>", we would define
     * the route:
     *
     *      var url = new RegExp('/foo/([^/]+)');
     *      path.map(url).to(function (fullPath, id) {
     *          // ...
     *      });
     *
     * the first argument to the callback will always be the full path, and the
     * subsequent args correspond to the capture groups.
     *
     * one distinction between regexp routes and standard path.js routes is
     * multiple routes can be dispatched for a single navigation event.
     */
    function RegExpRoute(re) {
        this.re = re;
    }

    RegExpRoute.prototype._onNavigation = function () {
        var pth = path._getPath();
        var params;

        if (this.matches(pth)) {
            params = pth.match(this.re);
            if (this._previousPath !== pth) {
                this._previousPath = pth;
                this.callback.apply(this, params);
            }
        } else {
            this._previousPath = null;
        }
    };

    RegExpRoute.prototype.to = function (callback) {
        this.callback = callback;
        path.on('navigation', _.bind(this._onNavigation, this));
    };

    /**
     * RegExpRoute.prototype.run
     *
     * this is a noop since we use 'navigation' events to dispatch regexp routes
     */
    RegExpRoute.prototype.run = function () {};

    RegExpRoute.prototype.matches = function (pth) {
        return this.re.test(pth);
    };

    /**
     * RegExpRoute.prototype.do_exit
     *
     * this is also a noop to prevent path.js from throwing exceptions. if/when
     * we want to implement exit routes, we'll address it then, but we'll still
     * probably use navigation events and not do_exit.
     */
    RegExpRoute.prototype.do_exit = function () {};

    /**
     * path._getPath
     *
     * this returns the current path, normalized regardless of whether
     * pushState or hash routing is being used.
     *
     * this is only for initernal use.
     */
    path._getPath = function () {
        if (Modernizr.history) {
            return location.pathname;
        } else {
            // remove leading #, return '/' if there's no hash
            return location.hash.slice(1) || '/';
        }
    };

    /**
     * path.dispatch
     *
     * wrap the dispatch function to trigger a 'navigation' event whenever it's
     * called. this is nice because unlike window.onhashchange,
     * history.pushState does not trigger any observable event. if the
     * application just always uses path.history.pushState, then the changes
     * will always be observable. and things that depend on navigation will
     * still work in browsers that fall back to hashchange events.
     */
    path.dispatch = _.wrap(path.dispatch, function (f) {
        var args = _.toArray(arguments).slice(1);
        _.defer(_.bind(this.trigger, this, 'navigation'));
        return f.apply(this, args);
    });

    /**
     * path.history.listen
     *
     * unlike `path.listen`, `path.history.listen` does not do an initial
     * dispatch of the route, so we'll wrap it to behave the same regardless
     */
    path.history.listen = _.wrap(path.history.listen, function (f) {
        var args = _.toArray(arguments).slice(1);

        if (path._root && path._getPath() === '/') {
            if (Modernizr.history) {
                window.history.replaceState(null, null, path._root);
            } else {
                location.hash = '#' + path._root;
            }
        }

        f.apply(this, args);

        if (path.history.supported) {
            path.dispatch(location.pathname);
        }
    });

    /**
     * path.history.replaceState
     *
     * same api as path.history.pushState, except it calls history.replaceState
     * under the hood instead of pushState.
     */
    path.history.replaceState = function (state, title, pth) {
        if (path.history.supported) {
            if (path.dispatch(pth)) {
                window.history.replaceState(state, title, pth);
            }
        } else {
            if (path.history.fallback){
                location.replace(
                    location.origin + location.pathname + '#' + pth);
            }
        }
    };

    /**
     * path.map
     *
     * wrap path.map to accept regex's as routing patterns
     */
    path.map = _.wrap(path.map, function (f, pth) {
        if (_.isRegExp(pth)) {
            this._regExpRoutes = this._regExpRoutes || [];
            this._regExpRoutes.push(new RegExpRoute(pth));
            return _.last(this._regExpRoutes);
        } else {
            return f.apply(this, _.toArray(arguments).slice(1));
        }
    });

    /**
     * path.match
     *
     * wrap path.match in order to:
     *
     *  - preempt path's default matching w/ regexp matching
     *  - otherwise insert the leading '#' if we're falling back to hash routing
     */
    path.match = _.wrap(path.match, function (f, pth) {
        // check for a regexp match before pre-pending the hash so that
        // regexp's can always assume that the path starts with / and not #
        var matchingRegExpRoute = _.find(this._regExpRoutes, function (route) {
            return route.matches(pth);
        });
        var args = _.toArray(arguments).slice(2);

        if (matchingRegExpRoute) {
            return matchingRegExpRoute;
        }

        if (!path.history.supported && path.history.fallback &&
                pth[0] !== '#') {
            pth = '#' + pth;
        }

        return f.apply(this, [pth].concat(args));
    });

    /**
     * path.root
     *
     * the `path.root` function sets an initial path for the user when the just
     * hit the root of the page. the problem is it doesn't work with pushState,
     * so we overwrite it here.
     */
    path.root = function (pth) {
        path._root = pth;
    };

    return path;
});

