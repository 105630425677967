define('msgme/views/ads',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/ads',
    'msgme/views/View',
    'msgme/widgets/pager',
    'msgme/util/format',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
    'msgme/plugins'
],
function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    ad,
    View,
    Pager,
    format,
    featureFlags,
    sharedStrings
) {
    var view = new View('#ads-view');
    var page, pager;
    var cachedAds;

    ad.collection.noResults = ko.observable(false);
    ad.collection.type = ko.observable('current');
    pager = new Pager(ad.collection);

    function onFetchSuccess(adInstances) {
        _.each(adInstances, function (instance) {
            instance.flows = '';
            instance.creatorName =
                viewmodel.globals.users.oneById(instance.createdBy) ?
                viewmodel.globals.users.oneById(instance.createdBy).name : '';
            instance.start = format.uiDate(instance.startDate);
            instance.end = format.uiDate(instance.endDate);
            _.each(instance.mobileFlows, function (flow, index) {
                instance.flows =
                    index !== instance.mobileFlows.length - 1 ? instance.flows +
                    flow.name + ', ' : instance.flows + flow.name;
            });
            instance.impressions = _.isNull(instance.impressionsAllowed) ?
                instance.impressionsServed + ' / ' + 'Unlimited' :
                instance.impressionsServed + ' / ' +
                instance.impressionsAllowed;
        });

        ad.collection.rows(adInstances.slice());
        pager.update(adInstances);
        splash.hide();
        $('#ads-view-main').msgme('busy', false);
        $('#ads-view-empty').removeClass('hidden');
    }

    function onFailure(what, e) {
        console.error('Failed to fetch', what + ': ', e);
    }

    function fetch() {
        var type;
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#ads-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !ad.collection.rows().length) {
            if (!ad.collection.pageSize() || !ad.collection.pageIndex() ||
                !ad.collection.pageCount()) {
                resetViewmodelCollection();
            }

            type = ad.collection.type() === 'current' ?
                'currentAds' : 'expiredAds';

            // fetch the ads and populate page if not in a search
            view.callAPI(type + '.fetch', {
                    page: page,
                    size: ad.collection.pageSize()
                }).
                done(function (result) {
                    cachedAds = result;
                    onFetchSuccess(result);
                }).
                fail(_.bind(onFailure, this, 'ads'));
        } else {
            //fetch for cache
            view.callAPI(type + '.fetch', {
                    page: page,
                    size: 15
                }).
                done(function (result) {
                    cachedAds = result;
                    $('#ads-view-main').msgme('busy', false);
                }).
                fail(_.bind(onFailure, this, 'ads'));
        }
        
        /*
        viewmodel.globals.ads.refresh(true).done(function () {
            instantiateSearchBox();
        });
        */
    }

    view.root.on('click', 'td i.fa.fa-remove', function () {
        msgme.modal.confirm('Are you sure you want to delete this ad?', {
            title: 'Delete List'
        }).done(_.bind(function (value) {
            if (value) {
                var rowEl = $(this).closest('tr');
                var record = ad.collection.rows()[rowEl.index()];

                rowEl.addClass('deleting');
                record.del({
                    type: 'DELETE',
                    dataType: 'json',
                    contentType: 'application/json; charset=utf-8',
                    traditional: true,
                    url: '/api/v2/ad/:id'
                }).
                    done(function () {
                        ad.collection.rows.splice(rowEl.index(), 1);
                        msgme.alert.success('The ad has been deleted');
                        viewmodel.globals.ads.refresh(true);
                        fetch();
                    }).
                    fail(function () { rowEl.removeClass('deleting'); }).
                    fail(view.getRequestFailureFn(null,
                        'ad.delete',
                        record.data().id));
            }
        }, this));
    });

    function resetViewmodelCollection() {
        ad.collection.pageSize(15);
        ad.collection.pageCount(-1);
        ad.collection.pageIndex(-1);
        ad.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        /*
        var adNames = _.map(viewmodel.globals.ads(), function (ad) {
            return ad.mobileFlow.name;
        });
        var $searchBoxEl = view.root.find('.search-box');

        if (!!$searchBoxEl.hasOwnProperty('autocomplete')) {
            $searchBoxEl.autocomplete('destroy');
        }

        $searchBoxEl.autocomplete({
            source: adNames,
            delay: 100
        });
        */
    }

    /*
    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        ad.collection.noResults(false);
        if (!query) {
            onFetchSuccess(cachedAds);
        } else {
            results = _.map(viewmodel.globals.ads(), function (ad) {
                if(ad.mobileFlow.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return ad;
                }
            });

            onFetchSuccess(_.compact(results));

            if (!_.compact(results).length) {
                ad.collection.noResults(true);
            }
        }

        view.root.find('.search-box').autocomplete('close');
    });
    */

    view.root.on('click', '.set-current', function () {
        ad.collection.type('current');
        fetch();
    });

    view.root.on('click', '.set-expired', function () {
        ad.collection.type('expired');
        fetch();
    });

    view.root.on('click', '.search-flow', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        onFetchSuccess(cachedAds);
        view.root.find('.search-box').val('');
        ad.collection.noResults(false);
    });

    path.map(sharedStrings.sitemap.ads.url + '(/page/:page)').to(
        function () {
            viewmodel.globals.expiredAds.refresh(true);
            if (!ad.collection.pageSize() || !ad.collection.pageIndex() ||
                !ad.collection.pageCount() || !this.params.page) {
                resetViewmodelCollection();
            }
            view.show();
            instantiateSearchBox();
            page = this.params.page;
            fetch();
        });

    return view;
});

