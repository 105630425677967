define('msgme/widgets/pager',[
    'msgme/underscore'
], function (_) {
    function Pager(viewmodel, config) {
        config = _.defaults(config || {}, {
            numLinks: 5
        });

        viewmodel.url = viewmodel.url || function () {};

        this.config = config;
        this._viewmodel = viewmodel;
    }

    function generateLinksArray(index, count, numLinks) {
        numLinks = numLinks <= count ? numLinks : count;
        var range = Math.floor(numLinks / 2);
        var start = numLinks % 2 ? index - range : index - range + 1;
        var end = index + range;

        if (start < 1) {
            // the range starts before the pages, so shift right
            end += 1 - start;
            start = 1;

            // truncate if necessary
            end = end <= count ? end : count;
        } else if (end > count) {
            // the range ends after the pages, so shift left
            start -= end - count;
            end = count;

            // truncate if necessary
            start = start > 0 ? start : 1;
        }

        var result = [];
        for (var i = start; i <= end; i++) {
            result.push({
                target: i,
                disabled: i === index
            });
        }

        return result;
    }

    Pager.prototype.update = function (cursor) {
        if (cursor) {
            this._viewmodel.pageIndex(cursor.pageIndex);
            this._viewmodel.pageCount(cursor.pageCount);
            this._viewmodel.pageSize(cursor.pageSize);
            this._viewmodel.links(generateLinksArray(
                cursor.pageIndex, cursor.pageCount, this.config.numLinks));
        }
    };

    return Pager;
});

