define('msgme/viewmodel/group',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel'
], function(_, ko, viewmodel) {
    var model = ko.observable(null);
    model.error = ko.observable(false);
    return {
        model: viewmodel.group = model,
        collection: viewmodel.groups = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('subaccounts/page')
        },
        mapping: {
            defaults: {
                id: null,
                name: null
            },
            local: {
                account: null
            },
            computed: {
                roles: function() {
                    var roles =
                        viewmodel.globals.roles.allByGroup(this.id());
                    var rows = _.map(roles.slice(), function (role) {
                        var model =
                            viewmodel.globals.groups.oneById(role.group);
                        role.groupName = model ? model.name : '';
                        role.noOfUsers = viewmodel.globals.users.
                            allByRole(role.id).length;
                        return role;
                    });
                    return { rows : rows };
                },
                users: function() {
                    var users =
                        viewmodel.globals.users.allByGroup(this.id());
                    var rows = _.map(users.slice(), function (user) {
                        var model =
                            viewmodel.globals.groups.byId(user.group);
                        user.groupName = model ? model.name : '';
                        var role = viewmodel.globals.roles.byId(user.role);
                        user.roleName = role ? role.name : '';
                        return user;
                    });
                    return { rows : rows };
                }
            },
            validation: {
                name: {
                    required: true,
                    minLength: 3
                }
            }
        }
    };
});

