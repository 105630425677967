(function () {
    require({
        baseUrl: './scripts'
    }, [
        'msgme',
        'msgme/splash',
        'msgme/path',
        'msgme/ko',
        'msgme/auth',
        'msgme/viewmodel',
        'msgme/views/login',
        'msgme/views/app',
        'msgme/alert',
        'msgme/modal',
        'msgme/underscore',
        'msgme/util/api',
        'msgme/util/ga',
        'msgme/util/feature-flags',
        'json!widgets/shared-strings.json',
        'widgets/footer/index',
        'msgme/ko/validation',
        'widgets/navbar/index',
        'jquery.cookie',
        'lib/bootstrap'
    ], function (
         msgme,
         splash,
         path,
         ko,
         auth,
         viewmodel,
         loginView,
         appView,
         alert,
         modal,
         _,
         api,
         ga,
         featureFlags,
         sharedStrings
    ) {
        var header = $('header');
        var footer = $('footer');
        var welcomeMessageCookie;
        var cookieType = {
            PERMANENT: 'permanent',
            SESSION: 'session'
        };
        var sitemap = sharedStrings.sitemap;
        var version = sharedStrings.version;

        window.msgme.alert = alert;
        window.msgme.modal = modal;

        // show the login screen
        function showLogin(err) {
            $('body').addClass('login');
            header.hide();
            footer.hide();
            appView.hide();
            loginView.show(err);

            // hide any bootstrap modal backdrops:
            $('.modal-backdrop').hide();
        }

        path.map(sitemap.signout.url).to(function () {
            $(window).trigger('logout');
            waterfall.authenticate.logout().
                done(function () {
                    if (
                        $.cookie(welcomeMessageCookie) !== cookieType.PERMANENT
                    ) {
                        $.removeCookie(welcomeMessageCookie);
                    }
                    if (sharedStrings.deployTarget === 'marketron') {
                        path.history.pushState(
                            null, null, sitemap.dashboard.url);
                    } else {
                        path.history.pushState(null, null, sitemap.flows.url);
                    }
                    showLogin();
                });
        });

        $(window).on('beforeunload', function () {
            if (viewmodel.message() && viewmodel.message().id() &&
                window.location.pathname.indexOf('/message/') !== -1) {
                return true;
            }
        });

        $(document).ready(function() {
            function showApp() {
                var gaTrackingAccount;
                var hostname = window.location.hostname;

                if (hostname.indexOf('app.waterfall') !== -1 ||
                    hostname.indexOf('three.waterfall') !== -1) {
                    gaTrackingAccount = 'UA-39588087-8';
                } else if (hostname.indexOf('beta.waterfall') !== -1) {
                    gaTrackingAccount = 'UA-39588087-6';
                } else if (hostname.indexOf('staging') !== -1 ||
                    hostname.indexOf('localhost') !== -1 ||
                    hostname.indexOf('pizza') !== -1 ) {
                    gaTrackingAccount = 'UA-39588087-5';
                } else {
                    gaTrackingAccount = 'UA-39588087-7';
                }

                ga.track(gaTrackingAccount);

                splash.show();

                // show the login screen on a 401
                $('#main').ajaxError(function (event, jqxhr) {
                    if (jqxhr.status === 401 &&
                       jqxhr.responseText.toLowerCase().indexOf(
                           'invalid session or session has expired') >= 0
                    ) {
                        showLogin(
                            'Your session has expired. Please sign in again.');
                    }
                });

                waterfall.authenticate.isMasked =
                    msgme.auth.has('masknumber:create:*') ||
                    msgme.auth.has('masknumber:read:*') ||
                    msgme.auth.has('masknumber:update:*') ||
                    msgme.auth.has('masknumber:delete:*');

                $('header').removeClass('hidden');
                $('body').removeClass('login');

                waterfall.stream.inboxCount().
                    then(function (inboxCount) {
                        viewmodel.globals.inboxCount(inboxCount);
                    });
                    /*
                     * swallow the failure for stream.inboxCount
                    fail(api.getRequestFailureFn(null, 'stream.inboxCount'));
                    */

                viewmodel.globals.shortcodes.refresh().
                    done(function () {
                        if (sharedStrings.deployTarget === 'marketron') {
                            path.root(sitemap.dashboard.url);
                        } else {
                            path.root(sitemap.flows.url);
                        }
                        // pass 'true' in so path will fallback to hash routing
                        path.history.listen(true);

                        appView.show();
                        loginView.hide();

                        ko.applyBindings(viewmodel);

                        // show the main UI
                        header.msgme_navbar({
                            data: ko.observable({
                                shortcodes: viewmodel.globals.shortcodes,
                                streamCount: viewmodel.globals.inboxCount
                            })
                        });

                        var model;
                        for (var k in viewmodel) {
                            if (viewmodel.hasOwnProperty(k)) {
                                model = viewmodel[k];
                                model = ko.isObservable(model) ?
                                    model() : model;

                                if (model && model.isModified) {
                                    model.isModified(false);
                                }
                            }
                        }

                        var whitelabelFooterLinks = [{
                                name: 'Support',
                                url: sharedStrings.supportLink
                            }, {
                                name: 'Legal',
                                url: sharedStrings.legalUrl.full
                            }
                        ];

                        var msgmeFooterLinks = whitelabelFooterLinks.concat({
                            name: 'APIs',
                            url: 'http://apidocs.waterfall.com'
                        });

                        var footerLinks =
                            sharedStrings.deployTarget === 'msgme3.0' ?
                                msgmeFooterLinks :
                                whitelabelFooterLinks;

                        footer.msgme_footer({
                            model: {
                                links: footerLinks
                            }
                        }).show();


                        featureFlags('welcomeModal').
                            then(function () {
                                welcomeMessageCookie = [
                                    waterfall.authenticate.username, '.',
                                    waterfall.authenticate.account, '.',
                                    version.major, '.', version.minor, '.',
                                    version.patch
                                ].join('');

                                var $welcomeModal =
                                    $('#welcome-modal-view .msgme-modal');

                                $welcomeModal.msgme_modal({
                                    cancelEnabled: false,
                                    cookieDuration: 365 * 1000, // in days
                                    submit: function (e) {
                                        var vimeoEl =
                                            $('#welcome-modal-view .vimeo');
                                        var url =
                                            vimeoEl.attr('src').split('?')[0];
                                        var dontShow =
                                            $('.show', e.target).is(':checked');
                                        var cookieDuration = dontShow ?
                                            $(this).msgme_modal(
                                                'option', 'cookieDuration') :
                                            null;

                                        $.cookie(welcomeMessageCookie,
                                            dontShow ?
                                                cookieType.PERMANENT :
                                                cookieType.SESSION,
                                            {
                                                expires: cookieDuration
                                            });

                                        vimeoEl[0].contentWindow.postMessage(
                                            JSON.stringify({ method: 'pause'}),
                                            url);
                                    },
                                    hashchange: function () {
                                        return false;
                                    }
                                });

                                var videoUrl =
                                    sharedStrings.welcomeModal.videoUrl +
                                    '?api=1';

                                if ($.cookie(welcomeMessageCookie) == null) {
                                    $welcomeModal.
                                        find('.vimeo').
                                        attr('src', videoUrl);

                                    $welcomeModal.
                                        msgme_modal('open');
                                }
                            });
                    }).
                    fail(api.getRequestFailureFn(
                        null, 'shortcode.sessionshortcode'));
            }

            // set up validation tooltips
            $('body').on('focus', '.invalid', function () {
                var $this = $(this);

                // update bootstrap's cached title value
                $this.attr('data-original-title', $this.attr('title'));

                // the tooltip plugin ignores attempts to initialize a 2nd time
                $this.tooltip({
                    trigger: 'manual',
                    placement:
                        $this.data('invalid-tooltip-placement') || 'right'
                }).tooltip('show');

                $this.one('blur', function () {
                    $this.tooltip('hide');
                });
            });

            //remove toolbar popovers on click outside of popover
            $('html').on('click', function (e) {
                if (!$(e.target).parents('.toolbar-popover').length &&
                    !$(e.target).hasClass('open-popover') &&
                    !$(e.target).parents('.open-popover').length) {
                    $('.toolbar-popover').hide();
                }
            });

            // whoami
            //   done: hand off to appropriate view
            //   fail: show login view
            auth.whoami().
                done(showApp).
                fail(_.bind(showLogin, this, null));
        });
    });
}());

define("main", function(){});

