define('msgme/widgets/flowmodule-passbook',[
    'jquery',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-passbook',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/pass',
    'msgme/util/api'
],
function ($, ko, flowmodule, passbook, mapping, pass, api) {
    $.widget('msgme.msgme_flowmodule_passbook', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-passbook-'
        },

        _create: function () {
            var params = this.options.data().params;

            flowmodule.prototype._create.apply(this);

            this._passesCursor = new mapping.CursorModel(waterfall.passes, {
                record: {
                    mapping: pass.mapping
                }
            });

            this._onPassDropdown();
            this.on('change', '.pass-dropdown', '_onPassDropdown');

            this.element.find('.response-message .msgme-message-textbox').
                msgme_message_textbox({
                    data: params.response
                });

            this.element.find('.out-of-coupons-message .msgme-message-textbox').
                msgme_message_textbox({
                    data: params.outOfCouponsMessage
                });
        },

        _onPassDropdown: function () {
            var me = this;
            var id = this.options.data().params.passbookTemplateId();

            if (id) {
                this._passesCursor.fetch(id).done(function (result) {
                    var previewContainer = me.element.
                        find('.preview-container');
                    previewContainer.msgme('busy', true);
                    $.ajax({
                            type: 'POST',
                            url: '/api/v1/coupon/passbook/generate/preview',
                            contentType: 'application/json',
                            data: result.previewPayload(),
                            dataType: 'html'
                        }).
                        done(function (preview) {
                            var frame = document.createElement('iframe');

                            previewContainer.find('iframe').remove();
                            previewContainer.append(frame);

                            if (frame.contentDocument) {
                                frame.contentDocument.write(preview);
                            } else {
                                $(frame).on('load', function () {
                                    frame.contentDocument.write(preview);
                                });
                            }

                            previewContainer.msgme('busy', false);
                        }).fail(api.getRequestFailureFn(null,
                            'coupon.passbook.generate.preview',
                            result.previewPayload()));
                }).fail(api.getRequestFailureFn(null,
                    'coupon.passbook.fetch', id));
            }
        }
    });

    $.msgme.msgme_flowmodule_passbook.Model =
        flowmodule.modelFactory({}, passbook.config);

    return $.msgme.msgme_flowmodule_passbook;
});


