define('widgets/message-body/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/util/api',
    'msgme/viewmodel/message',
    'json!./strings.json',
    './../three-widget',
    './../file-uploader/index',
    './../metadata-value/index',
    'text!./template.html'
], function (_, ko, api, message, strings, ThreeWidget, fileUploader,
    metadataValue, template
) {
    var uploadButtonClasses = fileUploader.mapping.defaults.uploadButtonClasses;

    var mapping = {
        defaults: {
            data: null,
            maxLength: null,
            sliceable: true,
            metadataTemporarilyChecked: false,
            entirelyDisabled: false,
            accountFeaturesKey: null
        },

        // this is a bit shady. when we do the 'computedObservableArray' array
        // thing, we need a mapping to instantiate the objects. we could just
        // define that above in the function, and reference it via closure, but
        // then when we want to inherit from the message-body and extend that
        // mapping we have no way of getting to it.
        //
        // so i just threw the mapping here, and then i reference it in the
        // 'slices' computed with 'this.__ko_mapping__.sliceMapping'. if
        // there's a better way to do this we should definitely do so.
        sliceMapping: {
            defaults: {
                data: null,
                widgetId: null,
                enableFileUpload: false,
                placeholder: strings.placeholder + ' ' + strings.laterDate
            },

            computed: {
                channelWrapperClass: function () {
                    return {};
                },

                disable: function () {
                    return false;
                },

                placeholder: function () {
                    var placeholder = this.placeholder();

                    return this.enableFileUpload() ?
                        placeholder + ' ' + strings.enableFileUpload :
                        placeholder;
                }
            }
        },

        computed: {
            // FIXME: this is the 'computedObservableArray' functionality
            // that's in the audience-filter. it's pretty much a duplicate and
            // we should refactor it out into its own thing.
            slices: function () {
                var previous = this.__previousSlices__ =
                    this.__previousSlices__ || [];
                var dataSlices = this.data().slices();
                var slices = _.map(dataSlices, _.bind(function (dataSlice) {
                    var existing = _.find(previous, function (prev) {
                        return prev.data() === dataSlice;
                    });

                    // see the comment above 'sliceMapping'
                    return existing || ko.mapping.fromJS({
                        data: ko.observable(dataSlice)
                    }, this.__ko_mapping__.sliceMapping);
                }, this));
                var length = this.__previousSlices__.length;

                previous.splice.apply(this.__previousSlices__,
                        [0, length].concat(slices));

                return this.__previousSlices__;
            }
        }
    };

    $.widget('msgme.msgme_message_body', ThreeWidget, {
        _template: template,

        _create: function () {
            this._mapping = this._mapping || mapping;
            ThreeWidget.prototype._create.apply(this, arguments);
            var viewmodel = this.option('viewmodel');

            this.on('click', '.add-segment', 'onClickAddSegment');
            this.on('click', '.trash-slice', 'onClickTrash');
            viewmodel.slices.subscribe(this.onSlicesChange.bind(this));
            if (!viewmodel.slices().length) {
                this._addDataSlice();
            } else {
                this.populateMessages();
            }
        },

        _rebalancePercentages: function () {
            var data = this.option('viewmodel').data();
            var slices = data.slices();

            if (slices.length < 1) {
                return;
            }

            var anyPercentageChanged = _.some(slices, function (slice) {
                    return slice.percentage.isModified();
                });

            var total = 0;
            var share = Math.floor(100 / slices.length);
            if (!anyPercentageChanged) {
                _.each(slices, function (slice) {
                    var remaining;
                    total += share;

                    remaining = 100 - total;

                    if (0 < remaining && remaining < share) {
                        share += remaining;
                    }

                    slice.percentage(share);
                    slice.percentage.isModified(false);
                });
            }
        },

        _addDataSlice: function () {
            this.option('viewmodel').data().slices.push(ko.mapping.fromJS(
                this._contentItemParams(),
                message.mapping.content.mapping.slices.mapping));
        },

        _contentItemParams: function () {
            return {percentage: 0};
        },

        onClickAddSegment: function () {
            this._addDataSlice();
        },

        onClickTrash: function (evt) {
            var idx = $(evt.target).closest('.segment-wrapper').index();
            var viewmodel = this.option('viewmodel');
            var slice = viewmodel.slices()[idx].data();
            var slices = viewmodel.data().slices;

            slices.splice(_.indexOf(slices(), slice), 1);
        },

        populateMessages: function () {
            var vm = this.option('viewmodel');
            var slices = vm.slices();
            var maxLength = vm.maxLength() || null;

            this.element.find('.segment-wrapper').each(
                function renderSlice(i, el) {

                var $el = $(el);
                var $messageTextboxContainer = $el.find('.message-textbox');
                var $mobileMessageBodyContainer =
                    $el.find('.mobile-message-body-container');
                var slice = slices[i];

                function initMessageTextbox() {
                    $messageTextboxContainer.msgme_message_textbox({
                        data: slice.data().message,
                        maxLength: maxLength
                    });
                }

                function initMobileMessageBody() {
                    $mobileMessageBodyContainer.msgme_mobile_message_body({
                        model: {
                            subject: slice.data().subject,
                            message: slice.data().message,
                            fileIds: slice.data().files,
                            ad: slice.data().ad,
                            sponsorship: slice.data().sponsorship,
                            enableAds: true,
                            tagMetadata: true,
                            metadata: vm.data().metadata,
                            value: slice.data().value,
                            smsFallback: slice.data().smsFallback,


                            // Would like to define this in `mapping.
                            // sliceMapping.computed`, but `mapping.defaults.
                            // entirelyDisabled` was difficult to access from
                            // there since `root` would correspond to `mapping.
                            // sliceMapping`, not `mapping`.
                            isDisabled: ko.computed(function () {
                                return vm.entirelyDisabled() || slice.disable();
                            }),
                            currentTab: slice.data().currentTab,
                            accountFeaturesKey: vm.accountFeaturesKey
                        }
                    });
                }

                function initFileUploader() {
                    $el.find('.file-uploader-inner').msgme_file_uploader({
                        data: vm.data().image,
                        uploadButtonClasses: _.extend({}, uploadButtonClasses,
                            {btn: false, 'fa-paperclip': true}),
                        browseText: '',
                        accept: 'image/*'
                    });
                }

                function initMetadataValue() {
                    $el.find('.metadata-value-inner').msgme_metadata_value({
                        data: slice.data().value,
                        model: {
                            field: vm.data().metadata,
                            placeholder: strings.metadataValue
                        }
                    });
                }


                if ($messageTextboxContainer) { initMessageTextbox(); }
                if ($mobileMessageBodyContainer) { initMobileMessageBody(); }
                if (slice.enableFileUpload()) { initFileUploader(); }
                if (slices.length > 1) { initMetadataValue(); }
            });
        },

        onSlicesChange: function () {
            this._rebalancePercentages();
            this.populateMessages();
        },

    });

    $.msgme.msgme_message_body.mapping = mapping;

    return $.msgme.msgme_message_body;
});

