define('msgme/viewmodel/flowmodule-basictext',[
    './message',
    'json!widgets/shared-strings.json',
    'msgme/util/account-features'
], function (message, strings, accountFeatures) {
    var config = {
        mapping: {
            defaults: {
                params: {
                    response: '',
                    subject: '',
                    files: [],
                    ad: null,
                    sponsorship: null,
                    smsFallback: null
                }
            },

            local: {
                title: 'Send Message',
                head: '',
                currentTab: 'sms',
                accountFeaturesKey: 'fixedBasicTextMessage',
                responseLastSaved: '',
                validParams: null
            },

            validation: {
                validParams: {
                    validator: function () {
                        return this.parent.params.response.isValid();
                    }
                }
            },

            computed: {
                hasFixedMessageDiscrepancy: {
                    read: function () {
                        return accountFeatures.hasFixedMessageDiscrepancy(
                            this.accountFeaturesKey(),
                            this.responseLastSaved());
                    },

                    deferEvaluation: true
                },

                fieldLastSavedPairs: {
                    read: function () {
                        return [{
                            field: this.params.response,
                            fieldLastSaved: this.responseLastSaved
                        }];
                    },

                    deferEvaluation: true
                }
            },

            params: {
                mapping: {
                    validation: {
                        response: {
                            validator: function(val) {
                                var files = this.parent.files();

                                return val || (files && files.length);
                            },

                            message: function() {
                                var s = strings.message.validation;

                                return s.message[this.root.currentTab()];
                            }
                        }
                    }
                }
            }
        }
    };

    return {
        config: config
    };
});

