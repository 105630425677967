define('msgme/util/feature-flags',[
    'msgme/underscore',
    'msgme/viewmodel',
    'msgme/auth',
    'json!msgme/util/pmi-l1.json',
    'json!msgme/util/pmi-l2.json',
    'json!msgme/util/marketron-user-restriction.json',
    'json!widgets/shared-strings.json'
], function (
    _,
    viewmodel,
    auth,
    papaMurphysL1,
    papaMurphysL2,
    marketronRestriction,
    sharedStrings
) {
    var promise;

    function isAmfam(accountId) {
        return accountId === '521c2a0c22dd641c6060812f';
    }

    function isPapaMurphysL1(roleId) {
        return !_.isEmpty(_.find(papaMurphysL1, function (id) {
            return roleId === id;
        }));
    }

    function isPapaMurphysL2(roleId) {
        return !_.isEmpty(_.find(papaMurphysL2, function (id) {
            return roleId === id;
        }));
    }

    function useOldSubscription(accountId) {
        return accountId === '53a92217261891a9935ee1a2' || //PMI
            accountId === '50a1555198a72f1b6261306e'; //PETA
    }

    function hasListUpload(accountId) {
        // enable list upload for chad.acxiom:
        // accountId 5310e023801a6ae739276dfb
        // ayc_admin: 55c3de820cf28ad1ce00fc0b
        // fridays: 573b8561e4b084535544519e
        return accountId === '4e6fa6177a475eefd68fb5ee' ||
            accountId === '4e6fa6137a475eefd68fb5e2' ||
            accountId === '546239d10cf25fdcf0aea9b2' ||
            (accountId === '5310e023801a6ae739276dfb' &&
            waterfall.authenticate.user === '553ea4640cf2db1ff17235e0') ||
            (accountId === '55c3de820cf28ad1ce00fbf7' &&
            waterfall.authenticate.user === '55c3de820cf28ad1ce00fc0b') ||
            waterfall.authenticate.user === '573b8561e4b084535544519e' ||
            waterfall.authenticate.user === '56940090e4b0207226f960d7' ||
            waterfall.authenticate.user === '56cf42d7e4b087593e77a411' ||
            waterfall.authenticate.user === '56940052e4b0207226f960bf' ||
            waterfall.authenticate.user === '5820a969e4b0772d68e67224' ||
            waterfall.authenticate.user === '58fe5a0fe4b0e46c169fb010' ||
            waterfall.authenticate.user === '582ca5b2e4b07cc5aeb8652b' ||
            waterfall.authenticate.user === '5a9d75e07647351e279c1128' ||
            waterfall.authenticate.user === '5a9d80ae7647351e279d42c5' ||
            waterfall.authenticate.user === '5a9d7e932718400dd1265534' ||
            // credit one masteradmin role
            waterfall.authenticate.role === '55134b2f0cf2e196022e2b00';
    }

    function restrictMarketronUserPage(userId) {
        return !_.isEmpty(_.find(marketronRestriction, function (id) {
            return userId === id;
        }));
    }

    function marketronFeatures() {
        return sharedStrings.deployTarget === 'marketron' ||
            _.contains(['localhost', 'beta.waterfall.com'],
                window.location.hostname);
    }

    function devFeatures() {
        return _.contains(['localhost', 'beta.waterfall.com',
            'pizza.waterfall.com', 'staging.waterfall.com'],
            window.location.hostname);
    }

    /**
     * Access a cached promise of the feature flags
     *
     * This function provides async access to the feature flags, and if passed
     * a string, will resolve or reject based on whether that flag is truthy.
     *
     * ```
     * define(['msgme/util/feature-flags'], function (featureFlags) {
     *     featureFlags('dashboard').
     *         then(function () {
     *             path.map(url).to(function() {
     *                 // ...
     *             });
     *         }).
     *         fail(function (error) {
     *             if (error.statusCode) {
     *                 // the call to whoami() failed
     *             } else {
     *                 // error.dashboard is falsy
     *             }
     *         });
     * });
     * ```
     *
     * ```
     * define(['msgme/util/feature-flags'], function (featureFlags) {
     *     featureFlags().
     *         then(function (flags) {
     *             if (flags.foo && flags.bar) {
     *                 // ...
     *             }
     *         });
     * });
     * ```
     *
     * @param {String} [which] - which feature to test
     *
     * @returns {Promise} - a promise that resolves to the feature flags object
     */
    function featureFlags(which) {
        if (!promise) {
            promise = auth.whoami().then(function () {
                viewmodel.features = {
                    dashboard: true,
                    welcomeModal: false,
                    dynamicInsertion: true,
                    messageSidebar: true,
                    showSubscriberNames:
                        isAmfam(waterfall.authenticate.account),
                    restrictPapaMurphysUI:
                        isPapaMurphysL1(waterfall.authenticate.role) ||
                            isPapaMurphysL2(waterfall.authenticate.role),
                    restrictPapaMurphysUIL1:
                        isPapaMurphysL1(waterfall.authenticate.role),
                    enableListUpload:
                        hasListUpload(waterfall.authenticate.account),
                    disableListManagement:
                        useOldSubscription(waterfall.authenticate.account),
                    marketronFeatures: marketronFeatures(),
                    marketronDisableUserPage:
                        restrictMarketronUserPage(waterfall.authenticate.user),
                    devFeatures: devFeatures()
                };

                return viewmodel.features;
            });
        }

        return promise.then(function (flags) {
            if (which) {
                return flags[which] ?
                    flags :
                    $.Deferred().reject(viewmodel.features);
            } else {
                return flags;
            }
        });
    }

    featureFlags.reset = function () {
        promise = undefined;
    };

    return featureFlags;
});

