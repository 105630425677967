
define("json!widgets/message-preview/strings.json", function(){ return {
  "header": [
    " to "
  ],
  "buttons": {
    "testMessage": "Send test message"
  },
  "message": {
    "mobileFlow": [
      "Forward to ",
      " campaign"
    ],
    "none": "No message sent"
  },
  "destination": "Destination List(s): ",
  "segmentation": "Segmentation: ",
  "attachmentLink": "preview attachment",
  "unknownMessageType": "Unknown message type",
  "type": "Type: ",
  "tagMetadata": "Tag Metadata: "
};});

