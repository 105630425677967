define('widgets/datetimepicker/index',[
    'msgme/underscore',
    'msgme/util/format',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    'lib/bootstrap',
    'lib/bootstrap-datetimepicker'
], function(_, format, ThreeWidget, template, strings) {
    var mapping = {
        defaults: {
            data: null,
            // Nutjob date format here: bootstrap-datetimepicker.js#L1115
            format: 'MM/dd/yyyy HH:mm PP',
            placeholder: strings.placeholder,
            dateFormat: null,
            iconClass: 'fa-calendar'
        }
    };

    // should this just be msgme_datetime?
    $.widget('msgme.msgme_datetimepicker', ThreeWidget, {
        options: {
            pick12HourFormat: true,
            pickSeconds: false
        },

        _template: template,

        _mapping: mapping,

        _create: function () {
            var startDate = this.options.startDate;

            if (startDate) {
                // to work around the bootstrap datetimepicker's braindead
                // mixing of UTC and local dates, we need to set startDate to
                // just before midnight on the day before
                startDate = new Date(startDate.valueOf() - 24 * 60 * 60 * 1000);
                startDate.setHours(23);
                startDate.setMinutes(59);
                startDate.setSeconds(59);
                startDate.setMilliseconds(999);
                this.options.startDate = startDate;
            }

            ThreeWidget.prototype._create.apply(this, arguments);

            if (this.option('viewmodel').pickDate) {
                this.options.pickDate = this.option('viewmodel').pickDate();
            }

            // use any user-specified datetimepicker options
            var datetimepickerOptions = _.clone($.fn.datetimepicker.defaults);
            _.each(_.keys(datetimepickerOptions), function (option) {
                if (option in this.options) {
                    datetimepickerOptions[option] = this.options[option];
                }
            }, this);
            var $bsdp = this.element.find('.bs-datetimepicker-el').
                datetimepicker(datetimepickerOptions);

            this.element.addClass('msgme-datetimepicker');
            this.element.find('.clear').addClass('add-on');
            this.$dropdown = $bsdp.data('datetimepicker').widget;
            // i don't understand why we need to do this, but if you remove it,
            // you get stuck in the time mode
            this.$dropdown.on('shown', this.onClickCollapse);
            this.on('mouseup', '.clear', 'onClickClear');
            this.on('changeDate', 'onDateChange');
            this.option('viewmodel').data.subscribe(
                this.onDataChange.bind(this));
            this.picker = $bsdp.data('datetimepicker');
            this.onDataChange();
            this.on('show', 'onShow');
        },

        enable: function () {
            this.element.
                toggleClass('disabled').
                find('.bs-datetimepicker-el').datetimepicker('enable');
        },

        disable: function () {
            this.element.
                toggleClass('disabled').
                find('.bs-datetimepicker-el').datetimepicker('disable');
        },

        onClickClear: function() {
            this.option('viewmodel').data(null);
        },

        onClickCollapse: function (evt) {
            $(evt.target).addClass('collapse');
        },

        onDataChange: function (val) {
            var vm = this.option('viewmodel');

            if (vm.data() && !val) {
                this.picker.setLocalDate(Date.create(vm.data()));
            } else {
                if (val) {
                    this.picker.setLocalDate(Date.create(val));
                    if (vm.data() !== val) {
                        this.onDateChange();
                    } else if (vm.dateFormat() && Date.create(
                        format[vm.dateFormat()](val)) !== vm.data()) {
                        this.onDateChange();
                    }
                } else {
                    this.picker.setLocalDate(val);
                }
            }
        },

        onDateChange: function () {
            var vm = this.option('viewmodel');

            if (vm.dateFormat()) {
                vm.data(format[vm.dateFormat()](this.picker.getLocalDate()));
            } else {
                vm.data(this.picker.getLocalDate());
            }
        },

        onShow: function() {
            var date = this.option('viewmodel').data;
            var now = new Date();
            var nextHour = now.advance({hours: 1});
            var nextHourSharp = nextHour.set({minutes: 0, seconds: 0,
                milliseconds: 0});

            if (!date()) {
                date(nextHourSharp);
            }
        }
    });

    return {
        widget: $.msgme.msgme_datetimepicker,
        mapping: mapping
    };
});

