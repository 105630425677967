define('msgme/viewmodel/subscriber',[
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/subscriber-profile'
], function(ko, viewmodel, subscriberProfileMapping) {
    var model =
        ko.mapping.fromJS({
            subscriber: 'subscriber-profile'
        }, {
            subscriber: subscriberProfileMapping
        }).subscriber();
    model.widgetId('subscriber-profile');

    return {
        model: viewmodel.subscriber = model,
        collection: viewmodel.subscribers = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('subscriber/page')
        }
    };
});



