define('widgets/sms-message-body/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'widgets/message-body/index',
    'msgme/widgets/message-textbox',
    'text!./template.html',
    'json!./strings.json',
    'json!./../message-body/strings.json'
], function(_, ko, viewmodel, MessageBody, messageTextbox, smsMessageBodyHtml,
    strings, messageBodyStrings
) {
    var mapping = $.extend(true, {}, MessageBody.mapping, {
        defaults: {
            maxLength: null
        },

        sliceMapping: {
            defaults: {
                placeholder: strings.placeholder + ' ' +
                    messageBodyStrings.laterDate
            },

            computed: {
                // an sms slice will either receive a plain message, a
                // mobile flow, or no message. we're calling that the
                // 'type' for the sake of this viewmodel. the api
                // determines the slice's type based on the 'message' and
                // 'mobileFlow' properties, and the functions below
                // maintain the logic for setting those properties based on
                // the type the user chooses.
                type: {
                    read: function() {
                        var data = this.data();
                        return !data ? 'sms' :
                            data.mobileFlow() != null ? 'mobileflow' :
                            data.message() == null ? 'nomessage' :
                            'sms';
                    },
                    write: function(parent, value) {
                        var data = this.data();
                        if (!data) {
                            return;
                        }
                        if (value === 'mobileflow') {
                            data.mobileFlow('');
                        } else {
                            data.mobileFlow(null);
                        }
                        if (value === 'sms' && data.message() == null) {
                            data.message('');
                        }
                        if (value === 'nomessage') {
                            data.message(null);
                        }
                    }
                },
                channelWrapperClass: {
                    read: function() {
                        return {'not-sms-message': this.type() !== 'sms'};
                    },
                    deferEvaluation: true
                },
                disable: {
                    read: function() {
                        return this.type() !== 'sms';
                    },
                    deferEvaluation: true
                }
            }
        }
    });

    $.widget('msgme.msgme_sms_message_body', MessageBody, {
        _template: smsMessageBodyHtml,

        _create: function() {
            // the shortcodes aren't populated when the module loads, so we
            // need to set the default here
            mapping.defaults.maxLength =
                viewmodel.globals.shortcodes.session().maxSmsLength;

            this._mapping = this._mapping || mapping;
            this.element.addClass('sms-message-body');
            MessageBody.prototype._create.apply(this, arguments);
        },

        _createViewModel: function() {
            var vm = MessageBody.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        }
    });

    return {
        widget: $.msgme.msgme_sms_message_body,
        mapping: mapping
    };
});

