define('msgme/viewmodel/message',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/api',
    'msgme/util/format',
    'msgme/util/is-mms-enabled',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json'
], function (_, ko, viewmodel, api, format, isMmsEnabled, flags, strings) {
    var model = ko.observable(null);
    var pageLoadedAt = new Date();

    function humanReadableDI(message) {
        var dynamicInsertionRegexRaw = new RegExp(
            '\\{subscriber\\.metadata\\.\\("(.*?)"\\)(' +
            '; default=".*?")?(; format=".*?")?\\}', 'g');
        var couponRegexRaw = new RegExp(
            '\\{coupon\\.\\("(.*?)"\\)(' +
            '; default=".*?")?(; format=".*?")?\\}', 'g');
        var messageBody = message;

        if (messageBody) {
            messageBody = messageBody.replace(couponRegexRaw,
                function (match, couponId) {
                var coupons = viewmodel.globals.coupons();
                var coupon = _.find(coupons, function (coupon) {
                    return coupon.id === couponId;
                });

                if (!coupon) {
                    return match;
                }

                return '{coupon:' + coupon.name + '}';
            });
        }

        return messageBody && messageBody.replace(
            dynamicInsertionRegexRaw,
            function(match, metadatumId, defaultTextToken, formatTextToken) {

            defaultTextToken = defaultTextToken || '';
            formatTextToken = formatTextToken || '';

            var metadata = viewmodel.globals.metadata();
            var metadatum = _.find(metadata, function(metadatum) {
                return metadatum.id === metadatumId;
            });

            if (!metadatum) {
                return match;
            }

            var sameNameMetadata = _.filter(metadata, function(m) {
                return m.name === metadatum.name;
            });
            var metadatumIndex = _.indexOf(
                _.pluck(sameNameMetadata, 'id'), metadatum.id);
            var metadatumIndexToken = metadatumIndex > 0 ?
                ' (' + metadatumIndex + ')' : '';

            return '{' + metadatum.name + metadatumIndexToken +
                defaultTextToken + formatTextToken + '}';
        });
    }

    function genericUrlShortener(message) {
        var urlRegex = new RegExp('<URL:(.*?)>', 'gi');

        if (message && message.match(urlRegex)) {
            var replace = message.replace(urlRegex, 'http://dqs.co/xxxxxx');

            return replace;
        } else {
            return message;
        }
    }

    return {
        mapping: {
            defaults: {
                id: null,
                sendAt: null,
                tags: [],
                content: [],
                viewWidth: 12,
                isPapaMurphys: false,
                timezoneStatus: null
            },
            local: {
                state: 'compose',
                inFlightRequest: null,
                channels: [],
                onOlders: false,
                selectedAudience: null,
                selectedChannel: null,
                cachedSendAt: null,
                testSMS: {
                    segments: [],
                    selectSegment: [],
                    numbers: []
                },
                mapping: {
                    testSMS: {
                        mapping: {
                            subcomputed: {
                                numbers: {
                                    error: function () {
                                        return false;
                                    }
                                }
                            }
                        }
                    },
                    extend: {
                        channels: {
                            indexBy: {
                                keys: ['id'],
                                unique: true
                            }
                        }
                    },
                    channels: {
                        mapping: {
                            defaults: {
                                audiences: []
                            },
                            computed: {
                                filterable: function () {
                                    return this.id() === 'sms';
                                }
                            },
                            audiences: {
                                mapping: {
                                    defaults: {
                                        id: null,
                                        name: null,
                                        channel: null,
                                        filterId: null,
                                        isFiltered: false,
                                        queryFilters: null
                                    }
                                }
                            }
                        }
                    },
                    review: {
                        mapping: {
                            computed: {
                                date: function (root) {
                                    var scheduled = root.sendAt();
                                    return scheduled ?
                                        format.uiDateOnly(scheduled) : null;
                                },
                                time: function (root) {
                                    var scheduled = root.sendAt();
                                    return scheduled ?
                                        format.uiTimeOnlyNoSeconds(scheduled) :
                                        null;
                                },
                                day: function (root) {
                                    var scheduled = root.sendAt();
                                    return scheduled ?
                                        format.uiDayOnly(scheduled) : null;
                                }
                            }
                        }
                    }
                },
                review: {
                    count: 0
                }
            },
            subscribe: {
                sendAt: function (val, parent) {
                    if (!val) {
                        parent.onOlders(false);
                    }
                },
                onOlders: function (val, parent) {
                    if (val && (parent.timezoneStatus() !== 'ON_OLDERS' ||
                        parent.timezoneStatus() !== 'ON_IGNORE')) {
                        parent.timezoneStatus('ON_OLDERS');
                    } else {
                        parent.timezoneStatus(null);
                    }
                },
                timezoneStatus: function (val, parent) {
                    if (val === 'ON_OLDERS' || val === 'ON_IGNORE') {
                        parent.onOlders(true);
                    }
                }
            },
            extend: {
                content: {
                    indexBy: {
                        keys: ['channel'],
                        unique: true
                    }
                }
            },
            validation: {
                content: {
                    validArray: true
                },
                sendAt: {
                    validator: function (maybeDate) {
                        var now = new Date();
                        if (this.root.isPapaMurphys()) {
                            if (_.isNull(maybeDate)) {
                                if (now.getHours() >= 20 ||
                                    now.getHours() < 7) {
                                    return false;
                                }
                                return true;
                            } else {
                                if (maybeDate.getHours() >= 20 ||
                                    maybeDate.getHours() < 7) {
                                    return false;
                                }
                                return true;
                            }
                        } else {
                            if (_.isNull(maybeDate)) { return true; }
                            return maybeDate.isAfter(pageLoadedAt);
                        }
                    },
                    message: function () {
                        if (this.root.isPapaMurphys()) {
                            return strings.message.validation.pmiSendAt;
                        } else {
                            return strings.message.validation.sendAt;
                        }
                    }
                }
            },
            content: {
                mapping: {
                    defaults: {
                        channel: 'sms',
                        audience: [],
                        slices: [],
                        metadata: null,
                        image: null,

                        // simple payload
                        message: '',
                        subject: null,
                        files: [],
                        ad: null,
                        smsFallback: null,
                        sponsorship: null,
                        mobileFlow: null,
                        recipients: null,
                        embeddedAudience: []
                    },
                    local: {
                        count: -1
                    },
                    computed: {
                        filterable: function () {
                            return this.channel() === 'sms';
                        },
                        isHundredPercent: function () {
                            var sum = _.reduce(this.slices(),
                                function (sum, slice) {
                                    return sum + parseInt(
                                        slice.percentage(), 10);
                                }, 0);

                            return this.slices().length ? sum === 100 : true;
                        }
                    },
                    embeddedAudience: {
                        mapping: {
                            local: {
                                id: null,
                                count: null,
                                queryFilterDetails: {
                                    operator: null,
                                    details: [{
                                        operator: 'or',
                                        details: [{
                                            metadata: null,
                                            operator: null,
                                            value: null,
                                            radius: null
                                        }]
                                    }]
                                },
                                lists: [],
                                description: null
                            }
                        }
                    },
                    validation: {
                        slices: {
                            validation: [{
                                validator: function
                                    mmsNotEnabledValidator(val) {

                                    var slices = val;

                                    if (_.isEmpty(slices)) { return true; }

                                    var isNotABSplit = (slices.length === 1);
                                    var isCurrentTabMms =
                                        (slices[0].currentTab() === 'mms');

                                    return !(isNotABSplit && isCurrentTabMms &&
                                        !isMmsEnabled());
                                },

                                message: strings.
                                    message.body.validation.mmsNotEnabled
                            }, {
                                validator: function (val) {
                                    var sum = _.reduce(val,
                                        function (sum, slice) {
                                            return sum +
                                                parseInt(
                                                    slice.percentage(), 10);
                                        }, 0);

                                    return val.length < 2 || 100 === sum;
                                },
                                message: strings.
                                    message.body.validation.percentageSum
                            }, {
                                validator: function (val) {
                                    return _.all(val, function (slice) {
                                            return slice.isValid();
                                        });
                                },
                                message: strings.
                                    message.body.validation.slices
                            }]
                        }
                    },
                    slices: {
                        mapping: {
                            defaults: {
                                percentage: null,
                                message: '',
                                subject: null,
                                files: [],
                                ad: null,
                                sponsorship: null,
                                mobileFlow: null,
                                value: null,
                                recipients: null,
                                currentTab: 'sms',
                                smsFallback: null,
                                maxMsgLength: null
                            },
                            computed: {
                                mobileFlowName: function () {
                                    var flowId = this.mobileFlow();
                                    var flow = flowId ?
                                        viewmodel.globals.flows.byId(flowId) :
                                        null;

                                    return flow ? flow.name : null;
                                },
                                filesLink: function () {
                                    var urlify = [];

                                    _.each(this.files(), function (file, i) {
                                        urlify[i] = waterfall.file.url + '/' +
                                            file;
                                    });

                                    return urlify;
                                },
                                humanReadableDIMessage: function () {
                                    return genericUrlShortener(
                                        humanReadableDI(this.message()));
                                },
                                adCopy: function () {
                                    if (this.ad() && viewmodel.globals.ads() &&
                                        viewmodel.globals.ads.oneById(
                                            this.ad())) {
                                        return viewmodel.globals.ads.
                                            oneById(this.ad()).adCopy;
                                    }
                                },
                                sponsorshipCopy: function () {
                                    if (this.sponsorship() &&
                                        viewmodel.globals.sponsorships() &&
                                        viewmodel.globals.sponsorships.oneById(
                                            this.sponsorship())) {
                                        return viewmodel.globals.
                                            sponsorships.oneById(
                                                this.sponsorship()).name;
                                    }
                                },
                                isOverCharacterLength: function () {
                                    var smsLength = viewmodel.
                                        globals.shortcodes.
                                        session().maxSmsLength;
                                    var countStatus;
                                    this.maxMsgLength(smsLength);

                                    if (this.currentTab() === 'mms') {
                                        smsLength = strings.message.mmsMaxSize;
                                    }

                                    if (this.message()) {
                                        countStatus = format.charCountStatus(
                                            this.message(),
                                            smsLength, this.adCopy());
                                        this.maxMsgLength(countStatus &&
                                            countStatus.max);
                                    }

                                    return countStatus &&
                                        countStatus.isOverChar;
                                }

                            },
                            validation: {
                                percentage: {
                                    min: 1,
                                    max: 100
                                },

                                message: {
                                    validator: function(val) {
                                        var tab = this.parent.currentTab ||
                                            this.root.currentTab;
                                        var files;

                                        if (tab() !== 'mms') {
                                            return true;
                                        }

                                        files = this.parent.files();

                                        return val || (files && files.length);
                                    },

                                    message: function() {
                                        var s = strings.message.validation;

                                        return s.message.mms;
                                    }
                                }
                            }
                        }
                    }
                }
            },
            computed: {
                /**
                 * Return the members of each channel's `audiences` array that
                 * are actually targeted in this broadcast
                 *
                 * The contents of root.channels.byId()[foo].audiences is all
                 * audiences of channel "foo". Zero or more of these are
                 * actually targeted by the broadcast.
                 *
                 * This computed returns an object whose keys are channels with
                 * at least one targeted audience and values are an array of the
                 * targeted members of the channel's `audiences` array.
                 */
                targetAudiences: {
                    read: function (root) {
                        var contentByChannel = root.content.byChannel();
                        var result = {
                            channels: _.keys(contentByChannel)
                        };

                        _.each(contentByChannel, function (content, channel) {
                            if (!root.channels.byId()[channel]) {
                                // on save, the local channels data is blown
                                // away, which crashes us here. work around that
                                // by bailing since we don't actually care
                                // (we're about to redirect)
                                return {};
                            }

                            // iterate over the targetAudience array and grab
                            // the correct audiences, ensuring that we always
                            // return the same object for a given target
                            // audience so that knockout doesn't remove the
                            // audience row
                            result[channel] =
                                content.embeddedAudience();

                            result[channel].sort(function (a, b) {
                                return a.listName().toLowerCase() >
                                    b.listName().toLowerCase();
                            });
                        });

                        return result;
                    },
                    deferEvaluation: true
                },

                isFuture: function () {
                    return Date.create(this.sendAt()).isFuture();
                },

                isQuietTime: function () {
                    var firstHour = 22;
                    var lastHour = 10;

                    var safeSendAt = Date.create(this.sendAt());
                    var hour = safeSendAt.getHours();

                    return hour >= firstHour || hour < lastHour;
                },

                quietTimeWarningText: function () {
                    var safeSendAt = Date.create(this.sendAt());

                    return _.sprintf(
                        strings.message.review.warning.quietTime, {
                        time: safeSendAt.format('{h}:{mm} {TT}')
                    });
                },

                hasSmartlists: function () {
                    var smartlists = false;

                    _.each(this.content(), function (content) {
                        if (content.channel() === 'smartlists') {
                            smartlists = true;
                        }
                    });

                    return smartlists;
                },

                isMarketron: function () {
                    return strings.deployTarget === 'marketron';
                },

		/*
                listSelection: function () {
                    var lists = [{
                        name: 'All',
                        value: 'ALL'
                    }];

                    _.each(viewmodel.globals.lists(), function (list) {
                        lists.push({
                            name: list.name,
                            value: list.id,
                            class: 'list'
                        });
                    });
                    _.each(viewmodel.globals.smartlists(), function (list) {
                        lists.push({
                            name: list.name,
                            value: list.id,
                            class: 'smartlist'
                        });
                    });

                    return lists;
                },
		*/

                getLists: function () {
                    var lists = [{
                        name: 'All',
                        value: 'ALL'
                    }];

                    _.each(viewmodel.globals.lists(), function (list) {
                        lists.push({
                            name: list.name,
                            value: list.id,
                            class: 'list'
                        });
                    });
                    
                    return lists;
                },

                getSmartLists: function () {
                    var lists = [{
                        name: 'All',
                        value: 'ALL'
                    }];

                    _.each(viewmodel.globals.smartlists(), function (list) {
                        lists.push({
                            name: list.name,
                            value: list.id,
                            class: 'smartlist'
                        });
                    });

                    return lists;
                },

                listOptgroups: function () {
                    var groups = [];

                    if (viewmodel.globals.lists()) {
                        groups.push({
                            value: 'list',
                            label: 'Mobile'
                        });
                    }

                    if (viewmodel.globals.smartlists()) {
                        groups.push({
                            value: 'smartlist',
                            label: 'Smart Segments'
                        });
                    }

                    return groups;
                }
            }
        },
        model: viewmodel.message = model,
        collection: viewmodel.messages = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('messages/scheduled/page')
        }
    };
});

