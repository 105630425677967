define('widgets/broadcast-sidebar/index',[
    'msgme/underscore',
    'msgme/ko/observablePromise',
    './../three-widget',
    './../spinner/index',
    './../file-preview-modal/index',
    'text!./template.html',
    'json!./strings.json'
], function (
    _,
    observablePromise,
    ThreeWidget,
    spinner,
    filePreview,
    template
) {
    var mapping = {
        defaults: {
            title: null,
            request: null,
            broadcasts: [],
            broadcastPreviewId: null,
            noMessages: ''
        }
    };

    $.widget('msgme.msgme_broadcast_sidebar_widget', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            this._super.apply(this, arguments);
            this.on('click', '.show-media', '_onClickShowMedia');
            this.fetch();
        },

        _createViewModel: function () {
            var vm = this._super.apply(this, arguments);

            vm.request = observablePromise(false);

            return vm;
        },

        _onClickShowMedia: function (event) {
            var vm = this.option('viewmodel');
            var broadcastId = $(event.target).data('broadcast-id');
            var broadcast = _.find(vm.broadcasts(), function (broadcast) {
                return broadcast.id === broadcastId;
            });

            event.preventDefault();

            if (broadcast) {
                vm.broadcastPreviewId(broadcast.media);
            }
        },

        fetch: function () {
            throw new Error('msgme_broadcast_sidebar_widget fetch method ' +
                            'undefined');
        }
    });

    return {
        widget: $.msgme.msgme_broadcast_sidebar_widget,
        mapping: mapping
    };
});


