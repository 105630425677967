define('msgme/views/groups',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/util/format',
    'msgme/viewmodel',
    'msgme/viewmodel/group',
    'msgme/views/View',
    'msgme/widgets/pager',
    'json!widgets/shared-strings.json'
], function (
    _,
    splash,
    path,
    ko,
    format,
    viewmodel,
    groups,
    View,
    Pager,
    sharedStrings
) {
    var view = new View('#groups-view');
    var page, pager;
    var url = sharedStrings.sitemap.accounts.url;
    var cachedGroups;

    groups.collection.noResults = ko.observable(false);
    pager = new Pager(groups.collection);

    function onGroupsSuccess(groupsInstances) {
        var rows = _.map(groupsInstances.slice(), function (group) {
            group.noOfUsers =
                viewmodel.globals.users.allByGroup(group.id).length;
            group.creationDate = format.uiDate(group.creationDate);
            return group;
        });

        groups.collection.rows(rows);
        pager.update(groupsInstances);

        splash.hide();
        $('#groups-view-main').msgme('busy', false);
        $('groups-view-empty').removeClass('hidden');
    }

    function onFailure(what, e) {
        console.error('Failed to fetch', what + ': ', e);
    }

    function fetchGroups() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#groups-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !groups.collection.rows().length) {
            if (!groups.collection.pageSize() ||
                !groups.collection.pageIndex() ||
                !groups.collection.pageCount()) {
                resetViewmodelCollection();
            }

            // fetch the groups and populate page if not in a search
            view.callAPI('groups.fetch', {
                    page: page,
                    size: groups.collection.pageSize
                }).
                done(function (result) {
                    cachedGroups = result;
                    onGroupsSuccess(result);
                }).
                fail(_.bind(onFailure, this, 'groups'));
        } else {
            //fetch for cache
            view.callAPI('groups.fetch', {
                    page: page,
                    size: 15
                }).
                done(function (result) {
                    cachedGroups = result;
                    $('#groups-view-main').msgme('busy', false);
                }).
                fail(_.bind(onFailure, this, 'groups'));
        }
        
        viewmodel.globals.roles.refresh(true).done(function () {
            instantiateSearchBox();
        });
    }

    function resetViewmodelCollection() {
        groups.collection.pageSize(15);
        groups.collection.pageCount(-1);
        groups.collection.pageIndex(-1);
        groups.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var groupNames = _.map(viewmodel.globals.groups(), function (group) {
            return group.name;
        });
        var $searchBoxEl = view.root.find('.search-box');

        if (!!$searchBoxEl.hasOwnProperty('autocomplete')) {
            $searchBoxEl.autocomplete('destroy');
        }

        $searchBoxEl.autocomplete({
            source: groupNames,
            delay: 100
        });
    }

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        groups.collection.noResults(false);
        if (!query) {
            onGroupsSuccess(cachedGroups);
        } else {
            results = _.map(viewmodel.globals.groups(), function (group) {
                if (group.name) {
                    if (group.name.toLowerCase().
                        indexOf(query.toLowerCase()) !== -1) {
                        return group;
                    }
                }
            });

            onGroupsSuccess(_.compact(results));

            if (!_.compact(results).length) {
                groups.collection.noResults(true);
            }
        }

        view.root.find('.search-box').autocomplete('close');
    });

    view.root.on('click', '.search-group', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        onGroupsSuccess(cachedGroups);
        view.root.find('.search-box').val('');
        groups.collection.noResults(false);
    });

    // TODO: refactor this as functionality on the datatable
    $('#groups-view').on('click', 'td i.fa.fa-remove', function () {
        var rowEl = $(this).closest('tr');
        var record = groups.collection.rows()[rowEl.index()];
        var name = record.name;

        msgme.modal.confirm(
            'Are you sure you want to delete Sub-Account ' + name + '?', {
            title: 'Delete Sub-Account'
        }).done(_.bind(function (value) {
            if (value) {
                rowEl.addClass('deleting');
                record.del().
                    done(function () {
                        fetchGroups();
                        msgme.alert.success('The sub-account has been deleted');
                    }).
                    fail(function () { rowEl.removeClass('deleting'); }).
                    fail(view.getRequestFailureFn(null,
                        'group.delete',
                        record.data().id));
            }
        }, this));
    });

    path.map(url + '(/page/:page)').to(function () {
        view.show();
        page = this.params.page;
        fetchGroups();
        instantiateSearchBox();
    });

    return view;
});


