define('msgme/ko/mapping/subscribe',[
    'msgme/underscore',
    'msgme/ko/mapping'
],
function (_, komapping) {
    function getSubscriber(subscriber, root, parent) {
        return function (newValue) {
            subscriber.call(this, newValue, root, parent);
        };
    }

    /**
     * Subscribe to a mapped observable
     *
     * The value of the mapping must be a function, which we call the listener.
     * It will be called in the context of the ko.subscription object
     * corresponding to the listener. It will be passed the new value, the root
     * model, and the parent model of the subscribed observable.
     *
     * The mapped observable must exist at mapping time.
     *
     * Note: the plugin bounces the subscription through an intermediary
     *       function to accomplish the binding, so this.callback will not be
     *       the listener.
     *
     * For example:
     *
     * var model = ko.mapping.fromJS({
     *    foo: {
     *         bar: 'applesauce'
     *     }
     * }, {
     *     foo: {
     *         mapping: {
     *             subscribe: {
     *                 bar: function (newValue, root, parent) {
     *                     // this instance of ko.subscription
     *                     // this.target === model.foo.bar
     *                     // root === model
     *                     // parent === model.foo
     *
     *                     console.log('foo.bar changed:', newValue);
     *                 }
     *             }
     *         }
     *     }
     * });
     */
    komapping.fromJS.plugins.after.subscribe =
        function (source, mapping, target, root) {
            var subscribe = mapping.subscribe;

            for (var k in subscribe) {
                if (subscribe.hasOwnProperty(k)) {
                    if (!_.isFunction(subscribe[k])) {
                        throw new Error('Subscriber must be a function');
                    }

                    target[k].subscribe(
                        getSubscriber(subscribe[k], root, target));
                }
            }

            for (k in subscribe) {
                if (subscribe.hasOwnProperty(k)) {
                    if (k in source) {
                        target[k].valueHasMutated();
                    }
                }
            }
        };

    return komapping.fromJS.plugins.after.subscribe;
});

