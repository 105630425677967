define('msgme/viewmodel/flowmodule-listmanagement',[],function () {
    var mapping = {
        local: {
            title: 'Assign to Additional List',
            previousList: null
        },
        params: {
            mapping: {
                defaults: {
                    listId: null
                },

                validation: {
                    listId: {
                        validator: function (val) {
                            return val !== 'placeholder';
                        },
                        
                        message: 'Please select a list'
                    }
                }
            }
        }
    };

    return {
        mapping: mapping
    };
});

