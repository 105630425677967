define('msgme/widgets/flowmodule-basictext',[
    'jquery',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-basictext'
], function ($, ko, flowmodule, basicText) {
    $.widget('msgme.msgme_flowmodule_basictext', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-basictext-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
        }
    });

    $.msgme.msgme_flowmodule_basictext.Model =
        flowmodule.modelFactory({}, basicText.config);

    return $.msgme.msgme_flowmodule_basictext;
});


