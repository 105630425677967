define('msgme/viewmodel/audience',[
    'msgme/util/format'
], function(format) {
    return {
        mapping: {
            defaults: {
                name: null,
                channel: 'sms',
                isSegmented: false,
                subscribers: 45983,
                followers: 45983,
                fans: 45983,
                filters: []
            },
            local: {
                selected: false
            },
            computed: {
                size: function() {
                    var type = this.channel();
                    return type === 'sms'? this.subscribers() :
                        type === 'twitter'? this.followers() :
                        type === 'facebook'? this.fans() : 0;
                }
            },
            filters: {
                mapping: {
                    defaults: {
                        metadata: null,
                        operator: null,
                        value: null
                    }
                }
            },
            subcomputed: {
                size: {
                    formatted: function(root, parent) {
                        return format.integer(parent());
                    }
                }
            }
        }
    };
});



