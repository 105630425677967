define('widgets/stat-card/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/format',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json'
], function (
    _,
    ko,
    viewmodel,
    format,
    ThreeWidget,
    template,
    strings
) {
    var unwrap = ko.utils.unwrapObservable;
    var mapping = {
        defaults: {
            data: null
        },

        computed: {
            type: function (root) {
                return unwrap(root.data().type) || '';
            },

            title: function (root) {
                var type = root.type();

                return type ? strings[type].title : '';
            },

            count: function (root) {
                var count = unwrap(root.data().count);

                return format.integer(count == null ? strings.na : count);
            },

            countInfo: function (root) {
                var type = root.type();

                return type && root.data().count ?
                    strings[type].count.helptext : '';
            },

            delta: function (root) {
                var delta = unwrap(root.data().delta);
                var sign = delta >= 0 ? '+' : '-';

                if (delta == null) {
                    return '';
                }

                return sign + format.integer(Math.abs(delta)) + ' ';
            },

            deltaPos: function (root) {
                return unwrap(root.data().delta) >= 0;
            },

            deltaInfo: function (root) {
                var type = root.type();

                return type && root.data().delta ?
                    strings[type].delta.helptext : '';
            }
        }
    };

    $.widget('msgme.msgme_stat_card', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            this._super.apply(this, arguments);

            this.element.msgme('busy', true);
            this._loadData().
                always(_.bind(this.element.msgme, this.element, 'busy', false));
        },

        _loadData: function () {
            return $.Deferred().resolve().promise();
        }
    });

    return {
        widget: $.msgme.msgme_stat_card,
        mapping: mapping
    };
});

