define('msgme/views/role',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/role',
    'msgme/views/View',
    'json!widgets/shared-strings.json'
],
function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    mapping,
    role,
    View,
    sharedStrings
) {
    var view = new View('#role-view');
    var cm = new mapping.CursorModel(waterfall.roles, {
        record : {mapping: role.mapping}
    });
    var isNewRole = false;

    function populate(roleInstance) {
        role.model(roleInstance);
        if (isNewRole) {
            setDefaultPermissions();
        }

        splash.hide();
        $('#role-view').msgme('busy', false);
        role.model.error(false);
        // TODO: fix hacky way of showing/hiding elements as refreshing the
        // viewmodel wipes out the current scroll position when the dropdowns
        // update
        
        _.defer(function () {
            _.each(view.root.find('#role-view-main select'), function (row, i) {
                if (role.model().perms().rows[i].accessSelected === 'rw') {
                    $(view.root.find('.function-actions')[i]).
                        removeClass('hidden');
                }
            });
        });
    }

    view.root.on('click', 'button.add-group', function () {
        role.model().groupsVisible.push(null);
    });

    view.root.on('click', 'i.fa-remove', function () {
        var i = $(this).closest('.group-row').index();
        role.model().groupsVisible.splice(i, 1);     // remove element
        // if no default group is selected, clear the group observable
        role.model().group(null);
    });

    view.root.on('change', 'select.group', function () {
        var $this = $(this);
        var i = $this.closest('.group-row').index();
        // replace the group element that changed
        role.model().groupsVisible.splice(i, 1, $this.val());

        // if this is the default group, update the group observable
        if ($($this.siblings().
              find('input.default-group')[i]).prop('checked')
        ) {
            role.model().group($this.val());
        }
    });

    view.root.on('change', '#role-view-main select', function () {
        var perms;
        var $this = $(this);
        var i = $this.closest('tr').index();
        if ($this.val() === 'rw') {
            perms = role.model().perms();
            perms.rows[i].privs = {
                create : true,
                read : true,
                update : true,
                'delete' : true
            };
            $.extend({}, role.model().perms, perms);
            // TODO: fix hacky checkbox hiding
            $(view.root.find('.function-actions')[i]).removeClass('hidden');
            _.each($('input', $(view.root.find('.function-actions')[i])),
                function (checkbox) {
                $(checkbox).prop('checked', true);
            });
        } else if ($this.val() === 'ro') {
            perms = role.model().perms();
            perms.rows[i].privs = {
                create : false,
                read : true,
                update : false,
                'delete' : false
            };
            $.extend({}, role.model().perms, perms);
            $(view.root.find('.function-actions')[i]).addClass('hidden');
        } else {
            perms = role.model().perms();
            perms.rows[i].privs = {
                create : false,
                read : false,
                update : false,
                'delete' : false
            };
            $.extend({}, role.model().perms, perms);
            $(view.root.find('.function-actions')[i]).addClass('hidden');
        }
    });


    function setDefaultPermissions () {
        var rolesToSet = ['Broadcasts', 'Campaigns', 'Carriers', 'Content',
            'Coupon', 'File', 'Filters', 'Inbox', 'Keywords', 'Lists',
            'List Export', 'Metadata', 'Reporting',
            'Roles', 'Short Codes', 'Simple Message', 'Stream',
            'Sub-Accounts', 'Subscriber', 'Trigger', 'Users'];

        _.each(role.model().perms().rows, function (row) {
            if (row.hasPermission) {
                if (_.contains(rolesToSet, row.featureName)) {
                    row.accessSelected = 'rw';
                    row.privs = {
                        create: true,
                        read: true,
                        update: true,
                        delete: true
                    };
                }
            }
        });
        role.model.valueHasMutated();
    }

    view.root.on('click', 'a.role-save', function () {
        if (!role.model().isValid || role.model().isValid()) {
            role.updatePermissions();
            role.model().save().
            done(function() {
                viewmodel.globals.roles.refresh(true);
                path.history.pushState(null, null,
                    sharedStrings.sitemap.roles.url);
                msgme.alert.success('The role has been saved');
            }).
            fail(view.getRequestFailureFn(null,
                'role.save',
                ko.toJS(role.model())));
        } else {
            role.model.error(true);
        }
    });

    path.map(sharedStrings.sitemap.roles.url + '/:id').to(function () {
        view.show();
        $('#role-view').msgme('busy', true);

        if (this.params.id.toLowerCase() === 'new') {
            isNewRole = true;
            populate(cm.create());
        } else {
            isNewRole = false;
            cm.fetch(this.params.id).
                done(populate).
                fail(view.getRequestFailureFn(null,
                    'roles.fetch',
                    this.params.id));
        }
    });

    return view;
});

