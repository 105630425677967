define('msgme/views/list',[
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/list',
    'msgme/views/View',
    'msgme/widgets/pager',
    'msgme/globals',
    'msgme/underscore',
    'msgme/util/format',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
    'msgme/widgets/modal',
    'msgme/plugins'
],
function (splash, path, ko, viewmodel, mapping, list, View, Pager,
    globals, _, format, featureFlags, sharedStrings
) {
    var view = new View('#list-view');
    var pager;
    var url = sharedStrings.sitemap.lists.url;
    var isSubscriberNamesFeatureEnabled = ko.observable(false);

    var cm = new mapping.CursorModel(waterfall.lists, {
        record : {
            defaultData: {
                id: null
            },
            mapping: list.mapping
        }
    });

    pager = new Pager(list.model.subscribers);

    featureFlags('showSubscriberNames').then(function () {
        isSubscriberNamesFeatureEnabled(true);
    });

    featureFlags('restrictPapaMurphysUI').then(function () {
        view.root.find('.btn.list-export').hide();
        view.root.find('.btn.list-save').hide();
    });

    function populate(listInstance, subscribers) {
        list.model.list(listInstance);

        if (subscribers) {
            // update subscriber state
            var modifiedsubs = _.map(subscribers, function (subscriber) {
                subscriber.mobileNumber =
                    format.phoneNo(subscriber.mobileNumber);
                subscriber.url = waterfall.authenticate.isMasked ?
                    subscriber.id :
                    format.unformattedPhoneNo(subscriber.mobileNumber);
                subscriber.subscriberDisplayName = ko.computed(function () {
                    var metadata = viewmodel.globals.metadata();
                    var firstNameMetadata = _.find(metadata,
                        function (metadatum) {
                            return metadatum.scope === 'PROFILE' &&
                                metadatum.name === 'firstName';
                        });
                    var lastNameMetadata = _.find(metadata,
                        function (metadatum) {
                            return metadatum.scope === 'PROFILE' &&
                                metadatum.name === 'lastName';
                        });
                    var firstName, lastName;

                    if (!isSubscriberNamesFeatureEnabled() ||
                        !(firstNameMetadata && lastNameMetadata)) {
                        return subscriber.mobileNumber;
                    }

                    firstName =
                        subscriber.metaData[firstNameMetadata.id];
                    lastName =
                        subscriber.metaData[lastNameMetadata.id];

                    if (!firstName && !lastName) {
                        return subscriber.mobileNumber;
                    }

                    return [firstName, lastName].join(' ');
                });

                if (listInstance.id() &&
                        subscriber.lists[listInstance.id()]) {
                    subscriber.joinDate = format.uiDate(
                        subscriber.lists[listInstance.id()].joinDate);
                    subscriber.source =
                        subscriber.lists[listInstance.id()].source;
                } else {
                    subscriber.joinDate = '';
                    subscriber.source = '';
                }
                return subscriber;
            });
            list.model.subscribers.rows(modifiedsubs);
            list.model.subscribers.
                url(_.sprintf('lists/%s/subscribers/page', listInstance.id()));
            pager.update(subscribers);
        } else {
            list.model.subscribers.rows([]);
            list.model.subscribers.url(null);
        }

        splash.hide();
        $('#list-view-subscribers').msgme('busy', false);
    }

    view.root.on('click', 'button.list-save', function () {
        var listModel = list.model.list();

        list.model.duplicateName(false);
        if (listModel.isValid()) {
            cm.fetch({name: list.model.list().name()}).done(
                function (result) {
                    if (result.rows().length === 0 ||
                        result.rows()[0].id() ===
                        list.model.list().id()
                    ) {
                        list.model.list().save().
                            done(function() {
                                path.history.pushState(null, null, url);
                                msgme.alert.success('The list has been saved');
                                viewmodel.globals.lists.refresh(true);
                            }).
                            fail(view.getRequestFailureFn(null,
                                'listDetails.list.save',
                                ko.mapping.toJS(list.model.list())));
                    } else {
                        list.model.duplicateName(true);
                    }
                }).fail(view.getRequestFailureFn(null, 'listDetails.fetch',
                    list.model.list().name()));
        } else {
            listModel.isModified(true);
        }
    });

    view.root.on('click', 'button.list-export', function () {
        var current = list.model.list();
        var payload = {
            query: {
                type: 'listDetails',
                lists: [current.id()]
            }
        };

        current.exportListDetailsPromise(
            view.callAPI('reporting.create', payload).
                then(function () {
                    path.history.pushState(null, null,
                        sharedStrings.sitemap.reports.url);
                }));
    });

    path.map(url + '/:name(/subscribers/page/:page)').to(function () {
        var id = this.params.name;
        var name = format.parseUrlString(this.params.name.toLowerCase());
        //var listRecord, id;
        view.show();

        //reset list collection. need to fix the way pagination updates the
        //pager so that the collection params don't get messed up
        list.collection.pageSize(15);
        list.collection.pageCount(-1);
        list.collection.pageIndex(-1);

        list.model.duplicateName(false);
        if (name === 'new') {
            populate(cm.create());
        } else {
            /*
             * Commented code is for pretty list url
            $('#list-view-subscribers').msgme('busy', true);

            // look up the list by name
            listRecord = viewmodel.globals.lists.oneByName(name);

            if (!listRecord) {
                // not found as a name. is it an id?
                listRecord = viewmodel.globals.lists.oneById(name);

                if (listRecord) {
                    // found the list by id. navigate to the pretty URL
                    var listUrl = this.params.page ? url + '/' +
                        format.urlString(listRecord.name) +
                        '/subscribers/page/' + this.params.page :
                        url + '/' + format.urlString(listRecord.name);

                    path.history.replaceState(null, null, listUrl);
                    return;
                }
            }

            // use the id of the list or try the name as an id if not found
            id = listRecord ? listRecord.id : name;
            */
            $.when(
                cm.fetch(id).
                    fail(view.getRequestFailureFn(null,
                        'lists.fetch',
                        this.params.id)),
                view.callAPI('subscribers.fetch', {
                    listId: id,
                    page: this.params.page || 1,
                    size: list.collection.pageSize || 15
                })).
                done(populate);
        }
    });

    return view;
});


