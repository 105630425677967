define('msgme/widgets/datetime',[
    'jquery',
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/widget',
    'msgme/util/format'
], function ($, _, ko, Widget) {
    $.widget('msgme.msgme_datetime', Widget, {
        options: {
            showTime: true
        },

        _create: function () {
            Widget.prototype._create.apply(this);
            this.dateEl = this.element.find('input.date');
            this.hourEl = this.element.find('select.hour');
            this.minuteEl = this.element.find('select.minute');
            this.periodEl = this.element.find('select.period');

            if (this.options.showTime) {
                this.hourEl.show();
                this.minuteEl.show();
                this.periodEl.show();
            } else {
                this.hourEl.hide();
                this.minuteEl.hide();
                this.periodEl.hide();
            }
        }
    });

    return $.msgme.msgme_datetime;
});

