define('msgme/widgets/flowmodule-poll',[
    'jquery',
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-poll',
    'msgme/widgets/message-textbox'
], function ($, _, ko, flowmodule, poll) {
    $.widget('msgme.msgme_flowmodule_poll', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-poll-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);

            //this.on('change', 'select.choice-count', '_onChoiceCountChange');

            var data = this.options.data();
            var params = data.params;
            var choiceCount = params.choices().length;

            // initialize choiceCount
            if (choiceCount < 2) {
                params.choiceCount(3);
            } else {
                params.choiceCount(choiceCount);
            }

            this.on('change', '.send-message', '_onSendMessageChange');
            this.on('change', '.choice-count', '_updateSelectize');
            this._updateSelectize();
        },

        _updateSelectize: function () {
            var data = this.options.data();
            var delay;

            _.each(this.element.find('.choice-groups'), function (e, i) {
                if (data.metadataOptions()) {
                    delay = 0;
                } else {
                    delay = 3000;
                }
                _.delay(_.bind(function () {
                    if (!$(e).find(
                        '.metadata-select .selectize-control').length) {
                        $(e).find('.metadata-select').selectize({
                            maxOptions: 1000,
                            options: data.metadataOptions(),
                            optgroups: data.metadataOptgroups(),
                            optgroupField: 'class',
                            labelField: 'name',
                            searchField: 'name'
                        })[0].selectize.setValue(
                            data.params.choices()[i].metadataId());
                    }
                }, this), delay);
            });
        },

        _onRevive: function () {
            this._subscribe();
        },

        // this resets the current tab for the send message mobile message
        // boxes so that the invalid mms boxes would not trigger when the
        // send message input fields are toggled off

        _onSendMessageChange: function (e) {
            var params = this.options.data().params;

            if (!$(e.target).prop('checked')) {
                var index = $(e.target.closest('.choice-groups')).index();
            
                params.choices()[index].currentTab('sms');
            }
        }
    });

    $.msgme.msgme_flowmodule_poll.Model =
        flowmodule.modelFactory({}, {mapping: poll.mapping});

    return $.msgme.msgme_flowmodule_poll;
});


