define('msgme/views/roles',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/role',
    'msgme/views/View',
    'msgme/widgets/pager',
    'json!widgets/shared-strings.json'
],
function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    role,
    View,
    Pager,
    sharedStrings
) {
    var view = new View('#roles-view');
    var page, pager;
    var cachedRoles;

    role.collection.noResults = ko.observable(false);
    pager = new Pager(role.collection);

    function onFetchSuccess(roles) {
        var rows = _.map(roles.slice(), function (roleInstance) {
            var group = viewmodel.globals.groups.oneById(roleInstance.group);
            roleInstance.groupName = group ? group.name : '';
            roleInstance.noOfUsers =
                viewmodel.globals.users.allByRole(roleInstance.id).length;
            return roleInstance;
        });

        role.collection.rows(rows);
        pager.update(roles);

        splash.hide();
        $('#roles-view-main').msgme('busy', false);
        $('#roles-view-empty').removeClass('hidden');
    }

    function onFailure(what, e) {
        console.error('Failed to fetch', what + ': ', e);
    }

    function fetchRoles() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#roles-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !role.collection.rows().length) {
            if (!role.collection.pageSize() || !role.collection.pageIndex() ||
                !role.collection.pageCount()) {
                resetViewmodelCollection();
            }

            // fetch the roles and populate page if not in a search
            view.callAPI('roles.fetch', {
                    page: page,
                    size: role.collection.pageSize
                }).
                done(function (result) {
                    cachedRoles = result;
                    onFetchSuccess(result);
                }).
                fail(_.bind(onFailure, this, 'roles'));
        } else {
            //fetch for cache
            view.callAPI('roles.fetch', {
                    page: page,
                    size: 15
                }).
                done(function (result) {
                    cachedRoles = result;
                    $('#roles-view-main').msgme('busy', false);
                }).
                fail(_.bind(onFailure, this, 'roles'));
        }
        
        viewmodel.globals.roles.refresh(true).done(function () {
            instantiateSearchBox();
        });
    }

    function resetViewmodelCollection() {
        role.collection.pageSize(15);
        role.collection.pageCount(-1);
        role.collection.pageIndex(-1);
        role.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var roleNames = _.map(viewmodel.globals.roles(), function (role) {
            return role.name;
        });
        var $searchBoxEl = view.root.find('.search-box');

        if (!!$searchBoxEl.hasOwnProperty('autocomplete')) {
            $searchBoxEl.autocomplete('destroy');
        }

        $searchBoxEl.autocomplete({
            source: roleNames,
            delay: 100
        });
    }

    // TODO: refactor this as functionality on the datatable
    $('#roles-view').on('click', 'td i.fa-remove', function () {
        var rowEl = $(this).closest('tr');
        var record = role.collection.rows()[rowEl.index()];
        var name = record.name;

        msgme.modal.confirm('Are you sure you want to delete role ' +
            name + '?', {
            title: 'Delete Role'
        }).done(_.bind(function (value) {
            if (value) {
                rowEl.addClass('deleting');
                record.del().
                    done(function () {
                        fetchRoles();
                        msgme.alert.success('Role has been deleted');
                    }).
                    fail(function () { rowEl.removeClass('deleting'); }).
                    fail(view.getRequestFailureFn(null,
                        'role.delete',
                        record.data().id));
            }
        }, this));
    });

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        role.collection.noResults(false);
        if (!query) {
            onFetchSuccess(cachedRoles);
        } else {
            results = _.map(viewmodel.globals.roles(), function (role) {
                if(role.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return role;
                }
            });

            onFetchSuccess(_.compact(results));

            if (!_.compact(results).length) {
                role.collection.noResults(true);
            }
        }

        view.root.find('.search-box').autocomplete('close');
    });

    view.root.on('click', '.search-role', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        onFetchSuccess(cachedRoles);
        view.root.find('.search-box').val('');
        role.collection.noResults(false);
    });

    path.map(sharedStrings.sitemap.roles.url + '(/page/:page)').to(function () {
        view.show();
        page = this.params.page;
        instantiateSearchBox();
        fetchRoles();
    });

    return view;
});


