define('msgme/ko/extenders',[
    'msgme/ko/extenders/api-date',
    'msgme/ko/extenders/index-by'
], function (apiDate, indexBy) {
    return {
        apiDate: apiDate,
        indexBy: indexBy
    };
});

