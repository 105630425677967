define('msgme/views/reports',[
    'msgme/underscore',
    'lib/path',
    'msgme/splash',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/report',
    'msgme/util/api',
    'msgme/util/feature-flags',
    'msgme/util/format',
    'msgme/views/View',
    'msgme/widgets/pager',
    'msgme/modal',
    'widgets/report-parameters/index',
    'json!widgets/shared-strings.json',
    'json!widgets/create-report/strings.json',
    'widgets/create-report/index',
    'widgets/spinner/index'
],
function (
    _,
    path,
    splash,
    ko,
    viewmodel,
    mapping,
    report,
    api,
    featureFlags,
    format,
    View,
    Pager,
    modal,
    reportParameters,
    sharedStrings,
    createReportStrings
) {
    var view = new View('#reports-view');
    var pager = new Pager(report.collection);
    var page;
    var strings = sharedStrings.sitemap.reports;
    var url = new RegExp(strings.url +
        sharedStrings.sitemap.reports.regexExcludeScheduled +
        sharedStrings.sitemap.reports.regexExcludeRecurring +
        sharedStrings.sitemap.reports.regexExcludeNew +
        sharedStrings.sitemap.reports.regexExcludeId +
        strings.reportSubUrl);
    var cachedReports, allReports;

    function onFailure(what, e) {
        console.error('Failed to fetch', what + ': ', e);
    }

    function clearSearch() {
        populate(cachedReports);
        view.root.find('.search-box').val('');
        report.collection.noResults(false);
    }

    function fetchReports() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#reports-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !report.collection.rows().length) {
            if (!report.collection.pageSize() ||
                !report.collection.pageIndex() ||
                !report.collection.pageCount()) {
                resetViewmodelCollection();
            }

            // fetch the reports and populate page if not in a search
            waterfall.reporting.report.fetch({
                    page: page,
                    size: 5,
                    orderBy: '-dateScheduled'
                }).
                done(function (result) {
                    cachedReports = result;
                    populate(result);
                }).
                fail(_.bind(onFailure, this, 'reporting.fetch'));
        } else {
            //fetch for cache
            waterfall.reporting.report.fetch({
                    page: page,
                    orderBy: '-dateScheduled',
                    size: 5
                }).
                done(function (result) {
                    cachedReports = result;
                    $('#reports-view-main').msgme('busy', false);
                }).
                fail(_.bind(onFailure, this, 'reporting.fetch'));
        }
        
        waterfall.reporting.report.fetch({
                page: page,
                orderBy: '-dateScheduled',
                size: -1
            }).
            done(function (result) {
                allReports = result;
                instantiateSearchBox();
            });
    }

    function resetViewmodelCollection() {
        report.collection.pageSize(5);
        report.collection.pageCount(-1);
        report.collection.pageIndex(-1);
        report.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var reportNames = _.map(allReports, function (report) {
            return report.name;
        });
        var $searchBoxEl = view.root.find('.search-box');

        if (!!$searchBoxEl.hasOwnProperty('autocomplete')) {
            $searchBoxEl.autocomplete('destroy');
        }

        $searchBoxEl.autocomplete({
            source: reportNames,
            delay: 100
        });
    }

    function populate(reportsInstance) {
        var reportStrings = sharedStrings.reports;
        _.each(reportsInstance, function (report) {
            var data = report.data();
            var model = ko.mapping.fromJS({}, reportParameters.mapping);
            var repeatPrefix = report.repeatEvery > 1 ? 'Every ' + report.
                repeatEvery : '';

            report.reportStatus =
                reportStrings.status[report.report.status];
            if (report.report.status === 'COMPLETE') {
                report.date = format.uiDate(report.report.scheduledAt);
            } else if (report.report.status === 'ERROR') {
                report.date = 'Failed';
            } else {
                report.date = 'In progress';
            }
            report.type = createReportStrings.type.options[report.query.type];
            report.url = strings.url + (page > 1 ? '/page/' + page : '') +
                '/' + report.id;
            report.viewable = report.report.status === 'COMPLETE' &&
                report.query.type !== 'listDetails';

            model.data(ko.mapping.fromJS(data, report.mapping));
            report.summary =  model.summary();

            switch (report.repeatInterval) {
            case 'DAILY':
                report.recurringSummary = report.repeatEvery > 1 ?
                repeatPrefix + ' days' : 'Daily';
                return;
            case 'WEEKLY':
                report.recurringSummary = report.repeatEvery > 1 ?
                repeatPrefix + ' weeks' : 'Weekly';
                return;
            case 'MONTHLY':
                report.recurringSummary = report.repeatEvery > 1 ?
                repeatPrefix + ' months' : 'Monthly';
                return;
            default:
                report.recurringSummary = 'Never';
            }
        });

        splash.hide();
        $('#reports-view-main').msgme('busy', false);
        report.collection.rows(reportsInstance);
        pager.update(reportsInstance);
    }

    view.root.on('click', 'i.remove.fa.fa-remove', function () {
        var reportDeleteStrings = sharedStrings.reports['delete'];
        var $this = $(this);
        var entryEl = $this.closest('tr');
        var record = report.collection.rows()[entryEl.index()];
        var name = record.name;

        modal.confirm('Are you sure you want to delete report ' + name + '?', {
            title: reportDeleteStrings.title
        }).done(_.bind(function (value) {
            if (value) {
                record.del().
                    done(function () {
                        fetchReports();
                        msgme.alert.success(reportDeleteStrings.success);
                    }).
                    fail(view.getRequestFailureFn(null,
                        'reporting.delete', record.id));
            }
        }, this));
    });

    function resetShareReportModal() {
        var $modal = view.root.find('.share-report.modal');
        $modal.find('.permalink .spinner').show();
        $modal.find('.permalink input').val('').hide();
        $modal.find('.email .spinner').show();
        $modal.find('.email a').attr('href', '').hide();
        $modal.find('.error').hide();
    }

    function errorShareReportModal() {
        var $modal = view.root.find('.share-report.modal');
        $modal.find('.permalink .spinner').hide();
        $modal.find('.permalink input').show();
        $modal.find('.email .spinner').hide();
        $modal.find('.email a').hide();
        $modal.find('.error').show();
    }

    function successShareReportModal(reportFileUrl) {
        var $modal = view.root.find('.share-report.modal');
        $modal.find('.permalink .spinner').hide();
        $modal.find('.permalink input').val(reportFileUrl).show();
        $modal.find('.email .spinner').hide();
        $modal.find('.email a')
            .attr('href', 'mailto:?body=' + encodeURI(reportFileUrl)).show();
        $modal.find('.error').hide();
    }

    function showAndPopulateShareReportModal(event) {
        var $shareReportIcon = $(event.target);
        var reportId = $shareReportIcon.data('reportId');
        var $modal = view.root.find('.share-report.modal');

        $modal.modal('show');
        resetShareReportModal();

        function isReport(report) { return report.id === reportId; }
        var fileId = _.find(report.collection.rows(), isReport).report.file;

        api.call('fileV2.fetch', fileId + '/details').
            done(function (file) {
                if (file.url == null) {
                    errorShareReportModal();
                } else {
                    successShareReportModal(file.url);
                }
            }).
            fail(errorShareReportModal);
    }

    view.root.on('click', 'i.share-report', showAndPopulateShareReportModal);

    view.root.on('focus', '.share-report.modal .permalink input', function () {
        $(this).select();

        // Address WebKit bug that causes text to be de-selected on mouse-up:
        // https://code.google.com/p/chromium/issues/detail?id=4505
        $(this).one('mouseup', function (event) {
            event.preventDefault();
        });
    });

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        report.collection.noResults(false);
        if (!query) {
            populate(cachedReports);
        } else {
            results = _.map(allReports, function (report) {
                if (report.name && report.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return report;
                }
            });

            populate(_.compact(results));

            if (!_.compact(results).length) {
                report.collection.noResults(true);
            }
        }

        view.root.find('.search-box').autocomplete('close');
    });

    view.root.on('click', '.search-flow', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        clearSearch();
    });

    view.root.on('click', '.btn-success', function () {
        clearSearch();
    });

    path.map(url).to(function (pth, place, reportPage) {
        page = reportPage;
        view.show();
        fetchReports();
    });

    return view;
});

