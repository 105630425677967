define('widgets/footer/index',[
    'msgme/ko',
    './../three-widget',
    'text!./template.html',
    'json!./../shared-strings.json'
], function (ko, ThreeWidget, template, sharedStrings) {
    var mapping = {
        defaults: {
            data: null,
            tips: [],
            links: []
        },
        computed: {
            currentTip: function () {
                return this.tips()[0] || null;
            },
            copyright: function () {
                return sharedStrings.copyright + new Date().getFullYear() +
                    sharedStrings.talkToUs;
            }
        }
    };

    $.widget('msgme.msgme_footer', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            this.on('click', '.remove-tip', '_removeTip');
        },

        _removeTip: function () {
            this.option('viewmodel').tips.shift();
        }
    });

    return {
        widget: $.msgme.msgme_footer,
        mapping: mapping
    };
});

