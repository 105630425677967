define('widgets/follower-stat-card/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    './../stat-card/index',
    'text!./template.html'
], function (_, ko, viewmodel, statCard, template) {
    $.widget('msgme.msgme_follower_stat_card', statCard.widget, {
        _mapping: {
            defaults: {
                hasTwitterProfiles: true
            },
            computed: {
                hasTwitter: function () {
                    return !!viewmodel.globals.channels.oneByName('twitter');
                }
            }
        },

        _template: template,

        _createViewModel: function () {
            var vm = this._super.apply(this, arguments);

            vm.data(ko.mapping.fromJS({
                type: 'followers',
                count: null
            }));

            return vm;
        },

        _loadData: function () {
            var vm = this.option('viewmodel');
            var data = this.option('viewmodel').data;
            var twitter = viewmodel.globals.channels.byName('twitter');

            if (twitter) {
                return twitter.audiences.fetch().then(function (audiences) {
                    var count = _.reduce(audiences, function (count, audience) {
                        return count + audience.size;
                    }, 0);

                    vm.hasTwitterProfiles(audiences.length > 0);
                    data().count(count);
                });
            } else {
                vm.hasTwitterProfiles(false);
                return $.Deferred().resolve().promise();
            }
        }
    });

    return {
        widget: $.msgme.msgme_follower_stat_card,
        mapping: statCard.mapping
    };
});

