define('widgets/campaign-activity-entry/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/format',
    'msgme/util/api',
    'msgme/util/feature-flags',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    'json!./../shared-strings.json',
    'msgme/plugins'
], function (
    _,
    ko,
    viewmodel,
    format,
    api,
    featureFlags,
    ThreeWidget,
    template,
    strings,
    sharedStrings
) {
    var mapping = {
        defaults: {
            data: null,
            messages: [],
            createdAt: null,
            keyword: null,
            mobileFlow: null,
            msisdn: null,
            subscriber: null,
            message: null,
            _expand: null,
            response: null,
            subject: null,
            fileIds: [],
            currentTab: null
        },

        computed: {
            bodyVisible: {
                read: function (root) {
                    return !root.expand();
                },

                deferEvaluation: true
            },

            createdAtUiDate: function () {
                return format.uiDate(this.createdAt());
            },

            expand: {
                read: function (root) {
                    return root._expand();
                },

                write: function (root, val) {
                    root._expand(val);
                }
            },

            phoneNo: function () {
                return format.phoneNo(this.msisdn());
            },

            conversation: {
                read: function () {
                    return this.messages();
                },
                write: function (root, value) {
                    root.messages(value.reverse());
                }
            },

            keywordName: function () {
                var keyword =
                    viewmodel.globals.keywords.oneById(this.keyword());

                if (keyword) {
                    return keyword.name.toUpperCase();
                }
            },

            msgWithoutKeyword: function () {
                return this.message().replace(
                    new RegExp('^.*' + this.keywordName(), 'i'), '').trim();
            },

            msgHasKeyword: function () {
                return !!this.message().
                    match(new RegExp(this.keywordName(), 'i'));
            },

            isMarketron: function () {
                return sharedStrings.deployTarget === 'marketron';
            }
        },

        messages: {
            mapping: {
                defaults: {
                    createdAt: null
                },

                computed: {
                    createdAtUiDate: function () {
                        return format.uiDate(this.createdAt());
                    },

                    isMt: function () {
                        return this.details.messageType() === 'MT';
                    }
                }
            }
        }
    };

    $.widget('msgme.msgme_campaign_activity_entry', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            var el, vm;
            var subscribers = localStorage.getItem('hideSubscribers') ?
                JSON.parse(localStorage.getItem('hideSubscribers')) : [];

            ThreeWidget.prototype._create.apply(this, arguments);

            this._instantiated = {};

            el = this.element;
            vm = this.option('viewmodel');

            this.on('click', '.toggle-expand', '_onClickExpand');
            this.on('click', 'button.reply', '_onSubmitReply');
            this.on('click', '.hide-subscriber', '_hideSubscriber');

            if (_.indexOf(subscribers, vm.subscriber()) !== -1) {
                el.hide();
            }
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);

            vm.globals = viewmodel.globals;

            return vm;
        },

        _onClickExpand: function () {
            var vm = this.option('viewmodel');
            var el = this.element.find('.stream-entry');
            var sendMessageEl =
                this.element.find('.mobile-message-body-container');

            if (vm.expand()) {
                vm.expand(false);
                return;
            }

            el.msgme('busy', true);

            if (!sendMessageEl.find('.mobile-message').length) {
                sendMessageEl.msgme_mobile_message_body({
                    model: {
                        message: vm.response,
                        subject: vm.subject,
                        fileIds: vm.fileIds,
                        currentTab: vm.currentTab,
                        smsFallback: false
                    }
                });
            }

            api.call('stream.conversation', { page: 1, size: 10, id: vm.id() }).
                done(function (conversation) {
                    var mapped = _.map(conversation.collection,
                        function (message) {
                        return ko.mapping.fromJS(
                            message, mapping.messages.mapping);
                    });
                    vm.conversation(mapped);
                    vm.expand(true);
                }).
                always(function () { el.msgme('busy', false); }).
                fail(api.getRequestFailureFn(null,
                    'stream.conversation', { page: 1, size: 10 }));
        },

        _hideSubscriber: function (e) {
            var vm = this.option('viewmodel');
            var subscribers = localStorage.getItem('hideSubscribers') ?
                JSON.parse(localStorage.getItem('hideSubscribers')) : [];

            msgme.modal.confirm('Are you sure you want to hide this ' +
                'subscriber? You can unhide subscribers by clearing ' +
                'your cookies', {
                title: 'Hide Subscriber'
            }).done(_.bind(function (value) {
                if (value) {
                    subscribers.push(vm.subscriber());
                    localStorage.setItem('hideSubscribers',
                        JSON.stringify(subscribers));
                    $(e.target.closest('.campaign-activity-entry')).hide();
                }
            }));
        },

        _onSubmitReply: function () {
            var vm = this.option('viewmodel');
            var payload = {
                msisdns: [vm.msisdn()]
            };
            var message = vm.response();
            var subject = vm.subject();
            var files = vm.fileIds();

            var isNoMessage = !message && !subject && !files;
            var isMMS = !isNoMessage && (subject || files);
            var isSMS = !isNoMessage && !isMMS && message;

            if (isNoMessage) {
                payload.message = '';
            } else if (isSMS || isMMS) {
                payload.message = message;
                payload.subject = subject;
                payload.files = files;
            } else {
                console.warn(strings.unknownMessageType);
            }

            api.call('messaging.send', payload).
                done(function () {
                    msgme.alert.success('Message has been sent');
                }).
                fail(api.getRequestFailureFn(null, 'messaging.send', payload));
        }
    });

    return {
        widget: $.msgme.msgme_campaign_activity_entry,
        mapping: mapping
    };
});

