define('msgme/views/coupon',[
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/alert',
    'msgme/viewmodel',
    'msgme/viewmodel/coupon',
    'msgme/viewmodel/mapping',
    'msgme/views/View',
    'msgme/util/api',
    'json!widgets/shared-strings.json',
    'msgme/widgets/modal'
], function (splash, path, ko, alert, viewmodel, coupon, mapping, View, api,
    sharedStrings
) {
    var view = new View('#coupon-view');
    var coupons = coupon.coupons;
    var cm = new mapping.CursorModel(waterfall.coupons);

    function populate(couponInstance) {
        coupon.model(couponInstance);
        coupon.model.error(false);

        splash.hide();
        $('#coupon-view').msgme('busy', false);
    }

    function fetchCoupons () {
        return coupons.fetch(coupon.model().id()).
            done(populate).
            fail(view.getRequestFailureFn(
                null, 'coupons.fetch', coupon.model().id()));
    }

    /**
     * Initialize the coupon file upload modal
     */
    $('#coupon-view .msgme-modal.add-coupon').msgme_modal({
        okEnabled: false,
        submit: function () {
            var action = $(this).msgme_modal('option', 'action');
            var input = $('.add-coupon .coupon input[type="file"]');
            var filepath = input.val();
            var fileName = filepath.slice(filepath.lastIndexOf('\\') + 1);
            $('.add-coupon .coupon').ajaxSubmit({
                data: {
                    fileName: fileName
                },
                success: function (file) {
                    var model = coupon.model();

                    // file uploaded successfully, now save the new coupon group
                    if (action === 'add') {
                        model.addCodes(file.id).
                            done(function () {
                                alert.success(
                                    'Coupon file successfully uploaded.');
                                return fetchCoupons();
                            }).
                            fail(function () {
                                path.history.pushState(null, null,
                                    sharedStrings.sitemap.coupons.url +
                                        model.id());
                            }).
                            fail(view.getRequestFailureFn(null, 'coupon.save'));
                    } else if (action === 'remove') {
                        model.removeCodes(file.id).
                            done(function () {
                                alert.success(
                                    'Codes successfully removed.');
                                return fetchCoupons();
                            }).
                            fail(function () {
                                path.history.pushState(null, null,
                                    sharedStrings.sitemap.coupons.url +
                                        model.id());
                            }).
                            fail(view.getRequestFailureFn(null, 'coupon.save'));
                    }
                },
                error: (function () {
                    var failureFn = api.getRequestFailureFn(null, 'file');
                    return function () {
                        failureFn.call(this, arguments);
                        path.history.pushState(null, null,
                            sharedStrings.sitemap.coupons.url);
                    };
                }())
            });
        },
        cancel: function () {
            path.history.pushState(null, null,
                sharedStrings.sitemap.coupons.url +
                    coupon.model().id());
        }
    });

    view.root.on('click', '.btn.upload', function () {
        var model = coupon.model();
        if ($(this).hasClass('add')) {
            $('#coupon-view .msgme-modal.add-coupon').msgme_modal({
                action: 'add'
            }).msgme_modal('open');
            model.uploadType('add');
        } else if ($(this).hasClass('remove')) {
            $('#coupon-view .msgme-modal.add-coupon').msgme_modal({
                action: 'remove'
            }).msgme_modal('open');
            model.uploadType('remove');
        }
    });

    view.root.on('click', '.add-coupon .btn.browse', function () {
        $('.add-coupon .coupon-input').trigger('click');
    });

    view.root.on('change', '.add-coupon .coupon-input', function () {
        var filepath = $('.add-coupon .coupon-input').val();
        $('.add-coupon .filename').val(
            filepath.slice(filepath.lastIndexOf('\\') + 1));
        $('#coupon-view .msgme-modal.add-coupon').
          msgme_modal('option', 'okEnabled', true);
    });

    view.root.on('click', '.btn.save', function () {
        var model = coupon.model();

        if (model.isValid()) {
            view.root.msgme('busy', true);

            // FIXME: need to do this in validation instead
            cm.fetch({ name: model.name() }).
                done(function (result) {
                    if (result.rows().length === 0 ||
                        result.rows()[0].id() === model.id()
                    ) {
                        model.save().
                            done(function () {
                                view.root.msgme('busy', false);
                                alert.success('Coupon group saved.');
                                path.history.pushState(null, null,
                                    sharedStrings.sitemap.coupons.url +
                                        model.id());
                            }).
                            fail(view.getRequestFailureFn(null, 'coupon.save'));
                    } else {
                        view.root.msgme('busy', false);
                    }
                }).
                fail(view.getRequestFailureFn(
                    null, 'coupon.fetch', model.name()));
        } else {
            model.isModified(true);
        }
    });

    path.map(sharedStrings.sitemap.coupons.url + '/:id').to(function () {
        view.show();
        if (this.params.id.toLowerCase() === 'new') {
            populate(coupons.create());
        } else {
            $('#coupon-view').msgme('busy', true);
            $.when(
                coupons.fetch(this.params.id).
                    fail(view.getRequestFailureFn(null,
                        'coupons.fetch',
                        this.params.id))
                ).
                done(populate);
        }
    });

    return view;
});

