define('msgme/util/ko-wrap-observable',[
    'msgme/ko'
], function (
    ko
) {

    function koWrapObservable(maybeObservable) {
        return ko.isObservable(maybeObservable) ?
            maybeObservable :
            ko.observable(maybeObservable);
    }

    return koWrapObservable;
});

