define('msgme/ko/observableDate',[
    'lib/knockout',
    'msgme/util/format',
    'msgme/underscore'
], function (ko, format, _) {
    function observableDate(apidate) {
        apidate = apidate || format.apiDate(new Date());
        var o = {
            value : ko.observable(apidate)
        };

        o.jsdate = ko.computed(function() {
            return new Date(this.value());
        }, o);

        o.date = ko.computed({
            read: function() {
                // compute the date only string from the value observable
                // (indirectly through the jsdate computed observable)
                return this.jsdate().format(('{MM}/{dd}/{yyyy}'));
            },
            write: function(newval) {
                // update the value observable accordingly
                var date = new Date(_.sprintf('%s %s:%s %s',
                    newval, this.hour(), this.minute(), this.period()));
                if (!isNaN(date)) {
                    this.value(format.apiDate(date));
                }
            }
        }, o);

        o.hour = ko.computed({
            read: function() {
                // compute the date only string from the value observable
                // (indirectly through the jsdate computed observable)
                return this.jsdate().format(('{h}'));
            },
            write: function(newval) {
                // update the value observable accordingly
                var date = new Date(_.sprintf('%s %s:%s %s',
                    this.date(), newval, this.minute(), this.period()));
                if (!isNaN(date)) {
                    this.value(format.apiDate(date));
                }
            }
        }, o);

        o.minute = ko.computed({
            read: function() {
                // compute the date only string from the value observable
                // (indirectly through the jsdate computed observable)
                return this.jsdate().format(('{mm}'));
            },
            write: function(newval) {
                // update the value observable accordingly
                var date = new Date(_.sprintf('%s %s:%s %s',
                    this.date(), this.hour(), newval, this.period()));
                if (!isNaN(date)) {
                    this.value(format.apiDate(date));
                }
            }
        }, o);

        o.period = ko.computed({
            read: function() {
                // compute the date only string from the value observable
                // (indirectly through the jsdate computed observable)
                return this.jsdate().format(('{Tt}'));
            },
            write: function(newval) {
                // update the value observable accordingly
                var date = new Date(_.sprintf('%s %s:%s %s',
                    this.date(), this.hour(), this.minute(), newval));
                if (!isNaN(date)) {
                    this.value(format.apiDate(date));
                }
            }
        }, o);

        return o;
    }

    ko.observableDate = observableDate;

    return observableDate;
});

