
define("json!widgets/subscriber-growth-chart/strings.json", function(){ return {
  "listsPlaceholder": "Select lists...",
  "label": {
    "y": "Subscribers"
  },
  "on": "on",
  "timeframe": {
    "label": "Timeframe",
    "placeholder": "Select timeframe...",
    "options": [
      {
        "value": "LAST7",
        "text": "Last 7 days"
      },
      {
        "value": "LAST14",
        "text": "Last 14 days"
      },
      {
        "value": "LAST30",
        "text": "Last 30 days"
      }
    ]
  },
  "popover": {
    "subscribers": "Total subscribers on",
    "broadcastsSent": "broadcasts sent",
    "recipients": "recipients",
    "broadcastTo": "Broadcast to",
    "clicks": "clicks"
  }
};});

