define('widgets/dashboard-tour/index',[
    'msgme/underscore',
    'msgme/path',
    'json!./strings.json',
    'json!./../shared-strings.json',
    'text!./popover.html',
    'text!./restart.html',
    'lib/bootstrap',
    'lib/bootstrap-tour'
], function(_, path, strings, sharedStrings, popoverTemplate, restartTemplate) {
    function paragraphs(lines) {
        return _.map(lines, function (line) {
                return '<p>' + line + '</p>';
            }).
            join('\n');
    }

    // a later version of the bootstrap popover has the 'destroy' method which
    // hides the tip and removes it from the dom. the 'hide' method does the
    // same in our version
    if (!$.fn.popover.Constructor.prototype.destroy) {
        $.fn.popover.Constructor.prototype.destroy =
            $.fn.popover.Constructor.prototype.hide;
    }

    $.widget('msgme.msgme_dashboard_tour', {
        _create: function () {
            this._lastPath = location.pathname;
            this._super.apply(this, arguments);
            this._addRestartButton();
            this._createTour();
            path.on('navigation', _.bind(this._onNavigation, this));
        },

        _addRestartButton: function () {
            this.element.find('.dashboard .restart').prepend(restartTemplate);
            this.element.on('click', '.restart-tour button',
                _.bind(this._onClickRestart, this));
        },

        _createTour: function () {
            var el = this.element;

            // from the plugin docs: "Instance the tour"
            this.tour = new window.Tour({
                template: popoverTemplate,
                backdrop: true
            });

            this.tour.addSteps([
                {
                    element: el.find('.subscriber-growth > h3 .tour-anchor'),
                    content: paragraphs(strings.subscriberGrowth)
                },
                {
                    element: el.
                        find('.subscriber-contact-stat-card h3 .tour-anchor'),
                    content: paragraphs(strings.subscriberStatCard),
                    placement: 'left'
                },
                {
                    element: el.find('.contact-stat-card h3 .tour-anchor'),
                    content: paragraphs(strings.contactsStatCard),
                    placement: 'left'
                },
                {
                    element: el.find('.likes-stat-card h3 .tour-anchor'),
                    content: paragraphs(strings.likesStatCard)
                },
                {
                    element: el.find('.follower-stat-card h3 .tour-anchor'),
                    content: paragraphs(strings.followerStatCard)
                },
                {
                    element: el.find('.engagement-activity h3 .tour-anchor'),
                    content: paragraphs(strings.engagementActivity),
                    placement: 'left'
                }
            ]);

            this.tour.init();
            this.tour.start();
        },

        _onClickRestart: function () {
            this.restart();
        },

        _onNavigation: function () {
            var url = sharedStrings.sitemap.dashboard.url;

            if (location.pathname.indexOf(url) >= 0) {
                if (!this.tour.ended()) {
                    this.tour.start();
                }
            } else {
                // check if we're navigating *away* from the dashboard, and if
                // so, hide all of the tour stuff by pausing it
                if (this._lastPath.indexOf(url) >= 0) {
                    this.pause();
                }
            }

            this._lastPath = location.pathname;
        },

        /**
         * end the tour, but show it again next time it's initialized
         *
         * if the user navigates away, for instance w/ the back button, then we
         * want to hide the tour, if they return we want to show it again. this
         * enables tht 'pause' functionality.
         */
        pause: function () {
            var deleteTourEnd = !this.tour.ended();

            this.tour.end();

            if (deleteTourEnd) {
                delete localStorage.tour_end;
            }

            return this;
        },

        restart: function () {
            this.tour.restart();

            return this;
        }
    });

    return {
        widget: $.fn.msgme_dashboard_tour
    };
});

