define('widgets/line-chart/index',[
    'msgme/underscore',
    'msgme/ko',
    'lib/d3',
    './../three-widget',
    'text!./template.html'
], function (_, ko, d3, ThreeWidget, template) {
    var mapping = {
        defaults: {
            // height and width are the dimensions of the full svg element (the
            // actual chart will be set inside of this w/ margins). width will
            // be inferred from the containing element if null
            width: null,
            height: 400,

            yLabel: '',

            margin: {top: 30, right: 10, bottom: 20, left: 80},

            transitionDuration: 220,

            data: null
        }
    };

    $.widget('msgme.msgme_line_chart', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            var vm;

            ThreeWidget.prototype._create.apply(this, arguments);
            _.bindAll(this, '_createChart');

            this._palette = ['blue', 'green', 'yellow', 'red'];
            this._colors = _.clone(this._palette);
            this._colorMap = {};
            this._chartScales = {};

            vm = this.option('viewmodel');
            vm.data().dataSets.subscribe(this._createChart);
            vm.width(vm.width() || this.element.width());
            this._createChart();
        },

        _createChart: function () {
            var self = this;
            var vm = this.option('viewmodel');
            var lines;
            var removed;

            // 'h' and 'w' are the dimensions of the chart within the full svg.
            // 'margin' is the chart's margin, or the full svg element's
            // padding
            var margin = ko.mapping.toJS(vm.margin);
            var h = vm.height() - margin.top - margin.bottom;
            var w = vm.width() - margin.left - margin.right;

            var dataSets = ko.mapping.toJS(vm.data().dataSets);

            // pick out all of the x and y values
            var dataX = _.chain(dataSets).
                pluck('data').
                flatten(true).
                pluck('0').
                value();
            var dataY = _.chain(dataSets).
                pluck('data').
                flatten(true).
                pluck('1').
                value();

            // x and y are scales, which are functions that convert data points
            // to coordinates on the screen.
            //
            // note that since the default svg coordinate system starts from
            // the top and increasing y values go down, and we like to think in
            // terms of y values that start at the bottom and go up, we need to
            // reverse the range of the y scale.
            var xScale = _.isDate(dataX[0]) ? d3.time.scale : d3.scale.linear;
            var x = xScale().
                    domain([d3.min(dataX), d3.max(dataX)]).
                    range([0, w]);
            var y = d3.scale.linear().
                domain([d3.min(dataY), d3.max(dataY)]).
                range([h, 0]);

            // in order to draw a line in svg, we create a 'path' element whose
            // 'd' attribute contains the points' locations. line is a function
            // that translates screen coordinates (the stuff returned by x and
            // y) into the 'd' attribute needed to specify the line
            var line = d3.svg.line().
                x(function (d) { return x(d[0]); }).
                y(function (d) { return y(d[1]); });

            if (!this.chart) {
                // size the svg element and create a 'g' (group) that
                // represents the chart and is inset by the margins
                this.chart = d3.select(this.element.find('svg')[0]).
                    attr('width', w + margin.left + margin.right).
                    attr('height', h + margin.top + margin.bottom).
                    append('g').
                        attr('class', 'chart').
                        attr('transform',
                            'translate(' + margin.left + ', ' +
                                margin.top + ')');
            }

            // `lines` are the actual svg elements corresponding to the lines.
            // d3 binds pieces of data to html or svg elements, so in this case
            // we're binding arrays of (x, y) values to svg <path> elements, so
            // when we call `line.enter()`, the piece of data that has been
            // added is an array of (x, y) values, not the individual (x, y)
            // values.
            lines = this.chart.selectAll('path.line').
                data(dataSets, function (dataSet) {
                    return dataSet.id;
                });

            function dataLine(dataSet) {
                return line(dataSet.data);
            }

            lines.transition().
                duration(vm.transitionDuration()).
                attr('d', dataLine);

            removed = lines.exit().
                each(function () {
                    var color = this.getAttribute('data-stroke-color');

                    self._colors.push(color);
                    delete self._colorMap[this.__data__.id];
                }).
                remove();

            lines.enter().
                append('path').
                attr('class', 'line').
                attr('data-stroke-color', _.bind(function (d) {
                    var color = this._colors.pop();

                    this._colorMap[d.id] = color;

                    if (!this._colors.length) {
                        this._colors = _.clone(this._palette);
                    }

                    return color;
                }, this)).
                transition().
                    delay(vm.transitionDuration()).
                    attr('class', 'line').
                    attr('d', dataLine);

            if (!this.xAxis) {
                // axes are functions that, given an svg element, render into
                // the given element the <text> and <path> elements necessary
                // to show labels and tick marks on an axis.
                this.xAxis = d3.svg.axis().
                    scale(x).
                    ticks(7).
                    orient('bottom');
                this.yAxis = d3.svg.axis().
                    scale(y).
                    ticks(5).
                    tickSize(w).
                    orient('left');

                this.gx = this.chart.append('g').
                    attr('class', 'x axis').
                    attr('transform', 'translate(0, ' + h + ')').
                    call(this.xAxis);

                this.gy = this.chart.append('g').
                    attr('class', 'y axis').
                    attr('transform', 'translate(' + w + ', 0)').
                    call(this.yAxis);

                this.gy.append('text').
                        attr('dy', '-1em').
                        attr('dx', -w).
                        attr('text-anchor', 'end').
                        text(vm.yLabel());

            } else {
                this.xAxis.scale(x);
                this.gx.transition().
                    duration(vm.transitionDuration()).
                    call(this.xAxis);

                this.yAxis.scale(y);
                this.gy.transition().
                    duration(vm.transitionDuration()).
                    call(this.yAxis);
            }

            this.chart.selectAll('.overlay').remove();

            setTimeout(_.bind(this._createTooltipOverlay, this, dataSets, w, h,
                        x, y), vm.transitionDuration());
            this._chartScales = { x: x, y: y };
        },

        _createTooltipOverlay: function (dataSets, w, h, x, y) {
            var overlay;
            var data = _.chain(dataSets)
                .map(function (dataSet) {
                    return dataSet.data.map(function (point, idx) {
                        var ret = point.slice();

                        ret.id = dataSet.id;
                        ret.idx = idx;

                        return ret;
                    });
                }).
                flatten(true).
                uniq(false, function (data) {
                    return data[0].toString() + data[1].toString();
                }).
                value();

            function polygon(d) {
                return 'M' + d.join('L') + 'Z';
            }

            function point(d) {
                return [x(d[0]), y(d[1])];
            }

            function circleSelector(d) {
                return '[data-point="' + polygon(point(d.point)) + '"]';
            }

            function eventData(d, el) {
                return {
                    id: d.point.id,
                    data: d.point.slice(),
                    idx: d.point.idx,
                    el: el
                };
            }

            this.chart.selectAll('.overlay').remove();

            if (!data.length) {
                return;
            }

            overlay = this.chart.append('g').
                attr('class', 'overlay');

            overlay.selectAll('circle').
                    data(data).
                    enter().
                        append('circle').
                        attr('cx', function (d) {
                            return x(d[0]);
                        }).
                        attr('cy', function (d) {
                            return y(d[1]);
                        }).
                        attr('data-fill-color', _.bind(function (d) {
                            return this._colorMap[d.id];
                        }, this)).
                        attr('data-point', function (d) {
                            return polygon(point(d));
                        }).
                        attr('r', 5);

            var voronoi = d3.geom.voronoi().
                x(function (d) { return x(d[0]); }).
                y(function (d) { return y(d[1]); }).
                clipExtent([[0, 0], [w, h]]);

            overlay.selectAll('path').
                data(voronoi(data), polygon).
                enter().
                    append('path').
                    attr('d', polygon).
                    attr('class', 'region').
                    on('mouseenter', function (d) {
                        overlay.select(circleSelector(d)).each(function () {
                            $(this).
                                trigger('point-mouseenter', eventData(d, this));
                        });
                    }).
                    on('mouseleave', function (d) {
                        overlay.select(circleSelector(d)).each(function () {
                            $(this).
                                trigger('point-mouseleave', eventData(d, this));
                        });
                    });
        }
    });

    return {
        widget: $.msgme.msgme_line_chart,
        mapping: mapping
    };
});

