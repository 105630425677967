define('msgme/widgets/flowmodule-posttourl',[
    'jquery',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-posttourl'
],
function ($, ko, flowmodule, postToURL) {
    $.widget('msgme.msgme_flowmodule_posttourl', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-posttourl-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
            this.options.data().params.url.extend({ required: true });
        }
    });

    $.msgme.msgme_flowmodule_posttourl.Model = flowmodule.modelFactory(
        {}, postToURL.config
    );

    return $.msgme.msgme_flowmodule_posttourl;
});


