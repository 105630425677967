define('msgme/util/promise',[
    'msgme/underscore'
],
function (
    _
) {
    var exports = {
        /**
         * Promise to run a synchronous function on a later event loop turn
         *
         * @param Function fn - the function to execute, defaults to _.identity
         *
         * @return Promise the function's return value or any thrown error
         */
        defer: function (fn) {
            var d = $.Deferred();

            fn = fn || _.identity;

            _.defer(function () {
                try {
                    d.resolve(fn());
                } catch (e) {
                    d.reject(e);
                }
            });

            return d.promise();
        },

        /**
         * Promise to run a synchronous function after a specified timeout
         *
         * This is intended to mimic `window.setTimeout` except by returning a
         * promise.t
         *
         * @param Number ms - delay in milliseconds
         *
         * @return Promise containing extra arguments passed in
         */
        timeout: function (ms) {
            var d = $.Deferred();
            var args = [].slice.call(arguments, 1);

            setTimeout(d.resolve.bind(d, args), ms);

            return d.promise();
        },

        /**
         * Promise a value on a later event loop turn
         *
         * @param {*} value - the promised value
         *
         * @return Promise a promise that will resolve to `value` on a later
         *                 turn of the event loop
         */
        deferValue: function (value) {
            return exports.defer(function () {
                return value;
            });
        },

        /**
         * Serialize execution of one or more promise-producing functions
         *
         * The first function will be executed immediately. When its promise
         * resolves, the next function will be executed and so forth. If any
         * function rejects, the series stops and the returned promise is
         * rejected.
         *
         * @param {...Function} fns - the functions to execute
         *
         * @return Promise a promise of the last function's result
         */
        series: function (/* fns... */) {
            var fns = _.toArray(arguments);
            var first = fns[0];
            var rest = fns.slice(1);

            var result = _.reduce(rest, function (prev, fn) {
                return prev.then(fn);
            }, first());

            return result;
        },

        /**
         * Run a series of synchronous functions, one per event loop turn
         *
         * @param {...Function} fns - the functions to execute
         *
         * @return Promise the result of the series
         */
        deferSeries: function deferSeries(/* fns... */) {
            var fns = _.toArray(arguments);

            fns = _.map(fns, function (fn) {
                return function (result) {
                        return exports.defer(_.bind(fn, null, result));
                    };
            });

            return exports.series.apply(exports, fns);
        }
    };

    return exports;
});

