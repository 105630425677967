define('msgme/modelcollection',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel/mapping'
], function (_, ko, viewmodelMapping) {

    function ModelCollection(collection, mapping) {
        this._cm = new viewmodelMapping.CursorModel(collection, {
            record: {mapping: mapping || {}}
        });
        this._items = ko.observableArray();

        if (mapping && mapping.defaults &&
                mapping.defaults.hasOwnProperty('id')) {
            this.indexOneBy('id');
        }
    }

    /**
     * update existing record model with new values
     *
     * this is where we implement SPOT
     *
     * `recordModel` - the RecordModel instance that will be used to update the
     * existing model (if it exists)
     *
     * returns the existing record model, if it exists, otherwise undefined
     */
    ModelCollection.prototype._updateExisting = function (recordModel) {
        var existing = this.oneById()[recordModel.id()];

        if (existing) {
            ko.mapping.fromJS(ko.mapping.toJS(recordModel), existing);
            return existing;
        }
    };

    /**
     * fetch a set of record models
     *
     * any fetched models that are already tracked in `this._items` will just
     * be used to update the existing instances. any models that aren't in
     * `this._items` will be added, so `this._items` will only notify its
     * subscribers if the fetch call returned new models.
     *
     * note: fetch will never remove items from `this._items` because there's
     * no good way of determining if an existing model that's not in the
     * server's response was deleted, or just not a member of the queried
     * results.
     *
     * `config` - the config that is passed through to the CursorModel's fetch
     *
     * returns a promise of a list of record models
     */
    ModelCollection.prototype.fetch = function (config) {
        return this._cm.fetch(config).then(_.bind(function (result) {
            var newItems = [];

            _.each(result.rows(), _.bind(function (recordModel) {
                var existing = this._updateExisting(recordModel);
                if (!existing) {
                    newItems.push(recordModel);
                }
            }, this));
            if (newItems.length) {
                this._items.push.apply(this._items, newItems);
            }

            return this._items();
        }, this));
    };

    /**
     * fetch the record model of the given id
     *
     * if we've already got a copy of that record model, then update it with
     * the results of the fetch and resolve the returned promise w/ the
     * existing record model. otherwise add it to `this._items` and return the
     * new instance.
     *
     * if it's an existing model, then it will trigger changes for any
     * properties that change after the fetch. if it's not new, then it will
     * trigger a change on `this._items`
     *
     * returns a promise of the record model
     */
    ModelCollection.prototype.fetchOne = function (id) {
        return this._cm.fetchOne(id).then(_.bind(function (recordModel) {
            var existing = this._updateExisting(recordModel);

            if (!existing) {
                this._items.push(recordModel);
            }

            return existing ? existing : recordModel;
        }, this));
    };

    /**
     * provide a singular index by a given property
     *
     * adds a `oneBy{PropName}` computed to this instance which returns an
     * object whose keys are the value of the item's `prop` property.
     *
     * for example:
     *
     *     this.indexOneBy('id');
     *     this.oneById             // -> ko.computed
     *     this.oneById()           // -> {123: RecordModel({id: 123}), ...}
     *
     * if more than one object share the same value for the given property, one
     * will be arbitrarily chosen.
     *
     * if an object does not have an observable for the given property, an
     * exception will be thrown.
     *
     * `prop` - the property to be indexed
     *
     * returns this
     */
    ModelCollection.prototype.indexOneBy = function (prop) {
        this['oneBy' + _.capitalize(prop)] = ko.computed(_.bind(function () {
            return _.reduce(this._items(), function (index, item) {
                index[item[prop]()] = item;
                return index;
            }, {});
        }, this));

        return this;
    };

    /**
     * provide a plural index by a given property
     *
     * adds an `allBy{PropName}` computed to this instance which groups all of
     * the items in this._items into bins based on values of `prop`.
     *
     * for example:
     *
     *     this.indexAllBy('kind');
     *     this.allByKind           // -> ko.computed
     *     this.allByKind()         // -> {
     *                              //      foo: [
     *                              //        RecordModel({kind: 'foo'}),
     *                              //        ...
     *                              //      ],
     *                              //      ...
     *                              //    }
     *
     * if an object does not have an observable for the given property, an
     * exception will be thrown.
     *
     * `prop` - the property to be indexed
     *
     * returns this
     */
    ModelCollection.prototype.indexAllBy = function (prop) {
        this['allBy' + _.capitalize(prop)] = ko.computed(_.bind(function () {
            return _.reduce(this._items(), function (index, item) {
                var value = item[prop]();
                index[value] = index[value] || [];
                index[value].push(item);
                return index;
            }, {});
        }, this));

        return this;
    };

    /**
     * create a record model
     *
     * the record model will be automatically added to the model collection when
     * saved.
     *
     * `CursorModel.prototype.create` is used under the hood, so the new record
     * model has a monkey-patched `save` method that makes a POST request and
     * on success removes itself so that `RecordModel.prototype.save` is then
     * used instead (which makes PUT requests).
     *
     * example:
     *
     *     // where 'metadata()' is a ModelCollection for the metadata resource
     *     var rm = metadata().create({data: {name: 'foo', value: 123}})
     *
     *     // this makes the initial POST request
     *     rm.save().then(function () {
     *         rm.value(456);
     *
     *         // this makes a PUT request to just update the existing resource
     *         rm.save();
     *     });
     *
     * `config` - the config object that's passed into
     *   `CursorModel.prototype.create`
     *
     * returns a new record model with a monkey patched `save` method
     */
    ModelCollection.prototype.create = function (config) {
        var recordModel = this._cm.create(config);
        var save = recordModel.save;
        var modelCollection = this;

        recordModel.save = function () {
            return save.apply(this, arguments).
                then(function (recordModel) {
                    modelCollection._items.push(recordModel);
                    return recordModel;
                });
        };

        return recordModel;
    };

    return ModelCollection;
});

