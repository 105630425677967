define('msgme/viewmodel/mapping',[
    'msgme/underscore',
    'msgme/ko',
    'lib/knockout.mapping'
],
function (_, ko, komapping) {
    var emptyCursor = {
        rows: [],
        pageIndex: -1,
        pageCount: -1,
        pageSize: -1
    };
    var defaultRecordConfig = {
        mapping: {
            ignore: ['_record', '_config']
        },
        defaultData: {}
    };
    var defaultCursorConfig = {
        mapping: {},
        record: defaultRecordConfig
    };

    function deepClone(o) {
        return $.extend(true, {}, o);
    }

    function CursorModel(source, config) {
        this._config = config =
            deepClone(_.defaults(config || {}, defaultCursorConfig));

        komapping.fromJS(emptyCursor, config.mapping, this);
        this.rows = ko.observableArray();

        this._source = source;
    }

    // TODO: other cursor methods
    CursorModel.prototype.fetch = function (query, config) {
        config = config ? config : {cache: false};
        var d = new $.Deferred();

        var promise = this._source.fetch(query, config).
            done(_.bind(function (result) {
                if (_.isString(query)) {
                    d.resolve(new RecordModel(result, this._config.record));
                } else {
                    komapping.fromJS({
                        pageIndex: result.pageIndex,
                        pageCount: result.pageCount,
                        pageSize: result.pageSize
                    }, this);

                    this.rows(
                        _.map(result.slice(), function (record) {
                            return new RecordModel(record, this._config.record);
                        }, this));

                    this._cursor = result;
                    d.resolve(this);
                }
            }, this)).
            fail(function () {
                d.reject.apply(d, _.toArray(arguments));
            });

        var p = d.promise();
        p.abort = _.bind(promise.abort, promise);
        return p;
    };

    CursorModel.prototype.fetchOne = function (id, config) {
        config = config ? config : {cache: false};
        var d = new $.Deferred();

        var promise = this._source.fetch(id, config).
            done(_.bind(function (record) {
                d.resolve(new RecordModel(record, this._config.record));
            }, this)).
            fail(function () {
                d.reject.apply(d, _.toArray(arguments));
            });

        var p = d.promise();
        p.abort = _.bind(promise.abort, promise);
        return p;
    };

    function mockDataFn() {
        var result = deepClone(this);
        delete result.data;
        return result;
    }

    function saveNewRecord() {
        var d = new $.Deferred();
        var temp;

        _.extend(this._record, komapping.toJS(this));

        if (this._source && this._source.url === '/api/v1/mobileflow') {
            temp = JSON.stringify(this._record.modules).
                replace(/:"",/g, ':null,').replace(/:""}/g, ':null}');
            this._record.modules = JSON.parse(temp);
        }

        this._source.create(this._record.data(), this._config).
            done(_.bind(function (record) {
                komapping.fromJS(record.data(), this._config.mapping, this);
                this._record = record;

                delete this._source;
                delete this.save;

                d.resolve(this);
            }, this)).
            fail(function () {
                d.reject.apply(d, _.toArray(arguments));
            });

        return d.promise();
    }

    CursorModel.prototype.create = function (config) {
        config = _.defaults(config || {}, this._config.record);
        var mockRecord =
            deepClone(_.defaults(config.data || {}, config.defaultData));
        var result;

        mockRecord.data = mockDataFn;

        result = new RecordModel(mockRecord, this._config.record);
        result._source = this._source;
        result.save = saveNewRecord;

        return result;
    };

    function RecordModel(record, config) {
        this._config = config =
            deepClone(_.defaults(config || {}, defaultRecordConfig));

        komapping.fromJS(record.data(), config.mapping, this);

        this._record = record;
    }

    RecordModel.prototype.save = function () {
        var data = komapping.toJS(this);

        _.extend(this._record, data);
        return this._record.save();
    };

    RecordModel.prototype.del = function () {
        return this._record.del();
    };

    // TODO: other Record methods; is there a way to automagically handle them?

    RecordModel.prototype.reset = function () {
        komapping.fromJS(this._record.data(), this);
        return this;
    };

    return {
        CursorModel: CursorModel,
        RecordModel: RecordModel
    };
});

