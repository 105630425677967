define('msgme/views/login',[
    'msgme/views/View'
],
function (View) {
    var loginView = new View('#login-view');
    var root = loginView.root;
    var usernameEl = root.find('.username');
    var passwordEl = root.find('.password');
    var submitBtn = root.find('.sign-in');
    var errorAlert = root.find('.error-message');
    var errorAlertText = errorAlert.find('.text');

    var LOGIN_ERROR_401 = 'Wrong username and/or password. Try again.';
    var LOGIN_ERROR_OTHER =
        'Unable to log in (%s). Please try again in a few moments.';

    $('form').delegate('input', 'keyup', function () {
        submitBtn.attr('disabled',
            usernameEl.val() === '' || passwordEl.val() === '');
    });

    submitBtn.click(function (e) {
        errorAlert.hide();
        e.preventDefault();

        waterfall.authenticate.login(usernameEl.val(), passwordEl.val()).
            done(function () {
                location.reload();
            }).
            fail(function (e) {
                var message = e.status === 401 ?
                    LOGIN_ERROR_401 :
                    LOGIN_ERROR_OTHER.replace('%s',
                        e.status + ': ' + e.statusText);
                loginView.alert(message);
            });
    });

    loginView.show = function (err) {
        if (err) {
            this.alert(err);
        }

        var p = View.prototype.show.call(loginView);
        usernameEl.focus();
        return p;
    };

    loginView.alert = function (text) {
        errorAlertText.text(text);
        errorAlert.show();
    };

    return loginView;
});

