define('msgme/ko/extenders/index-by',[
    'msgme/underscore',
    'lib/knockout'
],
function (_, ko) {

    function getIndexer(indexFn, target, key, unique) {
        return ko.computed(_.bind(indexFn, target, key, unique));
    }

    function indexBy(key, unique) {
        var target = this();
        var result = {};
        var el, value;

        for (var i = 0; i < target.length; i++) {
            el = target[i];

            if (el && el[key] != null) {
                value = '' + ko.utils.unwrapObservable(el[key]);

                if (unique) {
                    result[value] = result[value] || el;
                } else {
                    result[value] = result[value] || [];
                    result[value].push(el);
                }
            }
        }

        return result;
    }

    /**
     * Index an observed array by one or more keys
     *
     * For each key, `indexBy` attaches a computed observable as a property of
     * the extended observable. The name of the computed observable is the
     * string 'by' prepended to the capitalized key. The observable for a key
     * 'foo', for example, will be named 'byFoo'. The value of the computed is
     * an object with keys for each unique value of the key in the observed
     * array.
     *
     * By default, the value of each key in the object the computed returns is
     * an array of elements in the observed array where the indexed key had the
     * value in question.
     *
     * Its configuration object has a mandatory `keys` property, which must
     * be an array of string keys. It also has an optional `unique` property,
     * which defaults to `false`.
     *
     * If `unique` is `true`, the value of each key in the object the computed
     * returns is the first element in the observed array where the indexed key
     * had the value in question.
     *
     * A single key string is a shortcut for this config object:
     *
     * `{ keys: [<key string>], unique: false }`
     *
     * An array of key strings is a shortcut for this config object: 
     *
     * `{ keys: <array of key strings>, unique: false }`
     *
     * Example:
     * ```javascript
     * var oa = ko.observableArray([{
     *   foo: 'bar',
     *   fiz: 'biz',
     *   frob: 'nard'
     * }, {
     *   foo: 'baz',
     *   fiz: 'biz'
     * }]);
     *
     * oa.extend({ indexBy: ['foo', 'fiz'] });
     *
     * oa.byFoo() // { bar: [<first element>], baz: [<second element>] }
     * oa.byFiz() // { biz: [<first element>, <second element>] }
     * ```
     */
    return function indexByExtender(target, config) {
        var value = target();

        if (!_.isArray(value)) {
            throw new Error(
                'Cannot index an observable that does not contain an array');
        }

        if (_.isString(config)) {
            config = {
                keys: [config]
            };
        } else if (_.isArray(config)) {
            config = {
                keys: config.slice()
            };
        } else if (_.isObject(config)) {
            config.unique = config.unique === true;
        } else {
            throw new Error(
                'config must be a string, array of strings, or object');
        }

        if (!_.isArray(config.keys) || config.keys.length < 1) {
            throw new Error(
                'config.keys must be a non-empty array');
        }

        var key, observableName;
        for (var i = 0; i < config.keys.length; i++) {
            key = config.keys[i];
            observableName = 'by' + _.capitalize(key);
            target[observableName] =
                getIndexer(indexBy, target, key, config.unique);
        }

        return target;
    };
});

