define('msgme/ko/mapping',[
    'jquery',
    'msgme/underscore',
    'lib/knockout',
    'lib/knockout.mapping',
    'msgme/util/deep'
],
function ($, _, ko, komapping, deep) {
    var fromJS = komapping.fromJS;

    komapping.defaultOptions().ignore.push('_mapping');

    function setModified(value, modified) {
        var unwrapped = ko.utils.unwrapObservable(value);

        if (value && value.isModified) {
            value.isModified(modified);
        } else if (unwrapped && unwrapped.isModified) {
            unwrapped.isModified(modified);
        }
    }

    function isModified(modified) {
        var keys = _.keys(this);

        if (modified !== void undefined) {
            _.each(keys, function isChildModified(key) {
                var value = this[key];

                setModified(value, modified);

                // the default isModified implementation does not recurse into
                // observable arrays, so we need to do so ourselves
                if (ko.isObservableArray(value)) {
                    _.each(value(), function (el) {
                        setModified(el, modified);
                    });
                }
            }, this);

            return modified;
        } else {
            return _.some(keys, function isChildModified(key) {
                return this[key] && this[key].isModified ?
                    this[key].isModified() : false;
            }, this);
        }
    }

    function getCreateHandler(val) {
        var createFn = val.create;

        return function createHandler(root, options) {
            var result = {};
            options.data = _.clone(options.data);

            if (_.isFunction(createFn)) {
                result = createFn.call(this, options);
            }

            komapping.fromJS(options.data, this.mapping, result, root);

            result.isModified = isModified;

            return result;
        };
    }

    function fixupNestedMappings(source, mapping, root) {
        var plugins = komapping.fromJS.plugins;
        var val, k;

        if (source.constructor === Object) {
            // only apply default mappings to child objects
            for (k in source) {
                if (_.isArray(source[k])) {
                    // set a default validation mapping for arrays
                    mapping.validation = mapping.validation || {};
                    mapping.validation[k] = mapping.validation[k] || {
                        allAreValid: true
                    };
                } else if (source[k] && source[k].constructor === Object) {
                    // nested objects must have a mapping, or ko will apply the
                    // parent mapping, which is not what we want
                    mapping[k] = mapping[k] || {};
                    mapping[k].mapping = _.isUndefined(mapping[k].mapping) ?
                        {} : mapping[k].mapping;
                }
            }
        }

        // support nested mapping
        for (k in mapping) {
            if (mapping.hasOwnProperty(k)) {
                val = mapping[k];

                if (val && val.mapping &&
                    !(k in plugins.before || k in plugins.after)
                ) {
                    if (!val.create || !val.create.__is_create_handler) {
                        // this hasn't been wrapped in a create handler yet
                        val.create = getCreateHandler(val).bind(val, root);
                        val.create.__is_create_handler = true;
                    }
                    fixupNestedMappings(source[k] || {}, val.mapping, root);
                }
            }
        }
    }

    // this tests if oa is either an observableArray or ko.observable([]) so it
    // is not an appropriate generic test for observable arrays
    ko.isObservableArray = ko.isObservableArray || function (oa) {
        return ko.isObservable(oa) &&
            !ko.isComputed(oa) &&
            _.isArray(ko.utils.unwrapObservable(oa));
    };

    // returns an array of errors from any direct children that are invalid
    function targetErrors() {
        return _.chain(this).
            filter(function (value) {
                return value && value.isValid && !value.isValid();
            }).
            map(function (observable) {
                // observables have an error property, while models have an
                // errors method (this method, in fact...)
                return observable.error || observable.errors();
            }).
            flatten().
            value();
    }

    // returns true if no children have errors and false otherwise
    function targetIsValid() {
        return this.errors().length === 0;
    }

    komapping.fromJS =
        function msgme_ko_fromJS (source, mapping, target, rootModel) {
            mapping = deep.clone(mapping || {});
            var plugins = komapping.fromJS.plugins;
            var args = _.toArray(arguments).slice(0, 3);
            var name;
            var restoreArrays;

            // set defaults for any undefined args
            if (_.isUndefined(mapping)) {
                mapping = {};
            } else if (_.isUndefined(target) && !!komapping.isMapped(mapping)) {
                // mapping is a target
                target = mapping;
                mapping = {};
            }

            // ensure we have a target so we can use it as the root if necessary
            target = target || {};

            // rootModel is target if not set
            rootModel = rootModel || target;

            // we will always pass a mapping and a target to komapping.fromJS
            args[1] = mapping;
            args[2] = target;

            for (name in plugins.before) {
                if (mapping[name]) {
                    plugins.before[name](source, mapping, target, rootModel);
                }
            }

            // if the data has an observableArray instance, the ko.mapping
            // plugin will create a new observableArray on the viewmodel. we
            // want it to preserve the original reference, so we track that
            // here.
            //
            // https://github.com/SteveSanderson/knockout.mapping/pull/177
            if (source.constructor === Object) {
                restoreArrays = _.reduce(source,
                    function (restoreArrays, val, prop) {
                        if (ko.isObservableArray(val) &&
                                !target.hasOwnProperty(prop)) {
                            restoreArrays.push(prop);
                        }
                        return restoreArrays;
                    }, []);
            }

            if (!target._mapping) {
                fixupNestedMappings(source, mapping, rootModel || target);
                fromJS.apply(komapping, args);
                target._mapping = mapping;
            } else {
                args[1] = mapping = target._mapping;
                fromJS.apply(komapping, args);
            }

            _.each(restoreArrays, function (prop) {
                target[prop] = source[prop];
            });

            target.isModified = isModified;

            for (name in plugins.after) {
                if (mapping[name]) {
                    plugins.after[name](source, mapping, target, rootModel);
                }
            }

            target.errors = target.errors || ko.computed({
                    read: targetErrors,
                    owner: target,
                    deferEvaluation: true
                });

            target.isValid = target.isValid || ko.computed({
                    read: targetIsValid,
                    owner: target,
                    deferEvaluation: true
                });

            return target;
        };

    function bindApply(fn, context, args) {
        return _.bind.apply(_, [fn, context].concat(args));
    }

    function getComputedObservable(computable, target, root) {
        var args = [root].concat(_.toArray(arguments).slice(3));

        if (_.isFunction(computable)) {
            return ko.computed(bindApply(computable, target, args));
        } else {
            computable = _.clone(computable);
            computable.owner = computable.owner || target;

            if (computable.read) {
                computable.read =
                    bindApply(computable.read, computable.owner, args);
            }

            if (computable.write) {
                computable.write =
                    bindApply(computable.write, computable.owner, args);
            }

            return ko.computed(computable);
        }
    }

    komapping.fromJS.plugins = {
        before: {
            defaults: function (source, mapping) {
                deep.defaults(source, mapping.defaults);
            },

            local: function (source, mapping, target, root) {
                var local = _.clone(mapping.local || {});
                var localMapping = local.mapping || {};
                delete local.mapping;
                root = root || target;

                var result = komapping.fromJS(local, localMapping, null, root);

                // don't keep the validation computeds attached by fromJS, as
                // they have the wrong context and will prevent attaching the
                // real computeds later
                result.errors = null;
                result.isValid = null;

                for (var k in result) {
                    if (result.hasOwnProperty(k) && k[0] !== '_') {
                        target[k] = result[k];
                    }
                }
                local.mapping = localMapping;
            }
        },

        after: {
            computed: function (source, mapping, target, root) {
                var computed = mapping.computed || {};

                for (var k in computed) {
                    if (computed.hasOwnProperty(k)) {
                        target[k] =
                            getComputedObservable(computed[k], target, root);
                    }
                }
            },

            /**
             * Replaces the named mapped observable with the result of calling
             * extend on it, passing the mapped value.
             */
            extend: function (source, mapping, target) {
                var extend = mapping.extend || {};

                for (var k in extend) {
                    if (extend.hasOwnProperty(k)) {
                        for (var extender in extend[k]) {
                            if (!ko.extenders[extender]) {
                                throw new Error(
                                        'No such extender: ' + extender);
                            }
                        }
                        target[k] = target[k].extend(extend[k]);
                    }
                }
            },

            subcomputed: function (source, mapping, target, root) {
                var subcomputed = mapping.subcomputed || {};

                for (var k in subcomputed) {
                    if (subcomputed.hasOwnProperty(k)) {
                        for (var j in subcomputed[k]) {
                            if (subcomputed[k].hasOwnProperty(j)) {
                                target[k][j] = getComputedObservable(
                                    subcomputed[k][j],
                                    target,
                                    root,
                                    target[k]);
                            }
                        }
                    }
                }
            }
        }
    };

    return komapping;
});

