define('msgme/modal',[
    'jquery',
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel'
],
function ($, _, ko, viewmodel) {
    var delegateInstalled = false;
    viewmodel.modal = {
        title: ko.observable(),
        body: ko.observable(),
        buttons: ko.observableArray()
    };

    /*
     * Displays a modal dialog to the user
     *
     * The method takes an object with three required fields:
     * title: A string of text to display in the modal title
     * body: A string of text to display in the modal body
     * buttons: An array of either strings or objects for the
     * modal buttons. The object takes two fields:
     * text: The string to be displayed on the button
     * classNames: An array of CSS class names
     *
     * When called, the modal is rendered and a promise is returned.
     * When a button is clicked, the promise is resolved with the value
     * of the text field of the button.
     */

    function modal (config) {
        var deferred = new $.Deferred();
        var modalEl = $('#msgme-modal');
        var button;
        var hasButton;

        viewmodel.modal.title(config.title);
        viewmodel.modal.body(config.body);

        _.each(config.buttons, function (b, i) {
            button = _.clone(config.buttons[i]);
            if (_.isString(button)) {
                button = { text: button };
            }

            button.classNames = _.reduce(
                button.classNames, classnameReducer, {});

            if (viewmodel.modal.buttons().length) {
                hasButton = _.find(viewmodel.modal.buttons(), function (b) {
                    return b.text === button.text;
                });
            }

            if (!hasButton) {
                viewmodel.modal.buttons.push(button);
            }
        });

        modalEl.data('deferred', deferred);

        if (!delegateInstalled) {
            modalEl.on('click', '.btn', function () {
                modalEl.data('deferred').resolve($(this).text());
                modalEl.modal('hide');
            });

            delegateInstalled = true;
        }

        // Clean on hide
        modalEl.on('hidden', function () {
            viewmodel.modal.title(null);
            viewmodel.modal.body(null);
            viewmodel.modal.buttons([]);
        });

        modalEl.modal();
        return deferred.promise();
    }

    function classnameReducer (classes, className) {
        classes[className] = true;
        return classes;
    }

    /*
     * Convenience method with a single 'Ok' button
     *
     * This method displays a modal dialog with a single button: 'Ok'
     * It takes two fields: a required 'body' string and an optional
     * 'config' object
     *
     * body takes a string which is displayed in the body of the modal
     * By default, the title is 'Waterfall'.
     * The config field takes in and object with the field 'title'.
     * The body and button fields are ignored
     */

    modal.notice = function (body, config) {
        var notice = {};
        config = config || {};

        notice.buttons = [{
            text: config.button ? config.button : 'Ok',
            classNames: ['btn-primary']
        }];

        notice.body = body;
        notice.title = config.title || 'Waterfall';

        return modal(notice);
    };

    /*
     * Convenience method with two buttons.
     *
     * Displays a modal with only two buttons and returns either true
     * or false. By default, the buttons are labeled 'Ok' and 'Cancel'.
     *
     * The method takes two fields: a required 'body' string
     * and optional 'config' object.
     * The config field accepts the title and buttons fields.
     * The body field is ignored.
     *
     * When called, a promise is returned. When a button is clicked,
     * the promise will be resolved with the value of true or false.
     */

    modal.confirm = function (body, config) {
        var deferred = new $.Deferred();
        var confirm = {};

        confirm.body = body;
        config = config || {};

        if (config.buttons && config.buttons.length !== 2) {
            throw _.sprintf('Must have only two buttons');
        }

        confirm.title = config.title || 'Waterfall';
        confirm.buttons = config.buttons ||
            [{ text: 'Cancel', classNames: null },
            { text: 'Ok', classNames: ['btn-primary'] }];


        modal(confirm).done(function (value) {
            deferred.resolve (value !== confirm.buttons[0].text);
        });

        return deferred.promise();
    };

    return modal;
});

