define('msgme/util/errors',[
    'msgme/underscore'
],
function (_) {
    var errors = {};
    errors.stackTrace = function stackTrace() {
        /*jslint noarg: false*/
        var fn = arguments.callee;
        var seen = [];
        var stack;

        // return the native stacktrace if the browser provides one
        var e = new Error('x');
        if (e.stack) {
            stack = e.stack.split('\n').map(function (line) {
                return _.trim(line);
            });

            // remove error and stackTrace call
            stack = stack.slice(2);
        }

        if (!stack) {
            stack = [];
            do {
                for (var i = 0; i < seen.length; i++) {
                    if (seen[i] === fn.caller) {
                        stack.push('Cycle at ' + fn.caller.name);
                        stack = stack.slice(1);

                        return stack;
                    }
                }

                stack.push(fn.name || 'anonymous');
                seen.push(fn);
                fn = fn.caller;
            } while (fn);

            // remove stackTrace call
            stack = stack.slice(1);
        }

        return stack;
    };

    return errors;
});

