define('msgme/ko/validation/all-are-valid',[
    'msgme/underscore',
    'msgme/ko',
    'json!widgets/shared-strings.json'
],
function (_, ko, strings) {
    /**
     * Validate all members of an array
     *
     * This rule calls `isValid` on each member of the validated array.
     * This rule validates asynchronously, even if all members of the array
     * validate synchronously.
     *
     * Validation of elements works as follows:
     * 1) if an element defines `isValid`, then validate using that
     * 2) if not, unwrap the element and try to validate
     * 3) unvalidated elements are valid
     *
     * Note that this rule can be applied to both observable arrays and
     * observables containing arrays.
     */
    ko.validation.rules.allAreValid = {
        async: true,
        validator: function (val, params, callback) {
            var promises = _.map(val, function (el) {
                // make sure it's an object
                if (!_.isObject(el)) { return $.Deferred().resolve(true); }

                // if not validatable, try unwrapping it
                var elUnwrapped = !_.has(el, 'isValid') ?
                    ko.utils.unwrapObservable(el) : el;

                // no validation
                if (!_.has(elUnwrapped, 'isValid')) {
                    return $.Deferred().resolve(true);
                }

                // sync validation
                var isValidating =
                    _.has(elUnwrapped, 'isValidating') && el.isValidating();
                if (!isValidating) {
                    return $.Deferred().resolve(elUnwrapped.isValid());
                }

                // async validation
                return elUnwrapped.isValid.promise();
            });

            $.when.apply($, promises).
                done(function () {
                    var results = _.toArray(arguments);

                    _.defer(callback, _.all(results, _.identity));
                }).
                fail(_.bind(callback, null, false));
        },

        message: strings.validation.allMustBeValid
    };
});

