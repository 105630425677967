
define("json!widgets/report-parameters/strings.json", function(){ return {
  "scope": {
    "keyword": "Keywords(%s)",
    "campaign": "Campaigns(%s)",
    "all": "All Campaigns and Keywords"
  },
  "messageType": {
    "all": "All messages"
  },
  "contentType": {
    "all": "All content"
  },
  "summaryType": {
    "KEYWORD": {
      "all": "All keywords",
      "one": "Keywords(%s)"
    },
    "MOBILEFLOW": {
      "all": "All campaigns",
      "one": "Campaigns(%s)"
    },
    "CAMPAIGN": {
      "all": "All campaigns",
      "one": "Campaigns(%s)"
    }
  },
  "timeframe": {
    "CUSTOM": "from %s to %s"
  },
  "bucketSize": {
    "template": "by %s"
  },
  "list": {
    "all": "All lists",
    "notFound": "[list not found]",
    "everything": "All lists and smart segments"
  },
  "couponGroup": {
    "all": "All coupons",
    "notFound": "[coupon group not found]"
  },
  "notFound": "[not found]",
  "flow": {
    "all": "All Campaigns",
    "notFound": "[campaign not found]"
  },
  "excludeBroadcasts": "Exclude broadcast MTs"
};});

