define('msgme/viewmodel/scheduled-reports',[
    'msgme/ko',
    'msgme/viewmodel'
], function(ko, viewmodel) {
    return {
        collection: viewmodel.scheduledReports = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(5),
            links: ko.observableArray(),
            url: ko.observable('reports/scheduled/page'),
            noResults: ko.observable(false)
        }
    };
});

