define('msgme/widgets/flowmodule-listmanagement',[
    'msgme/underscore',
    'jquery',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-listmanagement',
    'widgets/new-list-modal/index'
],
function (_, $, ko, flowmodule, listmanagement) {
    $.widget('msgme.msgme_flowmodule_addtoadditionallist', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-addtoadditionallist-'
        },

        _create: function () {
            this.on('change', '.list-selection', '_onListChange');

            this.options.data().params.listId.subscribe(function(oldValue) {
                this.options.data().previousList(oldValue);
            }, this, 'beforeChange');

            flowmodule.prototype._create.apply(this);
        },

        _onListChange: function (e) {
            var data = this.options.data();

            if (e.target.value === 'new') {
                data.params.listId(data.previousList());
                this._openModal();
            } else if (e.target.value === 'placeholder') {
                data.params.listId(data.previousList());
            }
        },

        _openModal: function () {
            var $newListEl = this.element.find('.new-list-container');

            $newListEl.msgme_new_list_modal('show');
        }
    });

    $.msgme.msgme_flowmodule_addtoadditionallist.Model =
        flowmodule.modelFactory({}, {
        mapping: listmanagement.mapping
    });

    return $.msgme.msgme_flowmodule_addtoadditionallist;
});


