define('msgme/widgets/flowmodule-daypart',[
    'msgme/underscore',
    'jquery',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-daypart'
],
function (_, $, ko, flowmodule, daypart) {
    $.widget('msgme.msgme_flowmodule_daypart', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-daypart-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);

            var params = this.options.data().params;

            if (!params.parts().length) {
                params.parts.push(
                    ko.mapping.fromJS({}, daypart.partsMapping));
                params.parts()[0].nextStep.isModified(false);
                params.parts()[0].timeZone.isModified(false);
            }

            this.on('click', '.add-part', '_onAddClick');
            this.on('click', '.delete-group', '_onClickDelete');
        },

        _onAddClick: function () {
            var params = this.options.data().params;

            params.parts.push(
                ko.mapping.fromJS({}, daypart.partsMapping));
            _.last(params.parts()).nextStep.isModified(false);
            _.last(params.parts()).timeZone.isModified(false);
        },

        _onClickDelete: function (e) {
            var params = this.options.data().params;
            var index = $(e.target.closest('.daypart-group')).index();

            params.parts.splice(index, 1);
        }
    });

    $.msgme.msgme_flowmodule_daypart.Model =
        flowmodule.modelFactory({}, {
        mapping: daypart.mapping
    });

    return $.msgme.msgme_flowmodule_daypart;
});


