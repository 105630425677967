define('msgme/viewmodel/ads',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/format'
//    'json!widgets/shared-strings.json',
], function(_, ko, viewmodel, format) {
    var model = ko.observable(null);
    return {
        model: viewmodel.ad = model,
        collection: viewmodel.ads = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('ads/page')
        },

        mapping: {
            defaults: {
                id: null,
                name: null,
                startDate: null,
                endDate: null,
                agency: null,
                advertiser: null,
                salesPerson: null,
                impressionsAllowed: null,
                rate: null,
                adCopy: null,
                notes: null,
                price: 'flatRate'
            },

            subscribe: {
                startDate: function (value, parent, root) {
                    root.startDateTime(value);
                },

                endDate: function (value, parent, root) {
                    root.endDateTime(value);
                }
            },
            computed: {
                startDateTime: {
                    read: function () {
                        if (!this.startDate()) {
                            return null;
                        } else {
                            return new Date(this.startDate());
                        }
                    },
                    write: function (parent, value) {
                        if (value) {
                            this.startDate(format.ISODate(value));
                        } else {
                            this.startDate(null);
                        }
                    },

                    deferEvaluation: true
                },

                endDateTime: {
                    read: function () {
                        if (!this.endDate()) {
                            return null;
                        } else {
                            return new Date(this.endDate());
                        }
                    },
                    write: function (parent, value) {
                        if (value) {
                            this.endDate(format.ISODate(value));
                        } else {
                            this.endDate(null);
                        }
                    },

                    deferEvaluation: true
                },

                status: function () {
                    if (this.startDateTime() && this.endDateTime()) {
                        if (this.endDateTime() > Date.create() &&
                           this.startDateTime() < Date.create()) {
                            return 'Active';
                        } else if (this.endDateTime() < Date.create()) {
                            return 'Expired';
                        } else if (this.startDateTime() > Date.create()) {
                            return 'Future';
                        } else {
                            return 'Inactive';
                        }
                    } else {
                        return 'Inactive';
                    }
                },

                adCopyLength: function () {
                    return this.adCopy() && this.adCopy().length;
                }
            },

            validation: {
                impressionsAllowed: {
                    validator: function (value) {
                        if (this.parent.impressionsAllowed()) {
                            if (!!Number(value)) {
                                return value >= 0;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    },

                    message: 'Please enter a number 0 or greater'
                },
                name: {
                    validator: function (value) {
                        return !!value;
                    },

                    message: 'Please enter a name'
                },
                startDateTime: {
                    required: true
                },
                endDateTime: {
                    required: true
                },
                rate: {
                    required: true
                },
                advertiser: {
                    required: true
                },
                adCopy: {
                    validator: function (value) {
                        return value && value.length > 0 && value.length < 40;
                    },

                    message: 'Please enter a message 39 characters or less'
                }
            }
        }
    };
});

