
define("json!widgets/navbar/strings.json", function(){ return {
  "brandtitle": "Msgme 3.0",
  "search": "Search for subscribers",
  "shortcodes": "Short codes",
  "overview": "Overview",
  "insights": "Insights",
  "lists": "Lists",
  "new_message": "Send",
  "impersonating": "You are currently impersonating user: ",
  "revert": "Click here to revert identity"
};});

