define('msgme/viewmodel/user',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel'
], function(_, ko, viewmodel) {
    var model = ko.observable(null);
    return {
        model: viewmodel.user = model,
        collection: viewmodel.users = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('users/page'),
            isRoot: ko.observable(waterfall.authenticate.isRoot)
        },
        mapping: {
            defaults: {
                id: null,
                group: null,
                password: null,
                name: null,
                role: null,
                defaultShortCode: null,
                shortCodes: []
            },
            computed: {
                rolesByGroup: function() {
                    return viewmodel.globals.roles.allByGroup(this.group());
                }
            },
            validation: {
                name: {
                    required: true
                },
                password: {
                    required: {
                        onlyIf: function () {
                            return !this.parent.id();
                        }
                    },
                    minLength: 5,
                    maxLength: 30
                },
                group: {
                    required: true
                },
                role: {
                    required: true
                },
                defaultShortCode: {
                    required: true
                },
                shortCodes: {
                    // FIXME: replace with allAreValidSync when available
                    validation: {
                        validator: function (shortCodesMaybeObservable) {
                            var unWrap = ko.utils.unwrapObservable;
                            var shortCodes = unWrap(shortCodesMaybeObservable);

                            function isNotBlank(shortCodeMaybeObservable) {
                                var shortCodeString =
                                    unWrap(shortCodeMaybeObservable);
                                return !_.isBlank(shortCodeString);
                            }

                            if (_.isEmpty(shortCodes)) { return false; }
                            return _.all(shortCodes, isNotBlank);
                        }
                    }
                }
            }
        }
    };
});

