define('msgme/viewmodel/flowmodule-subscription',[
    'msgme/underscore',
    'json!widgets/shared-strings.json',
    'msgme/util/account-features'
], function (_, sharedStrings, accountFeatures) {
    var mapping = {
        local: {
            title: 'Subscription',
            head: '',
            oldOptInStyle: false,

            optInCurrentTab: 'sms',
            optInAccountFeaturesKey: 'fixedSubscriptionOptInMessage',
            optInMessageLastSaved: '',

            confirmCurrentTab: 'sms',
            confirmAccountFeaturesKey: 'fixedSubscriptionConfirmMessage',
            confirmMessageLastSaved: '',

            subscribedCurrentTab: 'sms',
            subscribedAccountFeaturesKey:
                'fixedSubscriptionAlreadySubscribedMessage',
            subscribedMessageLastSaved: '',
            previousList: null,
            paramsValid: null
        },
        params: {
            mapping: {
                local: {
                    paramsValid: null
                },
                defaults: {
                    listId: null,
                    optInType: 'doubleOptIn',

                    optInMessage:
                        sharedStrings.message.placeholder.optInMessage,
                    optInSubject: '',
                    optInFiles: [],
                    optInSMSFallback: null,

                    confirmMessage:
                        sharedStrings.message.placeholder.confirmMessage,
                    confirmSubject: '',
                    confirmFiles: [],
                    confirmAd: null,
                    confirmSponsorship: null,
                    confirmSMSFallback: null,

                    subscribedMessage:
                        sharedStrings.message.placeholder.alreadySubscribed,
                    subscribedSubject: '',
                    subscribedFiles: [],
                    subscribedAd: null,
                    subscribedSponsorship: null,
                    subscribedSMSFallback: null
                },
                validation: {
                    listId: {
                        validator: function (val) {
                            return val !== 'placeholder';
                        },

                        message: 'Please select a list'
                    },
                    paramsValid: {
                        validator: function () {
                            return this.parent.optInMessage.isValid &&
                                this.parent.optInMessage.isValid() &&
                                this.parent.confirmMessage.isValid &&
                                this.parent.confirmMessage.isValid() &&
                                this.parent.subscribedMessage.isValid &&
                                this.parent.subscribedMessage.isValid();
                        }
                    }
                }
            }
        },
        computed: {
            hasOptInFixedMessageDiscrepancy: {
                read: function () {
                    return accountFeatures.hasFixedMessageDiscrepancy(
                        this.optInAccountFeaturesKey(),
                        this.optInMessageLastSaved());
                },

                deferEvaluation: true
            },

            hasConfirmFixedMessageDiscrepancy: {
                read: function () {
                    return accountFeatures.hasFixedMessageDiscrepancy(
                        this.confirmAccountFeaturesKey(),
                        this.confirmMessageLastSaved());
                },

                deferEvaluation: true
            },

            hasSubscribedFixedMessageDiscrepancy: {
                read: function () {
                    return accountFeatures.hasFixedMessageDiscrepancy(
                        this.subscribedAccountFeaturesKey(),
                        this.subscribedMessageLastSaved());
                },

                deferEvaluation: true
            },

            hasFixedMessageDiscrepancy: {
                read: function () {
                    return this.hasOptInFixedMessageDiscrepancy() ||
                        this.hasConfirmFixedMessageDiscrepancy() ||
                        this.hasSubscribedFixedMessageDiscrepancy();
                },

                deferEvaluation: true
            },

            fieldLastSavedPairs: {
                read: function () {
                    return [{
                        field: this.params.optInMessage,
                        fieldLastSaved: this.optInMessageLastSaved
                    }, {
                        field: this.params.confirmMessage,
                        fieldLastSaved: this.confirmMessageLastSaved
                    }, {
                        field: this.params.subscribedMessage,
                        fieldLastSaved: this.subscribedMessageLastSaved
                    }];
                },

                deferEvaluation: true
            }
        }
    };

    return {
        mapping: mapping
    };
});

