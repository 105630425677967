define('msgme/ko/mapping/validation',[
    'msgme/underscore',
    'msgme/ko/mapping'
],
function (_, komapping) {
    /**
     * Validate the mapped field
     *
     * The value of the mapping can have the following forms:
     * 
     * - an object with keys that are ko.validation constraints and no key named
     *   `validator`
     * - an object with a `validator` key whose value is a function
     * - a function
     *
     * In the latter two cases, the mapping is transformed to an object of the
     * first form that specifies an anonymous custom validation rule as
     * described [here](http://bit.ly/1c5oaP3) (https://github.com/
     * Knockout-Contrib/Knockout-Validation/wiki/Custom-Validation-Rules)
     *
     * If the mapping value is an object with a validator key, it becomes:
     *
     * ```javascript
     * originalMappingValue = {
     *   validator: function (val) {
     *     // ...
     *   },
     *   message: 'UR DOIN IT RONG'
     * }
     *
     * {
     *   validation: originalMappingValue
     * }
     * ```
     *
     * If the mapping value is a function, it becomes:
     *
     * ```javascript
     * originalMappingValue = function (val) {
     *   // ...
     * }
     *
     * {
     *   validation: {
     *     validator: originalMappingValue
     *   }
     * }
     *
     * In the first case, the object is expected to be a valid
     * Knockout.Validation rule, with the exception that custom validators may
     * be specified as functions as well as objects with a `validator` key.
     *
     * Validator, message, and onlyIf functions are called with a context that
     * provides the following:
     *
     * - `observable`: the mapped observable
     * - `parent`: the parent model of the mapped observable
     * - `root`: the root model of the mapped observable
     */
    komapping.fromJS.plugins.after.validation =
        function (source, mapping, target, root) {
            var validation = mapping.validation || {};
            var rule;

            function bindValidationFns(key) {
                if (_.isFunction(this.validationItem[key])) {
                    this.validationItem[key] =
                        _.bind(this.validationItem[key], this.context);
                }
            }

            for (var k in validation) {
                if (validation.hasOwnProperty(k)) {
                    rule = validation[k];

                    if (_.isFunction(rule)) {
                        // fn rules are transformed into anonymous custom
                        // validators
                        rule = {
                            validation: {
                                validator: rule
                            }
                        };
                    } else if (_.isObject(rule) && 'validator' in rule) {
                        // object rules with a validator key are transformed
                        // into anonymous custom validators
                        rule = {
                            validation: rule
                        };
                    }

                    if (rule.validation) {
                        // handle single validation objects as arrays
                        rule.validation = _.isArray(rule.validation) ?
                            rule.validation : [rule.validation];

                        for (var i = 0; i < rule.validation.length; i++) {
                            rule.validation[i] =
                                _.isFunction(rule.validation[i]) ?
                                { validator: rule.validation[i] } :
                                rule.validation[i];

                            // bind custom validators and message functions to
                            // run with a context that provides references to
                            // the target observable, its parent, and its root
                            _.each(['validator', 'message', 'onlyIf'],
                                bindValidationFns, {
                                    validationItem: rule.validation[i],
                                    context: {
                                        observable: target[k],
                                        parent: target,
                                        root: root
                                    }
                                });
                        }
                    } else if (_.isObject(rule)) {
                        for (var ruleName in rule) {
                            // bind onlyIf and message functions to run with a
                            // context that provides references to the target
                            // observable, its parent, and its root
                            if (_.isObject(rule[ruleName])) {
                                _.each(['message', 'onlyIf'],
                                    bindValidationFns, {
                                        validationItem: rule[ruleName],
                                        context: _.extend({}, rule[ruleName], {
                                            observable: target[k],
                                            parent: target,
                                            root: root
                                        })
                                    });
                            }
                        }
                    }

                    target[k].extend(rule);
                }
            }
        };

    return komapping;
});

