define('widgets/coupon-modal/index',[
    'msgme/underscore',
    'msgme/path',
    './../three-widget',
    'text!./template.html',
    'msgme/alert',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/util/api',
    'msgme/viewmodel/coupon',
    'json!widgets/shared-strings.json'
], function (
    _,
    path,
    ThreeWidget,
    template,
    alert,
    viewmodel,
    viewmodelMapping,
    api,
    coupon,
    sharedStrings
) {
    $.widget('msgme.msgme_coupon_modal', ThreeWidget, {
        _mapping: _.extend(coupon.mapping, {
            computed: {
                isCreateDisabled: function () {
                    return !this.name();
                }
            }
        }),

        _template: template,

        _create: function () {
            this._super.apply(this, arguments);
            this.on('click', '.btn.ok', '_onSave');
            this.on('click', '.cancel', 'hide');
            this.on('click', '.btn.browse', '_onBrowseClick');
            this.on('change', '.coupon-input', '_onCouponChange');
        },

        _instantiateModalIfNecessary: function () {
            var vm = this.option('viewmodel');

            if (!this.$modal) {
                this.$modal = this.element.find('.modal').
                    modal().
                    on('hide', vm.id.bind(vm, null));
            }
        },

        _onBrowseClick: function () {
            this.element.find('.coupon-input').trigger('click');
        },

        _onCouponChange: function () {
            var filepath = this.element.find('.coupon-input').val();
            this.element.find('.filename').val(
                filepath.slice(filepath.lastIndexOf('\\') + 1));
        },

        _onSave: function () {
            var vm = this.option('viewmodel');
            var model = coupon.coupons.create();
            var hide = this.hide;

            if (vm.isValid()) {
                var input = this.element.find('.coupon input[type="file"]');
                var filepath = input.val();
                var fileName = filepath.slice(filepath.lastIndexOf('\\') + 1);
                var name = vm.name();

                vm.inFlightRequest(true);

                api.call('coupons.fetch', {name: name}).
                    done(function (result) {
                        if (result.length === 0) {
                            model.name(name);
                            model.save().
                                done(function (result) {
                                    if (!fileName) {
                                        hide();
                                        return;
                                    }
                                    $('.coupon-modal .coupon').ajaxSubmit({
                                        data: {
                                            fileName: fileName
                                        },
                                        success: function (file) {
                                            model.id(result.id());
                                            model.addCodes(file.id).
                                                done(function () {
                                                    hide();
                                                    alert.success('Coupon '+
                                                        'group successfully ' +
                                                        'created.');
                                                }).
                                                fail(api.getRequestFailureFn(
                                                    null, 'coupon.save')).
                                                always(function () {
                                                    vm.inFlightRequest(false);
                                                });
                                        },
                                        error: function () {
                                            var failureFn = api.
                                                getRequestFailureFn(
                                                    null, 'file');
                                            vm.inFlightRequest(false);
                                            return function () {
                                                failureFn.call(this, arguments);
                                            };
                                        }
                                    });
                                });
                        } else {
                            alert.info(sharedStrings.couponModal.duplicateName);
                        }
                    });
            } else {
                vm.isModified(true);
            }
        },

        hide: function () {
            if (this.$modal) {
                this.$modal.modal('hide');
            }
            path.history.pushState(null, null,
                sharedStrings.sitemap.coupons.url);
            viewmodel.globals.coupons.refresh(true);
        },

        show: function () {
            var vm = this.option('viewmodel');

            this._instantiateModalIfNecessary();
            this.$modal.modal('show');
            // clear stale data
            this.element.find('.coupon-input').val('');
            this.element.find('.filename').val('');
            vm.name(null);
            vm.name.isModified(false);
            vm.inFlightRequest(false);
        }
    });

    return {
        widget: $.msgme.msgme_coupon_modal
    };
});

