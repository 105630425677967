define('msgme/ko/validation',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/util/promise',
    'msgme/ko/validation/all-are-valid',
    'msgme/ko/validation/unique'
],
function (_, ko, promise) {
    var kov = ko.validation;

    function getValidityPromise() {
        var observable = this.observable;
        var isValid = observable.isValid();
        var isValidating = observable.isValidating();

        if (!isValidating) {
            // validation was not async
            return promise.deferValue(isValid);
        }

        var deferred = $.Deferred();
        var subscription =
            observable.isValidating.subscribe(function (isValidating) {
                if (isValidating) {
                    throw new Error('isValidating should be truthy');
                }

                subscription.dispose();

                // the value of isValid does not update synchronously with
                // isValidating, so we must wait a tick
                _.defer(function () {
                    deferred.resolve(observable.isValid());
                });
            });

        return deferred.promise();
    }

    /**
     * Attach a `promise` method to the `isValid` observable if that observable
     * is validated by at least one asynchronous rule.
     *
     * The `promise` method returns a promise of `isValid`'s value the next time
     * that `isValidating` is `false`. If `false` when `promise` is called, the
     * returned promise resolves on a later turn of the event loop with the
     * value of `isValid` when `promise` was called.
     *
     * This method is *not* removed if the asynchronous rule is later removed.
     */
    kov.addRule = _.wrap(kov.addRule, function (addRule, observable, rule) {
        var result = addRule.call(this, observable, rule);
        var ruleDef = kov.rules[rule.rule];

        if (ruleDef && ruleDef.async === true && !observable.isValid.promise) {
            observable.isValid.promise = _.bind(getValidityPromise, {
                observable: observable
            });
        }

        return result;
    });

    ko.validation.registerExtenders();

    kov.patterns = {
        // source: http://stackoverflow.com/a/30971061
        url: new RegExp('^(https?://)?(((www\\.)?([-a-z0-9]{1,63}\\.)*?' +
            '[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6})|((\\d{1,3}\\.)' +
            '{3}\\d{1,3}))(:\\d{2,4})?((/|\\?)(((%[0-9a-fA-F]{2})|[-\\w@\\+\\.'+
            '~#\\?&/=$:!])*))?$'),
        urlNoSpaces: /^\S*$/
    };

    return kov;
});

