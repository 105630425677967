define('widgets/selectize/index',[
    'msgme/underscore',
    'msgme/ko',
    'lib/microplugin',
    'lib/sifter',
    'lib/selectize',
    './../three-widget'
], function () {
	// selectize binding taken from:
	// http://motorscript.com/selectize-js-binding-knockout-js/

	ko.bindingHandlers.selectize = {
		init: function (element, valueAccessor, allBindingsAccessor,
        viewModel, bindingContext) {
			var options = valueAccessor();
			var $select = $(element).selectize(options)[0].selectize;

			if (typeof allBindingsAccessor().value == 'function') {
				$select.addItem(allBindingsAccessor().value());
				allBindingsAccessor().value.subscribe(function (new_val) {
					$select.addItem(new_val);
				})
			}

			if (typeof allBindingsAccessor().selectedOptions == 'function') {
				allBindingsAccessor().selectedOptions.
                subscribe(function (new_val) {
					// Removing items which are not in new value
					var values = $select.getValue();
					var items_to_remove = [];
					for (var k in values) {
						if (new_val.indexOf(values[k]) == -1) {
							items_to_remove.push(values[k]);
						}
					}

					for (var k in items_to_remove) {
						$select.removeItem(items_to_remove[k]);
					}

					for (var k in new_val) {
						$select.addItem(new_val[k]);
					}

				});
				var selected = allBindingsAccessor().selectedOptions();
				for (var k in selected) {
					$select.addItem(selected[k]);
				}
			}


			if (typeof init_selectize == 'function') {
				init_selectize($select);
			}

			if (typeof valueAccessor().subscribe == 'function') {
				valueAccessor().subscribe(function (changes) {
					// To avoid having duplicate keys,
                    // all delete operations will go first
					var addedItems = new Array();
					changes.forEach(function (change) {
						switch (change.status) {
							case 'added':
								addedItems.push(change.value);
								break;
							case 'deleted':
								var itemId = change.value[options.valueField];
								if (itemId != null) {
                                    $select.removeOption(itemId);
                                }
						}
					});
					addedItems.forEach(function (item) {
						$select.addOption(item);
					});

				}, null, "arrayChange");
			}

		},
		update: function (element, valueAccessor, allBindingsAccessor) {

			if (allBindingsAccessor().object) {
				var optionsValue = allBindingsAccessor().optionsValue || 'id';
				var value_accessor = valueAccessor();
				var selected_obj = $.grep(value_accessor(), function (i) {
					if (typeof i[optionsValue] == 'function')
						var id = i[optionsValue]
					else
						var id = i[optionsValue]
					return id == allBindingsAccessor().value();
				})[0];

				if (selected_obj) {
					allBindingsAccessor().object(selected_obj);
				}
			}
		}
	}
});


