define('msgme/views/recurring-reports',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/path',
    'msgme/splash',
    'msgme/views/View',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/report',
    'msgme/viewmodel/recurring-reports',
    'msgme/widgets/pager',
    'msgme/modal',
    'widgets/report-parameters/index',
    'msgme/util/feature-flags',
    'msgme/util/format',
    'json!widgets/shared-strings.json',
    'json!widgets/create-report/strings.json'
], function (
    _,
    ko,
    path,
    splash,
    View,
    viewmodel,
    mapping,
    report,
    recurringReports,
    Pager,
    modal,
    reportParameters,
    featureFlags,
    format,
    sharedStrings,
    createReportStrings
) {
    var view = new View('#recurring-reports-view');
    var url = sharedStrings.sitemap.reports.url +
        sharedStrings.sitemap.reports.recurring;
    var pager = new Pager(recurringReports.collection);
    var strings = sharedStrings.sitemap.reports;
    var page, cachedReports, allReports;

    function onFailure(what, e) {
        console.error('Failed to fetch', what + ': ', e);
    }

    function fetchReports() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#recurring-reports-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !recurringReports.collection.rows().length) {
            if (!recurringReports.collection.pageSize() ||
                !recurringReports.collection.pageIndex() ||
                !recurringReports.collection.pageCount()) {
                resetViewmodelCollection();
            }

            // fetch the reports and populate page if not in a search
            view.callAPI('reporting.fetch', {
                    page: page,
                    size: recurringReports.collection.pageSize(),
                    orderBy: '-dateScheduled',
                    type: 'PERIODIC'
                }).
                done(function (result) {
                    cachedReports = result;
                    populate(result);
                }).
                fail(_.bind(onFailure, this, 'reporting.fetch'));
        } else {
            //fetch for cache
            view.callAPI('reporting.fetch', {
                    page: page,
                    size: recurringReports.collection.pageSize(),
                    orderBy: '-dateScheduled',
                    type: 'PERIODIC'
                }).
                done(function (result) {
                    cachedReports = result;
                    $('#recurring-reports-view-main').msgme('busy', false);
                }).
                fail(_.bind(onFailure, this, 'reporting.fetch'));
        }

        view.callAPI('reporting.fetch', {
                page: page,
                size: -1,
                orderBy: '-dateScheduled',
                type: 'PERIODIC'
            }).
                done(function (result) {
                    allReports = result;
                    instantiateSearchBox();
                }).
                fail(view.getRequestFailureFn(null, 'reporting.fetch'));
    }

    function resetViewmodelCollection() {
        recurringReports.collection.pageSize(5);
        recurringReports.collection.pageCount(-1);
        recurringReports.collection.pageIndex(-1);
        recurringReports.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var reportNames = _.map(allReports, function (report) {
            return report.name;
        });
        var $searchBoxEl = view.root.find('.search-box');

        if (!!$searchBoxEl.hasOwnProperty('autocomplete')) {
            $searchBoxEl.autocomplete('destroy');
        }

        $searchBoxEl.autocomplete({
            source: reportNames,
            delay: 100
        });
    }

    function populate(reportsInstance) {
        _.each(reportsInstance, function (report) {
            var data = report.data();
            var model = ko.mapping.fromJS({}, reportParameters.mapping);
            var repeatPrefix = report.repeatEvery > 1 ? 'Every ' + report.
                repeatEvery : '';

            report.date = format.uiDate(report.lastReport.scheduledAt);
            report.type = createReportStrings.type.options[report.query.type];
            report.url = strings.url + (page > 1 ? '/page/' + page : '') +
                '/' + report.id;
            report.viewable = report.lastReport.status === 'COMPLETE' &&
                report.query.type !== 'listDetails';
            model.data(ko.mapping.fromJS(data, report.mapping));
            report.summary = model.summary;

            switch (report.repeatInterval) {
            case 'DAILY':
                report.recurringSummary = report.repeatEvery > 1 ?
                repeatPrefix + ' days' : 'Daily';
                return;
            case 'WEEKLY':
                report.recurringSummary = report.repeatEvery > 1 ?
                repeatPrefix + ' weeks' : 'Weekly';
                return;
            case 'MONTHLY':
                report.recurringSummary = report.repeatEvery > 1 ?
                repeatPrefix + ' months' : 'Monthly';
                return;
            default:
                report.recurringSummary = 'Never';
            }
        });

        splash.hide();
        view.root.find('#recurring-reports-view-main').msgme('busy', false);
        recurringReports.collection.rows(reportsInstance);
        pager.update(reportsInstance);
    }

    view.root.on('click', 'i.remove.fa.fa-remove', function () {
        var reportDeleteStrings = sharedStrings.reports['delete'];

        modal.confirm(reportDeleteStrings.confirmation, {
            title: reportDeleteStrings.title
        }).done(_.bind(function (value) {
            if (value) {
                var $this = $(this);
                var entryEl = $this.closest('tr');
                var record =
                    recurringReports.collection.rows()[entryEl.index()];

                record.del().
                    done(function () {
                        fetchReports();
                        msgme.alert.success(reportDeleteStrings.success);
                    }).
                    fail(view.getRequestFailureFn(null,
                        'reporting.delete', record.id));
            }
        }, this));
    });

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        recurringReports.collection.noResults(false);
        if (!query) {
            populate(cachedReports);
        } else {
            results = _.map(allReports, function (report) {
                if (report.name && report.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return report;
                }
            });

            populate(_.compact(results));

            if (!_.compact(results).length) {
                recurringReports.collection.noResults(true);
            }
        }

        view.root.find('.search-box').autocomplete('close');
    });

    view.root.on('click', '.search-flow', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        populate(cachedReports);
        view.root.find('.search-box').val('');
        recurringReports.collection.noResults(false);
    });

    path.map(url + '(/page/:page)').to(function () {
        page = this.params.page ? this.params.page : '';
        view.show();
        fetchReports();
    });

    return view;
});

