
define("json!widgets/stream-entry/strings.json", function(){ return {
  "expand": "Expand conversation",
  "collapse": "Collapse conversation",
  "options": "Options",
  "addToList": "Add to list",
  "removeFromList": "Remove from list",
  "addMetadata": "Add metadata",
  "subscriberProfile": "Subscriber profile...",
  "clearMessage": "Clear message",
  "subscribed": "Subscribed to list",
  "unsubscribed": "Unsubscribed from list",
  "metadataAdded": "Metadata added",
  "clear": {
    "confirmation": "This action will remove the selected message from your conversation stream. Are you sure you want to do this?\n",
    "title": "Remove Message"
  },
  "company": "Me",
  "sendMessage": {
    "title": {
      "sms": "Reply via",
      "other": "Reply"
    },
    "messagePlaceholder": "Message goes here",
    "email": {
      "addEmailHelp": {
        "text": {
          "paragraph1": "There is currently no email address on record for this person.\n",
          "paragraph2": {
            "beforeInterpolation": "If you know the email address for this person, click\n",
            "afterInterpolation": "below. If not, consider running a campaign that includes a Collect Metadata component that asks for the email addresses of your subscribers.\n"
          }
        },
        "button": "Add Email"
      },
      "placeholder": {
        "to": "To: Email address",
        "from": {
          "name": "From: First and last name",
          "address": "From: Email address"
        },
        "subject": "Subject:"
      },
      "toPrefix": "To: ",
      "updateTo": " (update)",
      "repliedIndicator": "Replied by email",
      "validation": "Please enter a valid email address"
    },
    "channel": {
      "sms": "Mobile",
      "email": "Email"
    }
  },
  "likes": "Likes",
  "shares": "Shares",
  "reply": {
    "sms": "Send SMS Response",
    "mms": "Send MMS Response",
    "facebook": "Send Facebook Response",
    "email": "Send Email Response"
  },
  "warning": {
    "subscriberNotFetched": "Subscriber hasn't been fetched but their email address is expected"
  }
};});

