define('widgets/message-preview/index',[
    'msgme/underscore',
    'lib/moment-timezone',
    'msgme/ko',
    'msgme/viewmodel/message',
    'msgme/viewmodel',
    'msgme/util/api',
    'msgme/util/format',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    'msgme/widgets/modal'
], function (
    _,
    moment,
    ko,
    message,
    viewmodel,
    api,
    format,
    ThreeWidget,
    template,
    strings
) {
    var timezoneMap = [
        'America/Los_Angeles',
        'America/Denver',
        'America/New_York',
        'America/Chicago',
        'America/Phoenix',
        'America/Asuncion'
    ];

    var mapping = {
        defaults: {
            data: null,
            timezoneStatus: null,
            sendAt: null
        },
        local: {
            actionName: '',
            iconClass: '',
            targetName: '',
            numbers: '',
            selectedSlice: 0,
        },
        computed: {
            slices: function () {
                var slices = this.data().slices();
                var result = _.map(slices, function (slice, index) {
                    return {
                        text: _.sprintf('Segment %s - %s%%',
                            index + 1, slice.percentage()),
                        value: index
                    };
                });

                return result;
            },
            audienceList: function () {
                var audiences = this.data().embeddedAudience();

                return _.chain(audiences).
                    map(function (target) {
                        return target.listName();
                    }).
                    uniq().
                    value().join(', ');
            },
            type: function () {
                var channel = this.data().channel();

                if (channel === 'sms' || channel === 'smartlists') {
                    return 'SMS';
                } else {
                    return channel;
                }
            },
            tagMetadata: function () {
                var metadata = this.data().metadata();
                var metaRecord = viewmodel.globals.metadata.oneById(metadata);

                if (metadata && metaRecord) {
                    return _.map(this.data().slices(), function (slice) {
                        return 'Field (' + metaRecord.name + '), Value (' +
                            slice.value() + ')   ';
                    });
                } else {
                    return [];
                }
            },
            hasPastTimezones: function () {
                var enableWarning = false;
                var sendAt = this.sendAt();

                if (sendAt) {
                    _.each(timezoneMap, function (timezone) {
                        if (Date.create(moment.tz(new Date(), timezone).
                            format('LLL'))  > sendAt) {
                            enableWarning = true;
                            return;
                        }
                    });
                }

                return enableWarning;
            },
            dateString: function () {
                var scheduledFor = 'Scheduled for: ';

                if (this.sendAt()) {
                    if (this.timezoneStatus() === 'ON_OLDERS' ||
                        this.timezoneStatus() === 'ON_IGNORE') {
                        return scheduledFor + Date.create(this.sendAt()).format(
                            '{Weekday}, {Month} {d}, {yyyy} {hh}:{mm} {TT}') +
                            ' LOCAL';
                    } else {
                        return scheduledFor +
                            moment(this.sendAt()).
                            tz(moment.tz.guess()).format('LLLL z');
                    }

                } else {
                    return scheduledFor + 'now';
                }
            },
            count: function () {
                return format.integer(this.data().count());
            }
        },
        validation: {
            numbers: {
                validator: function () {
                    var numbers = this.parent.numbers().split(',');
                    var isValid = true;

                    _.each(numbers, function (number) {
                        if (!format.findPhoneNo(number)) {
                            isValid = false;
                            return;
                        }
                    });

                    return isValid;
                },

                message: 'One or more numbers are invalid'
            }
        }
    };

    $.widget('msgme.msgme_message_preview', ThreeWidget, {
        _template: template,
        _mapping: mapping,

        actionName: 'SMS',
        iconClass: 'fa-mobile-phone',
        targetName: 'subscribers',

        _create: function () {
            this._mapping = this._mapping || mapping;
            ThreeWidget.prototype._create.apply(this, arguments);
            var viewmodel = this.option('viewmodel');
            viewmodel.actionName(this.option('actionName'));
            viewmodel.iconClass(this.option('iconClass'));
            viewmodel.targetName(this.option('targetName'));

            $('.image-preview .modal').modal({show: false});
            this.element.
                find('.msgme-modal.test-broadcast-message').msgme_modal();
            this.on('click', '.test-message.btn', '_onTestMessageClick');
            this.on('click', '.test-broadcast-message .btn.send',
                '_onSendClick');
            this.on('click', 'a.preview', '_openAttachmentPreviewModal');
            this.on('click', '.attachment-preview-modal .done',
                '_closeAttachmentPreviewModal');
            this.on('hide', '.attachment-preview-modal .modal',
                '_resetAttachments');
        },

        _openAttachmentPreviewModal: function (evt) {
            var $modal = this.element.find('.attachment-preview-modal .modal');
            var src = evt.target.href;
            var fileId = _.last(src.split('/'));
            var fileType;

            evt.preventDefault();

            api.call('fileV2.fetch', fileId + '/details').
                done(function (file) {
                    fileType = _.first(file.contentType.split('/'));
                    if (fileType === 'image') {
                        $modal.find('img').attr('src', src);
                    } else if (fileType === 'video') {
                        $modal.find('video').attr({
                            src: src,
                            controls: 'controls'
                        });
                    } else if (fileType === 'audio') {
                        $modal.find('audio').attr({
                            src: src,
                            controls: 'controls'
                        });
                    }

                    $modal.modal('show');
                }).
                fail(api.getRequestFailureFn(null, 'fileV2.fetch', fileId));
        },

        _closeAttachmentPreviewModal: function () {
            this.element.find('.attachment-preview-modal .modal').modal('hide');
        },
       
        _resetAttachments: function () {
            var $modal = this.element.find('.attachment-preview-modal .modal');

            $modal.find('img').removeAttr('src');
            $modal.find('video').removeAttr('src controls');
            $modal.find('audio').removeAttr('src controls');
        },

        _onTestMessageClick: function () {
            var viewmodel = this.option('viewmodel');

            viewmodel.numbers('');
            viewmodel.numbers.isModified(false);
            this.element.find('.msgme-modal.test-broadcast-message').
                msgme_modal('open');
        },

        _onSendClick: function () {
            var viewmodel = this.option('viewmodel');

            if (viewmodel.numbers.isValid()) {
                this._sendTestMessage();
            } else {
                viewmodel.numbers.isModified(true);
            }
        },

        _sendTestMessage: function () {
            var viewmodel = this.option('viewmodel');
            var slice = viewmodel.data().slices()[viewmodel.selectedSlice()];
            var msisdnifyNumbers = _.map(viewmodel.numbers().split(','),
                function (num) {
                return format.msisdn(num);
            });

            var payload = {
                msisdns: msisdnifyNumbers
            };

            var message = slice.message();
            var mobileFlow = slice.mobileFlow();
            var subject = slice.subject();
            var files = slice.files();
            var ad = slice.ad();
            var sponsorship = slice.sponsorship();
            var smsFallback = slice.smsFallback();

            var isNoMessage = !message && !subject && !files && !mobileFlow;
            var isMobileFlow = !isNoMessage && mobileFlow;
            var isMMS = !isNoMessage && !isMobileFlow && (subject || files);
            var isSMS = !isNoMessage && !isMobileFlow && !isMMS && message;

            if (isNoMessage) {
                payload.message = '';
            } else if (isMobileFlow) {
                payload.mobileFlow = mobileFlow;
            } else if (isSMS || isMMS) {
                payload.message = message;
                payload.subject = subject;
                payload.files = files;
                payload.ad = ad;
                payload.sponsorship = sponsorship;
                payload.smsFallback = smsFallback;
            } else {
                console.warn(strings.unknownMessageType);
            }

            api.call('messaging.send', payload).
                done(function () {
                    msgme.alert.success('Test message has been sent');
                }).
                fail(api.getRequestFailureFn(null, 'messaging.send', payload));
        }
    });

    return {
        widget: $.msgme.msgme_message_preview,
        mapping: mapping
    };
});


