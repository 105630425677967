define('msgme/views/smartlist',[
    'lib/jquery.animateNumber',
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/util/api',
    'msgme/util/format',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/smartlists',
    'msgme/views/View',
    'msgme/widgets/pager',
    'json!widgets/shared-strings.json',
    'widgets/segment-metadata-value/index'
], function (
    animate,
    _,
    splash,
    path,
    ko,
    api,
    format,
    viewmodel,
    mapping,
    smartlist,
    View,
    Pager,
    sharedStrings
) {
    var view = new View('#smartlist-view');
    var cm = new mapping.CursorModel(waterfall.smartlists, {
        record : {
            mapping: smartlist.mapping
        }
    });
    var pager;
    var url = new RegExp(sharedStrings.sitemap.smartlists.url +
        sharedStrings.sitemap.smartlists.listSubUrl);
    var page;
    var commaSeparator = $.animateNumber.numberStepFactories.separator(',');

    function getCloneName(listName) {
        var index = 2;
        var cloneName = listName + ' Clone';

        while (viewmodel.globals.smartlists.oneByName(cloneName)) {
            cloneName = listName + ' Clone ' + index;
            index++;
        }

        return cloneName;
    }

    function cloneSmartlist(instance) {
        var model;
        var smartlistName;

        smartlistName = getCloneName(instance.name());
        model = cm.create();
        model.name(smartlistName);
        model.isNewSmartlist(true);
        model.lists(instance.lists());
        model.queryFilterDetails = instance.queryFilterDetails;

        return model;
    }

    function populate(smartlistInstance) {
        smartlist.model(smartlistInstance);
        splash.hide();
    }

    function populateSubscribers(subscribers) {
        var modifiedsubs = _.map(subscribers, function (subscriber) {
            subscriber.mobilePhoneNo =
                format.phoneNo(subscriber.mobileNumber);
            subscriber.url = waterfall.authenticate.isMasked ?
                subscriber.id :
                format.unformattedPhoneNo(subscriber.mobileNumber);
            subscriber.joinDate = format.uiDate(subscriber.dateAdded);
            return subscriber;
        });

        smartlist.model().collection.rows(modifiedsubs);
        smartlist.model().collection.url(
            _.sprintf('smartlists/%s/page', smartlist.model().id()));
        pager = new Pager(smartlist.model().collection);
        pager.update(subscribers);
        smartlist.model().isNewSmartlist(false);
        view.root.find('.datatable').msgme('busy', false);
    }

    function onQueryChange() {
        smartlist.model().isStale(true);
    }

    view.root.on('change', '.metadata-select', onQueryChange);
    view.root.on('change', '.metadata-operator', onQueryChange);
    view.root.on('change', '.metadata-value .value', onQueryChange);
    view.root.on('change', '.metadata-value-date', onQueryChange);

    view.root.on('click', '.submit', function () {
        var smartlistModel = smartlist.model();
        var queryIsValid = true;

        _.each(smartlistModel.queryFilterDetails.details(), function (detail) {
            _.each(detail.details(), function (d) {
                if (!d.metadata() || !d.value.isValid()){
                    d.value.valueHasMutated();
                    d.metadata.valueHasMutated();
                    queryIsValid = false;
                }
            });
        });

        if (smartlistModel.isValid() && queryIsValid) {
            smartlistModel.savePending(
                smartlistModel.save().
                    done(function() {
                        path.history.pushState(
                            null, null, sharedStrings.sitemap.smartlists.url);
                        msgme.alert.success('The segment has been saved');
                        viewmodel.globals.smartlists.refresh(true);
                    }).
                    fail(view.getRequestFailureFn(null,
                        'smartlist.save',
                        ko.mapping.toJS(smartlistModel))).
                    always(function() {
                        smartlist.model().pending(false);
                    }));
        } else {
            smartlistModel.isModified(true);
        }
    });

    view.root.on('click', '.btn.add-list', function () {
        var hasSameList = _.find(smartlist.model().lists(), function (list) {
            return smartlist.model().list() === list;
        });
        var model = smartlist.model();
        var isValid = true;
        var countQuery;
        var count;

        if (!hasSameList) {
            model.lists.push(model.list());
            model.list(null);

            if (model.lists().length === 1) {
                count = viewmodel.globals.lists.oneById(model.lists()[0]).
                    noOfSubscribers;
                model.count(count);
                view.root.find('.count').animateNumber({
                    number: count,
                    numberStep: commaSeparator
                });
            } else {
                _.each(model.queryFilterDetails.details(), function (d) {
                    _.each(d.details(), function (detail) {
                        if (!(detail.metadata() &&
                            detail.value.isValid())) {
                            isValid = false;
                        }
                    });
                });

                if (isValid) {
                    view.root.find('.refresh-active').click();
                } else {
                    countQuery = ko.mapping.toJS([smartlist.model()]);
                    countQuery[0].queryFilterDetails.details = [];
                    countQuery = JSON.stringify(countQuery);
                    smartlist.model().pending(true);
                    api.call('subscribers.count', countQuery).
                        then(function (count) {
                            smartlist.model().count(count);
                            smartlist.model().isStale(false);
                            smartlist.model().pending(false);
                            view.root.find('.count').animateNumber({
                                number: count,
                                numberStep: commaSeparator
                            });
                        }).
                        fail(view.getRequestFailureFn(null,
                            'subscribers.count',
                            ko.mapping.toJS(model.queryFilterDetails))).
                        always(function () {
                            smartlist.model().pending(false);
                        });
                }
            }
        }
    });

    view.root.on('click', '.remove-list', function (e) {
        var index = $(e.target.closest('.list-container')).index();

        smartlist.model().lists.splice(index, 1);
        smartlist.model().isStale(true);
    });

    view.root.on('click', '.add-filter', function () {
        smartlist.model().queryFilterDetails.details.push({
            operator: ko.observable('or'),
            details: ko.observableArray(
                [ko.mapping.fromJS(smartlist.mapping.details)])
        });
    });

    view.root.on('click', '.refresh-active', function () {
        var qfd = smartlist.model().queryFilterDetails;
        var details = qfd.details();
        var countQuery;

        var isValid = true;

        _.each(details, function (d) {
            _.each(d.details(), function (detail) {
                if (!(detail.metadata() &&
                    detail.value.isValid())) {
                    isValid = false;
                }
            });
        });

        if (_.isEmpty(qfd.details()) || !isValid) {
            _.each(details, function (d) {
                _.each(d.details(), function (detail) {
                    detail.metadata.valueHasMutated(true);
                    detail.value.valueHasMutated(true);
                });
            });
            return;
        }

        countQuery = JSON.stringify(ko.mapping.toJS([smartlist.model()]));
        smartlist.model().pending(true);
        api.call('subscribers.count', countQuery).
            then(function (count) {
                smartlist.model().count(count);
                smartlist.model().isStale(false);
                smartlist.model().pending(false);
                view.root.find('.count').animateNumber({
                    number: count,
                    numberStep: commaSeparator
                });
            }).
            fail(view.getRequestFailureFn(null,
                'subscribers.count',
                ko.mapping.toJS(qfd)));
    });

    view.root.on('click', '.remove-filter', function (e) {
        var qfd = smartlist.model().queryFilterDetails;
        var component = $(e.target.closest('.metadata-selection'));
        var filtersIndex = component.parent().find('.metadata-selection').
            index(component);
        var filterIndex = $(e.target.closest('.filter')).index();

        if (qfd.details()[filtersIndex].details().length > 1) {
            qfd.details()[filtersIndex].details.splice(filterIndex, 1);
            onQueryChange();
        } else {
            qfd.details.splice(filtersIndex, 1);
        }

        smartlist.model().isStale(true);
    });

    path.map(url).to(function (fullPath, isNew, listId, isClone, id, isPage) {
        view.show();
        var countQ;
        var ssId = id ? id : listId;

        if (isNew) {
            populate(cm.create());
            smartlist.model().isNewSmartlist(true);
            view.root.find('input.editable').focus();
        } else {
            view.root.find('.datatable').msgme('busy', true);
            cm.fetch(ssId).
                done(function (result) {
                    if (isClone) {
                        populate(cloneSmartlist(result));
                    } else {
                        populate(result);
                        countQ = ko.mapping.toJS([smartlist.model()]);
                        countQ = JSON.stringify(countQ);
                        api.call('subscribers.count', countQ).
                            then(function (count) {
                                smartlist.model().count(count);
                            }).
                            fail(view.getRequestFailureFn(null,
                                'subscribers.count', countQ));
                    }
                });
            if (!isClone) {
                page = isPage ? isPage.replace('/page/', '') : 1;
                waterfall.subscribers.v2.fetch({filterId: ssId,
                    page: page, size: 15}).
                    done(populateSubscribers).
                    fail(view.getRequestFailureFn(null,
                        'smartlists.fetch', ssId));
            }
        }
    });

    return view;
});

