define('msgme/widgets/flowmodule-tagmetadata',[
    'jquery',
    'msgme/ko',
    'msgme/underscore',
    'msgme/widgets/flowmodule',
    'msgme/util/api',
    'msgme/viewmodel/flowmodule-tagmetadata'
],
function ($, ko, _, flowmodule, api, flowmoduleTagMetadata) {
    $.widget('msgme.msgme_flowmodule_tagmetadata', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-tagmetadata-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
            var data = this.options.data();
            var delay;

            this._updateMetadata(data.metadataId());
            this.on('change', 'select.field', '_onFieldChange');
            if (data.metadataOptions()) {
                delay = 0;
            } else {
                delay = 2500;
            }
            _.delay(_.bind(function () {
                this.element.find('.metadata-select').selectize({
                    maxOptions: 1000,
                    options: data.metadataOptions(),
                    optgroups: data.metadataOptgroups(),
                    optgroupField: 'class',
                    labelField: 'name',
                    searchField: 'name'
                })[0].selectize.setValue(data.metadataId());
            }, this), delay);
        },

        _updateMetadata: function(metadataId) {
            // mark the valid values dropdown busy
            var busyEl = this.element.find('select.value');
            busyEl.msgme('busy', true);

       
            var validValues = this.options.data().validValues;

            api.call('metadata.fetch', metadataId).
                done(function (metadata) {
                    // clear the busy indicator for the valid values dropdown
                    busyEl.msgme('busy', false);
                    validValues(metadata.validValues);
                });
        },

        _onFieldChange: function () {
            var metadataId = this.options.data().metadataId();

            if (metadataId) {
                this._updateMetadata(metadataId);
            }
        }
    });

    $.msgme.msgme_flowmodule_tagmetadata.Model = flowmodule.modelFactory({}, {
        mapping: flowmoduleTagMetadata.mapping
    });

    return $.msgme.msgme_flowmodule_tagmetadata;
});


