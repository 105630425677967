define('msgme/widgets/subscriber-profile',[
    'jquery',
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/widgets/widget',
    'msgme/util/format',
    'msgme/util/api',
    'msgme/util/feature-flags'
],
function ($, _, ko, viewmodel, Widget, format, api, featureFlags) {
    $.widget('msgme.msgme_subscriber_profile', Widget, {
        options: {
            widgetEventPrefix: 'subscriber-profile-',
            saveEnabled: true,
            cancelEnabled: true,
            data: null
        },

        _create: function () {
            Widget.prototype._create.apply(this);
            var root = this.element;
            var metadataOptions;

            this.footer = root.find('.footer');

            this.on('click', 'ul.nav.nav-tabs a', '_onTabClick');

            this.on('click', '.footer.enabled button.send-message',
                '_onSendMessageClick');

            this.on('click', '.footer.enabled button.subscribe',
                '_onSubscribeClick');

            this.on('click', '.footer.enabled button.unsubscribe',
                '_onUnsubscribeClick');
            this.on('click', 'button.ok.unsubscribe',
                '_onUnsubscribeOkClick');

            this.on('click', '.footer.enabled button.add-metadata',
                '_onAddMetadataClick');
            this.on('click', 'button.ok.add-metadata',
                '_onAddMetadataOkClick');
            this.on('change', '.add-metadata select.metadata',
                '_onAddMetadataFieldChange');

            this.on('click', 'button.cancel', '_onCancelClick');
            this.on('click', '.metadatum button.delete', '_deleteMetadata');

            var customMetadata =
                _.map(viewmodel.globals.metadata.nonSystemGrouped().custom,
                    function (profile) {
                        return { id: profile.id, text: profile.name };
                    });


            if (viewmodel.globals.metadata.grouped().profile) {
                var profileMetadata =
                    _.map(viewmodel.globals.metadata.nonSystemGrouped().profile,
                        function (profile) {
                            return { id: profile.id, text: profile.name };
                        });

                metadataOptions = [{
                    id: '1',
                    text: 'Profile',
                    children:
                        profileMetadata
                }, {
                    id: '2',
                    text: 'Custom',
                    children:
                        customMetadata
                }];
            } else {
                metadataOptions = [{
                    text: 'Custom',
                    children:
                        customMetadata
                }];
            }

            this.element.find('select.metadata').select2({
                allowClear: false,
                data: metadataOptions
            });

            this.options.data().modals.sendmessage.widgetId = _.bind(
                function () {
                    return this.options.data().widgetId() + '-message';
                }, this);

            var modals = this.options.data().modals;
            root.find('.message .msgme-message-textbox').msgme_message_textbox({
                data: modals.sendmessage.message
            });

            root.find('.opt-in-message .msgme-message-textbox').
                msgme_message_textbox({
                    data: modals.subscribe.optInMessage
                });

            root.find('.confirm-message .msgme-message-textbox').
                msgme_message_textbox({
                    data: modals.subscribe.confirmMessage
                });

            root.find('.subscribed-message .msgme-message-textbox').
                msgme_message_textbox({
                    data: modals.subscribe.subscribedMessage
                });

            featureFlags('restrictPapaMurphysUI').then(_.bind(function () {
                // special case for PMI
                if (waterfall.authenticate.role ===
                    '54b67af70cf2ee69bde0f7c6') {
                    this.options.data().specialPMIRole(true);
                }
                this.options.data().restrictUI(true);
            }, this));
        },

        reset: function () {
            this.hideModals();
        },

        _onTabClick: function (e) {
            var $target = $(e.currentTarget);

            e.preventDefault();

            if (!$target.hasClass('disabled')) {
                $target.tab('show');
            }
        },

        _showModal: function (modal) {
            this._activeTab = this.element.find('.tab-pane.active');
            this.element.find('ul.nav.nav-tabs a').addClass('disabled');
            this.footer.removeClass('enabled').
                addClass('modal-visible');

            this.element.find('.tab-pane').removeClass('active');
            this.element.find('.tab-pane.' + modal).addClass('active');
        },

        _showPane: function (modal) {
            this.element.hide();
            this.hideModals();
            $('#subscriber-view-' + modal).removeClass('hidden');
        },

        hideModals: function () {
            this.footer.addClass('enabled').
                removeClass('modal-visible').
                removeClass('send-message-modal-visible').
                removeClass('subscribe-modal-visible').
                removeClass('unsubscribe-modal-visible').
                removeClass('add-metadata-modal-visible');

            this.element.find('ul.nav.nav-tabs a').removeClass('disabled');

            if (this._activeTab) {
                this.element.find('.tab-pane').removeClass('active');
                this._activeTab.addClass('active');
                delete this._activeTab;
            }
        },

        hidePanes: function () {
            $('.top-container').addClass('hidden');
            this.element.show();
        },

        _onSendMessageClick: function () {
            var data = this.options.data();

            data.modals.sendmessage.message('');
            data.modals.sendmessage.subject('');
            data.modals.sendmessage.message.isModified(false);
            this._showPane('send-message');
        },

        _onCancelClick: function () {
            this.hideModals();
            this.hidePanes();
        },

        _onSubscribeClick: function () {
            var data = this.options.data().modals.subscribe;

            data.listId('');
            data.optInMessage(null);
            data.optInSubject(null);
            data.optInFiles([]);
            data.optInCurrentTab('sms');
            data.optInAd(null);
            data.optInSponsorship(null);
            data.optInSmsFallback(null);
            data.confirmMessage(null);
            data.confirmSubject(null);
            data.confirmFiles([]);
            data.confirmCurrentTab('sms');
            data.confirmAd(null);
            data.confirmSponsorship(null);
            data.confirmSmsFallback(null);
            data.subscribedMessage(null);
            data.subscribedSubject(null);
            data.subscribedFiles([]);
            data.subscribedCurrentTab('sms');
            data.subscribedAd(null);
            data.subscribedSponsorship(null);
            data.subscribedSmsFallback(null);

            this._showPane('subscribe');
        },

        _onSubscribeOkClick: function () {
            var data = this.options.data();
            var subscribeModel = data.modals.subscribe;

            if (subscribeModel.isValid()) {
                api.call('messaging.send', {
                        modules: [
                            {
                                type: 'SUBSCRIPTION',
                                params: {
                                    listId: subscribeModel.listId(),
                                    optInType: subscribeModel.optInType(),
                                    optInMessage: subscribeModel.optInMessage(),
                                    confirmMessage:
                                        subscribeModel.confirmMessage(),
                                    subscribedMessage:
                                        subscribeModel.subscribedMessage()
                                }
                            }
                        ],
                        subscribers: [ this.options.data().id() ]
                    }).
                    done(_.bind(function () {
                        msgme.alert.success('Subscribed to list');
                        this._trigger('changed', null,
                            { id: data.id(), widgetId: data.widgetId() });
                    }, this));

                this.hideModals();
            } else {
                subscribeModel.listId.isModified(true);
            }
        },

        _onUnsubscribeClick: function () {
            this.footer.addClass('unsubscribe-modal-visible');
            this.options.data().modals.unsubscribe.listId(null);
            this._showModal('unsubscribe');
        },

        _onUnsubscribeOkClick: function () {
            var data = this.options.data();

            if (data.modals.unsubscribe.isValid()) {
                data.record.unsubscribe({
                        list : data.modals.unsubscribe.listId()
                    }).
                    done(_.bind(function () {
                        if (this.options._onSubscriptionsChange) {
                            this.options._onSubscriptionsChange();
                        }
                        msgme.alert.success('Unsubscribed from list');
                        this._trigger('changed', null,
                            { id: data.id(), widgetId: data.widgetId() });
                    }, this)).
                    fail(api.getRequestFailureFn(null,
                        'subscriber.unsubscribe', {
                            list: data.modals.unsubscribe.listId()
                        }));

                this.hideModals();
            } else {
                data.modals.unsubscribe.listId.isModified(true);
            }
        },

        _onAddMetadataClick: function () {
            var metadata = this.options.data().modals.metadata;

            this.footer.addClass('add-metadata-modal-visible');
            this._showModal('add-metadata');
            metadata.format(null);
            metadata.id(null);
            metadata.validValues(null);
            metadata.value(null);
            metadata.id.isModified(false);
        },

        _onAddMetadataOkClick: function () {
            var data = this.options.data();
            if (data.modals.metadata.isValid()) {
                data.record.metadata.add({
                        id: data.modals.metadata.id(),
                        value: data.modals.metadata.value()
                    }).
                    done(_.bind(function () {
                        if (this.options._onMetadataChange) {
                            this.options._onMetadataChange();
                        }
                        msgme.alert.success('Metadata added');
                        this._trigger('changed', null,
                            { id: data.id(), widgetId: data.widgetId() });
                    }, this)).
                    fail(api.getRequestFailureFn(null,
                        'subscriber.metadata.add', {
                            id: data.modals.metadata.id(),
                            value: data.modals.metadata.value()
                        }));

                this.hideModals();
            } else {
                data.modals.metadata.value.isModified(true);
            }
        },

        _onAddMetadataFieldChange: function () {
            var metadataId = this.options.data().modals.metadata.id();
            var valueEls = this.element.find('.add-metadata .value');

            valueEls.msgme('busy', true);
            if (metadataId) {
                api.call('metadata.fetch', metadataId).
                    done(_.bind(function (metadata) {
                        this.options.data().modals.metadata.validValues(
                            metadata.validValues);
                        this.options.data().modals.metadata.format(
                            metadata.format);
                        valueEls.msgme('busy', false);
                    }, this)).
                    fail(function () {
                        valueEls.msgme('busy', false);
                    });
            }
        },

        _deleteMetadata: function (e) {
            var data = this.options.data();
            var metadataValue =
                $(e.target).closest('.label').find('.metadata-value').html();
            var metadataId = $(e.target).closest('.metadata-id').attr('value');
            var subscriberId = viewmodel.subscriber.id();
            var metadataContainer = this.element.find('.metadata.span5');

            var query = {
                subscriberId: subscriberId,
                id: metadataId,
                value: metadataValue
            };


            msgme.modal.confirm('Are you sure you want to delete metadata: ' +
                metadataValue, { title: 'Delete Metadata' }).
                done(function (value) {
                    if (value) {
                        metadataContainer.msgme('busy', true);
                        api.call('subscribers.removeMetadata', query).
                            done(function () {
                                waterfall.subscribers.fetch(subscriberId).
                                    done(function (subscriberInstance) {
                                        data.metadata(
                                        _.filter(_.map(subscriberInstance.
                                            metadata,
                                            function (metadatum, id) {
                                                if (_.isArray(metadatum)) {
                                                    return {
                                                        id: id,
                                                        name: (viewmodel.
                                                               globals.metadata.
                                                               byId(id) || {}).
                                                               name,
                                                        values: metadatum
                                                    };
                                                }
                                            }), function (val)
                                                { return val; }));
                                    }).
                                    always(function () {
                                        metadataContainer.
                                           msgme('busy', false);
                                    }).
                                    fail(api.getRequestFailureFn(
                                        subscriberId));
                            });
                    }
                });
        },

        destroy: function () {
        }
    });

    var nextId = 0;
    $.msgme.msgme_subscriber_profile.modelFactory =
        function (defaultParams, config) {
            config = config || {};
            config.defaults = _.defaults(config.defaults || {}, { params: {} });
            config.mapping = config.mapping || {};
            config.mapping.local = config.mapping.local || {};
            config.mapping.computed = config.mapping.computed || {};

            return function (data) {
                data = _.defaults(data || {}, _.clone(config.defaults));
                data.params = _.defaults(data.params, _.clone(defaultParams));

                config.mapping.local.msgmeWidgetId = nextId++;
                config.mapping.computed.widgetId = function () {
                    return 'subscriber-profile-' + this.msgmeWidgetId();
                };

                ko.mapping.fromJS(data, _.clone(config.mapping), this);
            };
        };

    return $.msgme.msgme_subscriber_profile;
});

