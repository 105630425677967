define('msgme/util/api',[
    'msgme/underscore',
    'msgme/util/errors',
    'msgme/alert',
    'json!widgets/shared-strings.json',
    'jquery'
], function (_, errors, alert, strings) {
    // FIXME: should use an error code or something instead
    var SESSION_EXPIRED = 'Invalid Session or Session has expired';

    function onFailure(request, jqXHR, status, err) {
        var config = {};

        if (jqXHR.status === 401 &&
            jqXHR.responseText.indexOf(SESSION_EXPIRED) > 0
        ) {
            // don't show error alerts when the user's session times out
            return;
        } else if (jqXHR.status === 0) {
            // don't show error alerts when an XHR is aborted
            console.
                log('XHR failed with status 0', request, jqXHR, status, err);
            return;
        }

        var message = _.sprintf(
            'Call to %s failed.\n%s (%s)',
            request.api, jqXHR.status, jqXHR.statusText);

        var requestConfig;
        try {
            requestConfig = JSON.stringify(request.config);
        } catch (e) {
            requestConfig = 'Circular. Cannot dump.';
        }
        var source = _.sprintf('%s\nAPI: %s\nConfig:%s\nStacktrace: %s',
            location.pathname + location.hash, request.api, requestConfig,
            request.stack.join('\n\t'));

        var response = '';
        var details = status + ' ' + err;

        if (jqXHR && jqXHR.responseText) {
            try {
                response = JSON.parse(jqXHR.responseText);
                details = '\n';
                message += '\n' + response.errorMsg;
            } catch (e) {
                details = '';
            }
            details += _.sprintf('Response:\n%s\nHeaders:\n%s',
                jqXHR.responseText, jqXHR.getAllResponseHeaders());
        }
        config.details = _.sprintf('%s\n\n%s\n%s', source, message, details);

        config.email = strings.supportEmail;
        alert.error(message, config);
    }

    function callAPI(api, config) {
        var path = api.split('.');

        // walk from waterfall to context of call
        var context = _.reduce(path.slice(0, -1), function (o, key) {
            return o[key];
        }, waterfall);
        var method = path.slice(-1);

        if (!context || !context[method]) {
            throw _.sprintf('No such API "%s"', api);
        }

        return context[method](config).
            fail(getRequestFailureFn(this, api, config));
    }

    function getRequestFailureFn(context, api, params) {
        var config = {
            api: api,
            config: params,
            stack: errors.stackTrace().slice(1) // don't include this fn
        };

        return _.bind(onFailure, context, config);
    }

    return {
        call: callAPI,
        getRequestFailureFn: getRequestFailureFn
    };
});

