define('msgme/views/users',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/util/format',
    'msgme/viewmodel',
    'msgme/viewmodel/user',
    'msgme/views/View',
    'msgme/widgets/pager',
    'json!widgets/shared-strings.json'
], function (
    _,
    splash,
    path,
    ko,
    format,
    viewmodel,
    user,
    View,
    Pager,
    sharedStrings
) {
    var view = new View('#users-view');
    var page, pager;
    var cachedUsers;
    user.collection.noResults = ko.observable(false);

    pager = new Pager(user.collection);

    function onUsersSuccess(users) {
        var rows = _.map(users.slice(), function (userInstance) {
            var group = viewmodel.globals.groups.byId(userInstance.group);
            userInstance.groupName = group ? group.name : '';

            var role = viewmodel.globals.roles.byId(userInstance.role);
            userInstance.roleName = role ? role.name : '';

            var lastLogin = userInstance.details ?
                userInstance.details.lastLogin : null;

            if (userInstance.details) {
                userInstance.details.lastLogin =
                    !!lastLogin && !isNaN(new Date(lastLogin)) ?
                        format.uiDate(lastLogin) : 'none';
            }
            
            userInstance.parent = viewmodel.globals.users.oneById(
                userInstance.createdBy) ? viewmodel.globals.users.oneById(
                userInstance.createdBy).name : null;

            return userInstance;
        });

        user.collection.rows(rows);
        pager.update(users);

        splash.hide();
        $('#users-view-main').msgme('busy', false);
    }

    function fetchUsers() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#users-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !user.collection.rows().length) {
            if (!user.collection.pageSize() || !user.collection.pageIndex() ||
                !user.collection.pageCount()) {
                resetViewmodelCollection();
            }

            view.callAPI('users.fetch', {
                    page: page,
                    size: user.collection.pageSize
                }).
                done(function (result) {
                    cachedUsers = result;
                    onUsersSuccess(result);
                });
        } else {
            view.callAPI('users.fetch', {
                    page: page,
                    size: 15
                }).
                done(function (result) {
                    cachedUsers = result;
                    $('#users-view-main').msgme('busy', false);
                });
        }

        viewmodel.globals.users.refresh(true).done(function () {
            instantiateSearchBox();
        });
    }

    function resetViewmodelCollection() {
        user.collection.pageSize(15);
        user.collection.pageCount(-1);
        user.collection.pageIndex(-1);
        user.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var userNames = _.map(viewmodel.globals.users(), function (user) {
            return user.name;
        });

        view.root.find('.search-box').autocomplete({
            source: userNames,
            delay: 100
        });

    }

    $('#users-view').on('click', 'td i.fa.fa-eye', function () {
        var rowEl = $(this).closest('tr');
        var record = user.collection.rows()[rowEl.index()];

        msgme.modal.confirm('Are you sure you want to impersonate user ' +
            record.name + '?', {
            title: 'Impersonate User'
        }).done(_.bind(function (value) {
            if (value) {
                waterfall.impersonate(record.id).
                    done(function () {
                        path.history.pushState(
                            null, null, sharedStrings.sitemap.messages.url);
                        location.reload();
                    });
            }
        }, this));
    });

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        user.collection.noResults(false);
        view.root.find('.search-box').autocomplete('close');
        if (!query) {
            onUsersSuccess(cachedUsers);
        } else {
            results = _.map(viewmodel.globals.users(), function (user) {
                if(user.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return user;
                }
            });

            onUsersSuccess(_.compact(results));

            if (!_.compact(results).length) {
                user.collection.noResults(true);
            }
        }
    });

    view.root.on('click', '.search-flow', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        onUsersSuccess(cachedUsers);
        view.root.find('.search-box').val('');
        user.collection.noResults(false);
    });

    path.map(sharedStrings.sitemap.users.url + '(/page/:page)').to(function () {
        if (!user.collection.pageSize() || !user.collection.pageIndex() ||
            !user.collection.pageCount() || !this.params.page) {
            resetViewmodelCollection();
        }
        view.show();
        page = this.params.page;
        user.collection.isRoot((waterfall.authenticate.isRoot ||
            waterfall.authenticate.isAdmin) &&
            !waterfall.authenticate.impersonated);
        instantiateSearchBox();
        fetchUsers();
    });

    return view;
});


