define('msgme/views/View',[
    'msgme/underscore',
    'msgme/util/api'
],
function (_, api) {
    function View(root, config) {
        config = config || {};
        this.config = _.clone(config);
        this.root = $(root);
        this.hide();
    }

    View.prototype.show = function () {
        this.root.removeClass('hidden');
        this.root.addClass('shown');

        return $.Deferred().resolve();
    };

    View.prototype.callAPI = function callAPI() {
        return api.call.apply(this, arguments);
    };

    View.prototype.getRequestFailureFn = function getRequestFailureFn() {
        return api.getRequestFailureFn.apply(this, arguments);
    };

    View.prototype.hide = function () {
        this.root.removeClass('shown');
        this.root.addClass('hidden');

        return $.Deferred().resolve();
    };

    return View;
});


