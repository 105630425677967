define('msgme/views/flow',[
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/underscore',
    'msgme/util/promises',
    'msgme/util/format',
    'msgme/util/account-features',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/flow',
    'msgme/views/View',
    'msgme/plugins',
    'msgme/alert',
    'lib/emojionearea',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
    'msgme/widgets/flowmodule',
    'msgme/widgets/flowmodule-collectmetadata',
    'msgme/widgets/flowmodule-basictext',
    'msgme/widgets/flowmodule-posttourl',
    'msgme/widgets/flowmodule-dynamiccontent',
    'msgme/widgets/flowmodule-tagmetadata',
    'msgme/widgets/flowmodule-subscription',
    'msgme/widgets/flowmodule-texttowin',
    'msgme/widgets/flowmodule-validation',
    'msgme/widgets/flowmodule-sweeps',
    'msgme/widgets/flowmodule-poll',
    'msgme/widgets/flowmodule-agegate',
    'msgme/widgets/flowmodule-passbook',
    'msgme/widgets/flowmodule-listmanagement',
    'msgme/widgets/flowmodule-dategate',
    'msgme/widgets/flowmodule-sportsfeed',
    'msgme/widgets/flowmodule-daypart',
    'msgme/widgets/datetime',
    'widgets/async-button/index'
],
function (
    splash,
    path,
    ko,
    _,
    promises,
    format,
    accountFeatures,
    viewmodel,
    mapping,
    flow,
    View,
    plugins,
    alert,
    emoji,
    featureFlags,
    sharedStrings
) {
    var view = new View('#flow-view');
    var url = new RegExp(sharedStrings.sitemap.flows.url +
        sharedStrings.sitemap.flows.flowSubUrl);
    var cm = new mapping.CursorModel(waterfall.mobileflows, {
        record: {
            idKey: 'flowId',
            url: waterfall.mobileflows.config.recordConfig.
                saveConfig.url.replace(/:.+$/, ''),
            mapping: flow.mapping(view)
        }
    });
    var disableListManagement = ko.observable(false);
    var isMarketron = ko.observable(false);

    function populate(flowInstance) {
        var emojiEl;

        flow.model(flowInstance);

        // FIXME: doing this in the mapping does not work for some reason
        flow.model().mobileflow.name.extend({
            required: true,
            minLength: 1,
            maxLength: 150
        });
        render();

        splash.hide();
        emojiEl = view.root.find('.keyword-emoji-input');
        emojiEl.emojioneArea({ pickerPosition: 'bottom' });
        emojiEl[0].emojioneArea.on('emojibtn.click', function () {
            updateNewKeyword();
        });
        $('#flow-view').msgme('busy', false);
    }

    /*
     * Takes in a flow name and appends a number if the name is currently
     * in use by comparing it to the current names in viewmodel.globals.flows.
     * It will continue to increment until there is one available.
     *
     * Ex: getCloneName('Foo Bar')
     * returns 'Foo Bar' if name doesn't exist in viewmodel.globals.flows
     *
     * If 'Foo Bar', 'Foo Bar 2', 'Foo Bar 3' exists
     * getCloneName('Foo Bar')
     * returns 'Foo Bar 4'
     */
    function getCloneName(flowName) {
        var index = 2;
        var cloneName = flowName + ' Clone';

        while (viewmodel.globals.flows.oneByName(cloneName)) {
            cloneName = flowName + ' Clone ' + index;
            index++;
        }

        return cloneName;
    }

    function cloneCampaign(flowInstance) {
        var model;
        var mobileFlow;
        var flowName;
        var instanceMobileFlow = flowInstance.mobileflow;
        var instanceModules = flowInstance.modules();

        // clear the module ids since it's a new campaign
        for (var i = 0; i < instanceModules.length; i++) {
            instanceModules[i]().head(null);
        }

        flowName = getCloneName(instanceMobileFlow.name());
        model = cm.create();

        mobileFlow = model.mobileflow;

        mobileFlow.name(flowName);
        mobileFlow.frequency(instanceMobileFlow.frequency());
        mobileFlow.programName(instanceMobileFlow.programName());
        model.modules(flowInstance.modules());

        return model;
    }

    function render() {
        var modules = flow.model().modules();
        var flowModules = view.root.find('.msgme-flowmodule');

        _.each(modules, function (module, index) {
            var flowModule = flowModules.eq(index);
            var widgetName = flowModule.attr('data-widget');

            flowModule[widgetName]({ data: module });
        });

        view.root.find('.collapse').collapse({ toggle: false });
        view.root.on('hide', '.flow-component-container', function () {
            $(this).find('input, select, textarea').blur();
        });

        view.root.find('#flow-view-components').sortable({
            items: '.msgme-flowmodule',
            containment: '#flow-view-components',
            tolerance: 'pointer',
            forceHelperSize: true,
            handle: '.accordion-toggle',
            update: function (event, ui) {
                var prev = ui.item.data('index');
                var newIndex = $(this).find('.msgme-flowmodule').index(ui.item);
                var module;

                if (newIndex !== prev) {
                    // move the module in the UI
                    // XXX: this is still a little finicky sometimes. you may
                    // need to go with the clone, splice, and insert new array
                    // technique.
                    module = flow.model().modules.splice(prev, 1)[0];
                    flow.model().modules.splice(newIndex, 0, module);
                    ui.item.remove();
                }
            }
        });
    }

    function onAddFinished(d, submitted) {
        var componentType = $('.popover.in select.type').val();

        this.popover('hide');
        if (submitted) {
            d.resolve(componentType);
        } else {
            d.reject();
        }
    }

    function getComponentType() {
        var content = view.root.find('.popover.add .content');
        var button = view.root.find('.sidebar button.add');
        var d = new $.Deferred();

        content.find('select.type').val('');

        button.popover({
            html: true,
            content: content.html(),
            placement: 'right',
            trigger: 'manual'
        }).popover('show');

        if (disableListManagement()) {
            $('.popover-content option[value="ADDTOADDITIONALLIST"]').remove();
        }

        if (!isMarketron()) {
            $('.popover-content option[value="SPORTSFEED"]').remove();
        }

        $('.popover button.ok').
            on('click', _.bind(onAddFinished, button, d, true));
        $('.popover button.cancel').
            on('click', _.bind(onAddFinished, button, d, false));
        path.on('navigation', function () {
            button.popover('hide');
        });

        return d.promise();
    }

    function getComponentPosition(type) {
        var d = new $.Deferred();
        var container = view.root.find('.flow-component-container');
        var components = view.root.find('.msgme-flowmodule');
        var helper = view.root.find('#flow-view-components .position .helper');

        function cleanUp() {
            container.find('.helper').remove();
            view.root.find('.sidebar button.add').
                attr('disabled', false);
        }

        if (components.length > 0) {
            view.root.find('.sidebar button.add').attr('disabled', true);

            // add insertion targets for the user to click on
            components.before(helper.html());
            components.last().after(helper.html());

            // cache the index
            container.find('.helper').each(function (index, helper) {
                    $(helper).data('position', index);
                });

            container.one('click', '.helper', function () {
                var index = $(this).data('position');

                cleanUp();
                d.resolve(type, index);
            });
        } else {
            cleanUp();
            d.resolve(type, 0);
        }

        return d.promise();
    }

    function updateFieldLastSavedObservables(modules) {
        _.each(modules, function (module) {
            if (!_.has(module(), 'fieldLastSavedPairs')) { return; }

            _.each(module().fieldLastSavedPairs(), function (pair) {
                pair.fieldLastSaved(pair.field());
            });
        });
    }

    function updateNewKeyword() {
        var model = flow.model();

        _.defer(function () {
            var text = view.root.find('.keyword-emoji-input')[0].
                emojioneArea.getText();

            model.newKeyword(text);
        });
    }

    view.root.on('click', '.sidebar button.add', function () {
        $('.flow-component-container .msgme-flowmodule').msgme('busy', true);
        promises.sequence(
                getComponentType,
                getComponentPosition
            ).
            done(function (type, position) {
                var module = flow.createModule({ type: type });
                var moduleEl;

                flow.model().modules.splice(position, 0, module);
                module().isModified(false);

                moduleEl = view.root.find('.msgme-flowmodule').eq(position);
                moduleEl[moduleEl.attr('data-widget')]({ data: module });
                $('.flow-component-container .msgme-flowmodule').
                    msgme('busy', false);
            }).
            fail(function () {
                var args = _.toArray(args);
                console.error('Failed to add component', args);
                $('.flow-component-container .msgme-flowmodule').
                    msgme('busy', false);
            });
    });

    view.root.on('click', '.sidebar button.save', function () {
        var model = flow.model();
        var pending = model.pending;
        var promise;

        model.mobileflow.duplicateName(false);

        /*
         * Remove this for the time being. Using knockout validation for non
         * supported characters right now
        var invalidChars = [];
        var paramMap = {
            'SUBSCRIPTION': ['optInMessage', 'confirmMessage',
                'subscribedMessage'],
            'BASICTEXT': ['response'],
            'SWEEPS': ['preSweepsMessage', 'response', 'postSweepsMessage',
                'alreadyEnteredMessage'],
            'POLL': ['pollQuestion', 'invalidAnswer'],
            'COLLECTMETADATA': ['requestMessage',
                'responseMessage', 'invalidFormatMessage'],
            'TEXTTOWIN': ['preFlightMessage', 'postFlightMessage',
                'duplicateEntryMessage', 'defaultMessage'],
            'VALIDATION': ['consequentResponse', 'alternativeResponse'],
            'AGEGATE': ['ageRequestMessage', 'invalidFormatMessage',
                'notOfAgeMessage']
        };

        // TODO: there has to be a better way of validating this.
        // not to mention the loops of doom
        _.each(model.modules(), function (module) {
            _.each(paramMap, function (params, key) {
                if (module().type() === key) {
                    _.each(params, function (param) {
                        if (module().params[param]()) {
                            _.each(module().params[param](), function (match) {
                                if (_.isNumber(format.
                                    knownCharCodeAt(match, 0)) &&
                                    format.knownCharCodeAt(match, 0) > 0 &&
                                    format.knownCharCodeAt(match, 0) < 65555) {
                                    return;
                                } else {
                                    invalidChars.push(match);
                                }
                            });
                        }
                    });
                }
            });
        });

        if (!_.isEmpty(invalidChars)) {
            return alert.info(invalidChars.join(', ') +
                sharedStrings.message.badChars);
        }
        */

        _.each(model.modules(), function (module) {
            if (module().type() === 'TEXTTOWIN') {
                module().params.winnerGroups.valueHasMutated();
            }
            if (module().type() === 'POLL') {
                module().params.choices.valueHasMutated();
            }
            if (module().type() === 'DAYPART') {
                module().params.parts.valueHasMutated();
            }
        });


        _.defer(function () {
            if (model.isValid()) {
                cm.fetch({name: model.mobileflow.name()}).
                    done(function (result) {
                        if (result.rows().length === 0 ||
                            result.rows()[0].id() === model.mobileflow.id()
                        ) {
                            promise = model.save().
                                done(function () {
                                    updateFieldLastSavedObservables(
                                        model.modules());

                                    // FIXME: this should come from a 
                                    // resources file
                                    alert.success(
                                        'Campaign saved successfully.');
                                    view.root.find('.in').collapse('hide');

                                    _.each(model.keywords(),
                                        function (keyword) {
                                        keyword.pending(false);
                                    });

                                    viewmodel.globals.flows.refresh(true);
                                    viewmodel.globals.sweeps.refresh(true);
                                    path.history.pushState(null, null,
                                        sharedStrings.sitemap.flows.url + '/' +
                                            model.mobileflow.id());
                                    model.mobileflow.error(false);
                                    // FIXME: pretty sure this is a NOP
                                    model.pending = pending;
                                }).
                                fail(function () {
                                    view.root.msgme('busy', false);
                                }).
                                fail(view.getRequestFailureFn(null,
                                    'mobileflow.save',
                                    model.mobileflow.id()));
                        } else {
                            model.mobileflow.duplicateName(true);
                        }

                        model.pending(promise);
                    }).
                    fail(view.getRequestFailureFn(null, 'mobileflows.fetch',
                                model.mobileflow.name()));
            } else {
                model.isModified(true);

                for (var i = 0; i < model.modules().length; i++) {
                    if (!model.modules()[i]().isValid()) {
                        $('#flow-view .accordion-body').eq(i).collapse('hide');
                        $('#flow-view .accordion-body').eq(i).collapse('show');
                    }
                }
            }
        });
    });

    view.root.on('click', '.accordion-heading i.fa.fa-remove', function (e) {
        var componentEl = $(this).closest('.msgme-flowmodule');
        var index = view.root.find('.msgme-flowmodule').index(componentEl);

        e.preventDefault();
        e.stopPropagation();

        componentEl.addClass('deleting');
        componentEl.find('input, select, textarea').blur();
        flow.model().modules.splice(index, 1);
    });

    view.root.on('keydown', '.new-keyword input', _.debounce(function () {
        flow.model().newKeyword($(this).val());
    }, 300));

    var onNewKeywordValidated = _.debounce(function () {
        var model = flow.model();

        if (model.newKeyword._inflightValidations > 0) {
            onNewKeywordValidated.subscription =
                model.newKeyword.isValidating.subscribe(onNewKeywordValidated);
        } else {
            if (onNewKeywordValidated.subscription) {
                onNewKeywordValidated.subscription.dispose();
                onNewKeywordValidated.subscription = null;
            }

            if (model.newKeyword.isValid()) {
                addKeyword();
                view.root.find('.keyword-emoji-input')[0].
                    emojioneArea.setText('');
            }
        }
    }, 10);

    function addKeyword() {
        var model = flow.model();
        var keyword = _.trim(model.newKeyword());

        if (keyword === '') {
            return;
        }

        if (model.deletedKeywords && _.has(model.deletedKeywords, keyword)) {
            // restoring a previously deleted keyword
            model.keywords.push(model.deletedKeywords[keyword]);
        } else {
            // adding a new keyword
            model.keywords.push(ko.mapping.fromJS({
                pending: true,
                name: keyword
            }));
        }
        setTimeout(function () {
            model.newKeyword('');
            view.root.find('.keywords span:last')[0].scrollIntoView();
            // fix state for firefox and safari
            model.newKeyword.valueHasMutated();
        }, 0);
    }

    view.root.on('click', '.btn.add-keyword', onNewKeywordValidated);

    view.root.on('click', '.keywords .btn.delete', function () {
        var model = flow.model();
        var index = $(this).closest('.keyword').index();
        var keyword = model.keywords.splice(index, 1)[0];

        if (!keyword.pending()) {
            // keep track of pending deletions so the user can re-add them
            model.deletedKeywords = model.deletedKeywords || {};
            model.deletedKeywords[keyword.name()] = keyword;
        }
    });

    view.root.on('blur', '.new-keyword .span2', onNewKeywordValidated);

    view.root.on('change keydown paste', '.keyword-emoji-input', function (e) {
        var model = flow.model();

        if (e.which === 13) {
            e.preventDefault();
            _.defer(function () {
                model.newKeyword(view.root.find('.keyword-emoji-input')[0].
                    emojioneArea.getText());
                onNewKeywordValidated();
            });
        } else {
            updateNewKeyword();
        }
    });

    view.root.on('focus', '[contenteditable="true"]', function () {
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
            var $this = $(this);
            $this.html( $this.html() + '<br>' );
        }
    });

    path.map('/flows/:id').to(function () {
        path.history.replaceState(null, null,
            sharedStrings.sitemap.flows.url + this.params.id);
    });

    path.map(url).to(function (fullPath, isNew, id, isClone) {
        // TODO: hide any existing modules while fetching the new flow
        view.show();
        $('#flow-view').msgme('busy', true);

        featureFlags('disableListManagement').then(function () {
            disableListManagement(true);
        });

        featureFlags('marketronFeatures').then(function () {
            isMarketron(true);
        });

        if (isNew) {
            populate(cm.create());
        } else {
            cm.fetch(id).
                done(function (result) {
                    updateFieldLastSavedObservables(result.modules());
                    populate(isClone ? cloneCampaign(result) : result);
                }).
                fail(view.getRequestFailureFn(null,
                    'mobileflows.fetch', id));
        }
    });

    return view;
});

