
define("json!widgets/dashboard-tour/strings.json", function(){ return {
  "welcome": "Welcome to Channel Insights!",
  "subscriberGrowth": [
    "See all the bumps and dips in your subscriber lists.",
    "Hover over the line graph for more detailed info."
  ],
  "subscriberStatCard": [
    "Track your total subscribers.",
    "Subscribers include mobile numbers that are currently on one or more of your lists."
  ],
  "contactsStatCard": [
    "Track your total contacts.",
    "Contacts include mobile numbers that have either interacted with one of your campaigns or received an SMS from you."
  ],
  "likesStatCard": [
    "View how many likes you have on your Facebook page.",
    "Add multiple pages to view the the number of total likes."
  ],
  "followerStatCard": [
    "View how many Twitter followers you have.",
    "Add multiple accounts to view the total number of followers."
  ],
  "engagementActivity": [
    "See how many people have recently interacted with your campaigns.",
    "Hover over each column to view daily details."
  ],
  "restartTour": "Restart tour"
};});

