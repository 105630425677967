define('msgme/viewmodel/pass',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/util/format',
    'msgme/viewmodel',
    'json!widgets/shared-strings.json'
], function (_, ko, format, viewmodel, sharedStrings) {
    var fileApiSubcomputed = {
        src:  function (root, parent) {
            var value = parent();
            return value ? '/api/v1/file/' + value : '';
        }
    };
    var HEX_COLOR_REGEX = /^#[0-9a-fA-F]{6}$/;
    var mapping = {
        defaults: {
            id: null,
            account: null,
            name: '',
            url: null,
            organization: null,
            dateRestriction: null,
            quantityRestriction: -1,
            lastUpdatedDate: null,
            images: {
                icon: null,
                logo: null,
                strip: null
            },
            noPassbook: {
                action: 'MOBILESITE',
                message: null,
                outOfCouponsMessage:
                    'No more coupons are available for this pass.'
            },
            passbook: {
                type: 'coupon',
                description: '',
                relevantDate: null,
                backgroundColor: '#000000',
                labelColor: '#2DA3FF',
                foregroundColor: '#FFFFFF',
                suppressStripShine: false,
                logoText: 'Waterfall',
                associatedStoreIdentifiers: [],
                barcode: {
                    format: 'PKBarcodeFormatQR',
                    messageType: 'STATIC',
                    message: '1234567890',
                    altTextType: 'STATIC',
                    altText: 'preview'
                },
                coupon: {
                    headerFields: [{label: 'Header', value: 'Head 1'}],
                    primaryFields: [{label: 'Subheadline', value: 'Headline'}],
                    secondaryFields: [{label: 'Secondary Label',
                        value: 'Label 1'}],
                    backFields: [{label: 'Pass Detail Headline',
                        value: '(details go here)'}]
                },
                locations: []
            }
        },

        extend: {
            dateRestriction: { apiDate: null },
            lastUpdatedDate: { apiDate: null }
        },

        local: {
            dateRestrictionChoice: 'UNRESTRICTED'
        },

        computed: {
            adamId: {
                read: function readAdamId(root) {
                    return root.
                        passbook.associatedStoreIdentifiers().join(', ');
                },

                write: function writeAdamId(root, value) {
                    value = value.split(',');

                    value = _.map(value, function (x) {
                        return parseInt(x, 10);
                    });

                    value = _.filter(value, function (x) {
                        return !isNaN(x);
                    });

                    root.passbook.associatedStoreIdentifiers(value);
                }
            },

            previewPayload: {
                read: function readPreviewPayload(root) {
                    var payload = ko.mapping.toJS(root);

                    if (!payload.passbook.barcode.message) {
                        payload.passbook.barcode.message = 'TESTING';
                    }

                    return JSON.stringify(payload);
                },

                deferEvaluation: true
            },

            lockScreenDateTime: {
                read: function readLockScreenDateTime() {
                    return this.passbook.relevantDate.formatted();
                },

                write: function writeLockScreenDateTime(root, value) {
                    this.passbook.relevantDate(!!value ? value : null);
                }
            },

            limitQuantity: {
                read: function readLimitQuantity() {
                    // true if pass payload is dynamic or if payload is static
                    // and a quantity restriction is specified
                    return (this.passbook.barcode.messageType() === 'STATIC' &&
                            this.quantityRestriction() >= 0) ||
                        this.passbook.barcode.messageType() === 'DYNAMICCODE';
                },

                write: function writeLimitQuantity(root, value) {
                    if (this.passbook.barcode.messageType() === 'STATIC') {
                        // if the user checks the box to limit quantity, we
                        // enter a default value of 0; if they uncheck it, we
                        // set it to -1
                        this.quantityRestriction(value ? 0 : -1);
                    }
                }
            },

            dateRestrictionChoice: {
                read: function readDateRestrictionChoice() {
                    return this.dateRestriction() !== null ?
                        'RESTRICTED' : 'UNRESTRICTED';
                },

                write: function writeDateRestrictionChoice(root, value) {
                    var restriction = this.dateRestriction();

                    if (value === 'RESTRICTED' && restriction === null) {
                        this.dateRestriction(Date.now());
                    } else {
                        this.dateRestriction(null);
                    }
                }
            }
        },


        validation: {
            // TODO: check that name is unique
            //
            // This check will be async. Possible implementations:
            // - move the check to the view and use some other mechanism than
            //   isValid
            //   - the problem here is that we need this check elsewhere
            // - define a validator factory that pings an API, calls a handler
            //   on response, and updates a private flag
            //   - the API ping would be debounced
            //   - this is more complicated, but probably is the way to go
            //   - it will require adding functionality to the validation
            //     binding that adds subcomputeds to isValid:
            //       foo.isValid.isUnique()
            name: {
                required: true,
                minLength: 5,
                maxLength: 30
            },
            organization: {
                required: true
            },
            quantityRestriction: {
                required: {
                    onlyIf: function () {
                        return this.parent.limitQuantity() === true;
                    }
                },
                number: {
                    onlyIf: function () {
                        return this.parent.limitQuantity() === true;
                    }
                }
            },
            dateRestriction: {
                required: {
                    onlyIf: function () {
                        return this.parent.
                            dateRestrictionChoice() === 'RESTRICTED';
                    }
                }
            }
        },

        noPassbook: {
            mapping: {
                validation: {
                    message: {
                        validation: {
                            validator : function (val) {
                                return this.parent.action() === 'MOBILESITE' ||
                                    !!val;
                            }
                        }
                    },
                    outOfCouponsMessage: {
                        required: true,
                        minLength: 5,
                        maxLength: 50
                    }
                }
            }
        },

        account: {
            create: function (options) {
                return ko.observable(options.data ||
                    waterfall.authenticate.account);
            }
        },

        images: {
            mapping: {
                subcomputed: {
                    icon: fileApiSubcomputed,
                    logo: fileApiSubcomputed,
                    strip: fileApiSubcomputed
                },
                validation: {
                    icon: {
                        required: true
                    },
                    logo: {
                        required: true
                    },
                    strip: {
                        required: true
                    }
                }
            }
        },

        passbook: {
            mapping: {
                validation: {
                    type: {
                        required: true,
                        equal: 'coupon'
                    },
                    description: {
                        required: true,
                        minLength: 5
                    },
                    backgroundColor: {
                        required: true,
                        pattern: {
                            message: sharedStrings.pass.validation.
                                backgroundColor,
                            params: HEX_COLOR_REGEX
                        }
                    },
                    foregroundColor: {
                        pattern: {
                            message: sharedStrings.pass.validation.
                                foregroundColor,
                            params: HEX_COLOR_REGEX
                        }
                    },
                    labelColor: {
                        pattern: {
                            message: sharedStrings.pass.validation.labelColor,
                            params: HEX_COLOR_REGEX
                        }
                    },
                    logoText: {
                        maxLength: 30
                    }
                },

                extend: {
                    relevantDate: { apiDate: null }
                },

                barcode: {
                    mapping: {
                        subcomputed: {
                            message: {
                                statik: {
                                    read: function () {
                                        return this.messageType() === 'STATIC' ?
                                            this.message() : null;
                                    },

                                    write: function (root, parent, value) {
                                        if (this.messageType() === 'STATIC') {
                                            this.message(value);
                                        }
                                    }
                                },

                                dynamic: {
                                    read: function () {
                                        var result = this.messageType() ===
                                            'DYNAMICCODE' ?
                                            this.message() : null;
                                        return result;
                                    },

                                    write: function (root, parent, value) {
                                        if (this.messageType() ===
                                            'DYNAMICCODE'
                                        ) {
                                            this.message(value);
                                        }
                                    }
                                }
                            }
                        },

                        validation: {
                            message: {
                                required: true
                            },
                            altTextType: {
                                validation: {
                                    validator: function (val) {
                                        // FIXME: why does this only work async?
                                        return val !== 'DYNAMICCODE' ||
                                            this.parent.messageType() ===
                                                'DYNAMICCODE';
                                    }
                                }
                            },
                            altText: {
                                validation: {
                                    validator: function (val) {
                                        return !this.parent.altTextType() ||
                                            !!val;
                                    }
                                }
                            }
                        }
                    }
                },

                coupon: {
                    mapping: {
                        headerFields: {
                            mapping: {
                                defaults: {
                                    type: 'string',
                                    label: '',
                                    value: ''
                                },

                                validation: {
                                    value: {
                                        required: true
                                    }
                                }
                            }
                        },
                        primaryFields: {
                            mapping: {
                                defaults: {
                                    type: 'string',
                                    label: '',
                                    value: ''
                                },

                                validation: {
                                    value: {
                                        required: true
                                    }
                                }
                            }
                        },
                        secondaryFields: {
                            mapping: {
                                defaults: {
                                    type: 'string',
                                    label: '',
                                    value: '',
                                    textAlignment: 'PKTextAlignmentLeft'
                                },

                                validation: {
                                    value: {
                                        required: true
                                    }
                                }
                            }
                        },
                        backFields: {
                            mapping: {
                                defaults: {
                                    type: 'string',
                                    label: '',
                                    value: ''
                                },

                                validation: {
                                    value: {
                                        required: true
                                    }
                                }
                            }
                        }
                    }
                },

                locations: {
                    mapping: {
                        defaults: {
                            latitude: '',
                            longitude: '',
                            relevantText: ''
                        },

                        validation: {
                            latitude: {
                                validation: {
                                    validator: function (val) {
                                        return !isNaN(parseFloat(val));
                                    },
                                    message:
                                        sharedStrings.pass.validation.latitude
                                }
                            },
                            longitude: {
                                validation: {
                                    validator: function (val) {
                                        return !isNaN(parseFloat(val));
                                    },
                                    message:
                                        sharedStrings.pass.validation.longitude
                                }
                            },
                            relevantText: {
                                required: {
                                    onlyIf: function () {
                                        var latitude = this.parent.latitude;
                                        var longitude = this.parent.latitude;

                                        if (latitude() || longitude()) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                },
                                minLength: 5,
                                maxLength: 50
                            }
                        }
                    }
                }
            }
        }
    };

    var result = {
        mapping: mapping,
        model: ko.observable(ko.mapping.fromJS({}, mapping)),
        collection: {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('passes/page')
        }
    };

    viewmodel.pass = result.model;
    viewmodel.passes = result.collection;

    ko.validation.group(result.model);

    return result;
});

