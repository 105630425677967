define('msgme/widgets/flowmodule',[
    'jquery',
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/widget',
    'msgme/viewmodel'
],
function ($, _, ko, Widget, viewmodel) {
    $.widget('msgme.msgme_flowmodule', Widget, {
        options: {
            widgetEventPrefix: 'flowmodule-',
            saveEnabled: true,
            cancelEnabled: true,
            data: null
        },

        _create: function () {
            Widget.prototype._create.apply(this);

            var root = this.element;

            this.on('click', 'button.ok', '_onOkClick');
            this.on('click', 'button.cancel', '_onCancelClick');

            root.on(this.widgetEventPrefix + 'saveenabled-change',
                this._onOkEnabledChange);
            root.on(this.widgetEventPrefix + 'cancelenabled-change',
                this._onCancelEnabledChange);

            this.option('saveEnabled', this.options.saveEnabled);
            this.option('cancelEnabled', this.options.cancelEnabled);
        },

        _onOkEnabledChange: function (e, data) {
            $(this).find('button.save').attr('disabled', !data);
        },

        _onCancelEnabledChange: function (e, data) {
            $(this).find('button.cancel').attr('disabled', !data);
        },

        _onOkClick: function () {
            this._trigger('submit');
        },

        _onCancelClick: function () {
            this._trigger('cancel');
        },

        destroy: function () {
            this.off('click', 'button.ok', '_onOkClick');
            this.off('click', 'button.cancel', '_onCancelClick');

            this.element.off(this.widgetEventPrefix + 'saveenabled-change',
                this._onOkEnabledChange);
            this.element.off(this.widgetEventPrefix + 'cancelenabled-change',
                this._onCancelEnabledChange);
        }
    });

    var nextId = 0;
    $.msgme.msgme_flowmodule.modelFactory = function (defaultParams, config) {
        config = config || {};
        config.defaults = _.defaults(config.defaults || {}, { params: {} });
        config.mapping = config.mapping || {};
        config.mapping.local = config.mapping.local || {};
        config.mapping.computed = config.mapping.computed || {};

        return function (data) {
            data = _.defaults(data || {}, _.clone(config.defaults));
            data.params = _.defaults(data.params, _.clone(defaultParams));

            config.mapping.local.msgmeWidgetId = nextId++;
            config.mapping.computed.widgetId = function () {
                return viewmodel.flow().mobileflow.id() + '-' +
                    this.msgmeWidgetId();
            };

            return ko.mapping.fromJS(data, _.clone(config.mapping), this);
        };
    };

    return $.msgme.msgme_flowmodule;
});

