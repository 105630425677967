define('widgets/async-button/index',[
    'msgme/underscore',
    'msgme/ko',
    './../spinner/index',
    'text!./template.html'
], function (_, ko, Spinner, template) {
    ko.bindingHandlers.asyncButton = {
        init: function (el, valueAccessor) {
            var config = valueAccessor() || {};
            var pending = config.pending || ko.observable(true);
            var origHtml = $(el).html();
            var $el = $(el).html(template);
            var $orig = $el.find('.async-button-orig-content').
                html(origHtml);
            var $invisible = $el.find('.async-button-invisible-content').
                html(origHtml);
            var spinner = new Spinner(_.extend({
                radius: 5,
                width: 2,
                length: 5,
                lines: 10
            }, config.spinner));

            function showSpinner() {
                $el.prop('disabled', true);
                $orig.hide();
                $invisible.show();
                spinner.spin(el);
            }

            function hideSpinner() {
                $el.prop('disabled', false);
                $orig.show();
                $invisible.hide();
                spinner.stop();
            }

            function onPendingChange(val) {
                if (val && _.isFunction(val.state)) {
                    // if val is a promise, then show the spinner only if it's
                    // pending
                    if (val.state() === 'pending') {
                        showSpinner();
                        val.always(hideSpinner);
                    } else {
                        hideSpinner();
                    }
                } else {
                    // otherwise only show the spinner if pending() is truthy
                    if (val) {
                        showSpinner();
                    } else {
                        hideSpinner();
                    }
                }
            }

            pending.subscribe(onPendingChange);
            onPendingChange(pending());
        }
    };
});

