define('msgme/alert',[
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/underscore',
    'json!widgets/shared-strings.json'
],
function (ko, viewmodel, _, strings) {
    var defaultAlertConfig = {
        severity: 'warning',
        summary: 'Warning',
        details: '',
        timeout: null,
        email: false
    };
    var DEFAULT_EMAIL_ADDRESS = strings.supportEmail;
    var $container;

    // the 2 pieces of state for the alerts are:
    //
    //  - alert: the observable containing the data for the currently-displayed
    //      alert
    //  - alerts: the queue of alerts that will be displayed one at a time
    var alert = viewmodel.alert = ko.observable();
    var alerts = [];

    alert.subscribe(function () {
        var a = alert();
        if (a) {
            // defer until the ko binding has populated the element
            _.defer(function () {
                $container.find('.alert').addClass('pop-in');
            });

            if (a.timeout) {
                setTimeout(alert.remove, a.timeout);
            }
        }
    });

    alert.remove = function() {
        $container.find('.alert').removeClass('pop-in').
            on($.support.transition.end, function () {
                alert(alerts.shift());
            });
    };

    // enqueue an alert message to show up after the current and pending alerts
    // have been dismissed or timed out
    function addAlert(message, config) {
        var a = _.extend({
            id: _.uniqueId(),
            message: message,
        }, defaultAlertConfig, config);

        $container = $container || $('#alert-container .alert-container');

        // details and email are no longer displayed in the alert, but we'll
        // keep them around in case we want to send them to google analytics or
        // something for debugging
        if (a.severity === 'warning') {
            a.details =
                'Version: ' + MSGME_VERSION + '\n' + (a.details || '');
        }

        if (a.email) {
            a.email = {
                address: _.isString(a.email) ? a.email :
                    a.email.address || DEFAULT_EMAIL_ADDRESS
            };
            a.email.href = _.sprintf('mailto:%s?subject=%s&body=%s',
                    a.email.address,
                    encodeURIComponent(_.sprintf('Msgme 3.0 %s: %s',
                        a.summary, a.message.split('\n')[0])),
                    encodeURIComponent(a.message));
        }

        alerts.push(a);

        if (!alert()) {
            alert(alerts.shift());
        }
    }

    var defaultErrorConfig = _.defaults({
        severity: 'error',
        summary: 'Error',
        timeout: null
    }, defaultAlertConfig);

    addAlert.error = function (message, config) {
        config = _.defaults(config || {}, defaultErrorConfig);
        config.details =
            'Version: ' + MSGME_VERSION + '\n' + (config.details || '');
        return addAlert(message, config);
    };

    var defaultSuccessConfig = _.defaults({
        severity: 'success',
        summary: 'Success',
        timeout: 2000
    }, defaultAlertConfig);

    addAlert.success = function (message, config) {
        config = _.defaults(config || {}, defaultSuccessConfig);
        return addAlert(message, config);
    };


    var defaultInfoConfig = _.defaults({
        severity: 'info',
        summary: 'Info',
        timeout: null
    }, defaultAlertConfig);

    addAlert.info = function (message, config) {
        config = _.defaults(config || {}, defaultInfoConfig);
        return addAlert(message, config);
    };

    return addAlert;
});

