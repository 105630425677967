define('msgme/widgets/flowmodule-sweeps',[
    'jquery',
    'msgme/ko',
    'msgme/underscore',
    'msgme/util/format',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-sweeps',
    'widgets/new-list-modal/index'
],
function ($, ko, _, format, flowmodule, flowmoduleSweeps) {
    $.widget('msgme.msgme_flowmodule_sweeps', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-sweeps-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);

            var data = this.options.data();

            _.defer(function () {
                data.params.startDate.valueHasMutated();
                data.params.endDate.valueHasMutated();
            });

            this.on('change', '.list-selection', '_onListChange');

            data.params.listId.subscribe(function(oldValue) {
                data.previousList(oldValue);
            }, this, 'beforeChange');
        },

        _onListChange: function (e) {
            var data = this.options.data();

            if (e.target.value === 'new') {
                data.params.listId(data.previousList());
                this._openModal(e);
            } else if (e.target.value === 'placeholder') {
                data.params.listId(data.previousList());
            }
        },

        _openModal: function () {
            var $newListEl = this.element.find('.new-list-container');

            $newListEl.msgme_new_list_modal('show');
        }
    });


    var config = {mapping: flowmoduleSweeps.mapping};

    $.msgme.msgme_flowmodule_sweeps.Model = flowmodule.modelFactory({}, config);

    return $.msgme.msgme_flowmodule_sweeps;
});

