define('msgme/views/message',[
    'msgme/splash',
    'msgme/path',
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/util/api',
    'msgme/util/format',
    'msgme/alert',
    'msgme/viewmodel/message',
    'msgme/viewmodel/audience',
    'msgme/viewmodel/query-filter',
    'msgme/views/View',
    'msgme/util/feature-flags',
    'msgme/util/is-mms-enabled',
    'json!widgets/shared-strings.json',
    'json!msgme/util/pmi-smut-list.json',
    'widgets/sms-message-body/index',
    'widgets/twitter-message-body/index',
    'widgets/facebook-message-body/index',
    'widgets/mobile-message-body/index',
    'widgets/push-message-body/index',
    'widgets/audience-row/index',
    'widgets/datetimepicker/index',
    'widgets/message-preview/index',
    'widgets/select/index',
    'widgets/async-button/index',
    'widgets/previous-messages/index',
    'widgets/future-messages/index'
],
function (
    splash,
    path,
    _,
    ko,
    viewmodel,
    mapping,
    api,
    format,
    alert,
    messageModel,
    audienceModel,
    queryfilter,
    View,
    flags,
    isMmsEnabled,
    sharedStrings,
    smutList
) {
    var view = new View('#message-view');
    var url = sharedStrings.sitemap.messages.url;
    var cm = new mapping.CursorModel(waterfall.broadcasts, {
        record: {
            mapping: messageModel.mapping
        }
    });
    var list = '1';
    var smartList = '2';

    // FIXME: this fucking sucks. it should be done with a binding
    function onTargetAudiencesChange(targetAudiences) {
        _.defer(function () {
            var message = viewmodel.message();

            if (!message) {
                return;
            }

            // for each channel
            view.root.find('.audiences .channel-audiences').each(function () {
                var $el = $(this);
                var channel = $el.data('channel');
                var type = $el.data('channel') === 'smartlists' ? 'sms' :
                    $el.data('channel');
                var selector = '.' + type + '-message-body-container .' +
                    type + '-message-body';
                var widgetName = 'msgme_' + type + '_message_body';

                if (!targetAudiences[channel] ||
                    targetAudiences[channel].length < 1
                ) {
                    return;
                }

                // find the audience rows and instantiate them
                $el.find('.audience-row').
                    each(function (index, rowEl) {
                        var $rowEl = $(rowEl);
                        var audience = _.find(viewmodel.message().content(),
                            function (content) {
                                return content.channel() === channel;
                            }).embeddedAudience()[index];

                        $rowEl.msgme_audience_row({
                            data: ko.observable(audience),
                            model: {
                                viewWidth: message.viewWidth,
                                currentChannel: ko.observable(channel)
                            }
                        });
                    });

                view.root.find(selector)[widgetName]({
                    data: ko.observable(message.content.byChannel()[channel]),
                    model: {
                        accountFeaturesKey: 'fixedBroadcastMessage'
                    }
                });

                // find the message previews and instantiate them
                var previewSelector =
                    '.message-preview-container[data-channel=' + channel + ']';
                var channelPreviewConfig = {
                    sms: {
                        messageName: 'SMS',
                        iconClass: 'fa fa-mobile-phone',
                        targetName: 'subscribers'
                    },
                    twitter: {
                        messageName: 'Tweet',
                        iconClass: 'fa fa-twitter',
                        targetName: 'followers'
                    },
                    facebook: {
                        messageName: 'Wall post',
                        iconClass: 'fa fa-facebook',
                        targetName: 'fans'
                    },
                    push: {
                        messageName: 'Notification',
                        iconClass: 'fa fa-exclamation-sign',
                        targetName: 'subscribers'
                    },
                    smartlists: {
                        messageName: 'SMS',
                        iconClass: 'fa fa-mobile-phone',
                        targetName: 'subscribers'
                    },
                };
                view.root.find(previewSelector).
                    msgme_message_preview(_.extend({
                        data: ko.observable(
                            message.content.byChannel()[channel]),
                        audiences: message.channels.byId()[channel].audiences,
                        model: {
                            timezoneStatus: message.timezoneStatus,
                            sendAt: message.sendAt
                        }
                    }, channelPreviewConfig[channel]));
            });

            // show the disabled placeholder message body if there aren't any
            // audiences
            view.root.find('.no-audiences .sms-message-body').
                each(function (i, el) {
                    $(el).msgme_sms_message_body({
                            data: ko.observable(ko.mapping.fromJS({},
                                messageModel.mapping.content.mapping))
                        }).
                        msgme_sms_message_body('option', 'viewmodel').
                        entirelyDisabled(true);
                });
            message.selectedChannel.valueHasMutated();
        });
    }

    function onSelectedChannelChange(channel) {
        var $channel =
            view.root.find('.channel-audiences[data-channel=' + channel + ']');

        // clear selected state for audiences in all other channels
        view.root.
            find('.channel-audiences').
            not($channel).
            find('.audience-row').
            each(function () {
                $(this).msgme_audience_row('option', 'viewmodel').
                    selected(false);
            });

        // set the same selected state for all audiences in this channel
        $channel.
            find('.audience-row').
            each(function () {
                $(this).msgme_audience_row('option', 'viewmodel').
                    selected(true);
            });

        // set the channel selection button
        view.root.
            find('.channel-selection .btn[data-channel=' + channel + ']').
            button('toggle');
    }

    function onImageChange(evt, image) {
        _.defer(function () {
            var viewmodel;
            view.root.find('.message-body-container .file-uploader').
                // create a list of file-uploader widget viewmodels, saving a
                // reference to the last one whose image ID matches the new
                // value
                map(function (i, el) {
                    var vm = $(el).parent().
                        msgme_file_uploader('option', 'viewmodel');
                    if (vm.file() && vm.file().id === image) {
                        viewmodel = vm;
                    }
                    return vm;
                }).
                // set the rest of the image values appropriately
                each(function (i, vm) {
                    vm.data(image);
                    if (viewmodel) {
                        vm.file(viewmodel.file());
                    }
                });
        });
    }

    function populate(message, channels) {
        // reset select2 state on load
        _.defer(function () {
            view.root.find('.add-list-input')[0].selectize.clear();
        });

        if (message.sendAt()) {
            message.cachedSendAt(message.sendAt());
        }

        _.each(channels, function (channel) {
            message.channels.push(ko.mapping.fromJS(channel,
                messageModel.mapping.local.mapping.channels.mapping));
        });

        _.each(message.content(), function (content) {
            var channel;

            if (content.audience().length) {
                if (viewmodel.globals.smartlists.
                    oneById(content.audience()[0].id())) {
                    content.channel('smartlists');
                }
            }

            channel = content.channel();

            if (channel === 'smartlists') {
                _.each(content.audience(), function (audience, index) {
                    message.content()[0].embeddedAudience()[index].listName =
                        ko.observable(audience.description());
                });
            } else {
                _.each(content.embeddedAudience(), function (audience) {
                    var currentChannel = _.find(message.channels(),
                        function (chan) {
                        if (channel === 'sms') {
                            return chan.name() === 'Mobile';
                        } else if (channel === 'smartlists') {
                            return chan.name() === 'Smart Segments';
                        } else {
                            return chan.name().toLowerCase() === channel;
                        }
                    });
                    var currentAudience = _.find(
                        currentChannel.audiences(), function (aud) {
                            return aud.id() === audience.lists()[0];
                        });
                    var listName;
                    var qfd = audience.queryFilterDetails.details;

                    // if there are more than one list in the array, assume that
                    // it is for all lists
                    if (audience.lists().length > 1) {
                        listName = 'All Lists';
                    } else {
                        listName = currentAudience ? currentAudience.name() :
                            '[list no longer exists]';
                    }

                    audience.listName = ko.observable(listName);

                    if (qfd().length && !qfd()[0].details) {
                        var details = [];

                        _.each(qfd(), function (detail) {
                            details.push({
                                operator: ko.observable('or'),
                                details: ko.observableArray([detail])
                            });
                        });

                        qfd(details);
                    }

                    _.each(audience.queryFilterDetails.details(),
                        function (d, i) {
                        _.each(d.details(), function (dd, j) {
                            if (!dd.hasOwnProperty('isValid')) {
                                audience.queryFilterDetails.details()[i].
                                    details()[j] =
                                        ko.mapping.fromJS(dd,
                                            queryfilter.mapping.
                                            queryFilterDetails.mapping);
                            }
                        });
                    });
                });
            }
        });

        // listen for changes to targetAudiences
        message.targetAudiences.subscribe(onTargetAudiencesChange);

        // listen for changes to selectedChannel
        message.selectedChannel.subscribe(onSelectedChannelChange);

        message.state('compose');
        viewmodel.message(message);

        if (viewmodel.message().id()) {
            var smsContent = viewmodel.message().content.byChannel().sms;

            if (smsContent) {
                _.each(smsContent.slices(), function (slice, index) {
                    smsContent.slices()[index].val = slice.value();
                });
            }
        }

        _.defer(function () {
            view.root.find('.compose .schedule').msgme_datetimepicker({
                data: message.sendAt,
                startDate: new Date(),
                model: {
                    placeholder: sharedStrings.broadcast.schedule.placeholder
                }
            });

            // re set some values after load
            if (viewmodel.message() && viewmodel.message().id()) {
                message.sendAt.valueHasMutated();
                if (view.root.find('.audience-row-bar')[0]) {
                    view.root.find('.audience-row-bar')[0].click();
                }
                if (smsContent) {
                    _.each(smsContent.slices(), function (slice, index) {
                        smsContent.slices()[index].value(slice.val);
                    });
                }
            }
        });

        splash.hide();
        view.show();
    }

    function addAudience(channel, audience) {
        var message = viewmodel.message();
        var channelContent = message.content.byChannel()[channel];
        var embeddedAudience;
        var qfd;
        var currentChannel = _.find(message.channels(), function (chan) {
            if (channel === 'sms') {
                return chan.name() === 'Mobile';
            } else if (channel === 'smartlists') {
                return chan.name() === 'Smart Segments';
            } else {
                return chan.name().toLowerCase() === channel;
            }
        });
        var currentAudience = _.find(
            currentChannel.audiences(), function (aud) {
                return aud.id() === audience;
            });
        var listName = audience === 'ALL' ? 'All Lists' :
            currentAudience.name();
        var audienceDetails = [];

        if (audience === 'ALL') {
            audience = _.map(viewmodel.globals.lists(), function (list) {
                return list.id;
            });
        }

        
        if (currentChannel.id() === 'smartlists') {
            var list = viewmodel.globals.smartlists.oneById(audience);
            
            if (list) {
                embeddedAudience = list;
                embeddedAudience.count = null;
                embeddedAudience.listName = list.name;
            }
        } else {
            if (channel === 'sms' && channelContent) {
                _.each(channelContent.slices(), function (slice) {
                    if (slice.currentTab() === 'mms') {
                        audienceDetails = [{
                            value: sharedStrings.message.mmsSegmentationValue,
                            metadata: '4ec0a3dc0364de64869d93c1',
                            operator: 'in'
                        }];
                    }
                });
            }

            embeddedAudience = {
                queryFilterDetails: {
                    operator: 'and',
                    details: audienceDetails
                },
                lists: _.isArray(audience) ? audience : [audience],
                description: null,
                listName: listName,
                count: null
            };
        }

        if (!channelContent) {
            // a content item for this channel does not exist, so create one
            channelContent = ko.mapping.fromJS({
                channel: channel,
                embeddedAudience: [embeddedAudience]
            }, messageModel.mapping.content.mapping);

            if (currentChannel.id() === 'smartlists') {
                qfd = channelContent.embeddedAudience()[0].
                    queryFilterDetails;

                _.each(qfd.details(), function (detail, index) {
                    qfd.details()[index] = ko.mapping.toJS(detail);
                    qfd.details()[index] = ko.mapping.fromJS(detail,
                        queryfilter.mapping.queryFilterDetails.mapping);
                });
            }

            message.content.push(channelContent);
        } else {
            if (currentChannel.id() === 'smartlists') {
                qfd = embeddedAudience.queryFilterDetails;

                _.each(qfd.details, function (detail, index) {
                    if (!ko.isObservable(qfd.details[index])) {
                        qfd.details[index] = ko.observable(
                            ko.mapping.fromJS(detail,
                            queryfilter.mapping.queryFilterDetails.mapping));
                    }
                });
            }
            channelContent.embeddedAudience.push(
                ko.mapping.fromJS(embeddedAudience));
        }

        // todo add duplicate check

        _.defer(function () {
            message.selectedChannel(channel);

            // if we're adding a row that allows image uploads, we want the
            // image to match the existing images, so trigger a change so that
            // the images are sync'ed to the existing selection
            _.find(viewmodel.message().content(), function (content) {
                if (content.audience()[0] !== audience && content.image()) {
                    onImageChange(null, content.image());
                    return true;
                }
            });
        });
    }

    function resetPersistedState(message) {
        var defaultsClone = $.extend(true, {}, messageModel.mapping.defaults);

        _.each(defaultsClone, function (value, prop) {
            message[prop](value);
        });
    }

    view.root.on('click', '.cancel-link', function () {
        var message = viewmodel.message();
        var id = message.id();
        var url = sharedStrings.sitemap.scheduled_messages.url;

        if (message.cachedSendAt() < Date.create()) {
            msgme.alert.info('The scheduled time for this broadcast has ' +
                'passed. Please choose a new time or send now.');
        } else {
            api.call('broadcasts.state', {
                id: id,
                state: 'RESCHEDULE'
            }).
                then(function () {
                    path.history.pushState(null, null, url);
                });
        }
    });

    function addCarrierSegmentation(audience) {
        audience.push({
            details: ko.observable([ko.mapping.fromJS({
                metadata: '4ec0a3dc0364de64869d93c1',
                operator: 'in',
                value: sharedStrings.message.mmsSegmentationValue,
                radius: null
            }, queryfilter.mapping.queryFilterDetails.
                mapping)]),
            operator: ko.observable('and')
        });
    }

    view.root.on('click', 'li[data-tab="mms"]', function () {
        if (!isMmsEnabled()) {
            return;
        }
        var message = viewmodel.message();
        var metadataValue = sharedStrings.message.mmsSegmentationValue;
        var metadataId = '4ec0a3dc0364de64869d93c1';

        _.each(message.content(), function (content) {
            if (content.channel() === 'sms') {
                _.each(content.embeddedAudience(), function (aud) {
                    var hasCarrierSegmentation = false;

                    if (!aud.queryFilterDetails.details().length) {
                        addCarrierSegmentation(aud.queryFilterDetails.details);
                        return;
                    }
                    _.each(aud.queryFilterDetails.details(), function (d) {
                        _.each(d.details(), function (detail) {
                            if (detail.metadata() === metadataId &&
                                detail.value() === metadataValue) {
                                hasCarrierSegmentation = true;
                                return;
                            }
                        });
                    });
                    if (!hasCarrierSegmentation) {
                        addCarrierSegmentation(
                            aud.queryFilterDetails.details);
                    }
                });
            }
        });
    });

    view.root.on('click', '.audience-selection .add', function () {
        var message = viewmodel.message();
        var $selectedAudience = $('.add-list-input');
        var currentChannel;

        if (!$selectedAudience) {
            return; // FIXME: not sure this is necessary
        }

        var audiences = $selectedAudience.val();
        var hasSms = false;
        var hasSmartlist = false;

        _.each(audiences, function(audience) {
            if (/^(?:\/[^\/]+)+/.test(audience)) {
                // if the value is a URL, navigate to it
                path.history.pushState(null, null, audience);
                return;
            }

            if (audience === 'ALL') {
                addAudience('sms', audience);
                return;
            }

            if (viewmodel.globals.lists.oneById(audience)) {
                if (hasSmartlist) {
                    return;
                }

                currentChannel = 'sms';
                hasSms = true;
            } else if (viewmodel.globals.smartlists.oneById(audience)) {
                if (hasSms) {
                    return;
                }

                currentChannel = 'smartlists';
                hasSmartlist = true;
            } else {
                return;
                /* we'll be removing social channels so this isn't necessary
                 * but just keeping it here for now
                 *
                _.each(message.channels(), function (channel) {
                    if (channel.name() === 'Facebook') {
                        _.each(channel.audiences(), function (aud) {
                            if (aud.id() === audience) {
                                currentChannel = 'facebook';
                            }
                        });
                    } else if (channel.name() === 'Twitter') {
                        _.each(channel.audiences(), function (aud) {
                            if (aud.id() === audience) {
                                currentChannel = 'twitter';
                            }
                        });
                    };
                });
                */
            }

            addAudience(currentChannel, audience);
        });
        view.root.find('.add-list-input')[0].selectize.clear();
        message.selectedAudience(null);
    });

    view.root.on('deleted', '.audiences .audience-row', function () {
        var $el = $(this);
        var index = $el.index();
        var $channel = $el.closest('.channel-audiences');
        var message = viewmodel.message();
        var content = message.content;
        var channelId = $channel.data('channel');
        var channel = content.byChannel()[channelId];
        var channels;

        if (channel.embeddedAudience().length > 1) {
            // remove the audience
            channel.embeddedAudience.splice(index, 1);
        } else {
            // remove the content item if it will have no audiences
            content.remove(channel);

            // select another channel
            channels = message.targetAudiences().channels;
            if (channels.length > 0) {
                message.selectedChannel(channels[0]);
            }
        }
    });

    view.root.on('selected', '.audiences .audience-row', function () {
        var $channel = $(this).closest('.channel-audiences');

        viewmodel.message().selectedChannel($channel.data('channel'));
    });

    view.root.on('click', '.btn.compose', function () {
        var message = viewmodel.message();

        message.state('compose');
    });

    view.root.on('click', '.btn.review', function () {
        var message = viewmodel.message();
        var smsContent = message.content.byChannel().sms;
        var smartlistContent = message.content.byChannel().smartlists;
        var banned = [];
        var invalidChars = [];
        var regex;
        var msgContent = smsContent || smartlistContent;
        var isQueryVaild = true;
        var countQuery = [];

        // force re evaluation of details computed so that the validation
        // will trigger
        if (msgContent) {
            _.each(msgContent.embeddedAudience(), function (aud) {
                aud.queryFilterDetails.details.valueHasMutated();

                _.each(aud.queryFilterDetails.details(), function (detail) {
                    _.each(detail.details(), function (d) {
                        if (d.isValid && !d.isValid()) {
                            message.isModified(true);
                            isQueryVaild = false;
                        }
                    });
                });
            });
        }

        if (message.isValid() && isQueryVaild) {
            _.each(message.content(), function (contentItem) {
                _.each(contentItem.slices(), function (slice) {
                    if (slice.currentTab() === 'mms') {
                        return;
                    }
                    if (slice.subject()) {
                        _.each(slice.subject(), function (char) {
                            if (_.isNumber(format.knownCharCodeAt(char, 0)) &&
                                format.knownCharCodeAt(char, 0) >= 0 &&
                                format.knownCharCodeAt(char, 0) <= 65535) {
                                return;
                            } else {
                                invalidChars.push(char);
                            }
                        });
                    }
                    if (slice.message()) {
                        _.each(slice.message(), function (char) {
                            if (_.isNumber(format.knownCharCodeAt(char, 0)) &&
                                format.knownCharCodeAt(char) >= 0 &&
                                format.knownCharCodeAt(char) <= 65535) {
                                return;
                            } else {
                                invalidChars.push(char);
                            }
                        });
                    }
                });
            });
            
            if (!_.isEmpty(invalidChars)) {
                return alert.info(invalidChars.join(', ') +
                    sharedStrings.message.badChars);
            }

            if (message.isPapaMurphys()) {
                _.each(message.content(), function (contentItem) {
                    _.each(contentItem.slices(), function (slice) {
                        _.filter(smutList, function (word) {
                            regex = new RegExp('\\b' + word + '\\b', 'i');
                            if (slice.message().search(regex) > -1) {
                                banned.push(word);
                            }
                        });
                    });
                });

                if (!_.isEmpty(banned)) {
                    return alert.info(sharedStrings.message.bannedWords +
                        banned.join(', '));
                }
            }


            if (message.targetAudiences().smartlists) {
                if (message.targetAudiences().smartlists.length > 1) {
                    smartlistContent.count('(...)');
                    countQuery = [];
                    _.each(smartlistContent.embeddedAudience(), function (aud) {
                        countQuery.push(ko.mapping.toJS(aud));
                    });
                    view.callAPI('subscribers.count',
                        JSON.stringify(countQuery)).
                            done(function (count) {
                                smartlistContent.count(count);
                            });
                } else if (message.targetAudiences().smartlists.length === 1) {
                    smartlistContent.count(
                        message.targetAudiences().smartlists[0].count());
                }
            }

            if (message.targetAudiences().sms) {
                if (message.targetAudiences().sms.length > 1 ||
                    message.targetAudiences().sms.length === 1 &&
                    message.targetAudiences().sms[0].queryFilterDetails.
                    details().length > 0) {
                    smsContent.count('(...)');
                    countQuery = [];
                    _.each(smsContent.embeddedAudience(), function (aud) {
                        countQuery.push(ko.mapping.toJS(aud));
                    });
                    view.callAPI('subscribers.count',
                        JSON.stringify(countQuery)).
                            done(function (count) {
                                smsContent.count(count);
                            });
                } else if (message.targetAudiences().sms.length === 1) {
                    smsContent.count(
                        message.targetAudiences().sms[0].count());
                }
            }

            message.state('review');
        } else {
            message.isModified(true);

            _.each(message.content(), function (content) {
                if (!content.isHundredPercent()) {
                    return alert.info(sharedStrings.message.body.
                        validation.percentageSum);
                }
            });
        }
    });

    view.root.on('click', '.btn.schedule', function () {
        var message = viewmodel.message();
        var promise;
        var messageObject;
        var broadcastPromise;

        // munge single-slice content for the platform
        // FIXME: teach message-body to use simple payload for unsliceable
        // channels
        messageObject = ko.mapping.toJS(message);

        if (messageObject.sendAt === null) {
            messageObject.sendAt = format.apiLocalDate(new Date());
        } else {
            messageObject.sendAt = format.apiLocalDate(messageObject.sendAt);
        }

        _.each(messageObject.content, function (contentItem) {
            var slices = contentItem.slices;

            if (contentItem.channel !== 'smartlists') {
                contentItem.audience = [];
            }

            _.each(slices, function (slice) {
                var isMobileFlow = !!slice.mobileFlow;
                var isNoMessage = !isMobileFlow && !slice.message;

                if (isMobileFlow) {
                    slice.message = null;
                } else if (isNoMessage) {
                    slice.message = '';
                    slice.mobileFlow = null;
                }
            });

            if (slices.length === 1) {
                // convert to a simple payload
                contentItem.message = slices[0].message;
                contentItem.mobileFlow = slices[0].mobileFlow;
                contentItem.subject = slices[0].subject;
                contentItem.files = slices[0].files;
                contentItem.filesLink = slices[0].filesLink;
                contentItem.ad = slices[0].ad;
                contentItem.sponsorship = slices[0].sponsorship;
                contentItem.smsFallback = slices[0].smsFallback;
                contentItem.value = slices[0].value;

                slices.length = 0;
            }
        });

        if (message.hasSmartlists()) {
            var contentItem = messageObject.content[0];

            contentItem.audience =
                _.map(contentItem.embeddedAudience, function (aud) {
                    return aud.id;
                });

            contentItem.audience = _.filter(contentItem.audience, function (a) {
                return a;
            });

            contentItem.channel = 'sms';

            messageObject.content[0] = _.omit(contentItem, 'embeddedAudience');

        }

        messageObject.count = false;

        if (message.id()) {
            broadcastPromise = waterfall.broadcasts.update(messageObject);
        } else {
            broadcastPromise = waterfall.broadcasts.send(messageObject);
        }

        promise = broadcastPromise.
            then(function () {
                if (message.isFuture()) {
                    alert.success(sharedStrings.message.scheduled);
                } else {
                    alert.success(sharedStrings.message.sent);
                }

                path.history.pushState(null, null,
                    sharedStrings.sitemap.scheduled_messages.url);
            }).
            fail(view.getRequestFailureFn(null, 'broadcasts.send'));

        message.inFlightRequest(promise);
    });

    view.root.on('change', '.file-uploader-inner', onImageChange);

    view.root.on('change', '#list-source', function () {
        var audienceList = $('.add-list-input');
        var audienceRow = $('.audience-row');
        var self = this;

        if (_.isEmpty(audienceList.val()) && audienceRow.length === 0) {
            switchList(self);
            return;
        }

        msgme.modal.confirm(sharedStrings.message.listSource.confirm,
            { title: sharedStrings.message.listSource.confirmationTitle })
            .done(function (ok) {
                if (ok) {
                    switchList(self);
                } else {
                    unswitchList(self);
                }
            });
    });

    function switchList(el) {
        var selectized = $('.add-list-input')[0].selectize;
        var audienceRow = $('.audience-row');
        var results;
        
        if (el.value === list) {
            results = messageModel.mapping.computed.getLists();
        } else {
            results = messageModel.mapping.computed.getSmartLists();
        }

        selectized.clear();
        selectized.clearOptions();
        selectized.load(function (callback) {
            callback(results);
        });
        audienceRow.trigger('deleted');
    }

    function unswitchList(el) {
        if (el.value === list) {
            el.value = smartList;
        } else {
            el.value = list;
        }
    }

    path.map('/messages/new').to(function () {
        path.history.replaceState(null, null, url);
    });

    path.map(url + '(/:id)').to(function () {
        // we want to show the view and hide whichever previous view it was on
        // but since this page requires many api calls, we want to show a
        // loading indicator, thus the show and hide calls.
        view.show();
        splash.show();
        view.hide();

        var id = this.params.id;

        if (id && viewmodel.message()) {
            resetPersistedState(viewmodel.message());
        }

        flags('messageSidebar').then(function() {
            var message = viewmodel.message();

            if (message) {
                message.viewWidth(9);
            }

            viewmodel.message.subscribe(function(message) {
                if (message) {
                    message.viewWidth(9);
                    flags('restrictPapaMurphysUI').then(_.bind(function () {
                        message.isPapaMurphys(true);
                    }, this));
                }
            });
        });

        $.when(id ? cm.fetch(id) : cm.create(),
                viewmodel.globals.metadata.refresh(),
                viewmodel.globals.flows.refresh()
            ).
            then(function (message) {
                var setEditStatePromise;

                if (message.broadcastStatus &&
                    message.broadcastStatus() !== 'EDITING') {
                    setEditStatePromise = api.call('broadcasts.state', {
                        id: id,
                        state: 'EDITING'
                    });
                } else {
                    setEditStatePromise = $.Deferred().resolve();
                }

                return $.when(
                    $.Deferred().resolve(message),
                    viewmodel.globals.lists.refresh(),
                    viewmodel.globals.channels.refresh(),
                    viewmodel.globals.smartlists.refresh(),
                    setEditStatePromise);
            }).
            then(function (message, lists, channels, smartlists) {
                // fetch the audiences for all sms if editing broadcast
                // and add them to the hash
                var smsAudiencePromises;

                if (message.id()) {
                    var smsContent = _.filter(message.content(),
                        function (content) {
                            return content.channel().toLowerCase() === 'sms';
                        });
                    var smartlist;

                    if (smsContent.length && smsContent[0].audience().length) {
                        smartlist = viewmodel.globals.smartlists.
                            oneById(smsContent[0].audience()[0].id());

                        if (smartlist) {
                            _.each(smsContent[0].audience(), function (aud) {
                                var list = viewmodel.globals.smartlists.
                                    oneById(aud.id());

                                smsContent[0].embeddedAudience.push(
                                    ko.mapping.fromJS({
                                        queryFilterDetails:
                                            list.queryFilterDetails,
                                        lists: list.lists,
                                        description: null,
                                        count: null,
                                        id: list.id
                                    }));
                                smsAudiencePromises = [$.Deferred().resolve()];
                            });
                        }
                    }

                    if (smsContent.length && !smartlist) {
                        smsAudiencePromises = _.map(smsContent[0].audience(),
                            function (audience, index) {
                                smsContent[0].embeddedAudience.push(
                                    ko.mapping.fromJS({
                                        queryFilterDetails: {
                                            operator: 'and',
                                            details: [],
                                        },
                                        lists: [],
                                        description: null,
                                        count: null
                                    }));
                                return api.call('filters.fetch', audience.id()).
                                    done(function (result) {
                                        var aud = smsContent[0].
                                            embeddedAudience()[index];
                                        aud.lists.push(result.lists[0]);

                                        _.each(result.queryFilterDetails,
                                            function (filter) {
                                                if (filter) {
                                                    aud.queryFilterDetails.
                                                        details.push(
                                                        ko.mapping.fromJS({
                                                        metadata: filter.
                                                            metadata,
                                                        operator: filter.
                                                            operator,
                                                        value: filter.value,
                                                        radius: null
                                                    }, queryfilter.mapping.
                                                        queryFilterDetails.
                                                        mapping));
                                                }
                                            }
                                        );
                                    });
                            });
                    }
                } else {
                    smsAudiencePromises = [$.Deferred().resolve()];
                }
                // Add SMS now, as we have the latest lists
                var audiencesByChannel = {
                        sms: _.map(lists, function (list) {
                            return {
                                id: list.id,
                                name: list.name,
                                channel: 'sms'
                            };
                        })
                    };

                audiencesByChannel.smartlists =
                    _.map(smartlists, function (list) {
                        return {
                            id: list.id,
                            name: list.name,
                            channel: 'smartlist'
                        };
                    });

                // return a new promise for all audiences
                return $.when.apply($, smsAudiencePromises).
                    then(function () {
                        var sortedChannels =
                            _.chain(sharedStrings.channels).
                            map(function (channel) {
                                var byChannel =
                                    audiencesByChannel[channel.id];

                                return _.isArray(byChannel) && {
                                    id: channel.id,
                                    name: channel.name,
                                    audiences: byChannel
                                };
                            }).
                            compact().
                            value();

                        return $.Deferred().
                            resolve(message, sortedChannels).
                            promise();
                    });
            }).
            done(populate).
            fail(function (error) {
                var url = sharedStrings.sitemap.scheduled_messages.url;

                if (error.responseText.indexOf(
                    'Broadcast has already started') !== -1) {
                    path.history.pushState(null, null, url);
                } else {
                    view.getRequestFailureFn(null,
                        'viewmodel.globals.lists|metadata|flows.refresh');
                }
            });
    });

    return view;
});


