
define("json!widgets/dashboard/strings.json", function(){ return {
  "subscriberGrowthChart": "List Growth",
  "engagementActivityChart": "Mobile Engagement",
  "last": "last",
  "days": "days",
  "subscribers": {
    "title": "Subscribers"
  },
  "timeframe": {
    "label": "Timeframe",
    "placeholder": "Select timeframe...",
    "options": [
      {
        "value": "LAST7",
        "text": "Last 7 days"
      },
      {
        "value": "LAST14",
        "text": "Last 14 days"
      },
      {
        "value": "LAST30",
        "text": "Last 30 days"
      }
    ]
  }
};});

