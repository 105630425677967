define('msgme/viewmodel/util/dateparts',[
    'msgme/util/format'
], function (format) {
    function period(date) {
        return date.getHours() >= 12 ? 'PM' : 'AM';
    }

    var getDateParts = function (binding) {
        return {
            asDate: {
                read: function (root) {
                    return new Date(root.params[binding]());
                },
                write: function (root, parent, value) {
                    value = new Date(value);
                    if (!isNaN(value)) {
                        root.params[binding](format.apiDate(value));
                    }
                }
            },
            date: {
                read: function (root, parent) {
                    var date = parent.asDate();
                    return date.format('{MM}/{dd}/{yyyy}');
                },
                write: function (root, parent, value) {
                    var curDate = parent.asDate();
                    var newDate = new Date(value);

                    curDate.setMonth(newDate.getMonth());
                    curDate.setDate(newDate.getDate());
                    curDate.setFullYear(newDate.getFullYear());
                    parent.asDate(curDate);
                }
            },
            hour: {
                read: function (root, parent) {
                    var curDate = parent.asDate();
                    var hours = curDate.getHours() % 12;

                    return hours === 0 ? 12 : hours;
                },
                write: function (root, parent, value) {
                    var curDate = parent.asDate();

                    value %= 12;

                    if (period(curDate) === 'PM') {
                        value += 12;
                    }

                    curDate.setHours(value);
                    parent.asDate(curDate);
                }
            },
            minute: {
                read: function (root, parent) {
                    return parent.asDate().getMinutes();
                },
                write: function (root, parent, value) {
                    var curDate = parent.asDate();
                    curDate.setMinutes(value);
                    parent.asDate(curDate);
                }
            },
            period: {
                read: function (root, parent) {
                    return period(parent.asDate());
                },
                write: function (root, parent, value) {
                    var curDate = parent.asDate();
                    var hour = curDate.getHours();

                    if (hour >= 12 && value === 'AM') {
                        curDate.setHours(hour - 12);
                    } else if (hour < 12 && value === 'PM') {
                        curDate.setHours(hour + 12);
                    }
                    parent.asDate(curDate);
                }
            }
        };
    };

    return getDateParts;
});

