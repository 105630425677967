
define("json!widgets/calendar/strings.json", function(){ return {
  "days": [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  "more": "+%d more",
  "preview": "Preview attachment...",
  "back": "Back"
};});

