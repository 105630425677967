define('widgets/metadata-select/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    './../three-widget',
    'text!./template.html'
], function(_, viewmodel, ThreeWidget, template) {

    var passedObservables = {metadatumId: null};
    var internalObservables = {};
    var allObservables = _.extend({}, passedObservables, internalObservables);

    var mapping = {
        defaults: _.clone(allObservables)
    };

    $.widget('msgme.msgme_metadata_select', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        }
    });

    return {
        widget: $.msgme.msgme_metadata_select,
        mapping: mapping
    };
});

