define('msgme/util/account-features',[
    'msgme/underscore',
    'msgme/viewmodel'
], function (_, viewmodel) {
    // account features are a bear to test, so we've compiled some notes on it:
    // https://gist.github.com/aaronj1335/fcc81c6e4d69e885f15d

    function strip(fromBeginning, str, remove) {
        var pad = 0;

        if (fromBeginning && _.startsWith(str, remove)) {
            if (str[remove.length] === ' ') {
                pad += 1;
            }

            str = str.slice(remove.length + pad);
        } else if (_.endsWith(str, remove)) {
            if (str[str.length - remove.length - 1] === ' ') {
                pad += 1;
            }

            str = str.slice(0, str.length - remove.length - pad);
        }

        return str;
    }

    function getFixedMessage(key, message) {
        var features = waterfall.authenticate.features;

        message = message ? message : '';
        if (features && _.has(features, key) &&
            features[key].status === 'ACTIVE') {
            if (features[key].type === 'FIXEDMESSAGE') {
                if (features[key].position === 'PREPEND') {
                    return features[key].message + ' ' + message;
                } else if (features[key].position === 'APPEND') {
                    return message + ' ' + features[key].message;
                } else if (features[key].position === 'SUBSTITUTE') {
                    return features[key].message;
                }
            } else if (features[key].type === 'MULTIPLEFIXEDMESSAGE') {
                return features[key].prependMessage + ' ' + message + ' ' +
                    features[key].appendMessage;
            }
        } else {
            return message;
        }

    }

    function stripFixedMessage(key, message) {
        var features = waterfall.authenticate.features;

        if (features && _.has(features, key) &&
                features[key].status === 'ACTIVE') {

            if (features[key].type === 'FIXEDMESSAGE') {
                if (features[key].position === 'PREPEND') {
                    return strip(true, message, features[key].message);
                } else if (features[key].position === 'APPEND') {
                    return strip(false, message, features[key].message);
                }
            } else if (features[key].type === 'MULTIPLEFIXEDMESSAGE') {
                return strip(true, strip(false, message,
                    features[key].appendMessage), features[key].prependMessage);
            }
        }

        return message;
    }

    function applyFixedMessage(key, message) {
        var stripped = stripFixedMessage(key, message);
        return getFixedMessage(key, stripped);
    }

    function isFixedMessageApplied(key, message) {
        return message === applyFixedMessage(key, message);
    }

    function hasFixedMessageDiscrepancy(key, message) {
        var features = waterfall.authenticate.features;

        if (features && _.has(features, key) &&
            features[key].status !== 'INACTIVE') {
            return !isFixedMessageApplied(key, message);
        } else {
            return false;
        }
    }

    var accountFeatures = {
        getFixedMessage: getFixedMessage,

        stripFixedMessage: stripFixedMessage,

        applyFixedMessage: applyFixedMessage,

        isFixedMessageApplied: isFixedMessageApplied,

        hasFixedMessageDiscrepancy: hasFixedMessageDiscrepancy,

        isActive: function (key) {
            var features = waterfall.authenticate.features;

            return features && _.has(features, key) &&
                features[key].status === 'ACTIVE';
        },

        isSubstituteMessage: function (key) {
            var features = waterfall.authenticate.features;

            return (features && _.has(features, key) &&
                features[key].status === 'ACTIVE' &&
                features[key].position === 'SUBSTITUTE');
        },

        getMaxLength: function (key, length) {
            var features = waterfall.authenticate.features;
            var defaultSmsLength =
                viewmodel.globals.shortcodes.session().maxSmsLength;

            if (features && (!_.has(features, key) || (_.has(features, key) &&
                features[key].status === 'INACTIVE'))) {
                return length || defaultSmsLength;
            } else {
                // decrement 1 extra to account for the space inserted between
                // the fixed message and the user's message
                if (features[key].type === 'FIXEDMESSAGE') {
                    return length - features[key].message.length - 1;
                } else if (features[key].type === 'MULTIPLEFIXEDMESSAGE') {
                    return length - features[key].appendMessage.length -
                        features[key].prependMessage.length - 2;
                }
            }
        },

        /**
         * fixedMessageMapping
         *
         * this returns an object with computeds that break out fixed
         * prepended/appended/substituted text. any mapping can be extended
         * with this object to include the computeds. it also wires up
         * validation.
         *
         * say you have a `message` observable in your mapping. you can break
         * that out into computeds by calling `fixedMessageMapping` and
         * extending your `mapping.computed` with it like so:
         *
         *     var mapping = {
         *         defaults: {
         *             message: null,
         *             ...
         *         },
         *         computed: {
         *            ...
         *         }
         *     };
         *
         *     mapping = $.extend(true, {}, mapping,
         *         fixedMessageMapping('message', 'fixedBroadcastMessage'));
         */
        fixedMessageMapping: function (field, keyProp) {
            var o = {computed: {}, validation: {}};

            o.computed[field + 'Prefix'] = function () {
                var rootViewmodel = this;
                var key = rootViewmodel[keyProp]();
                var feature = key && waterfall.authenticate.features[key];

                if (feature && feature.prependMessage &&
                    feature.status === 'ACTIVE') {
                    return feature.prependMessage;
                } else if (feature == null || feature.position !== 'PREPEND' ||
                    feature.status !== 'ACTIVE') {
                    return null;
                }

                return feature.message;
            };

            o.computed[field + 'Body'] = {
                read: function () {
                    var rootViewmodel = this;
                    var key = rootViewmodel[keyProp]();
                    var feature = key && waterfall.authenticate.features[key];

                    if (feature != null && feature.position === 'SUBSTITUTE') {
                        return feature.message;
                    }

                    if (key == null) {
                        return rootViewmodel[field]();
                    } else {
                        return stripFixedMessage(key, rootViewmodel[field]());
                    }
                },

                write: function (root, value) {
                    var rootViewmodel = this;
                    var key = rootViewmodel[keyProp]();

                    if (key == null) {
                        rootViewmodel[field](value);
                    } else {
                        rootViewmodel[field](getFixedMessage(key, value));
                    }
                }
            };

            o.computed[field + 'Postfix'] = function () {
                var rootViewmodel = this;
                var key = rootViewmodel[keyProp]();
                var feature = key && waterfall.authenticate.features[key];

                if (feature && feature.appendMessage &&
                    feature.status === 'ACTIVE') {
                    return feature.appendMessage;
                } else if (feature == null || feature.position !== 'APPEND' ||
                    feature.status !== 'ACTIVE') {
                    return null;
                }

                return feature.message;
            };

            o.validation[field + 'Body'] = {
                validator: function () {
                    var fieldObservable = this;
                    var isValid = fieldObservable.parent[field].isValid;

                    // the observable backing these computed's may not be
                    // validated, so check for the isValid function first
                    return !isValid || isValid();
                },

                message: function () {
                    var fieldObservable = this;
                    var error = fieldObservable.parent[field].error;

                    return error ? error() : '';
                }
            };

            return o;
        }
    };

    return accountFeatures;
});

