define('msgme/widgets/flowmodule-dynamiccontent',[
    'jquery',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-dynamiccontent'
],
function ($, ko, flowmodule, dynamicContent) {
    $.widget('msgme.msgme_flowmodule_dynamiccontent', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-dynamiccontent-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
            this.options.data().params.url.extend({ required: true });
        }
    });

    $.msgme.msgme_flowmodule_dynamiccontent.Model =
        flowmodule.modelFactory({}, dynamicContent.config);

    return $.msgme.msgme_flowmodule_dynamiccontent;
});


