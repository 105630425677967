define('widgets/facebook-message-body/index',[
    'msgme/underscore',
    './../message-body/index',
    'text!./template.html',
    'json!./strings.json',
    'json!./../message-body/strings.json'
], function (_, MessageBody, template, strings, messageBodyStrings) {
    var mapping = $.extend(true, {}, MessageBody.mapping, {
        defaults: {
            sliceable: false
        },

        sliceMapping: {
            defaults: {
                enableFileUpload: true,
                placeholder: strings.placeholder + ' ' +
                    messageBodyStrings.laterDate
            }
        }
    });

    $.widget('msgme.msgme_facebook_message_body', MessageBody, {
        _template: template,

        _create: function () {
            this._mapping = this._mapping || mapping;
            this.element.addClass('facebook-message-body');
            MessageBody.prototype._create.apply(this, arguments);
        },

        _contentItemParams: function () {
            return _.extend(
                MessageBody.prototype._contentItemParams.apply(this, arguments),
                {channel: 'facebook'});
        }
    });

    return {
        widget: $.msgme.msgme_facebook_message_body,
        mapping: MessageBody.mapping
    };
});


