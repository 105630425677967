
define("json!widgets/character-counter/strings.json", function(){ return {
  "warning": {
    "requiredOptionNotProvided": "Required option %(option)s not provided"
  },
  "characterCounter": {
    "hasDI": "Max characters: ",
    "title": "Character counter is disabled when dynamic insertion is in use, as the number of characters will vary depending on the subscriber and the field inserted"
  }
};});

