define('msgme/views/report',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/splash',
    'msgme/path',
    'msgme/views/View',
    'msgme/viewmodel',
    'msgme/viewmodel/mapping',
    'msgme/viewmodel/report',
    'msgme/util/format',
    'msgme/util/feature-flags',
    'msgme/widgets/pager',
    'json!widgets/shared-strings.json',
    'widgets/report-parameters/index'
], function (
    _,
    ko,
    splash,
    path,
    View,
    viewmodel,
    mapping,
    report,
    format,
    featureFlags,
    Pager,
    sharedStrings
) {
    var view  = new View('#report-view');
    var reports = sharedStrings.sitemap.reports;
    var url = new RegExp(reports.url +
        reports.regexExcludeScheduled + reports.regexExcludeRecurring +
        reports.regexExcludePage + reports.newReportRegex);
    var cm = new mapping.CursorModel(waterfall.reporting, {
        record: {mapping: report.mapping}
    });

    function populate(data) {
        var timeframe, runAt, contentTypes, lists, queryFilters;

        if (data.id()) {
            timeframe = data.query.timeframe();
            runAt = data.lastReport.scheduledAt();
            contentTypes = data.query.contentTypes();
            lists = data.query.lists();
            queryFilters = data.query.queryFilters();
        }

        report.model(data);

        if (data.id()) {
            report.model().timeframe(timeframe);
            report.model().runAt(new Date(runAt));
            view.root.find('.component-type').val(contentTypes).
                trigger('change');
            view.root.find('.multi-list-select').val(lists).trigger('change');
            view.root.find('.query-filter-select').val(queryFilters).
                trigger('change');
            view.root.find('.list-details-select').val(lists).trigger('change');
            if (report.model().notificationEmailList()) {
                report.model().emails(
                    report.model().notificationEmailList().join(','));
            }
        } else {
            view.root.find('.component-type').val([]).trigger('change');
        }

        report.model().isModified(false);

        view.show();
        splash.hide();

        featureFlags('restrictPapaMurphysUI').then(function () {
            report.model().restrictUI(true);
        });
        featureFlags('marketronFeatures').then(function () {
            report.model().isMarketron(true);
        });
    }

    view.root.on('change', '.select-type select', function () {
        report.model().emails(null);
        view.root.find('.sub-growth-list').val([]).trigger('change');
        view.root.find('.multi-list-select').val([]).trigger('change');
        view.root.find('.query-filter-select').val([]).trigger('change');
        view.root.find('.component-type').val([]).trigger('change');
        view.root.find('.list-details-select').val([]).trigger('change');
    });

    view.root.on('click', '.submit-report', function () {
        if (report.model().isValid()) {
            report.model().save().
                done(function () {
                    path.history.pushState(null, null,
                        sharedStrings.sitemap.reports.url);
                    msgme.alert.success('This report has been created');
                }).
                fail(function (err) {
                    var response = {};

                    try {
                        response = JSON.parse(err.responseText);
                    } catch (e) {}

                    report.model().error(response.errorMsg ||
                        sharedStrings.validation.badrequest);
                });
        } else {
            report.model().isModified(true);
        }
    });

    view.root.on('change', '.component-type', function (e) {
        var value = $(e.target).val();

        report.model().query.contentTypes(value);
    });

    view.root.on('change', '.sub-growth-list', function (e) {
        var value = $(e.target).val();

        if (value) {
            report.model().query.lists([value]);
        } else {
            report.model().query.lists(null);
        }
    });

    view.root.on('change', '.list-details-select', function (e) {
        var value = $(e.target).val();

        report.model().query.lists(value);
    });

    view.root.on('change', '.multi-list-select', function (e) {
        var value = $(e.target).val();

        report.model().query.lists(value);
    });

    view.root.on('change', '.query-filter-select', function (e) {
        var value = $(e.target).val();

        report.model().query.queryFilters(value);
    });

    view.root.on('click', '.cancel', function () {
        path.history.pushState(null, null, sharedStrings.sitemap.reports.url);
    });

    path.map(url).to(function (pth, newReport, id) {
        if (newReport) {
            populate(cm.create());
        } else if (id) {
            cm.fetch(id).
                done(populate).
                fail(view.getRequestFailureFn(null,
                    'report.fetch', id));
        }
    });

    return view;
});


