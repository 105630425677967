
define("json!widgets/sms-message-body/strings.json", function(){ return {
  "placeholder": "Enter text content here.",
  "receives": "receives",
  "segmentType": [
    {
      "val": "sms",
      "text": "Mobile Message"
    },
    {
      "val": "mobileflow",
      "text": "Forward to Campaign"
    },
    {
      "val": "nomessage",
      "text": "No Message"
    }
  ],
  "overMax": "Carrier may send multiple messages"
};});

