
define("json!widgets/stat-card/strings.json", function(){ return {
  "likes": {
    "title": "Facebook",
    "count": {
      "helptext": "likes"
    }
  },
  "followers": {
    "title": "Twitter",
    "count": {
      "helptext": "followers"
    }
  },
  "subscribersContacts": {
    "title": "Active Subscribers",
    "count": {
      "helptext": ""
    },
    "delta": {
      "helptext": "last 7 days"
    }
  },
  "subscribers": {
    "title": "Subscribers",
    "count": {
      "helptext": ""
    },
    "delta": {
      "helptext": "last 7 days"
    }
  },
  "contacts": {
    "title": "SMS Contacts",
    "count": {
      "helptext": "total mobile numbers"
    }
  },
  "glance": {
    "title": "Last Month at a Glance"
  },
  "recentBroadcast": {
    "title": "Most Recent Broadcast"
  },
  "na": "N/A"
};});

