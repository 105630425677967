define('msgme/util/ga',[
    'msgme/path'
], function (path) {
    return {
        track: function (accountId) {
            var ga;

            path.on('navigation', function () {
                var loc = location.protocol + '//' + location.hostname +
                    location.pathname + location.search + location.hash;

                ga = window.__threeGoogleAnalytics;
                ga('send', 'pageview', loc);
            });

            // google analytics snippet provided by:
            // https://developers.google.com/analytics/devguides/collection/analyticsjs/
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','//www.google-analytics.com/analytics.js','__threeGoogleAnalytics');

            ga = window.__threeGoogleAnalytics;

            ga('create', accountId, {
                'cookieDomain': 'waterfall.com'
            });
        }
    };
});

