define('msgme/viewmodel/flowmodule-passbook',[], function () {
    var config = {
        mapping: {
            local: {
                title: 'Passbook',
                responseMessage: {},
                outOfCoupons: {},
                mapping: {
                    subcomputed: {
                        responseMessage: {
                            widgetId: {
                                read: function (root) {
                                    return root.widgetId() + '-response';
                                },
                                deferEvaluation: true
                            }
                        },
                        outOfCoupons: {
                            widgetId: {
                                read: function (root) {
                                    return root.widgetId() +
                                      '-out-of-coupons';
                                },
                                deferEvaluation: true
                            }
                        }
                    }
                }
            },
            params: {
                mapping: {
                    defaults: {
                        response: '',
                        outOfCouponsMessage: '',
                        passbookTemplateId: ''
                    },
                    validation: {
                        passbookTemplateId: {
                            required: true
                        }
                    }
                }
            }
        }
    };

    return {
        config: config
    };
});

