define('msgme/auth',[
    'msgme',
    'msgme/underscore',
    'msgme/viewmodel'
], function (msgme, _) {
    var ALL = '*';
    var whoamiPromise;

    /*
     * Construct a Permission instance from a permission string
     *
     * Permission strings have the following form:
     * <account>:<group list>:<object type>:<action list>:<object>
     *
     * Returns an object of the form:
     * {
     *   account: <account>,
     *   groups: [<group>, ...],
     *   type: <type>,
     *   actions: [<action>, ...],
     *   object: <object>
     * }
     */
    function Permission(permissionString) {
        permissionString = permissionString.split(':');

        if (permissionString.length === 3) {
            permissionString.splice(0, 0,
                waterfall.authenticate.account, waterfall.authenticate.group);
        }

        this.account = permissionString[0];
        this.groups = permissionString[1].split(',').reduce(reduceList, {});
        this.type = permissionString[2];
        this.actions = permissionString[3].split(',').reduce(reduceList, {});
        this.object = permissionString[4];
    }

    function reduceList(actions, action) {
        actions[action] = true;
        return actions;
    }

    function checkList(toCheck, against) {
        return against[ALL] || Object.keys(toCheck).every(function (action) {
            return against[action];
        });
    }

    /**
     * Check this permission against another, possibly more general, permission
     *
     * Note that the `matches` relation is not commutative:
     * `permOne.matches(permTwo)` is not necessarily equal to
     * `permTwo.matches(permOne)`. In particular, if permOne is more general,
     * then `permOne.matches(permTwo)` will be false, while
     * `permTwo.matches(permOne)` will be true.
     *
     * Returns true if permission matches and false otherwise
     */
    Permission.prototype.matches = function Permission_matches(permission) {
        return (permission.account === ALL ||
                this.account === permission.account) &&
            (permission.groups === ALL ||
                checkList(this.groups, permission.groups)) &&
            (permission.type === ALL || this.type === permission.type) &&
            (permission.actions === ALL ||
                checkList(this.actions, permission.actions)) &&
            (permission.object === ALL || this.object === permission.object);
    };

    msgme.auth = {
        /**
         * Check if the current user has a permission
         *
         * Returns true if the current user has the passed permission and false
         * otherwise.
         */
        has: function (permissionToCheck) {
            permissionToCheck = new Permission(permissionToCheck);
            var permissions = waterfall.authenticate.permissions;

            return permissions.some(function (permission) {
                permission = new Permission(permission);
                return permissionToCheck.matches(permission);
            });
        },

        /**
         * Check if the current user has any of a list of permissions
         *
         * Returns true if the current user has any of the passed permissions
         * and false otherwise.
         */
        hasAny: function () {
            var permissionsToCheck = _.toArray(arguments);

            return permissionsToCheck.some(this.has, this);
        },

        /**
         * Check if the current user has all of a list of permissions
         *
         * Returns true if the current user has all of the passed permissions
         * and false otherwise.
         */
        hasAll: function () {
            var permissionsToCheck = _.toArray(arguments);

            return permissionsToCheck.every(this.has, this);
        },

        /**
         * Obtain a cached promise of waterfall.authenticate.whomai's value
         *
         * Returns the promise from waterfall.authenticate.whoami, but provides
         * a way to not call it more than once per page load.
         */
        whoami: function () {
            if (!whoamiPromise) {
                whoamiPromise = waterfall.authenticate.whoami();
            }

            return whoamiPromise;
        }
    };

    msgme.auth.whoami.reset = function () {
        whoamiPromise = undefined;
    };

    return msgme.auth;
});

