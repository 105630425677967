define('msgme/underscore',[
    'underscore', 'underscore.string'
],
function (_, _s) {
    // mix in underscore.string
    _.mixin(_s.exports());

    return _;
});

