define('msgme/views/coupons',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/coupon',
    'msgme/views/View',
    'msgme/widgets/pager',
    'json!widgets/shared-strings.json',
    'widgets/coupon-modal/index',
    'msgme/plugins'
], function (_, splash, path, ko, viewmodel, couponViewmodel, View, Pager,
    sharedStrings
) {
    var view = new View('#coupons-view');
    var page, pager;
    var url = sharedStrings.sitemap.coupons.url;
    var $couponModalEl = view.root.find('.new-coupon-modal');
    var cachedCoupons;

    pager = new Pager(couponViewmodel.collection);

    function onFailure(what, e) {
        console.error('Failed to fetch', what + ': ', e);
    }

    function onCouponsSuccess(coupons) {
        var rows = _.map(coupons.slice(), function (c) {
            var group = viewmodel.globals.groups.oneById(c.group);
            c.groupName = group ? group.name : '';
            c.sent = c.total - c.available;
            c.lastStatus = c.uploads.length ?
                _.last(c.uploads).status : 'PROCESSED';

            return c;
        });

        couponViewmodel.collection.rows(rows);
        pager.update(coupons);

        splash.hide();
        $('#coupons-view-main').msgme('busy', false);
        $('#coupons-view-empty').removeClass('hidden');
    }

    function fetchCoupons() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#coupons-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !couponViewmodel.collection.rows().length) {
            if (!couponViewmodel.collection.pageSize() ||
                !couponViewmodel.collection.pageIndex() ||
                !couponViewmodel.collection.pageCount()) {
                resetViewmodelCollection();
            }

            // fetch the coupons and populate page if not in a search
            view.callAPI('coupons.fetch', {
                    page: page,
                    size: couponViewmodel.collection.pageSize
                }).
                done(function (result) {
                    cachedCoupons = result;
                    onCouponsSuccess(result);
                }).
                fail(_.bind(onFailure, this, 'coupons'));
        } else {
            //fetch for cache
            view.callAPI('coupons.fetch', {
                    page: page,
                    size: 15
                }).
                done(function (result) {
                    cachedCoupons = result;
                    $('#coupons-view-main').msgme('busy', false);
                }).
                fail(_.bind(onFailure, this, 'coupons'));
        }
        
        viewmodel.globals.coupons.refresh(true).done(function () {
            instantiateSearchBox();
        });
    }

    function resetViewmodelCollection() {
        couponViewmodel.collection.pageSize(15);
        couponViewmodel.collection.pageCount(-1);
        couponViewmodel.collection.pageIndex(-1);
        couponViewmodel.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var couponNames = _.map(viewmodel.globals.coupons(), function (coupon) {
            return coupon.name;
        });
        var $searchBoxEl = view.root.find('.search-box');

        if (!!$searchBoxEl.hasOwnProperty('autocomplete')) {
            $searchBoxEl.autocomplete('destroy');
        }

        $searchBoxEl.autocomplete({
            source: couponNames,
            delay: 100
        });
    }

    $('#coupons-view').on('click', 'td i.fa.fa-remove', function () {
        var rowEl = $(this).closest('tr');
        var record =
            couponViewmodel.collection.rows()[rowEl.index()];
        var name = record.name;

        msgme.modal.confirm(
            'Are you sure you want to delete coupon group ' + name + '?', {
                title: 'Delete Coupon Group'
            }).done(_.bind(function (value) {
                if (value) {
                    rowEl.addClass('deleting');
                    record.del().
                        done(function () {
                            fetchCoupons();
                            msgme.alert.success('The coupon has been deleted');
                        }).
                        fail(function () { rowEl.removeClass('deleting'); }).
                        fail(view.getRequestFailureFn(null,
                            'coupons.delete',
                            record.data().id));
                }
            }, this));
    });

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        couponViewmodel.collection.noResults(false);
        if (!query) {
            onCouponsSuccess(cachedCoupons);
        } else {
            results = _.map(viewmodel.globals.coupons(), function (coupon) {
                if(coupon.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return coupon;
                }
            });

            onCouponsSuccess(_.compact(results));

            if (!_.compact(results).length) {
                couponViewmodel.collection.noResults(true);
            }
        }

        view.root.find('.search-box').autocomplete('close');
    });

    view.root.on('click', '.search-flow', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        onCouponsSuccess(cachedCoupons);
        view.root.find('.search-box').val('');
        couponViewmodel.collection.noResults(false);
    });

    path.map(sharedStrings.sitemap.coupon.url + '/:id').to(function () {
        if(this.params.id.toLowerCase() === 'new') {
            if ($couponModalEl.find('.coupon-modal').length) {
                $couponModalEl.msgme_coupon_modal('show');
            }
        }
    });
    path.map(url + '(/page/:page)').to(function () {
        view.show();
        page = this.params.page;
        fetchCoupons();
    });

    return view;
});

