define('msgme/viewmodel/flowmodule-daypart',[
    'msgme/underscore',
], function (_) {
    var timezoneMap = {
        'America/New_York': '-5:00',
        'America/Chicago': '-6:00',
        'America/Los_Angeles': '-8:00',
        'America/Denver': '-7:00',
        'Pacific/Honolulu': '-10:00',
        'America/Anchorage': '-9:00'
    };

    var timezoneName = {
        'America/New_York': 'ET',
        'America/Chicago': 'CT',
        'America/Los_Angeles': 'PT',
        'America/Denver': 'MT',
        'Pacific/Honolulu': 'HT',
        'America/Anchorage': 'AKT'
    };

    var dayMap = {
        'SUN': 'Sunday',
        'MON': 'Monday',
        'TUE': 'Tuesday',
        'WED': 'Wednesday',
        'THU': 'Thursday',
        'FRI': 'Friday',
        'SAT': 'Saturday'
    };

    function onInputChange (parts) {
        var slices;
        var startDate;
        var endDate;
        var compareStartDate;
        var compareEndDate;

        _.each(parts, function (part, index) {
            slices = parts.slice();
            slices.splice(index, 1);
            if (part.validDays) {
                part.validDays(true);

                _.each(part.daysOfWeek(), function (day) {
                    _.each(slices, function (slice) {
                        if (_.intersection([day], slice.daysOfWeek()).length &&
                            slice.timeZone()) {
                            startDate = Date.create(
                                Date.create(slice.startTime()) +
                                timezoneMap[slice.timeZone()]);
                            endDate = Date.create(Date.create(slice.endTime()) +
                                timezoneMap[slice.timeZone()]);
                            compareStartDate = Date.create(
                                Date.create(part.startTime()) +
                                timezoneMap[part.timeZone()]);
                            compareEndDate = Date.create(
                                Date.create(part.endTime()) +
                                timezoneMap[part.timeZone()]);
                            if (compareStartDate.
                                isBetween(startDate, endDate) ||
                                compareEndDate.isBetween(startDate, endDate) ||
                                startDate.is(compareStartDate) ||
                                startDate.is(compareEndDate) ||
                                endDate.is(compareStartDate) ||
                                endDate.is(compareEndDate)) {
                                part.validDays(false);
                                return;
                            }
                        }
                    });
                });
            }
        });
    }
    var partsMapping = {
        local: {
            currentTab: 'sms',
        },
        defaults: {
            daysOfWeek: [],
            startTime: null,
            endTime: null,
            message: null,
            nextStep: null,
            files: [],
            subject: null,
            mobileFlow: null,
            metadataId: null,
            ad: null,
            sponsorship: null,
            smsFallback: null,
            timeZone: null,
            validDays: true
        },

        computed: {
            datesString: function () {
                var daysOfWeek = this.daysOfWeek();

                if (daysOfWeek.length && this.startTime() &&
                    this.endTime() && this.timeZone()) {
                    var days = ' every ';

                    _.each(daysOfWeek, function (day, i) {
                        if (i+1 === daysOfWeek.length) {
                            days = days + dayMap[day];
                        } else {
                            days = days + dayMap[day] + ', ';
                        }
                    });

                    if (daysOfWeek.length === 7) {
                        days = ' Everyday';
                    }

                    return Date.create(this.startTime()).
                        format('{hh}:{mm} {TT}') + ' to ' +
                        Date.create(this.endTime()).
                        format('{hh}:{mm} {TT}') + ' ' +
                        timezoneName[this.timeZone()] + days;
                } else {
                    return '';
                }
            }
        },

        validation: {
            startTime: function () {
                if (this.parent.endTime()) {
                    return !!this.observable() &&
                        Date.create(this.observable()) <
                        Date.create(this.parent.endTime());
                } else {
                    return !!this.observable();
                }
            },

            endTime: function () {
                if (this.parent.startTime()) {
                    return !!this.observable() &&
                        Date.create(this.observable()) >
                        Date.create(this.parent.startTime());
                } else {
                    return !!this.observable();
                }
            },

            daysOfWeek: {
                validator: function () {
                    return this.parent.validDays();
                },

                message: ''
            },

            nextStep: {
                required: true
            },

            timeZone: {
                required: true
            }
        }
    };

    var mapping = {
        local: {
            title: 'Daypart'
        },
        params: {
            mapping: {
                local: {
                    noPartCurrentTab: 'sms'
                },
                defaults: {
                    parts: [],
                    noPartFiles: [],
                    noPartMessage: null,
                    noPartMobileFlow: null,
                    noPartMetadataValue: null,
                    noPartAd: null,
                    noPartSponsorship: null,
                    noPartSmsFallback: null,
                    noPartSubject: null,
                    noPartNextStep: null,
                },
                computed: {
                    timezones: function () {
                        return [{offset: 'Pacific',
                            value: 'America/Los_Angeles'},
                            {offset: 'Mountain',
                            value: 'America/Denver'},
                            {offset: 'Central',
                            value: 'America/Chicago'},
                            {offset: 'Eastern',
                            value: 'America/New_York'},
                            {offset: 'Alaska',
                            value: 'America/Anchorage'},
                            {offset: 'Hawaii',
                            value: 'Pacific/Honolulu'}
                        ];
                    },

                    reEval: function () {
                        return onInputChange(this.parts());
                    }
                },
                parts: {
                    mapping: partsMapping
                }
            }
        }
    };

    return {
        mapping: mapping,
        partsMapping: partsMapping
    };
});

