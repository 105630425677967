define('widgets/bar-chart/index',[
    'msgme/underscore',
    'msgme/ko',
    'lib/d3',
    './../three-widget',
    'text!./template.html'
], function (_, ko, d3, ThreeWidget, template) {
    var mapping = {
        defaults: {
            data: []
        }
    };

    var defaultConfig = {
    };

    function chart(el, dataObservable, config) {
        _.defaults(config, defaultConfig);

        var $container = $(el);
        var chartEl = $(el).find('svg').get(0);
        var margin = config.margin;
        var height = $container.height();
        var width = $container.width();

        config.height = config.height || height - margin.top - margin.bottom;
        config.width =  config.width || width - margin.left - margin.right;

        // render everything using SVG's default top-left origin, but then
        // transform it to be right-side up and inset by the top margin
        d3.select(chartEl).
            append('g').
            attr('class', 'chart').
            attr('transform',
                 _.sprintf('scale(1, -1) translate(%d, %d)',
                     margin.left, margin.top - height));
    }

    function barChart(el, dataObservable, config) {
        var chartEl = $(el).find('svg').get(0);
        var data = dataObservable();
        var chart = d3.select(chartEl).select('.chart');

        function render(data) {
            function onRectMouseenter() {
                $(this).trigger('bar-mouseenter');
            }

            function onRectMouseout() {
                $(d3.event.fromElement).
                    trigger('bar-mouseout');
            }

            if (data && _.isArray(_.first(data))) {
                data = _.zip.apply(_, data);
            }

            var width = config.width;
            var spacing = config.spacing;
            var len = data.length || 1;

            // got dem fencepost blues
            var sumOfMoMt = _.isArray(_.first(data)) ?
                _.map(data, function (set) {
                    return _.reduce(set, function (prev, cur) {
                        return prev + cur;
                    }, 0);
                }) : data;
            var barWidth = Math.floor((width - len + 1) / len);
            var yScale = d3.scale.linear().
                    domain([0, d3.max(sumOfMoMt)]).
                    range([0, config.height]);

            var bar = chart.selectAll('g').
                data(data).
                enter().
                append('g');

            bar.
                attr('class', 'bar').
                attr('transform', function (d, i) {
                    var dx = i * barWidth + i * spacing;

                    return _.sprintf('translate(%d, 0)', dx);
                }).
                on('mouseenter', onRectMouseenter).
                on('mouseout', onRectMouseout);

            // append background bar that fills the chart's entire height and
            // makes each bar a larger hover target
            bar.
                append('rect').
                attr('class', 'background').
                attr('height', config.height).
                attr('width', barWidth);

            // append a bar for each data series. note that instead of
            // offsetting one bar on top of the other, we just make the
            // background bar the height of the sum of the data values
            bar.
                append('rect').
                attr('class', 'value mo').
                attr('height', function (d) {
                    return yScale(d[0] + d[1]);
                }).
                attr('width', barWidth);
            bar.
                append('rect').
                attr('class', 'value mt').
                attr('height', function (d) {
                    return yScale(d[1]);
                }).
                attr('width', barWidth);
        }

        dataObservable.subscribe(render);
        render(data);
    }

    $.widget('msgme.msgme_bar_chart', ThreeWidget, {
        options: {
            chartConfig: {
                spacing: 1,
                margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            }
        },

        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);
            this._render();
        },

        _render: function () {
            var data = this.option('viewmodel').data;
            var config = this.option('chartConfig');

            chart(this.element, data, config);
            barChart(this.element, data, config);
        }
    });

    return {
        widget: $.msgme.msgme_bar_chart,
        mapping: mapping
    };
});

