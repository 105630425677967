define('widgets/future-messages/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    'msgme/util/format',
    './../broadcast-sidebar/index',
    'json!./strings.json'
], function (
    _,
    viewmodel,
    format,
    broadcastSidebarWidget,
    strings
) {
    function firstSliceOrContent(broadcast) {
        var slices = broadcast.content[0].slices;

        return slices && slices.length ? slices[0] : broadcast.content[0];
    }

    function icon() {
        return 'fa-comment';
    }

    function timestamp(broadcast) {
        return new Date(broadcast.sendAt).
            format('{MON} {d}, {year} at {h}:{mm} {Tt}');
    }

    function subject(broadcast) {
        var slice = firstSliceOrContent(broadcast);

        return slice.subject;
    }

    function message(broadcast) {
        var slice = firstSliceOrContent(broadcast);

        return slice.message;
    }

    function audienceName(broadcast) {
        if (_.isEmpty(broadcast.content[0].audience)) {
            return broadcast.content[0].embeddedAudience[0].description;
        } else {
            return broadcast.content[0].audience[0].description;
        }
    }

    function count(broadcast) {
        var n = broadcast.content[0].recipients;

        return n != null ? '(' + n + ')' : '';
    }

    function mediaId(broadcast) {
        var slice = firstSliceOrContent(broadcast);

        return slice.files && slice.files[0];
    }

    function humanReadableDI(message) {
        var dynamicInsertionRegexRaw = new RegExp(
            '\\{subscriber\\.metadata\\.\\("(.*?)"\\)(' +
            '; default=".*?")?\\}', 'g');
        var messageBody = message;

        return messageBody && messageBody.replace(
            dynamicInsertionRegexRaw,
            function(match, metadatumId, defaultTextToken) {

            defaultTextToken = defaultTextToken || '';

            var metadata = viewmodel.globals.metadata();
            var metadatum = _.find(metadata, function(metadatum) {
                return metadatum.id === metadatumId;
            });

            if (!metadatum) {
                return match;
            }

            var sameNameMetadata = _.filter(metadata, function(m) {
                return m.name === metadatum.name;
            });
            var metadatumIndex = _.indexOf(
                _.pluck(sameNameMetadata, 'id'), metadatum.id);
            var metadatumIndexToken = metadatumIndex > 0 ?
                ' (' + metadatumIndex + ')' : '';

            return '{' + metadatum.name + metadatumIndexToken +
                defaultTextToken + '}';
        });
    }

    $.widget('msgme.msgme_future_messages', broadcastSidebarWidget.widget, {
        _createViewModel: function () {
            var vm = this._super.apply(this, arguments);

            vm.title(strings.title);
            vm.noMessages(strings.noMessages);

            return vm;
        },

        fetch: function () {
            var vm = this.option('viewmodel');
            var query = {size: 3, count: false};
            var promise = waterfall.broadcasts.fetch(query).
                then(function (futureBroadcasts) {
                    vm.broadcasts(futureBroadcasts.map(function (broadcast) {
                        return {
                            id: broadcast.id,
                            icon: icon(broadcast),
                            timestamp: timestamp(broadcast),
                            audienceName: audienceName(broadcast),
                            count: count(broadcast),
                            subject: subject(broadcast),
                            message: humanReadableDI(message(broadcast)),
                            media: mediaId(broadcast)
                        };
                    }));
                });

            vm.request(promise);

            return promise;
        }
    });

    return {
        widget: $.msgme.msgme_future_messages,
        mapping: broadcastSidebarWidget.mapping
    };
});


