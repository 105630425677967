define('msgme/ko/observablePromise',[
    'msgme/ko'
], function (ko) {
    return function () {
        var o = ko.observable.apply(ko, arguments);

        o.pending = ko.observable(false);
        o.subscribe(function (value) {
            function markAsNotPending() {
                // if the value has changed since this was set, do nothing
                if (o() !== value) {
                    return;
                }

                o.pending(false);
            }

            if (value && value.then) {
                o.pending(true);
                value.then(markAsNotPending, markAsNotPending);
            } else {
                o.pending(false);
            }
        });

        return o;
    };
});

