define('msgme/viewmodel/flowmodule-posttourl',[
    'msgme/ko/validation'
],
function (kov) {
    var config = {
        mapping: {
            local: {
                title: 'Post-to-URL'
            },
            defaults: {
                params: {
                    url: ''
                }
            },
            params: {
                mapping: {
                    validation: {
                        url: {
                            required: true,
                            pattern: kov.patterns.urlNoSpaces
                        }
                    },
                    subscribe: {
                        url: function (val) {
                            this.target(val.trim());
                        }
                    }
                }
            }
        }
    };

    return {
        config: config
    };
});

