
define("json!widgets/shared-strings.json", function(){ return {
  "broadcast": {
    "schedule": {
      "placeholder": "Send immediately"
    },
    "allLists": "All Lists"
  },
  "message": {
    "body": {
      "title": "Message",
      "tagABSegment": "Tag this A/B Split as",
      "removeButtonTitle": "Remove",
      "addSegment": "Add A/B Split",
      "validation": {
        "mmsNotEnabled": "MMS is not enabled on this account or shortcode",
        "slices": "All A/B splits must be valid",
        "percentageSum": "Percentages must total 100%"
      }
    },
    "placeholder": {
      "optInMessage": "Reply Y to confirm opt-in for autodialed marketing txt msgs from [legal company name] to this mobile #.  Consent not required to buy goods/services. \n",
      "confirmMessage": "[Program Name]: Text STOP to end, HELP for info. [frequency] msg/mo. Msg&DataRatesMayApply.\n",
      "alreadySubscribed": "You are already subscribed to [Program Name]. Text STOP to end, HELP for info. [frequency] msg/mo. Msg&DataRatesMayApply.\n"
    },
    "sent": "Message sent",
    "scheduled": "Message scheduled",
    "validation": {
      "sendAt": "You are attempting to schedule this message for the past. Please select a time in the future or leave blank to send now.\n",
      "pmiSendAt": "Please reschedule or send this message between 7AM and 8PM.\n",
      "message": {
        "mms": "MMS messages must include either a file or message body text.",
        "sms": "SMS messages must include message body text."
      }
    },
    "mmsMaxSize": "2000",
    "review": {
      "warning": {
        "quietTime": "You are scheduling a message for %(time)s. This may be an inconvenient time for some of your recipients.\n"
      }
    },
    "bannedWords": "You are not permitted to use the following word(s): ",
    "badChars": " is not a valid character. This can happen if you copy/pasted your message or if the character is not supported.",
    "invalidNumber": "Please enter a valid phone number",
    "unknownMessageType": "Unknown Message Type",
    "mmsSegmentationValue": "AT&T Wireless,MetroPCS,Sprint,T-Mobile,Verizon Wireless",
    "listSource": {
      "confirm": "Selected audience will be cleared, are you sure you want to switch lists?",
      "confirmationTitle": "Switch List"
    }
  },
  "metadata": {
    "select": "Select metadata field ...",
    "enterTagValue": "Enter Tag Value",
    "geoZipcode": "ZIP Code",
    "geoRadius": "Radius in Miles",
    "validation": {
      "fields": {
        "__default__": {
          "single": "Please enter a valid value",
          "multiple": "Please check that all values are valid"
        },
        "areacode": {
          "single": "Please enter a valid 3-digit area code",
          "multiple": "Please check that all of your area codes are valid and 3 digits"
        }
      },
      "view": {
        "eventUrl": "Please enter a valid URL"
      },
      "length": "Please enter a number between %(minLength)s and %(maxLength)s",
      "msisdn": "Please enter a valid 10 or 13 digit number"
    },
    "groupLabels": {
      "system": "System",
      "profile": "Profile",
      "custom": "Custom"
    }
  },
  "channels": [
    {
      "id": "sms",
      "name": "Mobile"
    },
    {
      "id": "smartlists",
      "name": "Smart Segments"
    },
    {
      "id": "facebook",
      "name": "Facebook"
    },
    {
      "id": "twitter",
      "name": "Twitter"
    },
    {
      "id": "push",
      "name": "Push"
    }
  ],
  "flow": {
    "delete": "Deleting a campaign will also remove all associated keywords.",
    "deleteConfirm": "Are you sure you want to proceed?"
  },
  "mobileFlow": {
    "select": "Select campaign ..."
  },
  "operators": [
    {
      "value": "=",
      "text": "is equal to"
    },
    {
      "value": "!=",
      "text": "is not equal to"
    },
    {
      "value": "<",
      "text": "is less than"
    },
    {
      "value": "<=",
      "text": "is less than or equal to"
    },
    {
      "value": ">",
      "text": "is greater than"
    },
    {
      "value": ">=",
      "text": "is greater than or equal to"
    },
    {
      "value": "exists",
      "text": "exists"
    },
    {
      "value": "notexists",
      "text": "does not exist"
    },
    {
      "value": "nin",
      "text": "is not one of"
    },
    {
      "value": "in",
      "text": "is any of"
    },
    {
      "value": "all",
      "text": "is all of"
    },
    {
      "value": "geo",
      "text": "in radius of"
    },
    {
      "value": "notgeo",
      "text": "not in radius of"
    }
  ],
  "dateOperators": [
    {
      "value": "<=",
      "text": "is this age or older"
    },
    {
      "value": ">",
      "text": "is younger than"
    }
  ],
  "loading": "Loading...",
  "version": {
    "major": 3,
    "minor": 42,
    "patch": 5
  },
  "keywords": {
    "del": {
      "title": "Delete Keyword",
      "confirmation": "Are you sure you want to delete this keyword?",
      "success": "The keyword has been deleted"
    }
  },
  "yes": "Y",
  "no": "N",
  "mobileflows": {
    "validation": {
      "endDate": "End date cannot be before the start date"
    },
    "agegate": {
      "defaults": {
        "ageRequestMessage": "Welcome to (CAMPAIGN NAME) Sweeps. You must be 18 or older to participate. Reply with your birthdate in the following format MMDDYYYY (e.g. 11051973)",
        "invalidFormatMessage": "You have entered an invalid birth date. Please reply with your birth date in the following format: MMDDYYYY (e.g. 11051973) See rules at (WEBSITE)",
        "notOfAgeMessage": "Sorry, you must be 18 years of age to participate in (CAMPAIGN NAME)"
      }
    },
    "poll": {
      "defaults": {
        "invalidAnswer": "Sorry, we did not recognize your response, please enter A or B"
      }
    }
  },
  "reports": {
    "status": {
      "ERROR": "Failed",
      "COMPLETE": "Completed",
      "NEW": "New",
      "PENDING": "Pending"
    },
    "delete": {
      "title": "Delete Report",
      "confirmation": "Are you sure you want to delete this report?",
      "success": "The report has been deleted"
    },
    "validation": {
      "startEndLimits": "%(type)s reports must not start more than %(limit)s in the past and must not span more than %(duration)s.\n",
      "days": "days",
      "scheduleDate": "Please enter a date in the future"
    },
    "type": {
      "label": "Report type",
      "options": {
        "messageDetails": "Message Details",
        "messageSummary": "Message Summary",
        "subscriberGrowth": "Subscriber Growth",
        "broadcastSummary": "Broadcast Summary",
        "listDetails": "List Details",
        "pollSummary": "Poll Summary",
        "subscriberConversion": "Subscriber Conversion",
        "optInOptOut": "Opt Out"
      }
    },
    "contentType": {
      "label": "Component type",
      "placeholder": "All",
      "options": {
        "SUBSCRIPTION": "Sub opt-in",
        "BASICTEXT": "Basic text",
        "COLLECTMETADATA": "Collect metadata",
        "TAGMETADATA": "Tag metadata",
        "POLL": "Polls",
        "TEXTTOWIN": "Text to win",
        "SWEEPS": "Sweepstakes",
        "VALIDATION": "Validation",
        "AGEGATE": "Age Verification",
        "DATEGATE": "Campaign Duration",
        "DYNAMICCONTENT": "Dynamic content",
        "POSTTOURL": "Post to URL",
        "ADDTOADDITIONALLIST": "Assign to Additional List",
        "SPORTSFEED": "Sports Feed",
        "STOP": "Stop",
        "CATCHALL": "Catchall",
        "HELP": "Help",
        "DAYPART": "Daypart"
      }
    },
    "bucketSize": {
      "label": "Time summary",
      "placeholder": "Select time summary...",
      "options": {
        "DAY": "Day",
        "WEEK": "Week",
        "MONTH": "Month"
      }
    },
    "messageType": {
      "label": "Message type",
      "placeholder": "All",
      "options": {
        "MO": "Incoming (MOs)",
        "MT": "Outgoing (MTs)"
      }
    },
    "scope": {
      "label": "Scope",
      "placeholder": "All",
      "options": {
        "mobileFlow": "Campaign",
        "keyword": "Keyword"
      }
    },
    "summaryType": {
      "label": "Summarize by",
      "options": {
        "MOBILEFLOW": "All",
        "SINGLE_MOBILEFLOW": "Campaign",
        "KEYWORD": "Keyword"
      }
    },
    "timeframe": {
      "label": "Timeframe of report",
      "placeholder": "Select timeframe...",
      "options": {
        "TODAY": "Today",
        "YESTERDAY": "Yesterday",
        "THISWEEK": "This week (Sun - Today)",
        "LAST7": "Last 7 days",
        "LASTWEEK": "Last week (Sun - Sat)",
        "LAST14": "Last 14 days",
        "THISMONTH": "This month",
        "LAST30": "Last 30 days",
        "LASTMONTH": "Last month",
        "CUSTOM": "Custom"
      }
    },
    "repeatInterval": {
      "label": "Repeats",
      "placeholder": "Never",
      "options": {
        "DAILY": "Daily",
        "WEEKLY": "Weekly",
        "MONTHLY": "Monthly"
      }
    },
    "repeatEvery": {
      "label": "Repeats every",
      "options": {
        "1": "1",
        "2": "2",
        "3": "3",
        "4": "4",
        "5": "5",
        "6": "6",
        "7": "7",
        "8": "8",
        "9": "9",
        "10": "10",
        "11": "11",
        "12": "12",
        "13": "13",
        "14": "14",
        "15": "15",
        "16": "16",
        "17": "17",
        "18": "18",
        "19": "19",
        "20": "20",
        "21": "21",
        "22": "22",
        "23": "23",
        "24": "24",
        "25": "25",
        "26": "26",
        "27": "27",
        "28": "28",
        "29": "29",
        "30": "30"
      }
    }
  },
  "subscriberProfile": {
    "validation": {
      "value": "Invalid format"
    }
  },
  "pass": {
    "validation": {
      "backgroundColor": "Must be a valid hex color",
      "foregroundColor": "Must be a valid hex color",
      "labelColor": "Must be a valid hex color",
      "latitude": "Must be a valid latitude coordinate",
      "longitude": "Must be a valid longitude coordinate"
    }
  },
  "validation": {
    "badrequest": "Could not process your request",
    "allMustBeValid": "All must be valid"
  },
  "copyright": "&copy; ",
  "talkToUs": " JA.TXT SMS Marketing Software is a product of JA Interactive, LLC.",
  "supportEmail": "",
  "title": "JA.TXT",
  "deployTarget": "jatxt",
  "legalUrl": {
    "full": "http://www.jatxt.com/privacy-policy/",
    "short": "www.jatxt.com/privacy-policy/"
  },
  "supportLink": "http://www.jatxt.com/contact/",
  "settings": {
    "users": {
      "impersonateModal": "Are you sure you want to impersonate user "
    }
  },
  "sitemap": {
    "home": {
      "url": "/"
    },
    "dashboard": {
      "url": "/insights",
      "label": "Reports"
    },
    "conversations": {
      "url": "/old-conversations",
      "label": "Conversations"
    },
    "messages": {
      "url": "/message",
      "label": "Broadcasts"
    },
    "scheduled_messages": {
      "url": "/messages/scheduled",
      "label": "Scheduled",
      "calendar": "/calendar"
    },
    "campaigns": {
      "url": "",
      "label": "Campaigns"
    },
    "campaignActivity": {
      "url": "/campaignactivity",
      "label": "Campaign Activity"
    },
    "stream": {
      "url": "/conversations",
      "label": "Conversations"
    },
    "ads": {
      "url": "/ads",
      "label": "Ads & Sponsorships"
    },
    "sponsorships": {
      "url": "/sponsorships",
      "label": "Ads & Sponsorships"
    },
    "smartlists": {
      "url": "/smartlists",
      "label": "Smart Segments",
      "listSubUrl": "/(?:(new)|(?:([^/]+)(/clone)?)|(?:([^/]+)(/page/\\d)?))$"
    },
    "flows": {
      "url": "/campaigns",
      "label": "Campaigns",
      "clonePostFix": "/clone",
      "flowSubUrl": "/(?:(new)|(?:([^/]+)(/clone)?))$"
    },
    "keywords": {
      "url": "/keywords",
      "label": "Keywords"
    },
    "coupon": {
      "url": "/coupon"
    },
    "coupons": {
      "url": "/coupons",
      "label": "Coupons"
    },
    "passes": {
      "url": "/passes",
      "label": "Passes"
    },
    "reports": {
      "url": "/reports",
      "label": "Reports",
      "reportSubUrl": "(?:/page/([^/]+))?",
      "resultSubUrl": "(?:/([^/]+)(?:/page/([^/]+))?)?",
      "scheduled": "/scheduled",
      "recurring": "/recurring",
      "scheduledUrl": "/reports/scheduled/",
      "recurringUrl": "/reports/recurring",
      "regexExcludeScheduled": "(?!/scheduled)",
      "regexExcludeRecurring": "(?!/recurring)",
      "regexExcludePage": "(?!/page)",
      "regexExcludeId": "(?!/([a-zA-Z0-9]{24,}))",
      "toggleLabel": "Available",
      "scheduledToggleLabel": "Scheduled",
      "scheduledReportsLabel": "Reports",
      "recurringToggleLabel": "Recurring",
      "recurringReportsLabel": "Reports",
      "newReportUrl": "/reports/new",
      "regexExcludeNew": "(?!/new)",
      "newReportRegex": "/(?:(new)|(?:([^/]+)))"
    },
    "lists": {
      "url": "/lists",
      "label": "Lists"
    },
    "metadata": {
      "url": "/metadata",
      "label": "Metadata"
    },
    "subscriber": {
      "url": "/subscriber",
      "searchUrl": "/subscribers/search"
    },
    "channels": {
      "url": "/channels",
      "label": "Channels"
    },
    "users": {
      "url": "/users",
      "label": "Users"
    },
    "uploads": {
      "label": "Uploads",
      "url": "/uploads"
    },
    "roles": {
      "url": "/roles",
      "label": "Roles"
    },
    "settings": {
      "url": "",
      "label": "Settings"
    },
    "sweeps": {
      "url": "/sweeps",
      "label": "Sweepstakes"
    },
    "accounts": {
      "url": "/subaccounts",
      "label": "Sub-Accounts"
    },
    "signout": {
      "url": "/signout",
      "label": "Sign Out"
    }
  },
  "welcomeModal": {
    "videoUrl": "http://player.vimeo.com/video/74163428"
  },
  "couponModal": {
    "duplicateName": "This coupon group name is already in use. Please choose a new coupon group name."
  }
};});

