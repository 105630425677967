define('msgme/widgets/add-to-list',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/widget'
], function (_, ko, Widget) {
    $.widget('msgme.msgme_add_to_list', Widget, {

        _create: function () {
            Widget.prototype._create.apply(this);

            this.element.find('.opt-in-msg .msgme-message-textbox').
                msgme_message_textbox({
                data: this.options.data.addToList.optInMessage
            });
            this.element.find('.confirm-msg .msgme-message-textbox').
                msgme_message_textbox({
                data: this.options.data.addToList.confirmMessage
            });
            this.element.find('.already-subscribed .msgme-message-textbox').
                msgme_message_textbox({
                data: this.options.data.addToList.subscribedMessage
            });
        }
    });

    return $.widget.msgme_add_to_list;
});

