define('msgme/viewmodel/flowmodule-collectmetadata',[
    'msgme/underscore',
    'msgme/viewmodel'
], function (_, viewmodel) {
    var mapping = {
        defaults: {
            params: {
                metadataId: null,

                requestMessage: '',
                requestSubject: '',
                requestFiles: [],
                requestAd: null,
                requestSponsorship: null,
                requestSMSFallback: null,

                responseMessage: '',
                responseSubject: '',
                responseFiles: [],
                responseAd: null,
                responseSponsorship: null,
                responseSMSFallback: null,

                invalidFormatMessage: '',
                invalidFormatSubject: '',
                invalidFormatFiles: [],
                invalidFormatAd: null,
                invalidFormatSponsorship: null,
                invalidFormatSMSFallback: null
            }
        },

        local: {
            title: 'Collect Metadata',

            requestCurrentTab: 'sms',
            responseCurrentTab: 'sms',
            invalidFormatCurrentTab: 'sms',
            metadataOptions: null,
            metadataOptgroups: null
        },

        computed: {
            createMetadataDropdown: function () {
                var metadata = viewmodel.globals.metadata();

                this.metadataOptions(
                    _.compact(
                        _.map(metadata, function (meta) {
                            if (meta.scope === 'PROFILE') {
                                return {
                                    class: 'profile',
                                    value: meta.id,
                                    name: meta.name
                                };
                            } else if (meta.scope === 'GROUP' ||
                                meta.scope === 'ACCOUNT') {
                                return {
                                    class: 'custom',
                                    value: meta.id,
                                    name: meta.name
                                };
                            } else if (meta.scope === 'SYSTEM') {
                                return {
                                    class: 'system',
                                    value: meta.id,
                                    name: meta.name
                                };
                            }
                        })));
                this.metadataOptgroups([]);
                if (_.find(metadata, function (m) {
                    return m.scope === 'ACCOUNT' ||
                        m.scope === 'GROUP';
                })) {
                    this.metadataOptgroups().push({
                        value: 'custom',
                        label: 'Custom'
                    });
                }
                if (_.find(metadata, function (m) {
                    return m.scope === 'PROFILE';
                })) {
                    this.metadataOptgroups().push({
                        value: 'profile',
                        label: 'Profile'
                    });
                }
                if (_.find(metadata, function (m) {
                    return m.scope === 'SYSTEM';
                })) {
                    this.metadataOptgroups().push({
                        value: 'system',
                        label: 'System'
                    });
                }
            }
        },

        params: {
            mapping: {
                validation: {
                    metadataId: {
                        required: true
                    }
                }
            }
        }
    };

    return {
        mapping: mapping
    };
});

