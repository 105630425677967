define('widgets/glance-stat-card/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/format',
    './../stat-card/index',
    'text!./template.html'
], function (_, ko, viewmodel, format, statCard, template) {
    $.widget('msgme.msgme_glance_stat_card', statCard.widget, {
        _mapping: {
            defaults: {
                totalMO: 0,
                totalMT: 0,
                totalBroadcasts: 0,
                engagement: 0,
                hierarchy: null
            },
        },

        _template: template,

        _createViewModel: function () {
            var vm = this._super.apply(this, arguments);

            vm.data(ko.mapping.fromJS({
                type: 'glance',
            }));
            return vm;
        },

        _loadData: function () {
            var vm = this.option('viewmodel');
            var hierarchy = vm.hierarchy();
            var messageSummary =
                waterfall.reporting.messageSummary.MOBILEFLOW.fetch({
                    size: 30,
                    bucketSize: 'MONTH',
                    timeframe: 'LAST30',
                    group: hierarchy
                });
            var query = {
                size: 3,
                startDate: Date.create().rewind({months: 1}).toISOString(),
                count: false,
                group: hierarchy
            };
            var config = {
                url: waterfall.broadcasts.config.url + '/summaries'
            };
            var broadcasts = waterfall.broadcasts.fetch(query, config);
            var glanceEl = this.element;

            glanceEl.msgme('busy', true);

            return $.when(messageSummary, broadcasts).
                done(function (msgSummary, broadcasts) {
                    var totalMO = _.reduce(msgSummary, function (num, record) {
                        return record.mosTotal + num;
                    }, 0);
                    var totalMT = _.reduce(msgSummary, function (num, record) {
                        return record.smsMtsTotal + num;
                    }, 0);
                    var engagement = totalMO === 0 || totalMT === 0 ? '0%' :
                        Math.round((totalMO / totalMT) * 100 * 100) / 100 + '%';

                    vm.totalMT(format.integer(totalMT));
                    vm.totalMO(format.integer(totalMO));
                    vm.totalBroadcasts(format.integer(broadcasts.total));
                    vm.engagement(engagement);
                    glanceEl.msgme('busy', false);
                });
        },

        _create: function () {
            statCard.widget.prototype._create.apply(this, arguments);

            this.option('viewmodel').hierarchy.subscribe(
                _.bind(this._loadData, this));
        }
    });

    return {
        widget: $.msgme.msgme_glance_stat_card,
        mapping: statCard.mapping
    };
});

