define('msgme/viewmodel/channel',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel'
], function (_, ko, viewmodel) {
    var model = ko.observable(null);
    return {
        mapping: {
            defaults: {
                name: '',
                config: {}
            },
            local: {
                profiles: [],
                audiences: []
            },
            computed: {
                displayName: function (root) {
                    return _.capitalize(root.name());
                }
            }
        },
        model: viewmodel.channel = model,
        collection: viewmodel.channels
    };
});

