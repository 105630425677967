define('msgme/widgets/flowmodule-dategate',[
    'msgme/underscore',
    'jquery',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-dategate'
],
function (_, $, ko, flowmodule, dategate) {
    $.widget('msgme.msgme_flowmodule_dategate', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-dategate-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
        }
    });

    $.msgme.msgme_flowmodule_dategate.Model =
        flowmodule.modelFactory({}, {
        mapping: dategate.mapping
    });

    return $.msgme.msgme_flowmodule_dategate;
});


