define('msgme/widgets/flowmodule-collectmetadata',[
    'jquery',
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-collectmetadata'
], function ($, _, ko, flowmodule, collectmetadata) {
    $.widget('msgme.msgme_flowmodule_collectmetadata', flowmodule, {
        options: {
            widgetEventPrefix: 'flowmodule-collectmetadata-'
        },

        _create: function () {
            flowmodule.prototype._create.apply(this);
            var data = this.options.data();
            var params = data.params;
            var delay;

            params.requestMessage.extend({ required: true });
            params.invalidFormatMessage.extend({ required: true });

            if (data.metadataOptions()) {
                delay = 0;
            } else {
                delay = 300;
            }
            _.delay(_.bind(function () {
                this.element.find('.store-meta').
                    selectize({
                    maxOptions: 1000,
                    options: data.metadataOptions(),
                    optgroups: data.metadataOptgroups(),
                    optgroupField: 'class',
                    labelField: 'name',
                    searchField: 'name',
                    onInitialize: function () {
                        this.setValue(params.metadataId());
                    }
                });
            }, this), delay);
        }
    });

    $.msgme.msgme_flowmodule_collectmetadata.Model =
        flowmodule.modelFactory({}, { mapping: collectmetadata.mapping });

    return $.msgme.msgme_flowmodule_collectmetadata;
});


