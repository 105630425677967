define('msgme/widgets/modal',[
    'jquery',
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/widget'
],
function ($, _, ko, Widget) {

    function enableButton(button, enabled) {
        if (enabled) {
            button.removeAttr('disabled');
        } else {
            button.attr('disabled', 'disabled');
        }
    }

    $.widget('msgme.msgme_modal', Widget, {
        options: {
            widgetEventPrefix: 'modal-',
            modalOptions: { show: false },
            okEnabled: true,
            cancelEnabled: true,
            activeTab: null,
            action: null
        },

        _create: function () {
            Widget.prototype._create.apply(this);

            var root = this.element;

            // call handlers in the context of the plugin instance
            this._onOkClick = _.bind(this._onOkClick, this);
            this._onCancelClick = _.bind(this._onCancelClick, this);
            this._onHashChange = _.bind(this._onHashChange, this);
            this._onRevive = _.bind(this._onRevive, this);

            root.on('click', 'button.ok', this._onOkClick);
            root.on('click', 'button.cancel', this._onCancelClick);

            root.bind('modal-okenabled-change', function (e, data) {
                enableButton($(this).find('button.ok'), data.value);
            });
            root.bind('modal-cancelenabled-change', function (e, data) {
                enableButton($(this).find('button.cancel'), data.value);
            });

            // close the modal if the user navigates away
            $(window).on('hashchange', this._onHashChange);
            // hook to revive the child modal
            this.element.one('revive', this._onRevive);

            this.option('okEnabled', this.options.okEnabled);
            this.option('cancelEnabled', this.options.cancelEnabled);

            root.find('.modal').modal(this.options.modalOptions);
        },

        _onRevive: function (e, newEl) {
            // restore new modal to correct state
            if (this._open) {
                $(newEl).find('.modal').modal('show');
            } else {
                $(newEl).find('.modal').modal('hide');
            }
        },

        _onOkClick: function () {
            if (this._trigger('submit') !== false) {
                this.close();
            }
        },

        _onCancelClick: function () {
            if (this._trigger('cancel') !== false) {
                this.close();
            }
        },

        _onHashChange: function () {
            if (this._trigger('hashchange') !== false) {
                this.close();
            }
        },

        _setActiveTab: function (tabName) {
            $('.' + tabName + '-tab').tab('show');
            $('.tab-pane').removeClass('active');
            $('#' + tabName + '.tab-pane').addClass('active');
            $('.tab-content .row.pane').removeClass('hidden');
        },

        /**
         * Open the modal dialog.
         *
         * Fires 'modal-open' event. Handlers can cancel
         * opening by returning false.
         */
        open: function () {
            if (this._trigger('open') !== false) {
                this.element.find('.modal').modal('show');
                this._open = true;
                $(window).on('hashchange', this._onHashChange);
                this._trigger('afteropen');
                if (this.options.activeTab) {
                    this._setActiveTab(this.options.activeTab);
                }
            }
        },

        /**
         * Close the modal dialog.
         *
         * Fires 'modal-close' event. Handlers can cancel
         * closing by returning false.
         */
        close: function () {
            if (this._trigger('close') !== false) {
                this.element.find('.modal').modal('hide');
                this._open = false;
                $(window).off('hashchange', this._onHashChange);
                this._trigger('afterclose');
            }
        },

        destroy: function () {
            this.element.find('.modal').modal('hide');
            this.element.off('click', 'button.ok', this._onOkClick);
            this.element.off('click', 'button.cancel', this._onCancelClick);
            $(window).off('hashchange', this._onHashChange);
        }
    });

    return $.msgme.msgme_modal;
});

