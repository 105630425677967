define('msgme/views/smartlists',[
    'msgme/underscore',
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/viewmodel/smartlists',
    'msgme/views/View',
    'msgme/widgets/pager',
    'msgme/util/format',
    'msgme/util/feature-flags',
    'json!widgets/shared-strings.json',
    'msgme/plugins'
],
function (
    _,
    splash,
    path,
    ko,
    viewmodel,
    smartlist,
    View,
    Pager,
    format,
    featureFlags,
    sharedStrings
) {
    var view = new View('#smartlists-view');
    var page, pager;
    var cachedsmartlists;

    smartlist.collection.noResults = ko.observable(false);
    smartlist.collection.type = ko.observable('current');
    pager = new Pager(smartlist.collection);

    function onFetchSuccess(smartlistInstances) {
        _.each(smartlistInstances, function (list) {
            var names = _.map(list.lists, function (list) {
                if (viewmodel.globals.lists.oneById(list)) {
                    return viewmodel.globals.lists.oneById(list).name;
                }
            });

            list.listNames = '';

            _.each(names, function (name, index) {
                if (index === names.length - 1) {
                    list.listNames = list.listNames + name;
                } else {
                    list.listNames = list.listNames + name + ', ';
                }
            });

            _.each(list.queryFilterDetails.details, function (d) {
                if (d.details) {
                    _.each(d.details, function (detail) {
                        var metadata = viewmodel.globals.metadata.
                            oneById(detail.metadata);
                        var operator = _.find(sharedStrings.operators,
                            function (op) {
                            return op.value === detail.operator;
                        });

                        if (metadata) {
                            detail.description = metadata.name + ' ' +
                                operator.text + ' ' + detail.value;
                        } else {
                            detail.description = '(removed metadata) ' +
                                operator.text + ' ' + detail.value;
                        }
                    });
                } else {
                    var metadata = viewmodel.globals.metadata.
                        oneById(d.metadata);
                    var operator = _.find(sharedStrings.operators,
                        function (op) {
                        return op.value === d.operator;
                    });
                    var name = metadata ? metadata.name : '[deleted]';

                    d.description = name + ' ' + operator.text +
                        ' ' + d.value;
                }
            });
        });
        smartlist.collection.rows(smartlistInstances.slice());
        pager.update(smartlistInstances);
        splash.hide();
        $('#smartlists-view-main').msgme('busy', false);
        $('#smartlists-view-empty').removeClass('hidden');
    }

    function onFailure(what, e) {
        console.error('Failed to fetch', what + ': ', e);
    }

    function fetch() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#smartlists-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !smartlist.collection.rows().length) {
            if (!smartlist.collection.pageSize() ||
                !smartlist.collection.pageIndex() ||
                !smartlist.collection.pageCount()) {
                resetViewmodelCollection();
            }

            // fetch the smartlists and populate page if not in a search
            view.callAPI('smartlists.fetch', {
                    page: page,
                    size: smartlist.collection.pageSize()
                }).
                done(function (result) {
                    cachedsmartlists = result;
                    onFetchSuccess(result);
                }).
                fail(_.bind(onFailure, this, 'smartlists'));
        } else {
            //fetch for cache
            view.callAPI('smartlists.fetch', {
                    page: page,
                    size: 15
                }).
                done(function (result) {
                    cachedsmartlists = result;
                    $('#smartlists-view-main').msgme('busy', false);
                }).
                fail(_.bind(onFailure, this, 'smartlists'));
        }
        
        viewmodel.globals.smartlists.refresh(true).done(function () {
            instantiateSearchBox();
        });
    }

    view.root.on('click', 'td i.fa.fa-remove', function () {
        var rowEl = $(this).closest('tr');
        var record = smartlist.collection.rows()[rowEl.index()];
        var name = record.name;

        msgme.modal.confirm('Are you sure you want to delete segment ' +
            name + '?', {
            title: 'Delete Segment'
        }).done(_.bind(function (value) {
            if (value) {
                rowEl.addClass('deleting');
                record.del().
                    done(function () {
                        smartlist.collection.rows.
                            splice(rowEl.index(), 1);
                        msgme.alert.success(
                            'The segment has been deleted');
                        viewmodel.globals.smartlists.refresh(true);
                        fetch();
                    }).
                    fail(function () { rowEl.removeClass('deleting'); }).
                    fail(view.getRequestFailureFn(null,
                        'smartlist.delete',
                        record.data().id));
            }
        }, this));
    });

    function resetViewmodelCollection() {
        smartlist.collection.pageSize(15);
        smartlist.collection.pageCount(-1);
        smartlist.collection.pageIndex(-1);
        smartlist.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var smartlistNames = _.map(viewmodel.globals.smartlists(),
            function (smartlist) {
            return smartlist.name;
        });
        var $searchBoxEl = view.root.find('.search-box');

        if (!!$searchBoxEl.hasOwnProperty('autocomplete')) {
            $searchBoxEl.autocomplete('destroy');
        }

        $searchBoxEl.autocomplete({
            source: smartlistNames,
            delay: 100
        });
    }

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        smartlist.collection.noResults(false);
        if (!query) {
            onFetchSuccess(cachedsmartlists);
        } else {
            results = _.map(viewmodel.globals.smartlists(),
                function (smartlist) {
                if(smartlist.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return smartlist;
                }
            });

            onFetchSuccess(_.compact(results));

            if (!_.compact(results).length) {
                smartlist.collection.noResults(true);
            }
        }

        view.root.find('.search-box').autocomplete('close');
    });

    view.root.on('click', '.search-flow', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        onFetchSuccess(cachedsmartlists);
        view.root.find('.search-box').val('');
        smartlist.collection.noResults(false);
    });

    path.map(sharedStrings.sitemap.smartlists.url + '(/page/:page)').to(
        function () {
            viewmodel.globals.smartlists.refresh(true);
            if (!smartlist.collection.pageSize() ||
                !smartlist.collection.pageIndex() ||
                !smartlist.collection.pageCount() || !this.params.page) {
                resetViewmodelCollection();
            }
            view.show();
            instantiateSearchBox();
            page = this.params.page;
            fetch();
        });

    return view;
});

