define('widgets/emoji-insertion/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    './../three-widget',
    'json!./strings.json',
    'text!./template.html',
    './../metadata-select/index',
    './../selectize/index'
], function (_, viewmodel, ThreeWidget, strings, template) {

    var passedObservables = {
        isDisabled: false
    };

    var internalObservables = {
        message: ''
    };

    var allObservables = _.extend({}, passedObservables, internalObservables);

    var mapping = {
        defaults: _.clone(allObservables),
    };

    $.widget('msgme.msgme_emoji_insertion', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            var submitEmoji = _.bind(this._onEmojiClick, this);

            this.on('click', '.open-modal', '_openModal');
            this.element.find('.emoji-stuff').
                emojioneArea({
                    pickerPosition: 'right',
                    standalone: true,
                    hidePickerOnBlur: false,
                    events: {
                        emojibtn_click: function (editor) {
                            submitEmoji(
                                emojione.shortnameToUnicode(
                                    editor.context.dataset.name));
                        }
                    }
                });
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        },

        _onEmojiClick: function (value) {
            var $message =
                this.element.closest('.tab-pane').find('.message')[0];
            var startPos = $message.selectionStart;
            var text = value;

            if (startPos || startPos === 0) {
                var endPos = $message.selectionEnd;
                var completeMessage = $message.value.substring(0, startPos) +
                    text +
                    $message.value.substring(endPos, $message.value.length);
                
                $message.value = completeMessage;
                $($message).change();
            }
        },

        _openModal: function () {
            this.element.find('.emojionearea-button').click();
        }
    });

    return {
        widget: $.msgme.msgme_emoji_insertion,
        mapping: mapping
    };
});

