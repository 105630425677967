define('msgme/ko/validation/unique',[
    'msgme/underscore',
    'msgme/ko'
],
function (_, ko) {

    function tryQuery(query, params, callback, attempts) {
        var promise = params.source.fetch(query);

        promise.done(function (result) {
                var rows = result.rows();

                if (params.id !== void undefined) {
                    rows = rows.filter(function (element) {
                        return element.id() !== params.id;
                    });
                }

                callback(rows.length <= 0);
            }).
            fail(function (jqXHR, statusText, err) {
                attempts++;

                if (err.toLowerCase() !== 'abort' &&
                    attempts <= params.retries
                ) {
                    // try again with exponential backoff
                    setTimeout(
                        _.bind(tryQuery, this,
                            query, params, callback, attempts
                        ),
                        params.delay);
                    params.delay *= 2;
                }
            });

        return promise;
    }

    ko.validation.rules.uniqueValue = {
        async: true,
        validator: function (val, params, callback) {
            var query = {};

            if (this._pending && this._pending.abort) {
                this._pending.abort();
            }

            params = _.defaults(_.clone(params), {
                retries: 3,
                delay: 100
            });

            query[params.key] = val;

            this._pending = tryQuery(query, params, callback, 0).
                done(function () {
                    this._pending = null;
                });
        },
        message: 'Value must be unique'
    };
});

