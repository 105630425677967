define('widgets/subscriber-stat-card/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/util/format',
    'lib/d3',
    './../stat-card/index',
    'text!./template.html'
], function (_, ko, format, d3, statCard, template) {
    var dateOffsetConversion = {
        LAST7: ' last 7 days',
        LAST14: ' last 14 days',
        LAST30: ' last 30 days'
    };

    $.widget('msgme.msgme_subscriber_stat_card', statCard.widget, {
        _mapping: {
            defaults: {
                subs: 0,
                unsubs: 0,
                diff: 0,
                timeframe: 'LAST30',
                diffPos: 0,
                hierarchy: null
            },
            computed: {
                dateOffset: function () {
                    return dateOffsetConversion[this.timeframe()];
                }
            }
        },

        _template: template,

        _createViewModel: function () {
            var vm = this._super.apply(this, arguments);

            vm.data(ko.mapping.fromJS({
                type: 'subscribers',
                count: null,
                delta: null
            }));

            return vm;
        },

        _loadData: function () {
            var vm = this.option('viewmodel');
            var element = this.element;
            var hierarchy = vm.hierarchy();

            element.msgme('busy', true);
            return waterfall.reporting.subscriberGrowth.fetch({
                    timeframe: vm.timeframe(),
                    size: 30,
                    group: hierarchy
                }).
                then(function (results) {
                    var subs = _.reduce(results, function (sum, result) {
                        return result.noSubs + sum;
                    }, 0);

                    var unsubs = _.reduce(results, function (sum, result) {
                        return result.noUnsubs + sum;
                    }, 0);

                    vm.subs(format.integer(subs));
                    vm.unsubs(format.integer(unsubs));
                    vm.diff(format.integer(subs-unsubs));
                    vm.diffPos((subs - unsubs) > 0);
                }).
                always(function () {
                    element.msgme('busy', false);
                });
        },

        _create: function () {
            statCard.widget.prototype._create.apply(this, arguments);

            var vm = this.option('viewmodel');
            vm.timeframe.subscribe(_.bind(function () {
                this._loadData();
            }, this));
            vm.hierarchy.subscribe(_.bind(function () {
                this._loadData();
            }, this));
        },
    });

    return {
        widget: $.msgme.msgme_subscriber_stat_card,
        mapping: statCard.mapping
    };
});

