define('msgme/views/keywords',[
    'msgme/splash',
    'msgme/path',
    'msgme/ko',
    'msgme/underscore',
    'msgme/util/format',
    'msgme/viewmodel',
    'msgme/viewmodel/keywords',
    'msgme/views/View',
    'msgme/widgets/pager',
    'json!widgets/shared-strings.json',
    'msgme/plugins'
], function (splash, path, ko, _, format, viewmodel, keywords, View, Pager,
    sharedStrings
) {
    var view = new View('#keywords-view');
    var LINK_TEMPLATE = '<a href="%s/%s">%s</a>';
    var page, pager;
    var url = sharedStrings.sitemap.keywords.url;
    var keywordDelete = sharedStrings.keywords.del;
    var cachedKeywords;
    var allKeywords;
    keywords.collection.noResults = ko.observable(false);

    pager = new Pager(keywords.collection);

    function onKeywordsSuccess(keywordsInstances) {
        var rows = _.map(keywordsInstances.slice(), function (keyword) {
            keyword.flowLink = _.sprintf(LINK_TEMPLATE,
                sharedStrings.sitemap.flows.url,
                keyword.mobileFlow.id,
                keyword.mobileFlow.name);
            keyword.listLinks = _.map(keyword.lists, function (list) {
                return _.sprintf(LINK_TEMPLATE,
                    '/lists',
                    list.id,
                    list.name);
            }).join(', ');
            keyword.trigger.creationDate =
                format.uiDate(keyword.trigger.creationDate);

            return keyword;
        });

        keywords.collection.rows(rows);
        pager.update(keywordsInstances);

        splash.hide();
        $('#keywords-view-main').msgme('busy', false);
        $('#keywords-view-empty').removeClass('hidden');
    }

    function fetchKeywords() {
        page = page && _.isNumber(parseInt(page, 10)) ? page : 1;
        $('#keywords-view-main').msgme('busy', true);

        if (!view.root.find('.search-box').val() ||
            !keywords.collection.rows().length) {
            if (!keywords.collection.pageSize() ||
                !keywords.collection.pageIndex() ||
                !keywords.collection.pageCount()) {
                resetViewmodelCollection();
            }

            view.callAPI('keywords.details.fetch', {
                    page: page,
                    size: keywords.collection.pageSize
                }).
                done(function (result) {
                    cachedKeywords = result;
                    onKeywordsSuccess(result);
                });
        } else {
            view.callAPI('keywords.details.fetch', {
                    page: page,
                    size: 15
                }).
                done(function (result) {
                    cachedKeywords = result;
                    $('#keywords-view-main').msgme('busy', false);
                });

        }

        viewmodel.globals.keywords.refresh(true).done(function () {
            instantiateSearchBox();
        });
    }

    function fetchAllKeywords() {
        view.callAPI('keywords.details.fetch', {
                size: -1
            }).
            done(function (result) {
                allKeywords = result;
            });
    }

    function resetViewmodelCollection() {
        keywords.collection.pageSize(15);
        keywords.collection.pageCount(-1);
        keywords.collection.pageIndex(-1);
        keywords.collection.noResults(false);
        view.root.find('.search-box').val('');
    }

    function instantiateSearchBox() {
        var keywordNames = _.map(viewmodel.globals.keywords(),
            function (keyword) {
            return keyword.name;
        });

        view.root.find('.search-box').autocomplete({
            source: keywordNames,
            delay: 100
        });
    }

    // TODO: refactor this as functionality on the datatable
    $('#keywords-view').on('click', 'td i.fa.fa-remove', function () {
        var rowEl = $(this).closest('tr');
        var record = keywords.collection.rows()[rowEl.index()];
        var name = record.trigger.name;

        msgme.modal.confirm('Are you sure you want to delete keyword ' +
            name + '?', {
            title: keywordDelete.title
        }).done(_.bind(function (value) {
            if (value) {
                rowEl.addClass('deleting');
                record.del().
                    done(function () {
                        keywords.collection.rows.splice(rowEl.index(), 1);
                        msgme.alert.success(keywordDelete.success);
                        fetchKeywords();
                    }).
                    fail(function () { rowEl.removeClass('deleting'); }).
                    fail(view.getRequestFailureFn(null,
                        'trigger.delete',
                        record.data().id));
            }
        }, this));
    });

    view.root.on('submit', '.search-form', function () {
        var query = $('.search-box', this).val();
        var results;

        keywords.collection.noResults(false);
        view.root.find('.search-box').autocomplete('close');
        if (!query) {
            onKeywordsSuccess(cachedKeywords);
        } else {
            results = _.map(allKeywords, function (keyword) {
                if(keyword.trigger.name.toLowerCase().
                    indexOf(query.toLowerCase()) !== -1) {
                    return keyword;
                }
            });

            onKeywordsSuccess(_.compact(results));

            if (!_.compact(results).length) {
                keywords.collection.noResults(true);
            }
        }
    });

    view.root.on('click', '.search-flow', function () {
        view.root.find('.search-form').submit();
    });

    view.root.on('click', '.clear-search', function () {
        onKeywordsSuccess(cachedKeywords);
        view.root.find('.search-box').val('');
        keywords.collection.noResults(false);
    });

    path.map('/triggers(/page/:page)').to(function () {
        var page = this.params.page ? '/page/' + this.params.page : '';
        path.history.replaceState(null, null, url + page);
    });

    path.map(url + '(/page/:page)').to(function () {
        if (!keywords.collection.pageSize() ||
            !keywords.collection.pageIndex() ||
            !keywords.collection.pageCount()) {
            resetViewmodelCollection();
        }
        if (!this.params.page || !allKeywords) {
            fetchAllKeywords();
        }
        instantiateSearchBox();
        view.show();
        page = this.params.page;
        fetchKeywords();
    });

    return view;
});


