define('msgme/viewmodel/flowmodule-tagmetadata',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel'
], function(_, ko, viewmodel) {
    return {
        mapping: {
            local: {
                title: 'Tag Metadata',
                validValues: null,
                metadataOptions: null,
                metadataOptgroups: null
            },

            defaults: {
                params: null
            },

            /**
             * The computed and params mapping work around the busted payload
             * provided by the platform, which looks like:
             *
             * { 'some-id': 'the value' }
             */
            computed: {
                createMetadataDropdown: function () {
                    var metadata = viewmodel.globals.metadata();

                    this.metadataOptions(
                        _.compact(
                            _.map(metadata, function (meta) {
                                if (meta.scope === 'PROFILE') {
                                    return {
                                        class: 'profile',
                                        value: meta.id,
                                        name: meta.name
                                    };
                                } else if (meta.scope === 'GROUP' ||
                                    meta.scope === 'ACCOUNT') {
                                    return {
                                        class: 'custom',
                                        value: meta.id,
                                        name: meta.name
                                    };
                                } else if (meta.scope === 'SYSTEM') {
                                    return {
                                        class: 'system',
                                        value: meta.id,
                                        name: meta.name
                                    };
                                }
                            })));
                    this.metadataOptgroups([]);
                    if (_.find(metadata, function (m) {
                        return m.scope === 'ACCOUNT' ||
                            m.scope === 'GROUP';
                    })) {
                        this.metadataOptgroups().push({
                            value: 'custom',
                            label: 'Custom'
                        });
                    }
                    if (_.find(metadata, function (m) {
                        return m.scope === 'PROFILE';
                    })) {
                        this.metadataOptgroups().push({
                            value: 'profile',
                            label: 'Profile'
                        });
                    }
                    if (_.find(metadata, function (m) {
                        return m.scope === 'SYSTEM';
                    })) {
                        this.metadataOptgroups().push({
                            value: 'system',
                            label: 'System'
                        });
                    }
                },

                metadataId: {
                    read: function () {
                        var params = this.params();
                        for (var k in params) {
                            if (params.hasOwnProperty(k)) {
                                return k;
                            }
                        }
                        return null;
                    },
                    write: function (rootModel, value) {
                        var params = {};

                        if (value) {
                            params[value] = this.validValues() ?
                                this.fixedValue() : this.freeValue();
                            this.params(params);
                        }
                    }
                },

                fixedValue: {
                    read: function () {
                        var params = this.params();
                        for (var k in params) {
                            if (params.hasOwnProperty(k)) {
                                // return the first and only value
                                return params[k];
                            }
                        }

                        return '';
                    },
                    write: function (rootModel, value) {
                        if (this.validValues()) {
                            var id = this.metadataId();
                            var params = {};

                            if (id) {
                                params[id] = value;
                                this.params(params);
                            }
                        }
                    }
                },

                freeValue: {
                    read: function () {
                        var params = this.params();
                        for (var k in params) {
                            if (params.hasOwnProperty(k)) {
                                // return the first and only value
                                return params[k];
                            }
                        }

                        return '';
                    },
                    write: function (rootModel, value) {
                        if (!this.validValues()) {
                            var id = this.metadataId();
                            var params = {};

                            if (id) {
                                params[id] = value;
                                this.params(params);
                            }
                        }
                    }
                }
            },

            params: {
                create: function (options) {
                    return ko.observable(options.data);
                },
                mapping: false
            },

            validation: {
                metadataId: {
                    required: true
                },
                freeValue: {
                    required: {
                        onlyIf: function () {
                            return this.parent.validValues() == null;
                        }
                    }
                },
                fixedValue: {
                    required: {
                        onlyIf: function () {
                            return this.parent.validValues() != null;
                        }
                    }
                }
            }
        }
    };
});

