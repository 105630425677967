define('msgme/viewmodel/keywords',[
    'msgme/ko',
    'msgme/viewmodel'
], function(ko, viewmodel) {
    return {
        collection: viewmodel.keywords = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('keywords/page')
        }
    };
});



