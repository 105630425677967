define('msgme/ko/option',[
    'lib/knockout'
], function (ko) {
    ko.bindingHandlers.option = {
        update: function(element, valueAccessor) {
            var value = ko.utils.unwrapObservable(valueAccessor());
            ko.selectExtensions.writeValue(element, value);
        }
    };

    return ko.bindingHandlers.option;
});


