define('widgets/navbar/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/path',
    'msgme/globals',
    'msgme/auth',
    'msgme/viewmodel',
    'msgme/util/format',
    'msgme/util/feature-flags',
    './../three-widget',
    'text!./template.html',
    'json!./strings.json',
    'json!./../shared-strings.json'
], function (
    _,
    ko,
    path,
    globals,
    auth,
    viewmodel,
    format,
    featureFlags,
    ThreeWidget,
    template,
    strings,
    sharedStrings
) {

    var iconMap = {
        dashboard: 'fa fa-dashboard',
        conversations: 'fa fa-comments-alt',
        messages: 'fa fa-edit',
        flows: 'fa fa-sitemap',
        lists: 'fa fa-sitemap',
        metadata: 'fa fa-tag',
        keywords: 'fa fa-key',
        coupons: 'fa fa-ticket',
        passes: 'fa fa-apple',
        reports: 'fa fa-bar-chart',
        scheduled_messages: 'fa fa-calendar',
        uploads: 'fa fa-upload',
        sweeps: 'fa fa-trophy',
        ads: 'fa fa-usd',
        sponsorships: 'fa fa-usd',
        smartlists: 'fa fa-rocket'
    };

    var mapping = {
        defaults: {
            data: null,

            // 'current' stores a string corresponding to the entry in
            // sharedStrings.sitemap that indicates our current page
            current: null,

            // topnav, topnavSecondary, and subnav determine what the heirarchy
            // of the navbar will be. if a page should have subnav, add a
            // corresponding entry for it in the subnav object.
            //
            // the names correspond to the property names of
            // sharedStrings.sitemap
            topnav: [
                'dashboard',
                'stream',
                'messages'
            ],
            topnavSecondary: [
                // make sure settings is not last, otherwise it will mess up
                // the slight offset of the first secondary nav item.
                'settings',
                'flows',
                'lists'
            ],
            // If a link appears multiple times below, the ones that appear
            // earlier will redirect to the one that appears last. This is
            // taking advantage quirks in the behavior of `currentTop` as of
            // 10e3cb3, which actually assumes that a link will only appear
            // once below. It also takes advantage of the fact that all major
            // browsers using insertion order for object key ordering.
            // (http://stackoverflow.com/a/23202095/414220)
            subnav: {
                flows: [
                    'flows',
                    'keywords',
                    'coupons',
                    'sweeps',
                    //'passes',
                    'campaignActivity',
                    'sponsorships',
                    'ads'
                ],
                dashboard: [
                    'dashboard',
                    'reports'
                ],
                messages: [
                    'messages',
                    'scheduled_messages'
                ],
                lists: [
                    'lists',
                    'smartlists',
                    'uploads',
                    'metadata'
                ],
                settings: [
                    'users',
                    'roles',
                    'accounts'
                ]
            },
            restrictUI: false,
            listUploadEnabled: false,
            isMarketron: false,
            marketronRestrictUsers: false
        },

        computed: {
            currentLabel: {
                read: function () {
                    var page = sharedStrings.sitemap[this.currentTop()];

                    return page && page.label;
                },
                deferEvaluation: true
            },

            // if we're on a page corresponding to an item in the subnav, we
            // still want to highlight the corresponding top-nav item. this is
            // used by the template to highlight the topnav item even if we're
            // on one of its subnavs
            currentTop: function () {
                var current = this.current();
                var currentTop = current;

                // we need to use ko.mapping.toJS b/c the mapping added
                // properties to this.subnav lik isValid, isDirty, etc., and we
                // only want to iterate over the items defined in the defaults
                _.each(ko.mapping.toJS(this.subnav), function (subs, token) {
                    if (_.indexOf(subs, current) >= 0) {
                        currentTop = token;
                    }
                });

                return currentTop;
            },

            flag: {
                read: function () {
                    return this.session().maxSmsLength < 160 ?
                        '/img/three/flags/ca.png' : '/img/three/flags/us.png';
                },
                deferEvaluation: true
            },

            nav: function () {
                var tokenIcon;

                function navObject(token, secondary) {
                    // if dashboard, use report icon as we renamed the nav
                    // from insights to reports
                    if (token === 'dashboard') {
                        tokenIcon = 'reports';
                    } else {
                        tokenIcon = token;
                    }

                    return _.extend({
                            name: token,
                            icon: iconMap[tokenIcon],
                            token: token,
                            secondary: secondary
                        }, sharedStrings.sitemap[token]);
                }

                return _.chain(this.topnav()).
                    filter(function (nav) {
                        return !(viewmodel.features.hasOwnProperty(nav) &&
                            !viewmodel.features[nav]);
                    }).map(function (nav) {
                        return navObject(nav, false);
                    }).value().
                    concat(_.map(this.topnavSecondary(), function (nav) {
                        return navObject(nav, true);
                    }));
            },

            // this and the 'shortcodes' and 'sortedShortcodes' computeds are
            // just shortcuts for the templates
            session: function () {
                var data = this.data();
                var shortcodes = data && data.shortcodes;

                return (shortcodes && shortcodes.session()) || {};
            },

            shortcodes: function () {
                var shortcodes = this.data() && this.data().shortcodes();

                return shortcodes || ko.observableArray([]);
            },

            sortedShortcodes: function () {
                function getShortcode(record) { return record.shortcode; }

                return _.sortBy(this.shortcodes(), getShortcode);
            },

            // if the current page has no subnav, this returns undefined,
            // otherwise it returns a list of the appropriate items from
            // sharedStrings.sitemap
            sub: function () {
                var current = this.currentTop();
                var me = this;
                var subnavLabel;
                var hasSweeps;
                var toggleAdsSponsorships = 'sponsorships';
                var disableAds = null;
                var disableSponsorships = null;

                if (viewmodel.globals.sweeps() &&
                    viewmodel.globals.sweeps().length) {
                    hasSweeps = true;
                } else {
                    hasSweeps = false;
                }

                if (this.current() === 'ads') {
                    toggleAdsSponsorships = 'sponsorships';
                } else if (this.current() === 'sponsorships') {
                    toggleAdsSponsorships = 'ads';
                }

                if (sharedStrings.deployTarget !== 'marketron') {
                    disableAds = 'ads';
                    disableSponsorships = 'sponsorships';
                }

                return current && this.subnav[current] &&
                    _.map(_.filter(this.subnav[current](), function (sub) {
                        return !((sub === 'passes' &&
                            !auth.has('passbook:read:*')) ||
                            (sub === 'coupons' && !auth.has('coupon:read:*')) ||
                            (sub ==='uploads' && !me.listUploadEnabled()) ||
                            (sub === 'sweeps' && !hasSweeps) ||
                            sub === 'campaignActivity' ||
                            sub === toggleAdsSponsorships ||
                            sub === disableSponsorships ||
                            sub === disableAds ||
                            (sub === 'channels' &&
                            sharedStrings.deployTarget === 'marketron') ||
                            (sub === 'passes' &&
                            sharedStrings.deployTarget === 'experian'));
                    }), function (sub) {
                        if (current === 'messages') {
                            subnavLabel = strings.new_message;
                        } else if (current === 'dashboard') {
                            subnavLabel = strings.insights;
                        } else if (current === 'lists') {
                            subnavLabel = strings.lists;
                        } else {
                            subnavLabel = strings.overview;
                        }

                        return _.extend({
                            icon: iconMap[sub],
                            token: sub
                        }, sharedStrings.sitemap[sub], {
                            label: current === sub ? subnavLabel :
                                sharedStrings.sitemap[sub].label
                        });
                    });
            },

            unread: function () {
                var count = this.data().streamCount &&
                    this.data().streamCount();

                return _.isNumber(count) ?
                    (count > 500 ? '500+' : count) : null;
            },

            username: function () {
                return waterfall.authenticate.username;
            },

            isImpersonating: function () {
                return waterfall.authenticate.impersonated;
            }
        }
    };

    $.widget('msgme.msgme_navbar', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);
            this.element.find('.dropdown-menu').dropdown();
            this.on('submit', '.navbar-form', '_onSearch');
            this.on('mouseup', '.dropdown-menu a', '_onMenuClick');
            this.on('click', '.revert-impersonation', '_revertImpersonation');
            path.on('navigation', _.bind(this._onNavigation, this));
            this._onNavigation();
            featureFlags('restrictPapaMurphysUIL1').then(_.bind(function () {
                this.options.viewmodel.restrictUI(true);
            }, this));
            featureFlags('enableListUpload').then(_.bind(function () {
                this.options.viewmodel.listUploadEnabled(true);
            }, this));
            featureFlags('marketronFeatures').then(_.bind(function () {
                this.options.viewmodel.isMarketron(true);
            }, this));
            featureFlags('marketronDisableUserPage').then(_.bind(function () {
                this.options.viewmodel.marketronRestrictUsers(true);
            }, this));
        },

        // bootstrap eats the click events at the <html> level for menu items,
        // so we need to explicitly handle them even if we're just setting the
        // path
        _onMenuClick: function (evt) {
            var $el = $(evt.currentTarget);
            var id = $el.attr('data-shortcode-id');
            var href = $el.attr('href');

            if (id) {
                waterfall.shortcodes.session(id).
                    // TODO: handle the failure case here -- we need to
                    // standardize three widget error handling
                    done(function () {
                        location.reload();
                    });
            } else if (href) {
                this._setPath(href);
            }
        },

        _onNavigation: function () {
            var path = Modernizr.history ?
                location.pathname : location.hash.slice(1);

            // set the current page by matching the path to the sitemap. if no
            // match is found, match / (we sort descending by length so that
            // /messages doesn't match /)
            _.chain(sharedStrings.sitemap).
                map(function (v, k) { return _.extend({token: k}, v);}).
                sortBy(function (a) { return -a.url.length; }).
                find(_.bind(function (page) {
                    if (path.indexOf(page.url) === 0) {
                        this.option('viewmodel').current(page.token);

                        return true;
                    }
                }, this));
        },

        _onSearch: function (evt) {
            evt.preventDefault();
            var $in = this.element.find('.navbar-form input');
            var formattedNum;
            var searchQuery;

            // Prevent searches on the empty string
            if (!$in.val()) { return; }
            formattedNum = format.findPhoneNo($in.val());
            searchQuery = formattedNum ? formattedNum : $in.val();

            this._setPath(sharedStrings.sitemap.subscriber.searchUrl +
                '/' + searchQuery);
            $in.val('');
        },

        _setPath: function (pth) {
            path.history.pushState(null, null, pth);
        },

        _revertImpersonation: function () {
            msgme.modal.confirm(
                'Are you sure you want revert to the previous user?', {
                title: 'Revert Impersonation'
            }).done(function (value) {
                if (value) {
                    waterfall.impersonate.stop().
                        done(function () {
                            path.history.pushState(null, null,
                                sharedStrings.sitemap.users.url);
                            location.reload();
                        });
                }
            });
        }
    });

    return {
        widget: $.msgme.msgme_navbar,
        mapping: mapping
    };
});

