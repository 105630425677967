define('msgme/viewmodel/flowmodule-dategate',[
    'msgme/util/format',
    'msgme/viewmodel',
], function (format, viewmodel) {
    var mapping = {
        local: {
            title: 'Campaign Duration'
        },
        params: {
            mapping: {
                defaults: {
                    startDate: null,
                    endDate: null,
                    preDateMessage: null,
                    preDateFiles: [],
                    preDateAd: null,
                    preDateSponsorship: null,
                    preDateSubject: null,
                    preDateNextStep: 'terminate',
                    preDateMobileFlow: null,
                    preDateSMSFallback: null,
                    postDateMessage: null,
                    postDateFiles: [],
                    postDateAd: null,
                    postDateSponsorship: null,
                    postDateSubject: null,
                    postDateNextStep: 'terminate',
                    postDateMobileFlow: null,
                    preDateMessageCurrentTab: null,
                    postDateMessageCurrentTab: null,
                    postDateSMSFallback: null,
                    endDateTime: null
                },

                subscribe: {
                    startDate: function (value, parent, root) {
                        root.startDateTime(value);
                    },

                    endDate: function (value, parent, root) {
                        root.endDateTime(value);
                    }
                },

                computed: {
                    startDateTime: {
                        read: function () {
                            if (!this.startDate()) {
                                return null;
                            } else {
                                return new Date(this.startDate());
                            }
                        },
                        write: function (parent, value) {
                            if (value) {
                                this.startDate(format.apiDate(value));
                            } else {
                                this.startDate(null);
                            }
                        },

                        deferEvaluation: true
                    },

                    endDateTime: {
                        read: function () {
                            if (!this.endDate()) {
                                return null;
                            } else {
                                return new Date(this.endDate());
                            }
                        },
                        write: function (parent, value) {
                            if (value) {
                                this.endDate(format.apiDate(value));
                            } else {
                                this.endDate(null);
                            }
                        },

                        deferEvaluation: true
                    }
                },

                validation: {
                    endDateTime: {
                        validator: function (value) {
                            if (this.parent.startDateTime()) {
                                return Date.create(this.parent.endDateTime()) >=
                                    Date.create(this.parent.startDateTime());
                            } else {
                                return !!value;
                            }
                        },

                        message: function () {
                            return 'Please choose a date after the start date';
                        }
                    },

                    startDateTime: {
                        required: true
                    },

                    preDateNextStep: {
                        required: true
                    },

                    postDateNextStep: {
                        required: true
                    },

                    preDateMobileFlow: {
                        validator: function (val) {
                            if (this.parent.preDateNextStep() ===
                                'forwardToMobileFlow') {
                                if (viewmodel.flow() && viewmodel.flow().id) {
                                    return !!val && val !==
                                        viewmodel.flow().id();
                                } else {
                                    return !!val;
                                }
                            } else {
                                return true;
                            }
                        },

                        message: 'Please select a different list'
                    },

                    postDateMobileFlow: {
                        validator: function (val) {
                            if (this.parent.postDateNextStep() ===
                                'forwardToMobileFlow') {
                                if (viewmodel.flow() && viewmodel.flow().id) {
                                    return !!val && val !==
                                        viewmodel.flow().id();
                                } else {
                                    return !!val;
                                }
                            } else {
                                return true;
                            }
                        },

                        message: 'Please select a different list'
                    }
                }
            }
        }
    };

    return {
        mapping: mapping
    };
});

