define('msgme/util/is-mms-enabled',[
    'msgme/auth',
    'msgme/viewmodel'
], function (auth, viewmodel) {

    function isMmsEnabled() {
        return auth.has('mms:create:*') &&
            viewmodel.globals.shortcodes.session().mms;
    }

    return isMmsEnabled;
});

