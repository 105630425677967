define('widgets/pass-insertion/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    './../three-widget',
    'json!./strings.json',
    'text!./template.html'
], function (_, viewmodel, ThreeWidget, strings, template) {
    var passedObservables = {
        isDisabled: false
    };

    var internalObservables = {
        pass: null
    };

    var allObservables = _.extend({}, passedObservables, internalObservables);

    var isDev = _.contains(['localhost',
        'pizza.waterfall.com', 'staging.waterfall.com'],
        window.location.hostname);

    var mapping = {
        defaults: _.clone(allObservables),
        computed: {
            passesList: function () {
                return _.chain(viewmodel.globals.wallets()).
                    filter(function (wallet) {
                        return wallet.status === 'PUBLISHED' ||
                            wallet.status === 'COMPLETED';
                    }).
                    sortBy(function (wallet) {
                        return wallet.name && wallet.name.toLowerCase();
                    }).value();
            },

            hasPasses: function () {
                return this.passesList().length > 0;
            }
        }
    };

    $.widget('msgme.msgme_pass_insertion', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            this.on('click', '.open-popover', '_openPopover');
            this.on('click', '.insert', '_insert');
            this.on('mouseenter', '.popover', '_onEnter');
            this.on('mouseleave', '.popover', '_onLeave');
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        },

        _onEnter: function () {
            this.element.find('.pass-insertion').addClass('remove-hover');
        },

        _onLeave: function () {
            this.element.find('.pass-insertion').removeClass('remove-hover');
        },

        _buildCodeFromSelection: function () {
            var vm = this.option('viewmodel');

            this._insert('{coupon.(' + vm.coupon + ')}');
        },

        _insert: function (e) {
            var vm = this.option('viewmodel');
            var $message = $(e.target.closest('.tab-pane')).find('.message')[0];
            var currentPosition = $message.selectionStart;
            var pass = vm.pass();
            var baseApi = isDev ? 'https://staging-wallet-api.' :
                'https://wallet-api.';
            var baseUrl = baseApi + 'waterfall.com/pass/signing/' +
                pass + '?passTag={subscriber.id}';
            var passString = '<URL:' + baseUrl + ' wallet:true>';

            if (currentPosition || currentPosition === 0) {
                var startPos = currentPosition;
                var endPos = $message.selectionEnd;

                $message.value = $message.value.substring(0, startPos) +
                    passString +
                    $message.value.substring(endPos, $message.value.length);
                $($message).change();
            }

            this._closePopover();
        },

        _openPopover: function () {
            // hide any popover that may already be open 
            $('.toolbar-popover').hide();

            this.element.find('.popover').show();
        },

        _closePopover: function () {
            this.element.find('.popover').hide();
        }
    });

    return {
        widget: $.msgme.msgme_pass_insertion,
        mapping: mapping
    };
});

