define('msgme/viewmodel/flowmodule-sportsfeed',[
    'msgme/underscore',
    'msgme/viewmodel',
    'json!widgets/shared-strings.json'
],
function (
    _,
    viewmodel,
    sharedStrings
) {
    var mapping = {
        local: {
            title: 'Sports Feed',
            league: 'MLB',
            optInCurrentTab: 'sms',
            confirmCurrentTab: 'sms',
            subscribedCurrentTab: 'sms'
        },
        params: {
            mapping: {
                defaults: {
                    listId: null,
                    optInMessage:
                        sharedStrings.message.placeholder.optInMessage,
                    optInSubject: null,
                    optInFiles: [],
                    optInAd: null,
                    optInSponsorship: null,
                    optInSMSFallback: null,
                    confirmMessage:
                        sharedStrings.message.placeholder.confirmMessage,
                    confirmSubject: null,
                    confirmFiles: [],
                    confirmAd: null,
                    confirmSponsorship: null,
                    confirmSMSFallback: null,
                    subscribedMessage:
                        sharedStrings.message.placeholder.alreadySubscribed,
                    subscribedSubject: null,
                    subscribedFiles: [],
                    subscribedAd: null,
                    subscribedSponsorship: null,
                    subscribedSMSFallback: null,
                    teamId: null,
                    messageFrequency: 'PERIOD'
                },
                subscribe: {
                    teamId: function (value, root) {
                        if (viewmodel.globals.teams() &&
                            viewmodel.globals.teams.oneById(value)) {
                            root.league(
                                viewmodel.globals.teams.oneById(value).league);
                        }
                    }
                },
                validation: {
                    listId: {
                        required: true
                    }
                }
            }
        },

        computed: {
            mlb: function () {
                return _.filter(viewmodel.globals.teams(), function (team) {
                    return team.league === 'MLB';
                });
            },
            nhl: function () {
                return _.filter(viewmodel.globals.teams(), function (team) {
                    return team.league === 'NHL';
                });
            },
            nba: function () {
                return _.filter(viewmodel.globals.teams(), function (team) {
                    return team.league === 'NBA';
                });
            },
            nfl: function () {
                return _.filter(viewmodel.globals.teams(), function (team) {
                    return team.league === 'NFL';
                });
            },
            leagueOptions: function () {
                if (this.league()) {
                    return this[this.league().toLowerCase()]();
                }
            },
            period: function () {
                if (this.league() === 'MLB') {
                    return 'inning';
                } else if (this.league() === 'NBA' || this.league() === 'NFL') {
                    return 'quarter';
                } else {
                    return 'period';
                }
            },
            listName: function () {
                var listId = this.params.listId();
                if (listId) {
                    if (viewmodel.globals.lists.oneById(listId)) {
                        return viewmodel.globals.lists.oneById(listId).name;
                    }
                }
            }
        }
    };

    return {
        mapping: mapping
    };
});

