define('msgme/viewmodel/add-metadata',[
    'msgme/ko'
], function () {
    var nextId = 0;
    var mapping = {
        local: {
            validValues: null,
            format: null,
            metadataId: null,
            value: null,
            widgetIdValue: null
        },

        computed: {
            widgetId: function () {
                var id = this.widgetIdValue();
                if (id === null) {
                    id = 'add-metadata-' + nextId++;
                    this.widgetIdValue(id);
                }
                return id;
            }
        },

        subcomputed: {
            value: {
                free: {
                    read: function () {
                        if (!this.validValues()) {
                            return this.value();
                        }
                    },
                    write: function (root, parent, value) {
                        if (!this.validValues()) {
                            this.value(value);
                        }
                    }
                },

                fixed: {
                    read: function () {
                        if (!!this.validValues()) {
                            return this.value();
                        }
                    },
                    write: function (root, parent, value) {
                        if (!!this.validValues()) {
                            this.value(value);
                        }
                    }
                }
            }
        }
    };

    return {
        mapping: mapping
    };
});

