define('widgets/metadata-value/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    '../three-widget',
    'text!./template.html'
], function(_, viewmodel, ThreeWidget, template) {
    var metadata = viewmodel.globals.metadata;
    var mapping = {
        defaults: {
            field: null,
            initialChange: true,
            validValuesNotifier: null,
            placeholder: null,
            operator: null,
            multiValue: null
        },
        subcomputed: {
            field: {
                validValues: function (root, parent) {
                    var record = metadata.oneById(parent());

                    // ensure there's a dependency on validValuesNotifier
                    root.validValuesNotifier();

                    return record ? record.validValues : null;
                }
            },
            data: {
                fixed: {
                    read: function (root, parent) {
                        return parent();
                    },
                    write: function (root, parent, value) {
                        value = value === void undefined ? null : value;

                        if (!this._locked && !!root.field.validValues() &&
                           !root.isMultiValue()) {
                            // ko.validation fires changes on observables when
                            // their validity changes, but passes the wrong
                            // value to subscribers (it passes the value of the
                            // private __valid__ observable sted the value of
                            // the observable itself).
                            //
                            // The upshot is that you have to select fixed
                            // values twice in the segmentation filter dropdown,
                            // which is a bummer. so, ignore attempts to write
                            // when we're already writing.

                            if (!((root.operator() === 'nin' ||
                                root.operator() === 'in' ||
                                root.operator() === 'all') &&
                                root.field.hasOwnProperty('validValues') &&
                                root.field.validValues())) {
                                this._locked = true;
                                parent(value);
                                this._locked = false;
                            }
                        }
                    },
                    deferEvaluation: true
                },
                free: {
                    read: function (root, parent) {
                        return parent();
                    },

                    write: function (root, parent, value) {
                        if (!root.field.validValues()) {
                            parent(value);
                        }
                    },
                    deferEvaluation: true
                },
                multi: {
                    read: function (root, parent) {
                        return parent();
                    },

                    write: function (root, parent, value) {
                        parent(value);
                    },
                    deferEvaluation: true
                }
            }
        },
        subscribe: {
            field: function (value, root) {
                // this breaks shit. i'm not sure what it's for
                /*
                if (!root.initialChange()) {
                    root.data(null);
                } else {
                    root.initialChange(false);
                }
                */

                if (root.data.isModified) {
                    _.defer(function () {
                        root.data.isModified(false);
                    });
                }

                if (root.field()) {
                    metadata.oneById(root.field()).fetch().then(function () {
                        // trigger an update to field.validValues
                        root.validValuesNotifier(_.uniqueId());
                    });
                }
            }
        },
        computed: {
            isMultiValue: function (root) {
                return ((root.operator() === 'nin' ||
                    root.operator() === 'in' || root.operator() === 'all') &&
                    root.field.hasOwnProperty('validValues') &&
                    root.field.validValues());
            }
        }
    };

    /**
     * View and change a fixed or free metadata value
     */
    $.widget('msgme.msgme_metadata_value', ThreeWidget, {
        _mapping: mapping,
        _template: template,

        _create: function () {
            var model = this.options.model;
            var field;
            var operator;
            var vm;

            if (!model) {
                field = this.options.field;
                operator = this.options.operator;


                if (!field) {
                    throw new Error('Cannot create metadata-value widget: ' +
                        'either "model" or "field" is required');
                }

                this.options.model = model = {
                    field: field,
                    operator: operator
                };
            }

            // refresh global metadata collection
            // FIXME: revisit after #234 is fixed
            metadata.refresh();

            this.on('change', 'select.multiple', 'onMultipleSelectChange');

            ThreeWidget.prototype._create.apply(this, arguments);

            vm = this.option('viewmodel');

            if (vm.operator() && vm.operator() === 'nin' ||
                vm.operator() === 'in' || vm.operator() === 'all') {

                this.option('viewmodel').operator.valueHasMutated();

                if (vm.data() && vm.data().length) {
                    this.element.find('.value.multiple').
                        val(vm.data().split(',')).trigger('change');
                }
            }

        },

        onMultipleSelectChange: function (event) {
            var value = $(event.target).val();

            if (value) {
                this.option('viewmodel').data.multi(value.join());
            }
        }
    });

    return {
        widget: $.msgme.msgme_metadata_value
    };
});

