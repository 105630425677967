define('widgets/file-preview-modal/index',[
    'msgme/underscore',
    './../three-widget',
    './../file-preview/index',
    'text!./template.html'
], function (_, ThreeWidget, filePreview, template) {
    var mapping = {
        defaults: {
            id: null,
            header: ''
        }
    };

    $.widget('msgme.msgme_file_preview_modal', ThreeWidget, {
        _mapping: mapping,

        _template: template,

        _create: function () {
            this._super.apply(this, arguments);
            this.option('viewmodel').id.
                subscribe(_.bind(this._onIdChange, this));
        },

        _instantiateModalIfNecessary: function () {
            var vm = this.option('viewmodel');

            if (!this.$modal) {
                this.$modal = this.element.find('.modal').
                    modal().
                    on('hide', vm.id.bind(vm, null));
            }
        },

        _onIdChange: function () {
            var id = this.option('viewmodel').id();

            if (id) {
                this.show();
            } else {
                this.hide();
            }
        },

        hide: function () {
            if (this.$modal) {
                this.$modal.modal('hide');
            }
        },

        show: function () {
            this._instantiateModalIfNecessary();
            this.$modal.modal('show');
        }
    });

    return {
        widget: $.msgme.msgme_file_preview_modal,
        mapping: mapping
    };
});

