define('msgme/modelcollection/filter',[
    'msgme/modelcollection',
    'msgme/viewmodel/query-filter'
], function (ModelCollection, queryFilter) {

    var mapping = queryFilter.mapping;
    var filter;

    function FilterModelCollection() {
        ModelCollection.apply(this, arguments);
    }

    FilterModelCollection.prototype = new ModelCollection();

    // don't actually instantiate anything. if we do it this way it'll be
    // easier to come back and mock up or provide hooks to clear the cached
    // copy
    return function () {
        return filter ||
            (filter = new FilterModelCollection(waterfall.filters, mapping));
    };
});

