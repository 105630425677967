define('msgme/widgets/flowmodule-texttowin',[
    'jquery',
    'msgme/ko',
    'msgme/underscore',
    'msgme/viewmodel',
    'msgme/util/format',
    'msgme/widgets/flowmodule',
    'msgme/viewmodel/flowmodule-texttowin',
    'msgme/util/api'
],
function (
    $,
    ko,
    _,
    viewmodel,
    format,
    flowmodule,
    textToWin,
    api
) {
    $.widget('msgme.msgme_flowmodule_texttowin', flowmodule, {
        _create: function () {
            var root = this.element;
            var data = this.options.data();
            var params = data.params;
            var delay;

            flowmodule.prototype._create.apply(this);

            this.on('change', 'select.has-limit', '_onHasLimitChange');

            this.on('change', 'input.tag-metadata', '_onTagMetadataChange');
            this.on('change', 'select.metadata-field',
                '_onTagMetadataFieldChange');
            this.on('change', 'select.methodology', '_onMethodologyChange');
            this._updateMetadata(params.metadataId());

            this.on('click', '.add-group', '_onAddGroupClick');
            this.on('click', '.add-group', '_onMethodologyChange');
            this.on('click', '.remove-group', '_onRemoveGroupClick');
            this.on('click', '.download-button', '_onDownloadClick');
            this.on('change', '.allow-multiple-entry', '_onMultipleEntryClick');

            if (data.metadataOptions()) {
                delay = 0;
            } else {
                delay = 300;
            }
            _.delay(_.bind(function () {
                this.element.find('.metadata-field .metadata-select').
                    selectize({
                    maxOptions: 1000,
                    options: data.metadataOptions(),
                    optgroups: data.metadataOptgroups(),
                    optgroupField: 'class',
                    labelField: 'name',
                    searchField: 'name',
                })[0].selectize.setValue(params.metadataId());
            }, this), delay);

            // initialize date time widgets
            // (this does both startDate and endDate)
            root.find('.msgme-datetime').msgme_datetime();
        },

        _onAddGroupClick: function () {
            var mapping =
                textToWin.mapping.params.mapping.winnerGroups.mapping;

            this.options.data().params.winnerGroups.
                push(ko.mapping.fromJS({}, mapping, null, this.options.data()));
            this._trigger('changed');
        },

        _onRemoveGroupClick: function (e) {
            var i = $(e.currentTarget).closest('.winning-group').index();
            this.options.data().params.winnerGroups.splice(i, 1);
            this._trigger('changed');
        },

        _onDownloadClick: function () {
            var data = this.options.data();

            window.location = window.location.origin + '/api/v2/ttw/' +
                viewmodel.flow().id() + '/content/' + data.head() +
                '/winners/export';
        },

        _onHasLimitChange: function (e) {
            var $this = $(e.currentTarget);
            var i = $this.closest('.winning-group').index();

            if ($this.val() === 'No Limit') {
                this.options.data().params.winnerGroups()[i].messageLimit(null);
            }
        },

        _updateMetadata: function (metadataId) {
            // mark the valid values dropdown busy
            var busyEl = this.element.find('select.tag-value');
            busyEl.msgme('busy', true);

            // save off handle to local observable for validValues
            var validValues = this.options.data().params.validValues;

            api.call('metadata.fetch', metadataId).
                done(function (metadata) {
                    // clear the busy indicator for the valid values dropdown
                    busyEl.msgme('busy', false);
                    validValues(metadata.validValues);
                }).
                fail(function () {
                    busyEl.msgme('busy', false);
                });
        },

        _onTagMetadataChange: function (e) {
            this.options.data().params.
                metadataId(e.currentTarget.checked ? '' : null);
            this.options.data().params.metadataId.isModified(false);
        },

        _onTagMetadataFieldChange: function () {
            var metadataId = this.options.data().params.metadataId();

            if (metadataId) {
                this._updateMetadata(metadataId);
            }
        },

        _onMethodologyChange: function () {
            var params = this.options.data().params;
            var methodology = params.methodology();

            if (methodology === 'SEQUENTIAL' || methodology === 'PACEEVENLY') {
                for (var i = 0; i < params.winnerGroups().length; i++) {
                    params.winnerGroups()[i].limit('Limit');
                }
            }
        },

        _onMultipleEntryClick: function () {
            var params = this.options.data().params;

            params.duplicateEntryNextStep(null);
            params.duplicateEntryNextStep.isModified(false);
        }
    });

    $.msgme.msgme_flowmodule_texttowin.Model = flowmodule.modelFactory({
        winnerGroups: [{}]
    }, textToWin);

    return $.msgme.msgme_flowmodule_texttowin;
});

