define('widgets/character-counter/index',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    './../three-widget',
    'json!./strings.json',
    'text!./template.html'
], function (_, ko, viewmodel, ThreeWidget, strings, template) {

    var requiredOptions = {maxNumChars: null, message: null};
    var optionalOptions = {adCopyLength: null};
    var acceptedOptions = _.extend({}, requiredOptions, optionalOptions);
    var dynamicInsertionRe = /{[^}]+}/;
    var doubleLengthCharRe = /([\^_{}[\]|€~\\])/g;
    var spanishRegex = /[èéùìòÇöñüà]/g;
    var gsmRegex = new RegExp('^[!\"#$%&\'()*+,-.\/0123456789:;<=>?@ABCDEFGHI' +
        'JKLMNOPQRSTUVWXYZ[\\]^_abcdefghijklmnopqrstuvwxyz{|}~\u00A3\u00A5' +
        '\u00D8\u00F8\u00C5\u00E5\u0394\u03A6\u0393\u039B\u03A9\u03A0\u000D' +
        '\u03A8\u03A3\u0398\u039E\u00C6\u00E6\u00DF\u00C9\u00A4\u0020\\\\' +
        '\u00A1\u00C4\u00D6\u00D1\u00DC\u00A7\u00BF\u00E4\u20AC\u000A' +
        '\u2018\u2019\u201C\u201D]*$');
    var urlRegex = new RegExp('<URL:(.*?)>', 'gi');

    var mapping = {
        defaults: _.clone(acceptedOptions),

        computed: {
            hasDynamicInsertion: function (root) {
                var strippedMsg;

                if (root.message() && root.message().match(urlRegex)) {
                    strippedMsg = root.message().replace(urlRegex, '');
                    if (strippedMsg.match(dynamicInsertionRe)) {
                        return root.message() &&
                            dynamicInsertionRe.test(root.message());
                    } else {
                        return false;
                    }
                } else if (root.message() && !root.message().match(urlRegex)){
                    return dynamicInsertionRe.test(root.message());
                } else {
                    return false;
                }
            },
            doubleLengthChars: function (root) {
                if (root.message() && doubleLengthCharRe.test(root.message())) {
                    return root.message().match(doubleLengthCharRe).length;
                } else {
                    return 0;
                }
            },
            numCharsRemaining: function (root) {
                var length = root.message() ? root.message().length : 0;
                var diff = 0;
                var isMms = root.maxNumChars() > 160;

                if (root.message() && root.message().match(urlRegex)) {
                    _.each(root.message().match(urlRegex), function (match) {
                        diff = diff - 7 + match.length;
                    });
                }

                if (root.adCopyLength()) {
                    length = length + root.adCopyLength();
                }

                if (isMms) {
                    return root.maxNumChars() - length + diff;
                } else if (root.message() && root.message().match(gsmRegex)) {
                    if (root.message().match(spanishRegex)) {
                        return 70 - length + diff;
                    } else {
                        return root.maxNumChars() - length + diff -
                            root.doubleLengthChars();
                    }
                } else if (!root.message()) {
                    return root.maxNumChars();
                } else {
                    return 70 - length + diff;
                }
            },
            isNegative: function (root) {
                return root.numCharsRemaining() < 0;
            }
        }
    };

    $.widget('msgme.msgme_character_counter', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);
            this._extractOptions();
        },

        _extractOptions: function () {
            this._checkRequiredOptions();
            this._applyAcceptedOptionsToViewmodel();
        },

        _checkRequiredOptions: function () {
            var passedOptionNames = _.union(
                _.keys(this.options),
                _.keys(this.option('model')));
            var requiredUnpassedOptions =
                _.omit(requiredOptions, passedOptionNames);

            function warnRequiredOptionNotProvided(optionVal, optionName) {
                var warningString = _.sprintf(
                    strings.warning.requiredOptionNotProvided,
                    {option: optionName});

                console.warn(warningString);
            }

            _.each(requiredUnpassedOptions, warnRequiredOptionNotProvided);
        },

        _applyAcceptedOptionsToViewmodel: function () {
            var passedOptions = this.options;
            var acceptedOptionNames = _.keys(acceptedOptions);
            var acceptedPassedOptions =
                _.pick(passedOptions, acceptedOptionNames);

            function applyOptionToViewmodel(optionVal, optionName) {
                var o = passedOptions.viewmodel[optionName];

                if (ko.isWriteableObservable(o)) {
                    passedOptions.viewmodel[optionName](optionVal);
                }
            }

            _.each(acceptedPassedOptions, applyOptionToViewmodel);
        }
    });

    return {
        widget: $.msgme.msgme_character_counter,
        mapping: mapping
    };
});

