define('widgets/coupon-insertion/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    './../three-widget',
    'json!./strings.json',
    'text!./template.html'
], function (_, viewmodel, ThreeWidget, strings, template) {
    var passedObservables = {
        isDisabled: false
    };

    var internalObservables = {
        select2DropdownCssClass: 'dynamic-insertion-dropdown',
        coupon: null
    };

    var allObservables = _.extend({}, passedObservables, internalObservables);

    var mapping = {
        defaults: _.clone(allObservables),
        computed: {
            hasCoupons: function () {
                return viewmodel.globals.coupons().length > 0;
            },
            couponList: function () {
                return _.map(viewmodel.globals.coupons(), function (coupon) {
                    return {
                        value: coupon.id,
                        text: coupon.name
                    };
                });
            },
            couponsAvailable: function () {
                if (this.coupon()) {
                    var coupon = viewmodel.globals.coupons.
                        oneById(this.coupon());

                    return coupon.available;
                }
            },
            couponsTotal: function () {
                if (this.coupon()) {
                    var coupon = viewmodel.globals.coupons.
                        oneById(this.coupon());

                    return coupon.total;
                }
            }
        }
    };

    $.widget('msgme.msgme_coupon_insertion', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            this.on('click', '.open-popover', '_openPopover');
            this.on('click', '.insert', '_insert');
            this.on('mouseenter', '.popover', '_onEnter');
            this.on('mouseleave', '.popover', '_onLeave');
        },

        _createViewModel: function () {
            var vm = ThreeWidget.prototype._createViewModel.
                apply(this, arguments);
            vm.globals = viewmodel.globals;
            return vm;
        },

        _onEnter: function () {
            this.element.find('.coupon-insertion').addClass('remove-hover');
        },

        _onLeave: function () {
            this.element.find('.coupon-insertion').removeClass('remove-hover');
        },

        _buildCodeFromSelection: function () {
            var vm = this.option('viewmodel');

            this._insert('{coupon.(' + vm.coupon + ')}');
        },

        _insert: function (e) {
            var vm = this.option('viewmodel');
            var $message = $(e.target.closest('.tab-pane')).find('.message')[0];
            var currentPosition = $message.selectionStart;
            var coupon;

            if (!vm.coupon()) {
                this._closePopover();
                return;
            } else {
                coupon = '{coupon.("' + vm.coupon() + '")}';
            }

            if (currentPosition || currentPosition === 0) {
                var startPos = currentPosition;
                var endPos = $message.selectionEnd;

                $message.value = $message.value.substring(0, startPos) +
                    coupon +
                    $message.value.substring(endPos, $message.value.length);
                $($message).change();
            }

            this._closePopover();
        },

        _openPopover: function () {
            // hide any popover that may already be open 
            $('.toolbar-popover').hide();

            this.element.find('.popover').show();
        },

        _closePopover: function () {
            this.element.find('.popover').hide();
        }
    });

    return {
        widget: $.msgme.msgme_coupon_insertion,
        mapping: mapping
    };
});

