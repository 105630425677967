define('msgme/plugins',[
    'jquery'
],
function ($) {
    var methods = {
        busy : function (options) {
            if (options === true) {
                this.addClass('busyable busy');
                if ($('.shim', this).length <= 1) {
                    this.prepend('<div class="shim"></div>');
                }
            } else if (options === false) {
                this.removeClass('busy');
                this.children('.shim').remove();
            } else {
                console.error('$.msgme.busy: unsupported option', options);
            }
        }
    };

    $.fn.msgme = function (method) {
        // Method calling logic
        if (methods[method]) {
            return methods[method].apply(this,
                Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || ! method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' + method + ' does not exist on jQuery.msgme');
        }
    };
});

