define('msgme/widgets/send-message',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/widget'
], function (_, ko, Widget) {
    $.widget('msgme.msgme_send_message', Widget, {
        _create: function () {
            Widget.prototype._create.apply(this);

            var data = this.options.data;

            this.element.find('.msgme-message-textbox').
                msgme_message_textbox({
                    data: data.sendMessage.message
                });
        }
    });

    return $.widget.msgme_send_message;
});

