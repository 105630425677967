define('widgets/three-widget',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/widgets/widget'
], function(_, ko, Widget) {
    $.widget('msgme.msgme_three_widget', Widget, {
        _template: '',

        _create: function() {
            _.bind(this._errorHandler, this);
            Widget.prototype._create.apply(this, arguments);
            this.element.html(this._template);
            this._setData();
        },

        _createViewModel: function(data, model) {
            var rawJS = $.extend(true, {data: data}, model);
            var vm = ko.mapping.fromJS(rawJS, this._mapping);
            return vm;
        },

        // the _errorHandler helper makes it easier to run stuff like this:
        // api.call(...).then(..., this._errorHandler);
        _errorHandler: function(err) {
            if (this.element) {
                this.element.trigger('error', err);
            } else {
                msgme.alert.error(err);
            }
        },

        _setData: function() {
            var data = this.option('data') || ko.observable(null);
            var vm = this._createViewModel(data, this.option('model') || {});
            var $topLevelChild = this.element.find('>');

            this.option('viewmodel', vm);

            // assume that the template has a top level node
            if ($topLevelChild.length !== 1) {
                console.warn('three_widget templates must have a single ' +
                    'top-level child');
            }

            ko.applyBindings(vm, $topLevelChild[0]);
        }
    });

    /**
     * knockout binding that creates a three widget
     *
     * any bindings in the descendent nodes are handled by the widget. note
     * that bindings on the widget's root element are still processed by the
     * original viewmodel.
     *
     * the binding can be declared in one of two ways. the first more verbose,
     * where the `valueAccessor` is declared explicitly in the binding:
     *
     *     <div data-bind="threewidget: { \
     *           widget: 'msgme_my_widget', \
     *           data: myData \
     *         }">
     *        ...
     *
     * and the second more succinct, where only the widget class is passed in
     * and the data is assumed to be the `$data` of the binding context:
     *
     *     <div data-bind="threewidget: 'msgme_my_widget'">
     *        ...
     *
     * the following parameters apply to the object returned by the
     * `valueAccessor` function.
     *
     * @param {String} widget - the class name of the ThreeWidget to
     * instantiate
     * @param {Object} data - the object passed into the widget's `data`
     * property when it is instantiated
     * @param {Object} model - the object passed into the widget's `model`
     * property when it is instantiated
     */
    ko.bindingHandlers.threewidget = {
        init: function (el, valueAccessor, allBindings, viewModel,
                  bindingContext) {

            var value = valueAccessor();
            var config = _.isString(value) ? {widget: value} : value;

            config.data = config.data || bindingContext.$data;

            if (!ko.isObservable(config.data)) {
                config.data = ko.observable(config.data);
            }

            if (!$(el).data(config.widget)) {
                $(el)[config.widget]({
                    data: config.data,
                    model: config.model
                });

                // prevent ko from processing bindings beyond this, since the
                // widget applies its own bindings
                return {controlsDescendantBindings: true};
            }
        }
    };

    return $.msgme.msgme_three_widget;
});

