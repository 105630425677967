define('msgme/viewmodel/sponsorships',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/format'
], function(_, ko, viewmodel, format) {
    var model = ko.observable(null);
    return {
        model: viewmodel.sponsorship = model,
        collection: viewmodel.sponsorships = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('sponsorships/page')
        },

        mapping: {
            defaults: {
                id: null,
                name: null,
                startDate: null,
                endDate: null,
                agency: null,
                advertiser: null,
                salesPerson: null,
                rate: null,
                notes: null
            },

            subscribe: {
                startDate: function (value, parent, root) {
                    root.startDateTime(value);
                },

                endDate: function (value, parent, root) {
                    root.endDateTime(value);
                }
            },
            computed: {
                startDateTime: {
                    read: function () {
                        if (!this.startDate()) {
                            return null;
                        } else {
                            return new Date(this.startDate());
                        }
                    },
                    write: function (parent, value) {
                        if (value) {
                            this.startDate(format.ISODate(value));
                        } else {
                            this.startDate(null);
                        }
                    },

                    deferEvaluation: true
                },

                endDateTime: {
                    read: function () {
                        if (!this.endDate()) {
                            return null;
                        } else {
                            return new Date(this.endDate());
                        }
                    },
                    write: function (parent, value) {
                        if (value) {
                            this.endDate(format.ISODate(value));
                        } else {
                            this.endDate(null);
                        }
                    },

                    deferEvaluation: true
                },

                status: function () {
                    if (this.startDateTime() && this.endDateTime()) {
                        if (this.endDateTime() > Date.create() &&
                           this.startDateTime() < Date.create()) {
                            return 'Active';
                        } else if (this.endDateTime() < Date.create()) {
                            return 'Expired';
                        } else if (this.startDateTime() > Date.create()) {
                            return 'Future';
                        }
                    } else {
                        return 'Inactive';
                    }
                }
            }
        }
    };
});

