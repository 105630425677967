define('widgets/previous-messages/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    'msgme/util/format',
    './../broadcast-sidebar/index',
    'json!./strings.json'
], function (
    _,
    viewmodel,
    format,
    broadcastSidebarWidget,
    strings
) {
    function icon() {
        return 'fa-comment';
    }

    function timestamp(broadcast) {
        return new Date(broadcast.sentAt).
            format('{MON} {d}, {year} at {h}:{mm} {Tt}');
    }

    function subject(broadcast) {
        return broadcast.content[0].segments[0].subject;
    }

    function message(broadcast) {
        return broadcast.content[0].segments[0].message;
    }

    function audienceName(broadcast) {
        return broadcast.content[0].filters[0].lists[0];
    }

    function listId(broadcast) {
        var lists = broadcast.content[0].lists;

        return lists && lists[0];
    }

    function count(broadcast) {
        var list = viewmodel.globals.lists.byId(listId(broadcast));
        var n = list && list.noOfSubscribers;
        var countString = n == null ? '' : '(' + n + ')';

        return countString;
    }

    function mediaId(broadcast) {
        return broadcast.content[0].segments[0].files[0];
    }

    function humanReadableDI(message) {
        var dynamicInsertionRegexRaw = new RegExp(
            '\\{subscriber\\.metadata\\.\\("(.*?)"\\)(' +
            '; default=".*?")?\\}', 'g');
        var messageBody = message;

        return messageBody && messageBody.replace(
            dynamicInsertionRegexRaw,
            function(match, metadatumId, defaultTextToken) {

            defaultTextToken = defaultTextToken || '';

            var metadata = viewmodel.globals.metadata();
            var metadatum = _.find(metadata, function(metadatum) {
                return metadatum.id === metadatumId;
            });

            if (!metadatum) {
                return match;
            }

            var sameNameMetadata = _.filter(metadata, function(m) {
                return m.name === metadatum.name;
            });
            var metadatumIndex = _.indexOf(
                _.pluck(sameNameMetadata, 'id'), metadatum.id);
            var metadatumIndexToken = metadatumIndex > 0 ?
                ' (' + metadatumIndex + ')' : '';

            return '{' + metadatum.name + metadatumIndexToken +
                defaultTextToken + '}';
        });
    }

    $.widget('msgme.msgme_previous_messages', broadcastSidebarWidget.widget, {
        _create: function () {
            var returnValue = this._super.apply(this, arguments);

            this.element.addClass('previous-messages');

            return returnValue;
        },

        _createViewModel: function () {
            var vm = this._super.apply(this, arguments);

            vm.title(strings.title);
            vm.noMessages(strings.noMessages);

            return vm;
        },

        fetch: function () {
            var vm = this.option('viewmodel');
            var query = {
                size: 3,
                startDate: Date.create().rewind({months: 3}).toISOString(),
                count: false
            };
            var config = {
                url: waterfall.broadcasts.config.url + '/summaries'
            };
            var promise = waterfall.broadcasts.fetch(query, config).
                then(function (pastBroadcasts) {
                    var sorted = _.sortBy(pastBroadcasts, 'sentAt');

                    vm.broadcasts(_.map(sorted, function (broadcast) {
                        return {
                            id: broadcast.id,
                            icon: icon(broadcast),
                            timestamp: timestamp(broadcast),
                            audienceName: audienceName(broadcast),
                            count: count(broadcast),
                            subject: subject(broadcast),
                            message: humanReadableDI(message(broadcast)),
                            media: mediaId(broadcast)
                        };
                    }));
                });

            vm.request(promise);

            return promise;
        }
    });

    return {
        widget: $.msgme.msgme_previous_messages,
        mapping: broadcastSidebarWidget.mapping
    };
});

