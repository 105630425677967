define('msgme/viewmodel/campaign-activity',[
    'msgme/ko',
    'msgme/viewmodel'
], function(ko, viewmodel) {
    var model = ko.observable(null);

    return {
        model: viewmodel.campaignActivity = model,
        mapping: {
            defaults: {
                data: null,
                newMessage: null,
                newMessageCache: null,
                hasMoreMessages: true,
                moreMessagesPromise: null,
                idle: 0
            }
        }
    };
});

