define('msgme/ko',[
    'msgme/underscore',
    'lib/knockout',
    'lib/knockout.mapping',
    'lib/knockout.validation',
    'msgme/ko/extenders',
    'msgme/ko/mapping/validation',
    'msgme/ko/mapping/subscribe',
    'msgme/ko/mapping',
    'msgme/ko/observableDate',
    'msgme/ko/ignore'
], function (_, ko, kom, kov, extenders) {
    ko.mapping = kom;
    ko.validation = kov;

    /*
     * Aggregate validation of all the validated elements within an array
     * Parameter: true|false
     * Example
     *
     * viewModel = {
     *    person: ko.observableArray([{
     *       name: ko.observable().extend({ required: true }),
     *       age: ko.observable().extend({ min: 0, max: 120 })
     *    }, {
     *       name: ko.observable().extend({ required: true }),
     *       age: ko.observable().extend({ min:0, max:120 })
     *    }]).extend({ validArray: true })
     * };
     *
     * source:
     * https://github.com/Knockout-Contrib/
     *   Knockout-Validation/wiki/User-Contributed-Rules
     */
    ko.validation.rules.validArray = {
        validator: function (arr, bool) {
            if (!_.isArray(arr)) {
                throw new Error('[validArray] Parameter must be an array');
            }
            return bool === (_.filter(arr, function (element) {
                return ko.validation.group(
                    ko.utils.unwrapObservable(element))().length !== 0;
            }).length === 0);
        },
        message: 'Every element in the array must validate to "{0}"'
    };

    ko.validation.init({
        insertMessages: false,
        decorateElement: true,
        errorClass: 'invalid',
        errorsAsTitle: true,
        grouping: {
            deep: false
        }
    });

    _.extend(ko.extenders, extenders);

    // make ko available globally so the devtool plugin works
    window.ko = ko;

    return ko;
});

