define('msgme/viewmodel/sweep',[
    'msgme/underscore',
    'msgme/ko',
    'msgme/viewmodel',
    'msgme/util/api',
    'msgme/util/format',
    'json!widgets/shared-strings.json',
], function(_, ko, viewmodel, api, format, strings) {
    var model = ko.observable(null);
    model.error = ko.observable(false);

    function sendWinnerMessage(params) {
        var payload = {
            msisdns: params.selectedChoices()
        };
        var message = params.message();
        var subject = params.subject();
        var files = params.files();
        var smsFallback = params.smsFallback();

        var isNoMessage = !message && !subject && !files;
        var isMMS = !isNoMessage && (subject || files);
        var isSMS = !isNoMessage && !isMMS && message;

        if (isNoMessage) {
            payload.message = '';
        } else if (isSMS || isMMS) {
            payload.message = message;
            payload.subject = subject;
            payload.files = files;
            payload.smsFallback = smsFallback;
        } else {
            console.warn(strings.unknownMessageType);
        }

        api.call('messaging.send', payload).
            done(function () {
                msgme.alert.success('Message has been sent to winners');
            }).
            fail(api.getRequestFailureFn(null, 'messaging.send', payload));
    }

    return {
        model: viewmodel.sweep = model,
        collection: viewmodel.sweeps = {
            rows: ko.observableArray(),
            pageIndex: ko.observable(-1),
            pageCount: ko.observable(-1),
            pageSize: ko.observable(15),
            links: ko.observableArray(),
            url: ko.observable('sweeps/page')
        },

        mapping: {
            local: {
                name: null,
                noOfWinners: null,
                winnerGroupsPromise: null
            },

            defaults: {
                id: null,
                keywords: [],
                winnerGroups: [],
                headNode: {
                    startDate: null,
                    endDate: null
                }
            },

            winnerGroups: {
                mapping: {
                    local: {
                        message: null,
                        subject: null,
                        files: [],
                        currentTab: null,
                        sendMessage: function (val) {
                            sendWinnerMessage(val);
                        },
                        selectionDate: null,
                        smsFallback: null
                    },

                    defaults: {
                        id: null,
                        name: null,
                        winners: [],
                        selectedChoices: []
                    },

                    computed: {
                        formattedSelectionDate: function () {
                            return format.uiDate(this.selectionDate());
                        },

                        selectAll: {
                            read: function () {
                                return this.selectedChoices().length ===
                                    this.winners().length;
                            },
                            
                            write: function () {
                                var allSelected = _.map(this.winners(),
                                    function (winner) {
                                        return winner.msisdn();
                                    });

                                this.selectedChoices(
                                    this.selectedChoices().length ===
                                    this.winners().length ? [] :
                                    allSelected);
                            }
                        }
                    },

                    winners: {
                        mapping: {
                            defaults: {
                                entryDate: null,
                                msisdn: null
                            },

                            computed: {
                                formattedEntryDate: function () {
                                    return format.uiDate(this.entryDate());
                                },

                                phoneNo: function () {
                                    return format.phoneNo(this.msisdn());
                                }
                            }
                        }
                    }
                }
            },

            computed: {
                keywordList: function () {
                    var list = '';
                    var keywords = this.keywords();

                    _.each(keywords, function (keyword, index) {
                        list = index !== keywords.length - 1 ?
                            list + keyword.name() + ', ' :
                            list + keyword.name();
                    });

                    return list;
                },
            
                duration: function () {
                    return format.uiDate(this.headNode.startDate()) + ' to ' +
                        format.uiDate(this.headNode.endDate());
                }
            },
            
            validation: {
                name: {
                    required: true
                },
                noOfWinners: {
                    required: true
                }
            }
        }
    };
});

