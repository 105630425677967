define('widgets/subscriber-search/index',[
    'msgme/underscore',
    'msgme/viewmodel',
    'msgme/util/format',
    'msgme/path',
    './../three-widget',
    'json!./strings.json',
    'text!./template.html',
    'json!widgets/shared-strings.json'
], function (
    _,
    viewmodel,
    format,
    path,
    ThreeWidget,
    strings,
    template,
    sharedStrings
) {

    var passedObservableDefaults = {};

    var internalObservableDefaults = {
        searchQuery: '',
        subscriberResults: []
    };

    var allObservableDefaults =
        _.extend({}, passedObservableDefaults, internalObservableDefaults);

    function subscriberProfileUrl(subscriberOrMobileNumber) {
        var isMobileNumber = _.isString(subscriberOrMobileNumber);
        var mobileNumber = (isMobileNumber ? subscriberOrMobileNumber :
            subscriberOrMobileNumber.mobileNumber);

        return sharedStrings.sitemap.subscriber.url + '/' + mobileNumber;
    }

    function visitSubscriberProfile(subscriberOrMobileNumber) {
        var profileUrl = subscriberProfileUrl(subscriberOrMobileNumber);

        path.history.replaceState(null, null, profileUrl);
    }

    var mapping = {
        defaults: _.clone(allObservableDefaults),

        computed: {
            subscriberResultsFormatted: function (root) {
                function addDisplayFields(subscriber) {
                    var displayFields = {
                        mobileNumberFormatted:
                            format.phoneNo(subscriber.mobileNumber),
                        profileUrl: subscriberProfileUrl(subscriber)
                    };

                    return _.extend({}, subscriber, displayFields);
                }

                return root.subscriberResults().map(addDisplayFields);
            }
        }
    };

    $.widget('msgme.msgme_subscriber_search', ThreeWidget, {
        _template: template,

        _mapping: mapping,

        _create: function () {
            ThreeWidget.prototype._create.apply(this, arguments);

            var vm = this.options.viewmodel;

            vm.searchQuery.subscribe(
                _.bind(this._performSearchOrLegacySearch, this));
            vm.subscriberResults.subscribe(
                _.bind(this._visitSubscriberProfileIfOnly1Result, this));
        },

        _performSearchOrLegacySearch: function () {
            var profileMetadata =
                viewmodel.globals.metadata.allByScope('PROFILE');

            if (!_.isEmpty(profileMetadata)) { return this._performSearch(); }
            return this._performLegacySearch();
        },

        _performSearch: function () {
            var widget = this;
            var vm = this.options.viewmodel;
            var searchQuery = vm.searchQuery();

            function setLoadingIndicator(bool) {
                widget.element.msgme('busy', bool);
            }

            function updateSubscriberResults(newSubscriberResults) {
                vm.subscriberResults(newSubscriberResults);
                setLoadingIndicator(false);
            }

            setLoadingIndicator(true);
            waterfall.subscribers.v2.search(searchQuery).
                then(updateSubscriberResults);
        },

        _performLegacySearch: function () {
            var vm = this.options.viewmodel;
            var hopefullyMobileNumber = vm.searchQuery();

            visitSubscriberProfile(hopefullyMobileNumber);
        },

        _visitSubscriberProfileIfOnly1Result: function () {
            var vm = this.options.viewmodel;
            var subscriberResults = vm.subscriberResults();

            function visitLoneSubscribersProfile() {
                var loneSubscriber = subscriberResults[0];
                visitSubscriberProfile(loneSubscriber);
            }

            if (subscriberResults.length === 1) {
                visitLoneSubscribersProfile();
            }
        },

        searchOn: function (searchQuery) {
            var vm = this.options.viewmodel;

            if (vm.searchQuery() === searchQuery) {
                vm.searchQuery.valueHasMutated();
            } else {
                vm.searchQuery(searchQuery);
            }
        }
    });

    return {
        widget: $.msgme.msgme_subscriber_search,
        mapping: mapping
    };
});

